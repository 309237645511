

export const companiesData = [

  /////////////////////////////////////// FISH-NET //////////////////////////////////////////////////////////
  {
    name: 'FISH-NET', // Кожанова
    lines: [
      {
        coordinates: [
          [42.328423, 69.570603], // координаты начальной точки
          [42.334024, 69.587365],
          [42.333745, 69.588892], // координаты второй точки
        ],
        color: '#0000FF', // Красный цвет для линии 1 компании A
      },
      {
        coordinates: [
          [42.331884, 69.578441], // Есениязова
          [42.334779, 69.586875],
          [42.336445, 69.587613]

        ],
        color: '#0000FF', // Зеленый цвет для линии 2 компании A
      },
      {
        coordinates: [
          [42.331439, 69.586151], // Аманкулова
          [42.338949, 69.582102] // КОНЕЦ
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.333460, 69.580977], // Искры
          [42.334555, 69.584403],
          [42.335140, 69.586513],
          [42.334721, 69.588425] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.330725, 69.582497], // Байсеитова
          [42.331889, 69.585852] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.331672, 69.581969], // Никитина
          [42.332812, 69.585356] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.331193, 69.582210], // Панфилова
          [42.332566, 69.586470] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.328690, 69.583294], // Фестивальная
          [42.327800, 69.580563] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.328372, 69.580965], // Фестивальный переулок
          [42.328858, 69.582413] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.329428, 69.582920], // Бокина
          [42.328538, 69.580221] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.335318, 69.589505], //Темир Казык 
          [42.336023, 69.586607],
          [42.333111, 69.577686],
          [42.332935, 69.577722],
          [42.331255, 69.572342],
          [42.330824, 69.571388],
          [42.330539, 69.570569],
          [42.330366, 69.570241],
          [42.330075, 69.569845],
          [42.329200, 69.568984] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.335318, 69.589505], // Лескова
          [42.336010, 69.586612],
          [42.336642, 69.586824],
          [42.336714, 69.586472],
          [42.336702, 69.586320],
          [42.334644, 69.580209],
          [42.334698, 69.580144],
          [42.334299, 69.578977],
          [42.334252, 69.578527] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.336605, 69.590076], // Аманжолова
          [42.337427, 69.586616],
          [42.337427, 69.586352],
          [42.335573, 69.580608],
          [42.335188, 69.579840],
          [42.334806, 69.578772] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.337944, 69.587309], // Лобачевского
          [42.338166, 69.586438],
          [42.338160, 69.586286],
          [42.337093, 69.583099] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.337917, 69.590624], // Байдибек би
          [42.338889, 69.586452],
          [42.338871, 69.586175],
          [42.336491, 69.579218],
          [42.336455, 69.578822],
          [42.331472, 69.564383] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.339178, 69.591282], // Керимше Адильбекова
          [42.340398, 69.586344],
          [42.340262, 69.585690],
          [42.337805, 69.578606] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.341144, 69.589572], // Сырдарьинская
          [42.340490, 69.589287],
          [42.340113, 69.590774],
          [42.340021, 69.590779],
          [42.339350, 69.590479] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.336045, 69.586637], // Народовольцев
          [42.344061, 69.590052] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.339101, 69.588777], // Коримше
          [42.338760, 69.590156] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.338902, 69.586733], // Курский переулок
          [42.341577, 69.587883] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.340305, 69.586732], // Харьковская
          [42.339243, 69.586274] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.339958, 69.584808], // 9 Января
          [42.338654, 69.585533] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.339657, 69.583945], // Динамовский переулок
          [42.339050, 69.584284],
          [42.339007, 69.584283],
          [42.338985, 69.584261],
          [42.338681, 69.583364],
          [42.338681, 69.583312],
          [42.338698, 69.583265],
          [42.339295, 69.582902] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.336803, 69.580102],
          [42.337457, 69.579720], // Утемисова
          [42.338044, 69.581510] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.338077, 69.579372], // Утемисова
          [42.337454, 69.579720] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.346729, 69.581192], // Тажибаева
          [42.345418, 69.582185],
          [42.342217, 69.585576],
          [42.342096, 69.585782],
          [42.342040, 69.585933],
          [42.340593, 69.591978] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.341328, 69.592332], // Сары-Агашская
          [42.342966, 69.585599],
          [42.342969, 69.585440],
          [42.342943, 69.585354],
          [42.342741, 69.585036] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.341985, 69.592629], // Райымбека Ахметова
          [42.343543, 69.586405],
          [42.343571, 69.586324],
          [42.343611, 69.586273],
          [42.343657, 69.586232] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.342666, 69.592924], // Жуманова
          [42.343956, 69.587753] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.343358, 69.593276], // Аккараган
          [42.344089, 69.590050],
          [42.344330, 69.589727] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.344022, 69.593560], // Юннатов
          [42.344664, 69.590850] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.345080, 69.594201], // Шынар
          [42.345084, 69.593697],
          [42.344706, 69.590975],
          [42.344620, 69.590584],
          [42.344343, 69.589741],
          [42.344353, 69.589409],
          [42.343542, 69.585773],
          [42.343516, 69.585517] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.345753, 69.580787], // Постышева
          [42.344925, 69.581366],
          [42.344000, 69.582068],
          [42.343497, 69.582714],
          [42.341405, 69.584886],
          [42.340879, 69.585348],
          [42.339188, 69.586222] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.340016, 69.584977], // Черкасова
          [42.340217, 69.584821],
          [42.343665, 69.581083],
          [42.343969, 69.580795],
          [42.344265, 69.580581],
          [42.345211, 69.579995] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.339835, 69.584263], // Джалиля
          [42.343533, 69.580262],
          [42.343961, 69.579854],
          [42.344793, 69.579258] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.344588, 69.578427], // Юлаева
          [42.343843, 69.579075],
          [42.342311, 69.580555],
          [42.341761, 69.581174],
          [42.341520, 69.581497],
          [42.339572, 69.583549] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.339290, 69.582905], // Кашурникова
          [42.341433, 69.580589],
          [42.343436, 69.578539],
          [42.344346, 69.577716],
          [42.344641, 69.577385],
          [42.344733, 69.577246],
          [42.344869, 69.577089],
          [42.345492, 69.576533],
          [42.346742, 69.575272],
          [42.350169, 69.572043] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.344592, 69.576813], // Бейбарыс Султана
          [42.344191, 69.577016],
          [42.343688, 69.577426],
          [42.341869, 69.579188],
          [42.340916, 69.580252] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.339071, 69.577233], // Бейбарыс Султана
          [42.340506, 69.579642],
          [42.341247, 69.580792] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.340507, 69.579640], // Шиели
          [42.341589, 69.578453],
          [42.343033, 69.577048],
          [42.343389, 69.576760],
          [42.343732, 69.576525],
          [42.344167, 69.576283] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.340151, 69.579042], // Алмалы
          [42.342521, 69.576602],
          [42.342725, 69.576413],
          [42.343668, 69.575745],
          [42.343887, 69.575644],
          [42.344343, 69.575499] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.339802, 69.578456], // Шабдалы
          [42.341713, 69.576416],
          [42.342123, 69.575979],
          [42.342344, 69.575770],
          [42.342902, 69.575380],
          [42.343642, 69.574937],
          [42.343879, 69.574825],
          [42.344207, 69.574719],
          [42.344589, 69.574555] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.339444, 69.577865], // Жангакты
          [42.338075, 69.579376] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.340664, 69.581419], // Шардаринский переулок
          [42.340502, 69.580979],
          [42.340267, 69.580244] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.340227, 69.581891], // Меложайкова
          [42.339568, 69.579925] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.339772, 69.582383], // Маресьева
          [42.338829, 69.579607] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.339774, 69.579651], // 
          [42.339361, 69.578934] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.340875, 69.585351], // Динамовский переулок 
          [42.341108, 69.586021],
          [42.341134, 69.586123],
          [42.341142, 69.586188],
          [42.341135, 69.586285],
          [42.340873, 69.587567] // КОНЕЦ
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.345080, 69.594201], // Казиева
          [42.345376, 69.594743],
          [42.345400, 69.594800],
          [42.345406, 69.594858],
          [42.345396, 69.594996],
          [42.345385, 69.595078], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.346033, 69.595724], // 
          [42.345587, 69.595248],
          [42.345385, 69.595078], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.346033, 69.595724], // Утепова
          [42.346050, 69.595768],
          [42.346052, 69.595806],
          [42.345986, 69.596069],
          [42.345959, 69.596216],
          [42.345918, 69.596674],
          [42.345893, 69.596914],
          [42.344741, 69.601857], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.346697, 69.597611], // Бекбосынова
          [42.345715, 69.601899], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.345385, 69.595078], // Каныбекова
          [42.343859, 69.601330], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.344708, 69.594072], // Тогысбаева
          [42.343132, 69.600610], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.343947, 69.593647], // Утеген батыр
          [42.343455, 69.595736],
          [42.342551, 69.599336], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347113, 69.600203], // Рыскулбекова
          [42.342793, 69.598301],
          [42.342304, 69.597982],
          [42.341481, 69.597500],
          [42.341052, 69.597335], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.342124, 69.594766], // Зертас
          [42.341666, 69.596588],
          [42.341647, 69.596640],
          [42.341620, 69.596669],
          [42.341574, 69.596668],
          [42.341154, 69.596494], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.341662, 69.594291], // С. Жандосова
          [42.340987, 69.597215], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.341562, 69.594720], // Кенисбека Елшибекова
          [42.338738, 69.593564], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.341315, 69.595794], // Баянтау
          [42.339928, 69.595251],
          [42.338642, 69.594719],
          [42.338568, 69.594708], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.338428, 69.596016], // Керимше Адильбекова
          [42.338570, 69.594677],
          [42.338735, 69.593571],
          [42.338919, 69.592526], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.346624, 69.645550], // Дружбы
          [42.346720, 69.645765],
          [42.346726, 69.645798],
          [42.346720, 69.645833],
          [42.346317, 69.646941], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.346151, 69.645509], // Акшуак
          [42.346008, 69.645719],
          [42.345914, 69.645911],
          [42.345125, 69.648043],
          [42.344546, 69.647632], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.344681, 69.646678], // Самурык
          [42.344761, 69.646854],
          [42.344775, 69.646902],
          [42.344778, 69.646948],
          [42.344772, 69.647002],
          [42.344756, 69.647064],
          [42.342640, 69.652723], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.344434, 69.649806], // Самурык
          [42.343877, 69.649414], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.343785, 69.647333], // Жалын
          [42.343812, 69.647447],
          [42.343818, 69.647499],
          [42.343817, 69.647558],
          [42.343809, 69.647620],
          [42.343787, 69.647694],
          [42.341407, 69.654021], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.343300, 69.648991], // Жалын
          [42.343878, 69.649415], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.342652, 69.648431], // Шапагат\
          [42.342676, 69.648545],
          [42.342684, 69.648628],
          [42.342686, 69.648695],
          [42.342679, 69.648753],
          [42.342669, 69.648799],
          [42.340923, 69.653573], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.341685, 69.649189], // Шапагат
          [42.341706, 69.649241],
          [42.341706, 69.649319],
          [42.341698, 69.649368],
          [42.341573, 69.649668],
          [42.341304, 69.650405],
          [42.341131, 69.650798], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.337092, 69.658544], // Арна
          [42.337347, 69.658289],
          [42.337582, 69.657899],
          [42.337796, 69.657455],
          [42.337963, 69.657224],
          [42.338182, 69.657000],
          [42.338796, 69.656280],
          [42.339142, 69.655846],
          [42.339207, 69.655717],
          [42.339247, 69.655610],
          [42.339435, 69.655268],
          [42.339526, 69.655039],
          [42.339587, 69.654800],
          [42.339701, 69.654559],
          [42.339909, 69.654278],
          [42.340131, 69.654106],
          [42.340183, 69.654050],
          [42.340206, 69.653992],
          [42.340213, 69.653940],
          [42.340211, 69.653868],
          [42.340180, 69.653747],
          [42.340114, 69.653553],
          [42.339986, 69.653238],
          [42.339571, 69.652311],
          [42.339374, 69.651810],
          [42.339301, 69.651648],
          [42.339233, 69.651543],
          [42.338951, 69.651219],
          [42.338876, 69.651088], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.335623, 69.650343], // Кокбулак
          [42.338542, 69.654651],
          [42.338649, 69.654756],
          [42.338729, 69.654852],
          [42.338801, 69.655009], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.337555, 69.653210], // Кокбулак-Жана Жол
          [42.338198, 69.652796], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.338992, 69.653682], // Жана жол
          [42.337103, 69.654617], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.339349, 69.654689], // Дружбы
          [42.338800, 69.655013],
          [42.338663, 69.655077],
          [42.337525, 69.655647], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.335679, 69.651780], // микрорайон Мирас
          [42.335868, 69.651967],
          [42.335989, 69.652129],
          [42.336071, 69.652292],
          [42.336307, 69.652936],
          [42.336682, 69.653827],
          [42.336792, 69.654175], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.337652, 69.656773], // Арна- микрорайон Мирас
          [42.337977, 69.657208], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.361386, 69.601020], //Ермекбаева
          [42.358506, 69.613546], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360539, 69.600670], // Анар Аже
          [42.357648, 69.613129], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.359683, 69.600320], // Мырзабекова
          [42.356805, 69.612764], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.358715, 69.599912], //Балтабая Нурлыбекова
          [42.356030, 69.611871], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357305, 69.601579], //Сеитова
          [42.355783, 69.608319], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356959, 69.599133], //Жабай-ата
          [42.355115, 69.607054], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356109, 69.598787], //Шакирова
          [42.355011, 69.603428], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355132, 69.598402], //Малхарова
          [42.354060, 69.602390], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.361643, 69.604454], //Салтанатты
          [42.358102, 69.603043], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360919, 69.607590], //Умит
          [42.357398, 69.606004], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360228, 69.610352], //Байтурсынова
          [42.355210, 69.608246], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.359387, 69.614007], //Монке би
          [42.356231, 69.612649], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.362278, 69.601277], //Казиева
          [42.349142, 69.595694], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357902, 69.581529], //Мейирбекова
          [42.353188, 69.601744], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.358572, 69.582588], //Жана коныс
          [42.358061, 69.585223], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356266, 69.584584], //Еркиндык
          [42.357035, 69.581188], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356558, 69.579255], //Нуршуак
          [42.355423, 69.584080], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354927, 69.586353], //Жанбосынова
          [42.353868, 69.590878], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357601, 69.586830], //Отемиса Абдраимулы
          [42.354279, 69.585374], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353516, 69.592592], //Сулейменова
          [42.351744, 69.600102], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355811, 69.594546], //Бекмурат Батыра
          [42.353331, 69.593555], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355815, 69.578524], //Ырысбак
          [42.354520, 69.583953], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354445, 69.584301], //Тайманова
          [42.350861, 69.599544], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350039, 69.599196], //Сарсенбаева
          [42.354992, 69.577930], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352610, 69.576226], //Бектаева
          [42.354138, 69.576893],
          [42.354203, 69.576950],
          [42.354222, 69.576995],
          [42.354232, 69.577057],
          [42.354221, 69.577129],
          [42.349082, 69.599040], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352880, 69.579319], //Еркобек
          [42.351659, 69.578828], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357059, 69.588845], //Хайруллина
          [42.349365, 69.594087], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356181, 69.590492], //
          [42.349344, 69.595142], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347813, 69.594958], //Райымбека Абсейтова
          [42.349693, 69.586972], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350161, 69.584848], //
          [42.349693, 69.586972], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.349779, 69.582372], //Дустик
          [42.349230, 69.584776], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350838, 69.586071], //
          [42.348594, 69.585339], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348936, 69.582044], //Құрасбек Тыныбеков
          [42.348289, 69.584905], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350567, 69.587262], //Серикбаева
          [42.347424, 69.585883], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.349350, 69.587984], //Жас ел
          [42.347162, 69.587052], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350038, 69.589555], //З. Ескендирова
          [42.346868, 69.588208], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348810, 69.590344], //Каратаева
          [42.346317, 69.589271], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.349473, 69.591902], //Мухамеджана Танышбаева
          [42.346197, 69.590481], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348231, 69.592622], //Жаркент
          [42.345935, 69.591673], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347978, 69.593792], //Түпқараған
          [42.345897, 69.592911], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.345794, 69.592454], //С. Жандосова
          [42.348001, 69.581721], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.346677, 69.588716], //
          [42.348200, 69.581777], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352149, 69.576179], //Бижана Адиходжаева
          [42.350806, 69.582147], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351510, 69.578774], //Отарбаева
          [42.350583, 69.578345],
          [42.350547, 69.578309],
          [42.348999, 69.575277], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351249, 69.579932], //Кулыншак
          [42.350113, 69.579466],
          [42.350040, 69.579414],
          [42.348233, 69.576001], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350997, 69.581079], //Калменова
          [42.349446, 69.580385],
          [42.349378, 69.580293],
          [42.347509, 69.576716], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348661, 69.581187], //Юлаева
          [42.346771, 69.577352], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350911, 69.573714], //Юлаева
          [42.346780, 69.577330], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347961, 69.581385], //Жандосова
          [42.343842, 69.572186], // 
        ],
        color: '#0000FF'
      },
      // {
      //   coordinates: [
      //     [42.350084, 69.572102], //Кашурникова
      //     [42.345579, 69.576475], // 
      //   ],
      //   color: '#0000FF'
      // },
      {
        coordinates: [
          [42.346730, 69.575197], //Калменова
          [42.344682, 69.571308], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347437, 69.574469], //Кулыншак
          [42.345439, 69.570628], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348143, 69.573798], //Еркобек
          [42.346144, 69.569968], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.349555, 69.571103], //А. Розыбакиева
          [42.347754, 69.572864], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347249, 69.571844], //Жасөркен
          [42.349014, 69.570194], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.346718, 69.570890], //Куршим
          [42.348664, 69.569048], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348320, 69.567980], //Манас
          [42.343318, 69.569688], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347872, 69.566750], //Мырзаева
          [42.342399, 69.569942], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347556, 69.565887], //
          [42.345748, 69.567093], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347191, 69.564916], //Шилменбетова
          [42.342094, 69.569182], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.342899, 69.567706], //Западная подстанция
          [42.341765, 69.568719], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.342235, 69.569967], //
          [42.341026, 69.570729], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.341169, 69.573137], //
          [42.342316, 69.571868], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.342258, 69.571480], //
          [42.342434, 69.572165], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.409586, 69.615062], // 
          [42.409061, 69.614986], //
          [42.409024, 69.614986],
          [42.408969, 69.615001],
          [42.408893, 69.615071],
          [42.408852, 69.615126],
          [42.408832, 69.615209],
          [42.408825, 69.615268],
          [42.408818, 69.615752],
          [42.408821, 69.615808],
          [42.408829, 69.615853],
          [42.408905, 69.616104],
          [42.408908, 69.616116],
          [42.408860, 69.616956],
          [42.408864, 69.617037],
          [42.408878, 69.617111],
          [42.408894, 69.617154],
          [42.409021, 69.617337],
          [42.409073, 69.617359],
          [42.409366, 69.617375],
          [42.409426, 69.617342],
          [42.409607, 69.617367],
          [42.409640, 69.617387],
          [42.409784, 69.617620],
          [42.409793, 69.617643],
          [42.409795, 69.617667],
          [42.409755, 69.618282],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.408152, 69.614002], // 
          [42.402499, 69.613343], //
          [42.402277, 69.619562],
          [42.408217, 69.620478],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363842, 69.600519], // Корикты
          [42.363570, 69.601817], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.358071, 69.585660], // 
          [42.355093, 69.598263], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.358053, 69.585743], // 
          [42.358225, 69.585815], //
          [42.358327, 69.585901],
          [42.358510, 69.585975],
          [42.358650, 69.585987],
          [42.359013, 69.586148], //Шакирова
          [42.358252, 69.589531], //1
          [42.357575, 69.592549],
          [42.356959, 69.595060],
          [42.356128, 69.598629],
          [42.356122, 69.598690],
          [42.356117, 69.598736],
          [42.356109, 69.598785],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.359013, 69.586148], // 
          [42.359779, 69.586467], //
          [42.359489, 69.587575],
          [42.359416, 69.587665],
          [42.359372, 69.587774],
          [42.359171, 69.588592],
          [42.359158, 69.588714],
          [42.359174, 69.588767],
          [42.359244, 69.588831],
          [42.359362, 69.588892],
          [42.360192, 69.589199], //Сеитова
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.359489, 69.587575], // 
          [42.359599, 69.587670], //
          [42.361932, 69.588739],
          
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360192, 69.589199], // 
          [42.360665, 69.589350], //
          [42.360705, 69.589350],
          [42.360846, 69.589266],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360192, 69.589199], // 
          [42.359979, 69.590260], //Толепбергенова
          [42.359292, 69.593279], //Манат кыз
          [42.358689, 69.595791], //Бекмурат батыр
          [42.357828, 69.599423], //Казиева
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.361402, 69.589228], // 
          [42.361296, 69.589314], //
          [42.361247, 69.589385],
          [42.361207, 69.589470],
          [42.360925, 69.590623],
          [42.360195, 69.593670],
          [42.359620, 69.596197],
          [42.358750, 69.599735],
          [42.358735, 69.599793],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.359779, 69.586467], // 
          [42.360938, 69.586966], //
          [42.361083, 69.587076],
          [42.361294, 69.587154],
          [42.361413, 69.587172],
          [42.362643, 69.587706],
          [42.360565, 69.596599],
          [42.359714, 69.600194],
          
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.359237, 69.589420], // 
          [42.359132, 69.589900], //
          [42.358423, 69.592908],
          [42.357847, 69.595433],
          [42.356980, 69.599062],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363453, 69.588054], // 
          [42.363139, 69.589605], //
          [42.361994, 69.594450],
          [42.360558, 69.600541],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363453, 69.588054], // 
          [42.364340, 69.588443], //
          [42.363459, 69.592267],
          [42.362869, 69.594805],
          [42.362281, 69.597341],
          [42.361420, 69.600899],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363146, 69.597722], // 
          [42.359620, 69.596198], //
          [42.356036, 69.594651],
          [42.355955, 69.594622],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356563, 69.592129], // 
          [42.357574, 69.592550], //
          [42.360194, 69.593669],
          [42.363749, 69.595162],
        ],
        color: '#0000FF'
      },
      ///////////////Наурыз/////////////////////////
      {
        coordinates: [
          [42.356183, 69.616334], //
          [42.356242, 69.616125], // 
          [42.356252, 69.615965],
          [42.356235, 69.615751],
          [42.355928, 69.614201],
          [42.355525, 69.612206],
          [42.355193, 69.610534], 
          [42.355122, 69.610425], // Жанкент
          [42.354932, 69.609241], //Озат
          [42.354735, 69.608236],
          [42.354120, 69.605191], //Бабатай
          [42.353839, 69.603617],
          [42.353780, 69.603394],
          [42.353748, 69.603322],
          [42.353705, 69.603256],
          [42.352926, 69.602509],
          [42.352165, 69.601791], // Жана арка
          [42.351914, 69.601506],
          [42.351797, 69.601392],
          [42.351536, 69.601212], //Туякбаева
          [42.350825, 69.600900], //Даулетулы
          [42.350115, 69.600597], //Маргулан
          [42.348747, 69.600041], //Сауран
          [42.347621, 69.604933],
          [42.347543, 69.605143],
          [42.347310, 69.606213],
          [42.347312, 69.606329],
          [42.349330, 69.607210],
          [42.350825, 69.600900],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355928, 69.614201], //
          [42.355124, 69.613731], // 
          [42.354468, 69.613292],
          [42.353244, 69.612435],
          [42.353164, 69.612363],
          [42.353102, 69.612264],
          [42.353080, 69.612149],
          [42.353125, 69.610190],
          [42.353111, 69.609753],
          [42.353095, 69.609631],
          [42.353060, 69.609326],
          [42.353063, 69.608564],
          [42.353164, 69.608592],
          [42.354110, 69.608995],
          [42.354820, 69.609248],
          [42.354932, 69.609241],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355122, 69.610425], //
          [42.355128, 69.610532], //
          [42.355122, 69.610604], 
          [42.354075, 69.614951],
          [42.353971, 69.615260],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353125, 69.610190], //
          [42.354327, 69.610679], //
          [42.353809, 69.612826], 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355524, 69.612203],
          [42.355497, 69.612247],
          [42.355168, 69.613588],
          [],
          [42.355124, 69.613731], //
          [42.354728, 69.615259], // 
          [42.354676, 69.615286],
          [42.354458, 69.615205],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355759, 69.614104], //
          [42.355357, 69.615765], //
          [42.355043, 69.615593], 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355122, 69.610425], //
          [42.355058, 69.610443], // 
          [42.354975, 69.610438],
          [42.353094, 69.609629],
          [42.352932, 69.609560],
          [42.351004, 69.608726],
          [42.349921, 69.608269],
          [42.349698, 69.609192],
          [42.350661, 69.609652],
          [42.350717, 69.609675],
          [42.350831, 69.609688],
          [42.350904, 69.609709],
          [42.352708, 69.610512],
          [42.352801, 69.610540],
          [42.352854, 69.610537],
          [42.352926, 69.610511],
          [42.352934, 69.609562],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352926, 69.610511], //
          [42.352899, 69.611645], //
          [42.352840, 69.611962], 
          [42.352786, 69.612136],
          [42.351142, 69.611013],
          [42.350506, 69.610566],
          [42.349533, 69.609865],
          [42.349698, 69.609192],
          [42.349921, 69.608269],
          [42.350147, 69.607295], //Озат
          [42.352867, 69.608477],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352786, 69.612136], //
          [42.352870, 69.612195], // 
          [42.352906, 69.612283],
          [42.352912, 69.612340],
          [42.352869, 69.612848],
          [42.352837, 69.613035],
          [42.352811, 69.613105],
          [42.352749, 69.613151],
          [42.352676, 69.613127],
          [42.350819, 69.611940],
          [42.350759, 69.612096],
          [42.348598, 69.610592],
          [42.348566, 69.610549],
          [42.348487, 69.610379],
          [42.348451, 69.610329],
          [42.348403, 69.610285],
          [42.348032, 69.610009],
          [42.347611, 69.609711],
          [42.347510, 69.609708],
          [42.347090, 69.609768],
          [42.347008, 69.609803],
          [42.346966, 69.609896],
          [42.346939, 69.610199],
          [42.346921, 69.610252],
          [42.346897, 69.610290],
          [42.346721, 69.610431],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351140, 69.611016], //
          [42.350819, 69.611940],
          [42.350759, 69.612096],
          [42.350420, 69.613102],
          
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.346735, 69.604671], //
          [42.355251, 69.608283], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354120, 69.605191], //
          [42.353574, 69.607429], //
          [42.354661, 69.607908], 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353839, 69.603617], //
          [42.353754, 69.603679], // 
          [42.353720, 69.603749],
          [42.353681, 69.603849],
          [42.353265, 69.605186],
          [42.353187, 69.605308],
          [42.353015, 69.605462],
          [42.352960, 69.605574],
          [42.352933, 69.605855],
          [42.352952, 69.606448],
          [42.352939, 69.606595],
          [42.352831, 69.606982],
          [42.352782, 69.607105],
          [42.352755, 69.607220],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352165, 69.601791], //
          [42.351908, 69.602987], // 
          [42.351504, 69.604663],
          [42.351475, 69.604698],
          [42.351404, 69.604715],
          [42.350772, 69.604458],
          [42.351536, 69.601212],
          [42.351666, 69.600702],
          [42.351756, 69.600158],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351908, 69.602987], //
          [42.352698, 69.603333], // 
          [42.351896, 69.606860],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350115, 69.600597], //
          [42.348934, 69.605608], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.349331, 69.607209], //
          [42.348989, 69.608662], // 
          [42.348881, 69.609075],
          [42.348809, 69.609301],
          [42.349534, 69.609864],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351483, 69.606686], //
          [42.351459, 69.606782], // 
          [42.351023, 69.608604],
          [42.350981, 69.608851],
          [42.350677, 69.610028],
          [42.350506, 69.610565],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348809, 69.609301], //
          [42.348448, 69.609042], // 
          [42.348052, 69.608832],
          [42.347591, 69.608512],
          [42.347247, 69.608325],
          [42.347169, 69.608263],
          [42.347106, 69.608145],
          [42.346962, 69.607759],
          [42.346933, 69.607727],
          [42.346204, 69.607396],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350773, 69.604458], //
          [42.350149, 69.607295], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], // 
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], // 
        ],
        color: '#0000FF'
      },


    ],
  },

  /////////////////////////////////////// Kaz-IT ////////////////////////////////////////////////////////////
  {
    name: 'Kaz-IT',
    lines: [
      {
        coordinates: [
          [42.336325, 69.762119], // Құнарлы
          [42.336358, 69.762296],
          [42.336360, 69.762340],
          [42.336356, 69.762382],
          [42.336333, 69.762431],
          [42.335886, 69.762916],
          [42.327287, 69.771530],
          [42.327131, 69.771657], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.340708, 69.760715], // Шапағат
          [42.339509, 69.761579],
          [42.337316, 69.762948],
          [42.335654, 69.764635],
          [42.333490, 69.766818],
          [42.330845, 69.769475],
          [42.328250, 69.772027],
          [42.327915, 69.772306], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.333490, 69.766810], // 
          [42.332521, 69.765169], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.330078, 69.770228], // 
          [42.329093, 69.768632], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.327367, 69.770248], // О. Жамалова
          [42.328466, 69.767726],
          [42.328536, 69.767483],
          [42.328996, 69.765075],
          [42.329202, 69.764152],
          [42.329206, 69.764100],
          [42.329191, 69.764036],
          [42.329097, 69.763976],
          [42.328758, 69.763873],
          [42.328674, 69.763824], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.328997, 69.765067], // 
          [42.328031, 69.764716], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.327367, 69.770248], // А.Яссауи
          [42.327245, 69.769976],
          [42.327218, 69.769680],
          [42.327233, 69.769506],
          [42.327632, 69.766886],
          [42.328279, 69.763397], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.327245, 69.769976], // Х. Худайбергенова
          [42.327001, 69.769595],
          [42.326971, 69.769525],
          [42.326962, 69.769390],
          [42.327061, 69.767703],
          [42.327146, 69.766955],
          [42.327257, 69.766305],
          [42.327356, 69.762142],
          [42.327352, 69.760768], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.325664, 69.771009], // Досымулы Назан Ата
          [42.326096, 69.767023], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.327757, 69.792728], // Каратауский район
          [42.328408, 69.790881],
          [42.328771, 69.789671],
          [42.328911, 69.789360],
          [42.329167, 69.788880],
          [42.329471, 69.788214],
          [42.329771, 69.787406],
          [42.330222, 69.786336],
          [42.330809, 69.785172],
          [42.331007, 69.784614],
          [42.331041, 69.784464],
          [42.331224, 69.783967],
          [42.331350, 69.783719],
          [42.331645, 69.783239],
          [42.332398, 69.781235], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.328784, 69.793305], // Каратауский район
          [42.329240, 69.791535],
          [42.329550, 69.790262],
          [42.330305, 69.787759],
          [42.331006, 69.785305], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351959, 69.646876], //  Мирас 1
          [42.351260, 69.646528], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351862, 69.647417], // Мирас 2
          [42.350774, 69.646976], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351772, 69.647850], // Мирас 3
          [42.351608, 69.647746],
          [42.350826, 69.647430], // Конец

        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.351689, 69.648462], // Мирас 4
          [42.350856, 69.648145], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351631, 69.648784], // Мирас 5
          [42.350797, 69.648463], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351508, 69.649483], // Алтынайдар
          [42.351180, 69.649311],
          [42.350363, 69.648928], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351354, 69.650354], // Барсыганкол
          [42.351320, 69.650337],
          [42.350826, 69.649992],
          [42.350044, 69.649664], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351052, 69.652062], // Утегенбулак
          [42.350834, 69.651930],
          [42.350505, 69.651663],
          [42.350351, 69.651512],
          [42.350057, 69.651313],
          [42.349680, 69.651110], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350841, 69.650709], // Мирас 6
          [42.350491, 69.651653],
          [42.350181, 69.652329],
          [42.350158, 69.652339],
          [42.350107, 69.652337],
          [42.349740, 69.652032],
          [42.349654, 69.651978],
          [42.349580, 69.651944],
          [42.349495, 69.651898], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350451, 69.655512], // // Мирас 7
          [42.349801, 69.654990], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350294, 69.656446], // // Мирас 8
          [42.349516, 69.655841],
          [42.348826, 69.655449], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350223, 69.656843], // // Мирас 9
          [42.349689, 69.656458], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.349911, 69.658572], // Ескиозен
          [42.349887, 69.658475],
          [42.349838, 69.658347],
          [42.349499, 69.657557],
          [42.349158, 69.656808],
          [42.349126, 69.656722],
          [42.349056, 69.656618],
          [42.348476, 69.656118], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.349683, 69.659733], // Гартебе
          [42.349630, 69.659721],
          [42.349613, 69.659699],
          [42.348960, 69.658176],
          [42.348628, 69.657310], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.349204, 69.662675], // Жаужумыр
          [42.349159, 69.662660],
          [42.349059, 69.662587],
          [42.348979, 69.662503],
          [42.347386, 69.658735],
          [42.347379, 69.658707],
          [42.347384, 69.658679],
          [42.347441, 69.658542],
          [42.347534, 69.658386],
          [42.347944, 69.657921],
          [42.348163, 69.657737],
          [42.348628, 69.657308],
          [42.349113, 69.656832], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.349516, 69.660880], // Жаушыкум
          [42.349470, 69.660866],
          [42.349414, 69.660781],
          [42.348629, 69.658920],
          [42.348444, 69.658428],
          [42.348161, 69.657736], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347997, 69.663785], // Алем
          [42.348328, 69.663677],
          [42.348388, 69.663646],
          [42.348404, 69.663582],
          [42.348365, 69.663270],
          [42.348268, 69.662908],
          [42.347988, 69.662192],
          [42.347632, 69.661383],
          [42.346860, 69.659481], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348467, 69.665274], // Тулпар
          [42.348435, 69.665237],
          [42.348313, 69.664849],
          [42.347974, 69.664836],
          [42.347375, 69.664851],
          [42.346507, 69.664853],
          [42.346529, 69.664849], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348313, 69.664849], // Тулпар
          [42.347997, 69.663785],
          [42.347784, 69.663440],
          [42.347770, 69.663425],
          [42.347756, 69.663419],
          [42.347721, 69.663419],
          [42.345861, 69.663741],
          [42.345475, 69.664065],
          [42.344795, 69.664763],
          [42.344287, 69.665228],
          [42.343854, 69.665549],
          [42.343711, 69.665632],
          [42.343362, 69.665731],
          [42.341584, 69.666140],
          [42.340092, 69.666454],
          [42.339696, 69.666587], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347569, 69.663446], // Алтын Тау
          [42.346857, 69.661415],
          [42.346315, 69.660069], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.346692, 69.663599], // Бике Коныр
          [42.346694, 69.663575],
          [42.346616, 69.663289],
          [42.346243, 69.662212],
          [42.345806, 69.660898], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.345850, 69.663744], // Желдикара
          [42.345507, 69.662714],
          [42.345167, 69.661808], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.345404, 69.664131], // Алтынсу
          [42.344437, 69.662524],
          [42.344410, 69.662505],
          [42.344371, 69.662510],
          [42.344327, 69.662541],
          [42.344129, 69.662758],
          [42.343403, 69.663424],
          [42.343378, 69.663435],
          [42.343352, 69.663432],
          [42.343324, 69.663395],
          [42.343076, 69.662964], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.346988, 69.667856], // Алтынсу
          [42.346503, 69.666870],
          [42.346472, 69.666772],
          [42.346459, 69.666633],
          [42.346434, 69.666523],
          [42.345487, 69.664055], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347567, 69.667081], // Мирас 10
          [42.346961, 69.665925],
          [42.346948, 69.665881],
          [42.346947, 69.665859],
          [42.346965, 69.665828],
          [42.347285, 69.665668], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.346380, 69.668481], // Мирас 11
          [42.346077, 69.667891],
          [42.345838, 69.667343], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.344910, 69.664647], // Маятас
          [42.345600, 69.666398], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.344798, 69.664755], // Маятас
          [42.344165, 69.663785],
          [42.344141, 69.663712],
          [42.344145, 69.662740], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.345174, 69.667469], // Азаттык
          [42.344272, 69.665239], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.344817, 69.667564], // Азаттык
          [42.343969, 69.665465], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.344072, 69.667182], // Азаттык
          [42.343728, 69.665891],
          [42.343717, 69.665832],
          [42.343712, 69.665714],
          [42.343697, 69.665636], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.343846, 69.665549], // Азаттык
          [42.343459, 69.664398],
          [42.343194, 69.663844],
          [42.343142, 69.663722],
          [42.343072, 69.663520],
          [42.343029, 69.663235],
          [42.343026, 69.663028],
          [42.343039, 69.662983],
          [42.343068, 69.662948],
          [42.342943, 69.662741],
          [42.341689, 69.660150],
          [42.339752, 69.656641], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.345535, 69.668112], // Жети тобе
          [42.344428, 69.668947],
          [42.344111, 69.669008],
          [42.342929, 69.669113],
          [42.342746, 69.669074],
          [42.342683, 69.669142],
          [42.340986, 69.670275], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.345248, 69.669545], // Мирас 12
          [42.345221, 69.669464],
          [42.345190, 69.669413],
          [42.345139, 69.669397],
          [42.345056, 69.669393],
          [42.344764, 69.669470],
          [42.344597, 69.669463],
          [42.344296, 69.669203],
          [42.344175, 69.669071],
          [42.344128, 69.669006], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.344240, 69.669694], // Мирас 13
          [42.343977, 69.669021], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.344239, 69.669697], // Мирас 14
          [42.343550, 69.670116], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.343550, 69.670116], // Мирас 15
          [42.343207, 69.669089], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.343173, 69.671707], // Буркит
          [42.342539, 69.670020], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.343457, 69.667614], // Балды-су
          [42.343347, 69.665735], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.342621, 69.6658999], // Дербес
          [42.342456, 69.664551],
          [42.342365, 69.664104],
          [42.342347, 69.663892],
          [42.342361, 69.663767],
          [42.342387, 69.663657],
          [42.342504, 69.663381],
          [42.342623, 69.663193],
          [42.342801, 69.662981],
          [42.342939, 69.662802],
          [42.342962, 69.662740],
          [42.344964, 69.660302],
          [42.346538, 69.658315],
          [42.347844, 69.656689],
          [42.348368, 69.655999], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348650, 69.654898], // Жана Жол
          [42.348340, 69.654671],
          [42.347377, 69.654165],
          [42.347143, 69.654078],
          [42.347045, 69.654031],
          [42.346985, 69.654024],
          [42.344563, 69.654291],
          [42.344478, 69.654314],
          [42.343039, 69.654925],
          [42.342956, 69.654839],
          [42.342828, 69.654669],
          [42.342781, 69.654659],
          [42.342735, 69.654664],
          [42.341819, 69.654932],
          [42.341243, 69.655038],
          [42.341127, 69.655045],
          [42.341094, 69.655051],
          [42.341061, 69.655066],
          [42.340254, 69.655686],
          [42.340213, 69.655735],
          [42.339850, 69.656442],
          [42.339680, 69.656716],
          [42.339622, 69.656790],
          [42.338123, 69.658041],
          [42.337989, 69.658125],
          [42.337959, 69.658153],
          [42.337295, 69.659096], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348206, 69.654607], // Байшешек
          [42.348129, 69.654827],
          [42.348076, 69.654934],
          [42.348024, 69.655005],
          [42.347834, 69.655179],
          [42.347681, 69.655194],
          [42.347260, 69.655149],
          [42.346887, 69.655175],
          [42.345310, 69.655321],
          [42.344569, 69.655421],
          [42.343655, 69.655843], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347834, 69.655182], // Каскасу
          [42.347841, 69.655199],
          [42.347817, 69.655353],
          [42.347848, 69.655432],
          [42.347865, 69.655530],
          [42.347857, 69.655601],
          [42.347839, 69.655662],
          [42.347736, 69.655855],
          [42.347404, 69.656297],
          [42.347351, 69.656341],
          [42.347280, 69.656368],
          [42.347216, 69.656380],
          [42.346848, 69.656355],
          [42.346173, 69.656379],
          [42.344887, 69.656528],
          [42.344716, 69.656631],
          [42.344646, 69.656373],
          [42.344576, 69.656375],
          [42.344503, 69.656366],
          [42.343676, 69.656163],
          [42.342923, 69.655959],
          [42.342507, 69.655869],
          [42.342236, 69.655830],
          [42.342166, 69.655813],
          [42.342102, 69.655780],
          [42.341911, 69.655729],
          [42.341778, 69.655727],
          [42.341603, 69.655770],
          [42.341500, 69.655817],
          [42.341244, 69.656025],
          [42.340782, 69.656617],
          [42.340596, 69.656790],
          [42.340494, 69.656835],
          [42.340056, 69.657117], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.346542, 69.658307], // Мирас 16
          [42.346359, 69.657422],
          [42.346189, 69.656377], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.345587, 69.659482], // Мирас 17
          [42.344775, 69.656708],
          [42.344722, 69.656605],
          [42.344660, 69.656361],
          [42.344583, 69.655418],
          [42.344261, 69.652619], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.344717, 69.656631], // Мамыр
          [42.344672, 69.656666],
          [42.343602, 69.657725],
          [42.341564, 69.659919], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.343674, 69.656163], // Жупарлы
          [42.343634, 69.656234],
          [42.341060, 69.658999], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.344417, 69.660968], // Оркен
          [42.342856, 69.657859],
          [42.342055, 69.656515], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.342031, 69.660859], // Ата Зан
          [42.343816, 69.658923],
          [42.344267, 69.658452],
          [42.344622, 69.658119], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.342483, 69.661790], // Ажарлы
          [42.345046, 69.659104], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.341785, 69.660348], // Маусым
          [42.341230, 69.660767],
          [42.340817, 69.661054],
          [42.339889, 69.661779],
          [42.339859, 69.661831],
          [42.339816, 69.661891],
          [42.339737, 69.661956],
          [42.339490, 69.662131],
          [42.339473, 69.662159],
          [42.339473, 69.662195],
          [42.339540, 69.662502],
          [42.339634, 69.663077],
          [42.339693, 69.663386],
          [42.339965, 69.664318],
          [42.340156, 69.665023],
          [42.340300, 69.665402],
          [42.340329, 69.665433],
          [42.340364, 69.665419],
          [42.341243, 69.664468], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.339614, 69.661229], // Жемисти
          [42.340684, 69.660322],
          [42.341397, 69.659691],
          [42.341421, 69.659666], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.340875, 69.658675], // Алгабас
          [42.340473, 69.659054],
          [42.340028, 69.659458],
          [42.339420, 69.660022],
          [42.339113, 69.660269], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.340353, 69.657728], // Ушкын
          [42.338647, 69.659390],
          [42.338621, 69.659435],
          [42.338614, 69.659493],
          [42.338625, 69.659552],
          [42.338649, 69.659600],
          [42.339021, 69.660232],
          [42.339049, 69.660255],
          [42.339113, 69.660268], // Конец

        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.339878, 69.661798], // Мирас 18
          [42.339613, 69.661228],
          [42.339112, 69.660266], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.338058, 69.661591], // Мирас 19
          [42.338115, 69.661556],
          [42.338600, 69.661325],
          [42.338890, 69.661149], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.339775, 69.663667], // Дербес
          [42.340224, 69.663502],
          [42.340353, 69.663473],
          [42.340778, 69.663441],
          [42.340982, 69.663382],
          [42.341066, 69.663379],
          [42.341232, 69.663414],
          [42.341399, 69.663452],
          [42.341476, 69.663445],
          [42.341658, 69.663388],
          [42.342214, 69.663284],
          [42.342432, 69.663271],
          [42.342479, 69.663278],
          [42.342554, 69.663300], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.339692, 69.663379], // Дербес
          [42.339539, 69.663486],
          [42.338809, 69.664106], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347105, 69.668165], // Саянтау 
          [42.347323, 69.668553],
          [42.347386, 69.668607],
          [42.347447, 69.668621],
          [42.347526, 69.668605],
          [42.348556, 69.668287],
          [42.353501, 69.665586],
          [42.354138, 69.665264], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348682, 69.668219], // 194-й квартал
          [42.348339, 69.667184],
          [42.348327, 69.667127],
          [42.348329, 69.667062],
          [42.348339, 69.667007],
          [42.348359, 69.666953],
          [42.348426, 69.666887],
          [42.348540, 69.666795],
          [42.348698, 69.666696],
          [42.352477, 69.664700], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353461, 69.667108], // Алтын адам
          [42.352799, 69.667491],
          [42.349091, 69.669509],
          [42.345322, 69.671604], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.345322, 69.671604], // Алтын адам
          [42.345283, 69.671124],
          [42.345269, 69.671100],
          [42.345241, 69.671081],
          [42.345214, 69.671080],
          [42.345124, 69.671117],
          [42.345019, 69.671181],
          [42.344089, 69.671851],
          [42.343458, 69.672327],
          [42.343396, 69.672359],
          [42.343362, 69.672365],
          [42.343337, 69.672364],
          [42.343328, 69.672376],
          [42.343338, 69.672471],
          [42.343889, 69.674009], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352783, 69.669045], // Гун ели
          [42.346246, 69.672593],
          [42.345463, 69.673072], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352300, 69.670857], // Аккум
          [42.345952, 69.674330], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352309, 69.672400], // Сак ели
          [42.346385, 69.675673], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.349065, 69.678319], // Сак ели
          [42.347768, 69.678296],
          [42.346832, 69.678299],
          [42.346533, 69.678314],
          [42.346433, 69.678334], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353930, 69.677069], // Сак ели
          [42.353912, 69.677010],
          [42.353866, 69.676992],
          [42.353558, 69.676999],
          [42.353476, 69.677030],
          [42.351597, 69.677048], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352978, 69.673627], // Бикен Римова
          [42.350828, 69.674767],
          [42.346700, 69.676981], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353368, 69.674876], // Кулыншакты
          [42.349619, 69.676961],
          [42.349566, 69.677003],
          [42.349392, 69.677027],
          [42.346696, 69.677073], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.345346, 69.672058], // Кызгалдак
          [42.344900, 69.672454],
          [42.343966, 69.673189], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.345462, 69.673072], // Кызгалдак
          [42.345356, 69.673149],
          [42.344982, 69.673483],
          [42.344844, 69.673698],
          [42.344613, 69.674143],
          [42.344536, 69.674241],
          [42.344099, 69.674673],
          [42.343721, 69.674992],
          [42.343457, 69.674388],
          [42.343428, 69.674355],
          [42.343386, 69.674358],
          [42.342588, 69.674929], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.343721, 69.674991], // Мирас 20
          [42.344272, 69.676188],
          [42.344280, 69.676233],
          [42.344274, 69.676276],
          [42.344261, 69.676305],
          [42.344047, 69.676643],
          [42.343679, 69.676956],
          [42.343349, 69.677191], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.345354, 69.673152], // Шайназарова
          [42.345499, 69.673597],
          [42.345638, 69.673878],
          [42.345681, 69.674003],
          [42.345808, 69.674439],
          [42.345848, 69.674641],
          [42.345879, 69.674990],
          [42.345873, 69.675155],
          [42.345829, 69.675339],
          [42.345783, 69.675441],
          [42.343970, 69.678563],
          [42.343840, 69.678713], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.345321, 69.671604], // Кемекалган
          [42.345462, 69.673072],
          [42.345951, 69.674330],
          [42.346132, 69.674773],
          [42.346134, 69.674778],
          [42.346675, 69.676696],
          [42.346696, 69.676835],
          [42.346702, 69.676948],
          [42.346693, 69.677092],
          [42.346662, 69.677268],
          [42.346628, 69.677360],
          [42.346587, 69.677446],
          [42.346430, 69.677641],
          [42.346388, 69.677712],
          [42.346372, 69.677780],
          [42.346377, 69.678009],
          [42.346434, 69.678336],
          [42.346579, 69.679267],
          [42.346675, 69.679624],
          [42.346680, 69.679799],
          [42.346662, 69.680087],
          [42.346622, 69.680347],
          [42.346548, 69.680685],
          [42.346489, 69.681031],
          [42.346472, 69.681259],
          [42.346485, 69.681794],
          [42.346473, 69.682374],
          [42.346444, 69.682814],
          [42.346394, 69.683004],
          [42.346334, 69.683188],
          [42.346229, 69.683445],
          [42.346144, 69.683599],
          [42.346042, 69.683744],
          [42.345754, 69.684060],
          [42.345688, 69.684105], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348681, 69.668219], // Бакконган
          [42.351597, 69.677049],
          [42.351592, 69.677066],
          [42.351388, 69.677121],
          [42.351350, 69.677152],
          [42.351330, 69.677203],
          [42.351324, 69.677314],
          [42.351358, 69.679562],
          [42.351683, 69.679558],
          [42.351706, 69.681550],
          [42.351480, 69.684251],
          [42.351303, 69.684277],
          [42.351687, 69.686182], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.349561, 69.677010], // Биртилек\
          [42.349479, 69.679567],
          [42.349631, 69.679566],
          [42.349737, 69.681425],
          [42.349743, 69.681695],
          [42.349575, 69.683885], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354424, 69.679517], // Алдаспан
          [42.354418, 69.679600],
          [42.354428, 69.681291], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353930, 69.677070], // Шынырау
          [42.353916, 69.677141],
          [42.353854, 69.677243],
          [42.353798, 69.677415],
          [42.353781, 69.678189],
          [42.353806, 69.679547],
          [42.353764, 69.679548],
          [42.353820, 69.683698], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353135, 69.679552], // Жалантос
          [42.353157, 69.681244],
          [42.353122, 69.682081],
          [42.353048, 69.683089],
          [42.352976, 69.683570], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352506, 69.679552], // Айгак
          [42.352543, 69.681550],
          [42.352286, 69.683610], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350903, 69.679558], // Мирас 22
          [42.350903, 69.681134],
          [42.350981, 69.681768],
          [42.350790, 69.684253], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350282, 69.679555], // Қалқаман
          [42.350384, 69.681492],
          [42.350056, 69.681726],
          [42.349878, 69.683989], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.349008, 69.679570], // Мирас 23
          [42.349131, 69.681702],
          [42.348975, 69.683674], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348527, 69.679570], // Бекетай
          [42.348609, 69.681349],
          [42.348527, 69.681745],
          [42.348510, 69.683481],
          [42.348414, 69.683457],
          [42.348844, 69.685551],
          [42.348841, 69.685593],
          [42.348818, 69.685615],
          [42.348579, 69.685698],
          [42.348560, 69.685717],
          [42.348546, 69.685749],
          [42.348547, 69.685791],
          [42.348804, 69.686980],
          [42.348615, 69.686961],
          [42.348656, 69.687183],
          [42.349062, 69.688438], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348069, 69.679573], // Еңілік Кебек
          [42.348110, 69.680691],
          [42.348150, 69.682000],
          [42.348091, 69.683385], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347676, 69.679577], // Мирас 24
          [42.347613, 69.681266],
          [42.347613, 69.681266], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354424, 69.679535], // Мирас
          [42.354382, 69.679543],
          [42.350295, 69.679555],
          [42.346897, 69.679594],
          [42.346675, 69.679623], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.346531, 69.680778], // Мирас 25
          [42.345993, 69.680672], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353819, 69.683699], // Карабура аулие
          [42.353483, 69.683701],
          [42.352968, 69.683593],
          [42.352604, 69.683584],
          [42.352282, 69.683637],
          [42.351579, 69.684232],
          [42.351484, 69.684276],
          [42.351303, 69.684302],
          [42.350912, 69.684298],
          [42.350719, 69.684271],
          [42.350559, 69.684242],
          [42.348519, 69.683512],
          [42.346394, 69.683001], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351864, 69.683989], // 
          [42.352368, 69.685809], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350926, 69.684385], // 
          [42.351312, 69.686588], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350911, 69.684296], // 
          [42.351335, 69.686719], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350173, 69.684105], // 
          [42.350228, 69.684540],
          [42.350648, 69.686738], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.349329, 69.683804], // 
          [42.349955, 69.686971], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348773, 69.683603], // 
          [42.349200, 69.685765],
          [42.349202, 69.685794],
          [42.349200, 69.685819],
          [42.349143, 69.685910],
          [42.349135, 69.685952],
          [42.349138, 69.685991],
          [42.349527, 69.687682], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.347631, 69.683297], // Жайлы
          [42.348392, 69.687020],
          [42.348162, 69.687117],
          [42.348826, 69.689224],
          [42.349058, 69.690146],
          [42.349106, 69.690715],
          [42.348989, 69.691248],
          [42.348951, 69.691314],
          [42.348794, 69.691404],
          [42.348611, 69.691474],
          [42.348484, 69.691567], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.346592, 69.683049], // Акбосага
          [42.347605, 69.687270], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.329465, 69.793600], // Каратауский район
          [42.332647, 69.781500], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.330125, 69.794063], // Шапағат
          [42.333320, 69.781838], // Конец
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.330772, 69.794543], // Каратауский район
          [42.333941, 69.782122], // Конец
        ],
      },
      {
        coordinates: [
          [42.331525, 69.794988], // Каратауский район
          [42.333624, 69.786891], // Конец
        ],
      },
      {
        coordinates: [
          [42.332383, 69.795469], // Каратауский район
          [42.333056, 69.792461], // Конец
        ],
      },
      {
        coordinates: [
          [42.332343, 69.797729], // Каратауский район
          [42.327854, 69.792817], // Конец
        ],
      },
      {
        coordinates: [
          [], //
          [], // Конец
        ],
      },
      {
        coordinates: [
          [], //
          [], // Конец
        ],
      },
      {
        coordinates: [
          [], //
          [], // Конец
        ],
      },
      {
        coordinates: [
          [], //
          [], // Конец
        ],
      },
      {
        coordinates: [
          [], //
          [], // Конец
        ],
      },
      {
        coordinates: [
          [], //
          [], // Конец
        ],
      },
      {
        coordinates: [
          [], //
          [], // Конец
        ],
      },

    ],
  },

  /////////////////////////////////////// Састелеком ////////////////////////////////////////////////////////
  {
    name: 'Састелеком',
    lines: [
      {
        coordinates: [
          [42.431990, 69.659236], //
          [42.432032, 69.657996],
          [42.432083, 69.657872],
          [42.433428, 69.656340],
          [42.434293, 69.655214],
          [42.434605, 69.654848],
          [42.435746, 69.653550],
          [42.435873, 69.653363], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.435863, 69.653350], //
          [42.434839, 69.651911],
          [42.434319, 69.651058],
          [42.434238, 69.650878],
          [42.434196, 69.650671],
          [42.434159, 69.650360],
          [42.434183, 69.648959], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.435285, 69.646472], // Смайыл Отебайулы
          [42.435072, 69.647974],
          [42.435047, 69.648101],
          [42.435026, 69.648147],
          [42.435001, 69.648168],
          [42.434959, 69.648175],
          [42.434923, 69.648168],
          [42.434875, 69.648145],
          [42.434300, 69.647755], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.435739, 69.646329], //
          [42.436829, 69.646131],
          [42.437281, 69.646091], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.437176, 69.644964], //
          [42.435150, 69.645263], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.435150, 69.645263], //
          [42.435062, 69.645106],
          [42.435028, 69.644961],
          [42.435011, 69.644806],
          [42.435019, 69.644485],
          [42.435018, 69.644290],
          [42.435033, 69.644180], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.435033, 69.644180], //
          [42.435184, 69.644188],
          [42.437102, 69.643854], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.435033, 69.644180], //
          [42.434879, 69.644155],
          [42.434977, 69.643489],
          [42.434989, 69.643385],
          [42.434989, 69.643310],
          [42.434985, 69.643240],
          [42.434967, 69.643103],
          [42.434969, 69.643033],
          [42.434987, 69.642991],
          [42.435015, 69.642966],
          [42.435058, 69.642946],
          [42.435115, 69.642939], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.435115, 69.642939], //
          [42.437032, 69.642655], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.436879, 69.641564], //
          [42.436054, 69.641688],
          [42.434851, 69.641936], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.434305, 69.639608], //
          [42.434485, 69.640692],
          [42.434788, 69.641786],
          [42.434851, 69.641936],
          [42.435141, 69.642925], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.436712, 69.640447], //
          [42.436572, 69.640471],
          [42.435938, 69.640539],
          [42.435564, 69.640592],
          [42.435339, 69.640633],
          [42.434959, 69.640741],
          [42.434540, 69.640894], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.436735, 69.639267], //
          [42.434762, 69.639604],
          [42.434472, 69.639635],
          [42.434297, 69.639612], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.436625, 69.638092], //
          [42.436403, 69.638152],
          [42.434872, 69.638394],
          [42.434730, 69.638429],
          [42.434607, 69.638481],
          [42.434304, 69.638647],
          [42.434129, 69.638703], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.436476, 69.636884], //
          [42.435420, 69.637093],
          [42.434976, 69.637149],
          [42.434947, 69.637139],
          [42.434934, 69.637112],
          [42.434814, 69.636598],
          [42.434769, 69.636476],
          [42.434701, 69.636348],
          [42.434624, 69.636229],
          [42.434491, 69.636098], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.434491, 69.636098], //
          [42.435818, 69.635915],
          [42.436357, 69.635810], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.436722, 69.639268], //
          [42.436626, 69.638092],
          [42.436477, 69.636884],
          [42.436334, 69.635562],
          [42.436294, 69.635283],
          [42.436264, 69.634925], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.434598, 69.641101], // Асар
          [42.434421, 69.641949],
          [42.433261, 69.649437],
          [42.433155, 69.650500], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.431641, 69.654698], //
          [42.434421, 69.636773], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.430694, 69.655679], //
          [42.433772, 69.635837], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.430001, 69.655388], //
          [42.432953, 69.635940], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.429222, 69.655281], // Егемен Казакстан
          [42.432144, 69.635983], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.428502, 69.654490], //
          [42.431343, 69.635834], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.427742, 69.654224], // Ардагер
          [42.430510, 69.635983], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.427333, 69.651731], //
          [42.427913, 69.648130],
          [42.429700, 69.636463],
          [42.429701, 69.636413],
          [42.429691, 69.636367],
          [42.429671, 69.636340],
          [42.429635, 69.636325],
          [42.428882, 69.636484], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.428859, 69.636262], // Гулдер
          [42.428872, 69.636353],
          [42.428886, 69.636532],
          [42.428883, 69.636623],
          [42.426605, 69.651415], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.428079, 69.636460], //
          [42.428074, 69.636578],
          [42.428055, 69.636741],
          [42.425859, 69.651059], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.425734, 69.646578], // Балкадиша
          [42.425098, 69.650830],
          [42.425066, 69.650945],
          [42.425037, 69.651033],
          [42.425059, 69.651190],
          [42.425277, 69.651319],
          [42.425637, 69.651438],
          [42.425702, 69.651491],
          [42.425742, 69.651581],
          [42.425741, 69.651604],
          [42.425595, 69.652561],
          [42.425201, 69.654859], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.425734, 69.646578], //
          [42.426611, 69.640922],
          [42.426629, 69.640691],
          [42.426634, 69.639901],
          [42.426603, 69.638246],
          [42.426612, 69.636827], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.427216, 69.636671], // 
          [42.426797, 69.639570],
          [42.426780, 69.639623],
          [42.426746, 69.639658],
          [42.426625, 69.639715], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.425748, 69.637035], //
          [42.425748, 69.641478],
          [42.425732, 69.641698],
          [42.425672, 69.641972],
          [42.425618, 69.642192],
          [42.425494, 69.642931],
          [42.425402, 69.643621],
          [42.425039, 69.645962],
          [42.424967, 69.646344],
          [42.424431, 69.649866],
          [42.424390, 69.650064], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.424948, 69.637167], //
          [42.424953, 69.637227],
          [42.424961, 69.640556],
          [42.424943, 69.642370], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.424738, 69.642868], //
          [42.423954, 69.647968],
          [42.423843, 69.648564],
          [42.423837, 69.648667],
          [42.423840, 69.648741],
          [42.423853, 69.648830],
          [42.423880, 69.648917],
          [42.423957, 69.649272],
          [42.424071, 69.649546],
          [42.424270, 69.649858],
          [42.424390, 69.650064],
          [42.424957, 69.650957],
          [42.425036, 69.651028], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.424106, 69.637186], //
          [42.424112, 69.642117],
          [42.424099, 69.642214],
          [42.424023, 69.642496],
          [42.423257, 69.647281], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.423301, 69.637207], //
          [42.423295, 69.638566],
          [42.423274, 69.639475],
          [42.423290, 69.641935],
          [42.422711, 69.645694], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.422463, 69.637211], //
          [42.422464, 69.639426], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.421649, 69.637221], //
          [42.421627, 69.639415], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.420748, 69.637227], //
          [42.420625, 69.640698],
          [42.420644, 69.640888],
          [42.420797, 69.641433],
          [42.420849, 69.641766],
          [42.420857, 69.642139],
          [42.420829, 69.642484],
          [42.420712, 69.643194],
          [42.420637, 69.643496],
          [42.420485, 69.643830],
          [42.420333, 69.644057],
          [42.420259, 69.644142],
          [42.419411, 69.644860],
          [42.419339, 69.644938],
          [42.419299, 69.644999],
          [42.419267, 69.645072],
          [42.419216, 69.645254],
          [42.419191, 69.645259],
          [42.418977, 69.645345],
          [42.418805, 69.645472],
          [42.418727, 69.645516],
          [42.418623, 69.645536],
          [42.418506, 69.645526],
          [42.416669, 69.644980],
          [42.416190, 69.644761], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.419587, 69.637097], //
          [42.419571, 69.637185],
          [42.419302, 69.637861],
          [42.419107, 69.638273],
          [42.418936, 69.638552],
          [42.417605, 69.640011],
          [42.417490, 69.640215],
          [42.416853, 69.641815],
          [42.416403, 69.642894],
          [42.416335, 69.643231],
          [42.416190, 69.644761], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.434317, 69.635716], //
          [42.432241, 69.636013], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.434040, 69.639239], //
          [42.428762, 69.637744], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.434732, 69.644482], //
          [42.425757, 69.641972], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.434150, 69.648926], //
          [42.422576, 69.645635], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.431910, 69.652804], //
          [42.429642, 69.652210],
          [42.429157, 69.652129],
          [42.427714, 69.651630],
          [42.427667, 69.651632],
          [42.427622, 69.651656],
          [42.427498, 69.651773],
          [42.427465, 69.651787],
          [42.427417, 69.651787],
          [42.427323, 69.651774],
          [42.427334, 69.651727], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.426616, 69.639416], //
          [42.420673, 69.639420], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.430694, 69.655680], //
          [42.430686, 69.655710],
          [42.429973, 69.655472],
          [42.428166, 69.654993],
          [42.428132, 69.654998],
          [42.427965, 69.655116],
          [42.427844, 69.655141],
          [42.427619, 69.655055],
          [42.427518, 69.654909], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.427328, 69.658945], //
          [42.427221, 69.659044],
          [42.427167, 69.659112],
          [42.427124, 69.659202],
          [42.427091, 69.659337],
          [42.427094, 69.659526],
          [42.427076, 69.659699],
          [42.427037, 69.659874],
          [42.426978, 69.660020],
          [42.426651, 69.660505], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.427328, 69.658945], // Караой
          [42.427434, 69.658957],
          [42.427519, 69.658938],
          [42.427597, 69.658876],
          [42.427667, 69.658769],
          [42.427749, 69.658519],
          [42.427796, 69.658287],
          [42.427904, 69.657452],
          [42.427935, 69.656876],
          [42.427851, 69.656245],
          [42.427715, 69.655870],
          [42.427442, 69.655584],
          [42.427133, 69.655400],
          [42.425737, 69.654995],
          [42.424871, 69.654774],
          [42.424356, 69.654600], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.427763, 69.658028], //
          [42.421784, 69.656048], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.427328, 69.658945], //
          [42.421789, 69.657033],
          [42.421006, 69.656844],
          [42.420390, 69.656616], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.426651, 69.660505], //
          [42.426214, 69.660298],
          [42.422727, 69.659109],
          [42.422273, 69.658916],
          [42.421847, 69.658763],
          [42.421314, 69.658602],
          [42.418976, 69.657784],
          [42.418782, 69.657761], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.422962, 69.661060], // Абдураймова
          [42.418811, 69.659613],
          [42.418672, 69.659435],
          [42.415227, 69.655820], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.424862, 69.663183], //
          [42.424862, 69.663183],
          [42.423568, 69.662780],
          [42.420200, 69.661490],
          [42.420200, 69.661490],
          [42.419467, 69.661365],
          [42.418687, 69.661176],
          [42.418469, 69.661099],
          [42.418444, 69.661075],
          [42.418205, 69.660581], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.418205, 69.660581], //
          [42.418260, 69.660454],
          [42.418264, 69.660407],
          [42.418248, 69.660366],
          [42.416736, 69.658761],
          [42.414861, 69.656811], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.418205, 69.660581], //
          [42.417868, 69.660861],
          [42.417782, 69.660899],
          [42.417619, 69.660907],
          [42.417460, 69.660775],
          [42.417039, 69.660475],
          [42.416713, 69.660189],
          [42.415815, 69.659238],
          [42.415664, 69.659053],
          [42.414499, 69.657877], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.421791, 69.663530], //
          [42.420202, 69.663008],
          [42.419088, 69.662759],
          [42.418730, 69.662649],
          [42.418381, 69.662510],
          [42.415866, 69.661753],
          [42.415830, 69.661761],
          [42.415806, 69.661796],
          [42.415806, 69.661843],
          [42.416124, 69.663344], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.419796, 69.664030], //
          [42.418598, 69.663702],
          [42.417926, 69.663470],
          [42.416666, 69.663119],
          [42.416256, 69.663066],
          [42.416127, 69.663343], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.418782, 69.657761], //
          [42.418409, 69.657862],
          [42.415589, 69.654857], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.418630, 69.656719], // Сырлыбаева
          [42.415936, 69.653852], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.420390, 69.656616], // Дарханова
          [42.419655, 69.656361],
          [42.419538, 69.656301],
          [42.419396, 69.656178],
          [42.418035, 69.654699],
          [42.417702, 69.654335],
          [42.416278, 69.652862], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.420377, 69.656611], // Мангытай
          [42.420260, 69.656414],
          [42.420169, 69.656207],
          [42.419855, 69.655284],
          [42.419786, 69.655157],
          [42.419692, 69.655046],
          [42.419554, 69.654949],
          [42.416622, 69.651893], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.419554, 69.654949], // Байбори
          [42.419520, 69.654643],
          [42.419441, 69.654199],
          [42.419425, 69.653989],
          [42.419451, 69.653541],
          [42.419448, 69.653511],
          [42.419437, 69.653491],
          [42.416982, 69.650900], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.419648, 69.652359], //
          [42.417374, 69.649917], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.420017, 69.651385], //
          [42.417801, 69.649023], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.418282, 69.648214], //
          [42.417801, 69.649023], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.420152, 69.647501], // Салкынкол
          [42.420106, 69.647470],
          [42.419474, 69.646788],
          [42.419418, 69.646747], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.420141, 69.648791], // Салкынкол
          [42.420045, 69.648715],
          [42.418897, 69.647483], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.419217, 69.645256], //
          [42.419228, 69.645267],
          [42.419233, 69.645368],
          [42.419253, 69.645455],
          [42.419309, 69.645556],
          [42.419804, 69.646234],
          [42.419991, 69.646611],
          [42.420030, 69.646592],
          [42.420103, 69.646591],
          [42.420145, 69.646613],
          [42.420199, 69.646655],
          [42.420255, 69.646717],
          [42.421173, 69.647910],
          [42.421321, 69.648138],
          [42.422228, 69.647553],
          [42.422357, 69.647490],
          [42.422488, 69.647458],
          [42.422519, 69.647463],
          [42.422543, 69.647491],
          [42.422581, 69.647581],
          [42.422578, 69.647599],
          [42.422512, 69.647651],
          [42.422474, 69.647718],
          [42.422448, 69.647811],
          [42.422440, 69.647972],
          [42.422451, 69.648074],
          [42.422499, 69.648245],
          [42.422771, 69.649583],
          [42.422820, 69.649785],
          [42.422931, 69.650083], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.422767, 69.649572], // Ореховая
          [42.422623, 69.649683],
          [42.421650, 69.650537],
          [42.421395, 69.650787],
          [42.420241, 69.651825],
          [42.420095, 69.651905], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.422266, 69.648718], // Вишневая
          [42.420398, 69.650363],
          [42.420226, 69.650525],
          [42.420145, 69.650581],
          [42.420031, 69.650634], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.421321, 69.648139], // Степная
          [42.420513, 69.648687],
          [42.420292, 69.648821],
          [42.420255, 69.648834],
          [42.420188, 69.648841],
          [42.420152, 69.648838],
          [42.420139, 69.648834],
          [42.420141, 69.648791], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.420777, 69.648502], //
          [42.420198, 69.647765], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.419882, 69.655226], // Луговая
          [42.420094, 69.651953], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.421032, 69.655530], // Майская
          [42.420371, 69.652085], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.421831, 69.655928], // Цветочная
          [42.421766, 69.655656],
          [42.421551, 69.654404],
          [42.421061, 69.651998], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.421636, 69.651513], // Солнечная
          [42.422136, 69.653347],
          [42.422237, 69.653791],
          [42.422258, 69.653844],
          [42.422326, 69.653961],
          [42.422341, 69.654011],
          [42.422340, 69.654130],
          [42.422317, 69.654343],
          [42.422322, 69.654381],
          [42.422347, 69.654405],
          [42.422507, 69.654472], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.422773, 69.653774], // Ясная
          [42.422719, 69.653657],
          [42.422616, 69.653267],
          [42.422572, 69.653122],
          [42.421849, 69.651269], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.423781, 69.653947], //
          [42.422837, 69.651511],
          [42.422507, 69.650747],
          [42.422463, 69.650661], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.424230, 69.659607], // Вишнёвая
          [42.423987, 69.659078],
          [42.423938, 69.658827],
          [42.423932, 69.658498],
          [42.423962, 69.657981],
          [42.423984, 69.657755],
          [42.423998, 69.657395],
          [42.423984, 69.656782],
          [42.424060, 69.655380],
          [42.424115, 69.655054],
          [42.424164, 69.654914],
          [42.424240, 69.654764],
          [42.424356, 69.654602],
          [42.424376, 69.654544],
          [42.424434, 69.654249],
          [42.424444, 69.654071],
          [42.424424, 69.653968],
          [42.424388, 69.653722],
          [42.424365, 69.653661],
          [42.424326, 69.653552],
          [42.423046, 69.650316],
          [42.422930, 69.650080], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.425058, 69.651191], // 
          [42.424996, 69.651622],
          [42.424903, 69.651978],
          [42.424848, 69.652226],
          [42.424744, 69.652516],
          [42.424735, 69.652646],
          [42.424675, 69.653032],
          [42.424654, 69.653249],
          [42.424625, 69.653381],
          [42.424555, 69.653556],
          [42.424389, 69.653719], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.424746, 69.652506], //
          [42.424661, 69.652419],
          [42.424547, 69.652385],
          [42.423429, 69.649495],
          [42.423012, 69.647575], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.421913, 69.647754], //
          [42.420503, 69.645926],
          [42.420065, 69.645373],
          [42.419833, 69.645078],
          [42.419761, 69.644933],
          [42.419717, 69.644792],
          [42.419681, 69.644632], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.421771, 69.647566], //
          [42.422678, 69.646268],
          [42.422717, 69.646179], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.421345, 69.647029], //
          [42.422335, 69.645634],
          [42.422470, 69.645471], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.422222, 69.644666], //
          [42.420929, 69.646452], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.421919, 69.643926], //
          [42.420502, 69.645926], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.420065, 69.645373], //
          [42.421391, 69.643513],
          [42.421565, 69.643241], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.419694, 69.646081], // Қорғас
          [42.419610, 69.646338],
          [42.419336, 69.646918],
          [42.419248, 69.647047],
          [42.418283, 69.648215],
          [42.417793, 69.649037],
          [42.417684, 69.649227],
          [42.417367, 69.649932],
          [42.416983, 69.650899],
          [42.415596, 69.654842],
          [42.415226, 69.655820], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.418035, 69.645923], //
          [42.417523, 69.645924],
          [42.417274, 69.645858],
          [42.417235, 69.645868],
          [42.417215, 69.645891],
          [42.417205, 69.645960],
          [42.417227, 69.646130],
          [42.417221, 69.646172],
          [42.417209, 69.646202],
          [42.417194, 69.646219],
          [42.417175, 69.646232],
          [42.416125, 69.646424],
          [42.416003, 69.646399], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.418635, 69.646610], //
          [42.417810, 69.647196],
          [42.417411, 69.647399],
          [42.417365, 69.647411],
          [42.417202, 69.647418],
          [42.416208, 69.647514],
          [42.416142, 69.647511],
          [42.416015, 69.647487], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.417402, 69.647992], //
          [42.417119, 69.648095],
          [42.417119, 69.648095],
          [42.417010, 69.648112],
          [42.416206, 69.648143], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.416657, 69.648135], //
          [42.416653, 69.648392],
          [42.416635, 69.648501],
          [42.415833, 69.651190],
          [42.415802, 69.651176],
          [42.415402, 69.652556],
          [42.415360, 69.652640], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.415934, 69.647222], //
          [42.416000, 69.647415],
          [42.416018, 69.647504],
          [42.416022, 69.647590],
          [42.416059, 69.647745],
          [42.416168, 69.648002],
          [42.416210, 69.648161],
          [42.416223, 69.648294],
          [42.416224, 69.648423],
          [42.416213, 69.648491],
          [42.415900, 69.649583],
          [42.415438, 69.651029], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.415934, 69.647221], //
          [42.415866, 69.647443],
          [42.415648, 69.648710],
          [42.415460, 69.649405],
          [42.415023, 69.650872], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.416260, 69.649761], //
          [42.415899, 69.649585],
          [42.415832, 69.649569],
          [42.415459, 69.649404], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.416748, 69.651563], //
          [42.416382, 69.651403],
          [42.416335, 69.651391],
          [42.416269, 69.651405],
          [42.416220, 69.651392],
          [42.416008, 69.651263],
          [42.415439, 69.651029],
          [42.415023, 69.650873], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.411276, 69.636489], //
          [42.410988, 69.638327], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.410365, 69.635757], //
          [42.409977, 69.638287], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.409814, 69.638246], // Дәуір
          [42.409083, 69.643092],
          [42.409057, 69.643403],
          [42.408763, 69.645022],
          [42.408654, 69.645757],
          [42.408535, 69.646368],
          [42.408377, 69.647491],
          [42.408374, 69.647510],
          [42.408310, 69.647881],
          [42.408106, 69.648868],
          [42.407794, 69.651494],
          [42.407709, 69.651993], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.407304, 69.639757], // Нарсай
          [42.408279, 69.640034],
          [42.408300, 69.640053],
          [42.408315, 69.640136],
          [42.408291, 69.640356],
          [42.408281, 69.640642],
          [42.408234, 69.641213],
          [42.408118, 69.642043],
          [42.408118, 69.642043],
          [42.407971, 69.642461],
          [42.407963, 69.642704],
          [42.407905, 69.643112],
          [42.407755, 69.644491],
          [42.406684, 69.651343], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.407603, 69.637860], // Найзакескен
          [42.405603, 69.651345], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.407050, 69.633251], // Қадырғали Жалаири
          [42.404840, 69.648342], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.404627, 69.633487], // Ойқараған
          [42.405779, 69.633793],
          [42.405806, 69.633813],
          [42.405819, 69.633843],
          [42.405821, 69.633877],
          [42.405524, 69.635941],
          [42.404568, 69.642195], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.404794, 69.632344], // Байғұлы
          [42.403361, 69.641924], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.403671, 69.632059], // Омбы
          [42.402986, 69.636556],
          [42.402566, 69.639526],
          [42.401984, 69.642929],
          [42.401940, 69.643135],
          [42.401805, 69.644058],
          [42.401571, 69.645373],
          [42.401382, 69.646223],
          [42.399846, 69.645791], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.401837, 69.632991], //
          [42.402386, 69.633164],
          [42.402073, 69.635432],
          [42.401935, 69.636222],
          [42.401719, 69.637695],
          [42.401193, 69.640925], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.410988, 69.638326], //
          [42.410965, 69.638440],
          [42.410937, 69.638512],
          [42.410919, 69.638537],
          [42.410892, 69.638541],
          [42.409978, 69.638289],
          [42.409811, 69.638227],
          [42.409564, 69.638147],
          [42.406445, 69.637501],
          [42.405626, 69.637262],
          [42.405322, 69.637196],
          [42.404112, 69.636854],
          [42.403001, 69.636543],
          [42.401938, 69.636204],
          [42.401358, 69.636044], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.411027, 69.643909], // Үкілі Ыбырай
          [42.398861, 69.640729],
          [42.395311, 69.639979],
          [42.394774, 69.639934],
          [42.393885, 69.639931], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.404798, 69.642281], //
          [42.404620, 69.643344],
          [42.404583, 69.643431],
          [42.403873, 69.644349],
          [42.400086, 69.648894], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.405470, 69.644044], //
          [42.405156, 69.643909],
          [42.405020, 69.643871],
          [42.404846, 69.643871],
          [42.404759, 69.643892],
          [42.404683, 69.643940],
          [42.404462, 69.644164],
          [42.404296, 69.644382],
          [42.404106, 69.644570],
          [42.403997, 69.644734],
          [42.403880, 69.644970],
          [42.403747, 69.645165],
          [42.400875, 69.648582],
          [42.400153, 69.649320], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.401945, 69.632335], // Абылай Хан
          [42.398144, 69.656701], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.401670, 69.632945], //
          [42.401228, 69.632817],
          [42.401193, 69.632826],
          [42.401171, 69.632872],
          [42.399856, 69.640977],
          [42.399787, 69.640958],
          [42.398825, 69.647132],
          [42.398833, 69.647182],
          [42.398861, 69.647209],
          [42.399422, 69.647388], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.397659, 69.647889], // Түлкібасы
          [42.397734, 69.647102],
          [42.398375, 69.642961],
          [42.398844, 69.640747],
          [42.399116, 69.639135],
          [42.399672, 69.635602],
          [42.400004, 69.633055], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.397931, 69.640324], // Байжигитова
          [42.397931, 69.640324], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.397577, 69.640459], //
          [42.397201, 69.643958], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.398254, 69.630677], // Дастан
          [42.396829, 69.640278], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.397147, 69.630445], // Қыран
          [42.396371, 69.637324], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.395946, 69.630242], // Өсиет
          [42.395310, 69.639980], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.394657, 69.630310], // Алатау Батыр
          [42.393075, 69.656343], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.392818, 69.634233], // Тамды Аруы
          [42.392139, 69.646205],
          [42.392016, 69.648706],
          [42.392048, 69.649474],
          [42.391940, 69.651424], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.391685, 69.632028], //
          [42.391549, 69.634018],
          [42.391494, 69.634010],
          [42.390953, 69.643552], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.400979, 69.635933], //
          [42.394992, 69.634402],
          [42.394354, 69.634338],
          [42.394223, 69.634345],
          [42.393247, 69.634294],
          [42.391376, 69.633962], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.397239, 69.637519], // Сұңқар
          [42.395311, 69.637088],
          [42.394492, 69.636992],
          [42.394053, 69.636984],
          [42.392642, 69.636844],
          [42.391314, 69.636613], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.395862, 69.640096], //
          [42.395836, 69.640461],
          [42.395840, 69.640644],
          [42.395858, 69.640751],
          [42.395911, 69.640891],
          [42.396665, 69.642638], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.394782, 69.639935], //
          [42.394714, 69.641120],
          [42.396082, 69.644089], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.393690, 69.646242], //
          [42.395723, 69.646454],
          [42.395909, 69.647091],
          [42.396262, 69.647942],
          [42.396307, 69.648128],
          [42.396337, 69.648346],
          [42.396396, 69.649464], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.394699, 69.646348], //
          [42.394554, 69.649693], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.393733, 69.641445], //
          [42.391129, 69.640905], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.393799, 69.632629], //
          [42.393737, 69.634319], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.393168, 69.632684], //
          [42.393036, 69.634167], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.392459, 69.632312], //
          [42.392342, 69.634079], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.391691, 69.632100], //
          [42.391576, 69.633936], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.383896, 69.628903], //
          [42.383932, 69.629020], //
          [42.383954, 69.629070],
          [42.383970, 69.629098],
          [42.383996, 69.629102],
          [42.384297, 69.629068],
          [42.384384, 69.629101],
          [42.384444, 69.629156], //Ак кайнар
          [42.384462, 69.630700], //Ак кайнар 1
          [42.384452, 69.631976], //Ак кайнар 2
          [42.384406, 69.633203], //Ак кайнар 3
          [42.384352, 69.634255],
          [42.384340, 69.634296],
          [42.384310, 69.634313],
          [42.382108, 69.634469], //Жадыра Ак кайнар
          [42.382122, 69.635511],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384406, 69.633203], //
          [42.382074, 69.633294], //
          [42.382063, 69.632508],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.382074, 69.633294], //
          [42.382108, 69.634469], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384452, 69.631976], //
          [42.382677, 69.632108 ], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384462, 69.630700], //
          [42.382094, 69.630846 ], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384444, 69.629156], //
          [42.384975, 69.629120], //
          [42.385025, 69.629123],
          [42.385076, 69.629137],
          [42.385281, 69.632514],
          [42.385497, 69.634936], //1
          [42.381360, 69.635649],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.385497, 69.634936], //
          [42.385635, 69.636364], // Галымдар
          [42.381725, 69.637107],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.385635, 69.636364], //
          [42.386739, 69.636157], //
          [42.386785, 69.636115],
          [42.386786, 69.636084],
          [42.386738, 69.635748],
          [42.386739, 69.635665],
          [42.386752, 69.635560], //1 Куйши дина
          [42.386758, 69.635452],
          [42.386748, 69.635383],
          [42.386708, 69.635265],
          [42.386599, 69.634701],
          [42.386459, 69.634305],
          [42.386357, 69.634079],
          [42.386182, 69.633783],
          [42.386130, 69.633664],
          [42.386087, 69.633526],
          [42.385830, 69.631990],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.385753, 69.631211], //
          [42.385601, 69.630448], //
          [42.385529, 69.629689],
          [42.385517, 69.629624],
          [42.385498, 69.629571],
          [42.385469, 69.629523],
          [42.385433, 69.629481],
          [42.385285, 69.629340],
          [42.385162, 69.629196],
          [42.385126, 69.629163],
          [42.385076, 69.629137],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.385635, 69.636364], //
          [42.385635, 69.636364], //Жас Канат
          [42.385741, 69.637555], //Жас Канат 2
          [42.382009, 69.638265],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.386493, 69.636202], //
          [42.386612, 69.637507], //
          [42.386616, 69.637736],
          [42.386645, 69.638229], //Коркем
          [42.386739, 69.639381], //Тузелбаев
          [42.386827, 69.640586], // Нурмекен
          [42.386946, 69.642006], // Алтынсарина
          [42.387308, 69.646140], //Арыстанбая
          [42.387383, 69.646731],
          [42.387751, 69.651294], //Дайрабеков
          [42.387807, 69.652045],
          [42.387800, 69.652171],
          [42.387752, 69.652413],
          [42.387663, 69.652736],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.388038, 69.636806], //
          [42.385707, 69.637193], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.388605, 69.637856], //
          [42.387758, 69.638040], //
          [42.386645, 69.638229],
          [42.385822, 69.638356],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.389082, 69.638917], //
          [42.386739, 69.639381], //
          [42.385927, 69.639513],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.389612, 69.640166], //
          [42.389480, 69.640132], //
          [42.389379, 69.640140],
          [42.389116, 69.640192],
          [42.381888, 69.641421],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.389781, 69.640936], //
          [42.389482, 69.640840], //
          [42.389385, 69.640831],
          [42.389300, 69.640858],
          [42.389242, 69.640923],
          [42.389205, 69.641017],
          [42.389192, 69.641372],
          [42.389155, 69.641469],
          [42.389098, 69.641537],
          [42.389002, 69.641604],
          [42.388856, 69.641659], //1
          [42.386078, 69.642153], //Доспамбет
          [42.385152, 69.642257], //АКжелкен
          [42.384265, 69.642361], //Сарыжайлау
          [42.383370, 69.642487], //Медек
          [42.381686, 69.642771], //Кошеров
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.387822, 69.641843], //
          [42.387865, 69.642225], //
          [42.387889, 69.642516],
          [42.388162, 69.645879],
          [42.388153, 69.646047],
          [42.388184, 69.646425],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.388153, 69.646047], //
          [42.387308, 69.646140], //
          [42.381898, 69.647069],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.386078, 69.642153], //
          [42.386395, 69.646287],
          [42.386873, 69.651379], //Казангап
          [42.387310, 69.656323],
          [42.387310, 69.656427],
          [42.387305, 69.656536],
          [42.387284, 69.656602],
          [42.387247, 69.656641],
          [42.387190, 69.656664],
          [42.386435, 69.656799],
          [42.383560, 69.657229],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.385152, 69.642257], //
          [42.385504, 69.646426], //
          [42.385538, 69.646616],
          [42.385970, 69.651488],
          [42.386432, 69.656802],
          [42.386531, 69.657685],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.386873, 69.651379], //
          [42.387750, 69.651294], //
          [42.388063, 69.651293],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.386873, 69.651379], //
          [42.386868, 69.651374], //
          [42.385970, 69.651485],
          [42.385060, 69.651615],
          [42.383331, 69.651900],
          [42.382944, 69.651983],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.385741, 69.637555], //
          [42.385818, 69.638362], //
          [42.385847, 69.638764], //Коркем
          [42.382162, 69.639438],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.385847, 69.638764], //
          [42.385923, 69.639512], //
          [42.385932, 69.639850],
          [42.385916, 69.639928],
          [42.385882, 69.639956],
          [42.385830, 69.639974],
          [42.382359, 69.640598],
          [42.382161, 69.640607],
          [42.382027, 69.640601],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384153, 69.641057], //
          [42.384248, 69.642293], //
          [42.384291, 69.642444],
          [42.384606, 69.646556],
          [42.385054, 69.651604],
          [42.385517, 69.656936],
          [42.385646, 69.658613],
          [42.385663, 69.659293],
          [42.385654, 69.659430],
          [42.385604, 69.659687],
          [42.385606, 69.660210],
          [42.385580, 69.660403],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.383370, 69.642487], //
          [42.383716, 69.646735], //
          [42.384195, 69.651757],
          [42.384619, 69.657063],
          [42.384877, 69.659659],
          [42.384984, 69.661146],
          [42.385046, 69.661299],
          [42.385153, 69.661506],
          [42.385209, 69.661658],
          [42.385255, 69.661937],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384984, 69.661146], //
          [42.384439, 69.661366], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.385714, 69.661070], //
          [42.385048, 69.661298], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.382578, 69.643409], //
          [42.382699, 69.645143], //
          [42.382855, 69.646875],
          [42.383332, 69.651901],
          [42.383543, 69.654277],
          [42.383773, 69.657196],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381094, 69.629151], //
          [42.381127, 69.630158], //
          [42.381145, 69.633197], //3
          [42.381157, 69.634630],
          [42.381216, 69.635037],
          [42.381246, 69.635166],
          [42.381360, 69.635651],
          [42.381726, 69.637108],
          [42.382048, 69.638424], //Жас Канат
          [42.382118, 69.638755],
          [42.382143, 69.639017],
          [42.382160, 69.639438],
          [42.382154, 69.639711],
          [42.382139, 69.639873],
          [42.382024, 69.640601],
          [42.381687, 69.642643],
          [42.381682, 69.642773], //Алтынсарина
          [42.381696, 69.643015],
          [42.381723, 69.643217],
          [42.382170, 69.645545], //4
          [42.382218, 69.645867],
          [42.382224, 69.646074],
          [42.382190, 69.646276],
          [42.381895, 69.647069], //Арыстанбая
          [42.381600, 69.647793],
          [42.381535, 69.648048],
          [42.381520, 69.648153],
          [42.381520, 69.648243],
          [42.381534, 69.648361],
          [42.381559, 69.648471],
          [42.381616, 69.648642],
          [42.382729, 69.651095],
          [42.382863, 69.651534],
          [42.382941, 69.651984], //Даирбекова
          [42.383559, 69.657229], //5
          [42.383601, 69.657652],
          [42.383647, 69.657923],
          [42.383693, 69.658148],
          [42.384436, 69.661364],
          [42.384477, 69.661577],
          [42.384494, 69.661728],
          [42.384500, 69.661884],
          [42.384500, 69.662041],
          [42.384492, 69.662184],
          [42.384464, 69.662403], //Сиргели
          [42.384436, 69.662647],
          [42.383778, 69.666427],
          [42.384267, 69.666589],
          [42.384568, 69.666673],
          
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384267, 69.666589], //
          [42.384354, 69.666174], //
          [42.384373, 69.666141],
          [42.384413, 69.666106],
          [42.384467, 69.665086],
          [42.384018, 69.665064],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381145, 69.633197], //
          [42.377990, 69.633748], //
          [42.377866, 69.633813],
          [42.376794, 69.633995],
          [42.376767, 69.634050],
          [42.376771, 69.634086],
          [42.376931, 69.635700],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.382048, 69.638424], //
          [42.381790, 69.638497], //
          [42.381062, 69.638591],
          [42.380155, 69.638750],
          [42.379274, 69.638913],
          [42.379179, 69.638954],
          [42.378371, 69.639110], //Жамауова
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381682, 69.642773], //
          [42.377763, 69.643410], //
          [42.375868, 69.643621],
          [42.372403, 69.644353],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.380311, 69.629222], //
          [42.380305, 69.629571], //
          [42.380318, 69.629865],
          [42.380432, 69.631346],
          [42.380535, 69.633233],
          [42.380538, 69.633308],
          [42.381064, 69.638592],
          [42.381439, 69.642807],
          [42.381610, 69.645089],
          [42.381646, 69.645345],
          [42.381697, 69.645513],
          [42.381722, 69.645653],
          [42.381739, 69.645836],
          [42.381838, 69.647078],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.380305, 69.629571], //
          [42.381049, 69.629498], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381895, 69.647070], //
          [42.380906, 69.647173], //
          [42.377895, 69.647675],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377897, 69.647736], //
          [42.377411, 69.647807], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.379403, 69.629301], //
          [42.379490, 69.630841], //
          [42.379701, 69.633434],
          [42.380156, 69.638750],
          [42.380545, 69.642958],
          [42.380904, 69.647177],
          [42.381382, 69.652231],
          [42.381849, 69.657545],
          [42.382312, 69.662763],
          [42.382774, 69.668037],
          [42.382797, 69.668381],
          [42.382840, 69.668855],
          [42.382848, 69.669134],
          [42.382780, 69.669426],
          [42.382541, 69.669947],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.382111, 69.650757], //
          [42.382256, 69.652080], //
          [42.382755, 69.657373],
          [42.383207, 69.662636],
          [42.383569, 69.667641],
          
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378491, 69.629364],
          [42.378504, 69.629671], //
          [42.378810, 69.633583], //
          [42.379067, 69.636292],
          [42.379273, 69.638913],
          [42.379642, 69.643108],
          [42.380028, 69.647338],
          [42.380471, 69.652374],
          [42.380951, 69.657713],
          [42.381404, 69.662880],
          [42.381881, 69.668336],
          [42.382126, 69.671491],
          [42.382137, 69.671747],
          [42.382114, 69.671800],
          [42.382002, 69.671899],
          [42.381962, 69.671839],
          [42.381929, 69.671820],
          [42.381296, 69.671835], //Жамауова
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.382002, 69.671899], //
          [42.381762, 69.672539], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377102, 69.629337], //
          [42.377085, 69.629591], //
          [42.377086, 69.629780],
          [42.377129, 69.630366],
          [42.377400, 69.633895],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377628, 69.630304], //
          [42.377129, 69.630366], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377573, 69.629408], // Жамауова
          [42.377584, 69.629734], //
          [42.377628, 69.630304],
          [42.377915, 69.633784],
          [42.378372, 69.639109],
          [42.378760, 69.643246],
          [42.379132, 69.647480],
          [42.379561, 69.652511],
          [42.380512, 69.662998],
          [42.380932, 69.668053],
          [42.381296, 69.671665],
          [42.381296, 69.671839],
          [42.381293, 69.671910],
          [42.381275, 69.672022],
          [42.381250, 69.672121],
          [42.381220, 69.672206],
          [42.380792, 69.673109],
          [42.380675, 69.673223],
          [42.380557, 69.673415], //Наурыз
          [42.379856, 69.675115],
          [42.379806, 69.675221], // Каратау
          [42.379570, 69.675842],
          [42.379542, 69.675887],
          [42.379503, 69.675925],
          [42.379463, 69.675950],
          [42.379360, 69.675965],
          [42.379144, 69.675965],
          [42.378160, 69.675949],
          [42.377721, 69.675861],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.379144, 69.675965], //
          [42.379166, 69.676621], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378136, 69.636341], //
          [42.377210, 69.636482], //
          [42.377442, 69.639138],
          [42.377764, 69.642796],
          [42.377753, 69.643204],
          [42.377762, 69.643409],
          [42.377896, 69.647737],
          [42.378071, 69.652780],
          [42.378077, 69.653260],
          [42.378072, 69.653302],
          [42.378063, 69.653332],
          [42.378041, 69.653360],
          [42.378012, 69.653377],
          [42.377907, 69.653413], //Кахарман
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377210, 69.636482], //
          [42.377026, 69.636523],
          [42.376955, 69.636539], //
          
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377026, 69.636523], //
          [42.376970, 69.636037], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377442, 69.639138], //
          [42.378359, 69.638984], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378724, 69.647539], //
          [42.378916, 69.652606], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.382943, 69.651983], //
          [42.378916, 69.652606], //
          [42.378710, 69.652669],
          [42.378078, 69.652792],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378710, 69.652669], //
          [42.378940, 69.655054], //
          [42.379154, 69.658009],
          [42.379617, 69.663115],
          [42.380047, 69.667809],
          [42.380489, 69.673113],
          [42.380527, 69.673308],
          [42.380555, 69.673417],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.385352, 69.663278], //
          [42.385377, 69.663128], //
          [42.385348, 69.662795],
          [42.385343, 69.662563],
          [42.385356, 69.662474],
          [42.385355, 69.662430],
          [42.385340, 69.662386],
          [42.385315, 69.662353],
          [42.385285, 69.662343],
          [42.384871, 69.662393],
          [42.384660, 69.662427],
          [42.384564, 69.662422],
          [42.384461, 69.662400],
          [42.382313, 69.662766],
          [42.378338, 69.663286],
          [42.377892, 69.663336],
          [42.377805, 69.663320],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384871, 69.662393], //
          [42.384826, 69.663099], //
          [42.384852, 69.663223],
          [42.384901, 69.663305],
          [42.384973, 69.663361],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376625, 69.629187], //
          [42.376584, 69.629337], //
          [42.376515, 69.629680],
          [42.376474, 69.629839],
          [42.376340, 69.630270],
          [42.376328, 69.630331],
          [42.376318, 69.630468],
          [42.376322, 69.630546],
          [42.376955, 69.636539],
          [42.377191, 69.638831],
          [42.377651, 69.643418],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377191, 69.638831], //
          [42.376260, 69.639070], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375382, 69.628485], //
          [42.375364, 69.628749], //
          [42.375362, 69.628849],
          [42.375536, 69.633089],
          [42.375564, 69.633644],
          [42.375640, 69.634160],
          [42.376034, 69.637173],
          [42.376765, 69.643338],
          [42.376786, 69.643524],
          [42.377068, 69.645634],
          [42.377414, 69.647813],
          [42.377667, 69.650558],
          [42.377909, 69.653408],
          [42.378012, 69.654731],
          [42.378090, 69.656065],
          [42.378161, 69.658537],
          [42.378216, 69.660494],
          [42.378339, 69.663286],
          [42.378518, 69.667371],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375640, 69.634160], //
          [42.376126, 69.633975], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376034, 69.637173], //
          [42.375189, 69.637301], //
          [42.375089, 69.637254],
          [42.374076, 69.637150],
          [42.373773, 69.637117],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376765, 69.643338], //
          [42.376937, 69.643306], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378161, 69.658537], //
          [42.377710, 69.658748], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384464, 69.668295], //
          [42.384228, 69.668299], //
          [42.383958, 69.668348],
          [42.383628, 69.668376],
          [42.383387, 69.668345],
          [42.383042, 69.668292],
          [42.382921, 69.668310],
          [42.382841, 69.668338],
          [42.382796, 69.668378],
          [42.382693, 69.668440],
          [42.382567, 69.668472],
          [42.382428, 69.668466],
          [42.382259, 69.668434],
          [42.378195, 69.667280],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375111, 69.635065], //
          [42.375127, 69.636164], //
          [42.375084, 69.637256],
          
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375190, 69.637300], //
          [42.375245, 69.638123], //
          [42.375291, 69.638536],
          [42.375465, 69.639696],
          [42.375612, 69.641146],
          [42.375867, 69.643630],
          
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.374691, 69.637232], //
          [42.374895, 69.642775], //
        ],
        color: '#0000FF'
      },

      {
        coordinates: [
          [42.374069, 69.637150], //
          [42.374033, 69.640594], //
          [42.374030, 69.640782],
          [42.374020, 69.640922],
          [42.373984, 69.641196],
          [42.374007, 69.641417],
          [42.374382, 69.643948],
          [42.374780, 69.645847],
          [42.375144, 69.647484],
          [42.375459, 69.648935],
          [42.375854, 69.651021],
          [42.376427, 69.653695],
          [42.376734, 69.655053],
          [42.376808, 69.655277],
          [42.377103, 69.656366],
          [42.377711, 69.658745],
          [42.377964, 69.659773],
          [42.378058, 69.660077],
          [42.378215, 69.660494],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.373983, 69.641195], //
          [42.373688, 69.641088], //
          [42.372944, 69.640940],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.372446, 69.643061], //
          [42.372576, 69.643098], //
          [42.372967, 69.643119],
          [42.372999, 69.643143],
          [42.373038, 69.643203],
          [42.373077, 69.643213],
          [42.373093, 69.643227],
          [42.373101, 69.643257],
          [42.373253, 69.644211],
          [42.373629, 69.646354],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.373629, 69.646354], //
          [42.374002, 69.646185], //
          [42.374195, 69.646089],
          [42.374726, 69.645895],
          [42.374808, 69.645820],
          [42.375625, 69.645485],
          [42.375437, 69.643711],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375625, 69.645485], //
          [42.375757, 69.646254], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377196, 69.646551], //
          [42.376774, 69.646624], //
          [42.376414, 69.646726],
          [42.376306, 69.646785],
          [42.375388, 69.647365],
          [42.375146, 69.647484],
          [42.374324, 69.647813], //Торткультобе
          [42.373783, 69.648095], //Шымыр
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.373628, 69.646356], //
          [42.373438, 69.646483], //
          [42.373461, 69.646782],
          [42.373783, 69.648095],
          [42.373931, 69.648712],
          [42.374018, 69.649101],
          [42.374294, 69.650348],
          [42.374735, 69.652285],
          [42.374834, 69.652640],
          [42.374941, 69.652946],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.374002, 69.646183], //
          [42.374139, 69.646859], //
          [42.374324, 69.647813],
          [42.374518, 69.648714],
          [42.374968, 69.650698],
          [42.375474, 69.652631],
          [42.375758, 69.653955],
          [42.375787, 69.653994],
          [42.375832, 69.653997],
          [42.375853, 69.653991],
          [42.376423, 69.653699],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376309, 69.648251], //
          [42.376376, 69.648656], //
          [42.376569, 69.649843],
          [42.376690, 69.650772],
          [42.376824, 69.651761],
          [42.376910, 69.652275],
          [42.376956, 69.652666],
          [42.377340, 69.654999],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376376, 69.648656], //
          [42.375461, 69.648935], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376376, 69.648656], //
          [42.376855, 69.648495], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376690, 69.650772], //
          [42.377665, 69.650566], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376690, 69.650772], //
          [42.376364, 69.650849], //
          [42.375853, 69.651016],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377340, 69.654999], //
          [42.378011, 69.654730], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377340, 69.654999], //
          [42.376809, 69.655276], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378089, 69.656066], //
          [42.377105, 69.656366], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378725, 69.663232], //
          [42.378850, 69.664256], //
          [42.379116, 69.667530],
          [42.379414, 69.671167],
          [42.379806, 69.675221],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.379414, 69.671167], //
          [42.378526, 69.671314], //
          [42.378379, 69.671298],
          [42.378109, 69.671228],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378669, 69.668959], //
          [42.378673, 69.669425], //
          [42.378669, 69.669614],
          [42.378660, 69.669753],
          [42.378526, 69.671314],
          [42.378471, 69.671938],
          [42.378471, 69.672108],
          [42.378497, 69.672383],
          [42.378630, 69.672711],
          [42.378656, 69.672825],
          [42.378956, 69.675948],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378340, 69.673606], //
          [42.378258, 69.674480], //
          [42.378160, 69.675932],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378258, 69.674480], //
          [42.377834, 69.674445], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },{
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },

      {
        coordinates: [
          [42.297456, 69.571624], // Бадамская
          [42.297889, 69.570469],
          [42.297946, 69.570346],
          [42.298006, 69.570249],
          [42.299002, 69.568852],
          [42.299115, 69.568736],
          [42.299232, 69.568678],
          [42.299291, 69.568671],
          [42.299470, 69.568694],
          [42.299542, 69.568691],
          [42.299602, 69.568679],
          [42.299667, 69.568663],
          [42.299719, 69.568634],
          [42.299796, 69.568559],
          [42.299921, 69.568354],
          [42.300089, 69.568036],
          [42.300155, 69.567863],
          [42.300186, 69.567749],
          [42.300594, 69.565956],
          [42.300872, 69.564600],
          [42.301513, 69.562066],
          [42.301627, 69.561750],
          [42.301993, 69.561210],
          [42.302076, 69.561042],
          [42.302155, 69.560778],
          [42.302291, 69.560196],
          [42.302579, 69.558731],
          [42.302692, 69.558138],
          [42.302889, 69.557382],
          [42.302889, 69.557382],
          [42.303045, 69.556912],
          [42.303092, 69.556784],
          [42.303272, 69.556217],
          [42.304128, 69.553343],
          [42.304166, 69.553171],
          [42.304245, 69.552509],
          [42.304264, 69.552178],
          [42.304187, 69.551191],
          [42.304192, 69.551137],
          [42.304075, 69.551016],
          [42.304001, 69.550932],
          [42.303958, 69.550866],
          [42.303925, 69.550692],
          [42.303893, 69.550035],
          [42.303867, 69.549908],
          [42.303847, 69.549858],
          [42.303633, 69.549450],
          [42.303580, 69.549397],
          [42.303368, 69.549263],
          [42.303123, 69.549060],
          [42.303086, 69.549089],
          [42.303048, 69.549147],
          [42.303020, 69.549238],
          [42.302983, 69.549400],
          [42.302946, 69.549609],
          [42.302896, 69.549773],
          [42.302857, 69.549867],
          [42.302802, 69.549965], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.303964, 69.548182], // 3-я Бадамская
          [42.303990, 69.547949],
          [42.304003, 69.547719],
          [42.303984, 69.547129],
          [42.303942, 69.546974],
          [42.303776, 69.546422],
          [42.303675, 69.546012],
          [42.303632, 69.545772],
          [42.303619, 69.545476], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.304727, 69.546865], // 
          [42.304095, 69.546932],
          [42.303943, 69.546975], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.304191, 69.551136], // Курылыс
          [42.304210, 69.551081],
          [42.304288, 69.551011],
          [42.304471, 69.550977],
          [42.304596, 69.550952],
          [42.304630, 69.550927],
          [42.304646, 69.550895],
          [42.304656, 69.550867],
          [42.304667, 69.550773],
          [42.304674, 69.550593],
          [42.304650, 69.550097],
          [42.304649, 69.549677],
          [42.304637, 69.549274],
          [42.304517, 69.548527],
          [42.304503, 69.548492],
          [42.304426, 69.548402],
          [42.304306, 69.548318],
          [42.303848, 69.548133],
          [42.303564, 69.548092],
          [42.303028, 69.548076],
          [42.302391, 69.547811],
          [42.302329, 69.547743],
          [42.302306, 69.547697],
          [42.302157, 69.547116],
          [42.302138, 69.547009],
          [42.302101, 69.546617],
          [42.302105, 69.546484],
          [42.302124, 69.546338],
          [42.302220, 69.545954],
          [42.302282, 69.545793],
          [42.302303, 69.545759],
          [42.302241, 69.545179],
          [42.302220, 69.545025],
          [42.302200, 69.544943],
          [42.302172, 69.544870],
          [42.302142, 69.544812],
          [42.302092, 69.544731],
          [42.302050, 69.544682],
          [42.302016, 69.544655],
          [42.301966, 69.544643],
          [42.301808, 69.544631],
          [42.300652, 69.544415],
          [42.300590, 69.544406],
          [42.300539, 69.544416],
          [42.300492, 69.544444],
          [42.300447, 69.544482],
          [42.300258, 69.544734],
          [42.300214, 69.544773],
          [42.300174, 69.544790],
          [42.300007, 69.544788],
          [42.300012, 69.544806],
          [42.300017, 69.544854],
          [42.300036, 69.544908],
          [42.300154, 69.545024],
          [42.300456, 69.544955],
          [42.300533, 69.544949],
          [42.300705, 69.544986],
          [42.300706, 69.544987], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302322, 69.547732], // Кирпичный тупик
          [42.302313, 69.547744],
          [42.302291, 69.547836],
          [42.302136, 69.547705],
          [42.301969, 69.547515],
          [42.301754, 69.547344],
          [42.301675, 69.547301],
          [42.301554, 69.547247],
          [42.301397, 69.547190],
          [42.301164, 69.547094],
          [42.300835, 69.546982], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302290, 69.547835], // Багысбаева
          [42.302224, 69.548197],
          [42.302195, 69.548314],
          [42.302156, 69.548421],
          [42.302061, 69.548397],
          [42.301874, 69.548340],
          [42.301706, 69.548272],
          [42.301589, 69.548218],
          [42.301545, 69.548206],
          [42.301499, 69.548202],
          [42.301321, 69.548196],
          [42.301109, 69.547991],
          [42.301070, 69.547965],
          [42.301028, 69.547956],
          [42.300946, 69.547994],
          [42.300785, 69.548171], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.304264, 69.552169], // Меркенская
          [42.302642, 69.551805],
          [42.302585, 69.551801],
          [42.302543, 69.551822],
          [42.302507, 69.551860],
          [42.302432, 69.552018], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.303951, 69.553930], // Бадам-1
          [42.303610, 69.553808],
          [42.302650, 69.553482],
          [42.302621, 69.553488],
          [42.302595, 69.553504],
          [42.302573, 69.553535],
          [42.302506, 69.553925],
          [42.302431, 69.554613], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.303280, 69.556195], // Акку
          [42.303152, 69.556092],
          [42.303026, 69.555967],
          [42.302910, 69.555800],
          [42.302816, 69.555604],
          [42.302672, 69.555226],
          [42.302587, 69.555014],
          [42.302429, 69.554757],
          [42.302432, 69.554614],
          [42.302219, 69.554541],
          [42.302100, 69.554510],
          [42.301966, 69.554481],
          [42.301786, 69.554464],
          [42.301654, 69.554461],
          [42.301545, 69.554464],
          [42.301457, 69.554477],
          [42.301373, 69.554508],
          [42.300739, 69.555032],
          [42.300536, 69.555190],
          [42.300058, 69.555499], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302428, 69.554758], // Курылыс
          [42.302066, 69.555119],
          [42.302017, 69.555159],
          [42.301986, 69.555206],
          [42.301933, 69.555386],
          [42.301760, 69.556099], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302830, 69.557625], // Улгили
          [42.302610, 69.557506],
          [42.302571, 69.557472],
          [42.302571, 69.557472],
          [42.302509, 69.557345],
          [42.302480, 69.557310],
          [42.302195, 69.557183],
          [42.301940, 69.557121],
          [42.301883, 69.557101],
          [42.301830, 69.557048],
          [42.301329, 69.556648],
          [42.301274, 69.556640],
          [42.301261, 69.556653],
          [42.301183, 69.557021],
          [42.301188, 69.557354],
          [42.301201, 69.557441],
          [42.301313, 69.557633],
          [42.301394, 69.557924], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302696, 69.558126], // 
          [42.301813, 69.558063], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302555, 69.558862], // Акку
          [42.302293, 69.558849],
          [42.301996, 69.558855],
          [42.301466, 69.558892], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302490, 69.559184], // Вольного
          [42.302357, 69.559171],
          [42.302255, 69.559189],
          [42.302165, 69.559231],
          [42.302076, 69.559298],
          [42.301954, 69.559416],
          [42.301804, 69.559601],
          [42.301745, 69.559651],
          [42.301688, 69.559676],
          [42.301629, 69.559681],
          [42.301565, 69.559666],
          [42.301430, 69.559571], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299641, 69.564323], // Гулисман
          [42.299348, 69.564504],
          [42.299154, 69.564648],
          [42.299046, 69.564755],
          [42.298946, 69.564879],
          [42.298862, 69.565005],
          [42.298824, 69.565113],
          [42.298833, 69.565182],
          [42.298886, 69.565338],
          [42.298892, 69.565403],
          [42.298880, 69.565456],
          [42.298828, 69.565548],
          [42.298562, 69.565915],
          [42.298242, 69.566379],
          [42.298019, 69.566633],
          [42.296161, 69.569039],
          [42.295708, 69.569755],
          [42.295662, 69.569865],
          [42.295641, 69.569962],
          [42.295647, 69.570064],
          [42.295681, 69.570156],
          [42.295717, 69.570231],
          [42.296280, 69.570934],
          [42.296295, 69.570938],
          [42.296558, 69.570629],
          [42.296820, 69.570265],
          [42.297367, 69.569460],
          [42.297448, 69.569388],
          [42.297744, 69.569744],
          [42.297795, 69.569763],
          [42.297831, 69.569750],
          [42.298617, 69.568653],
          [42.298731, 69.568481],
          [42.299026, 69.568826], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299015, 69.567294], // Подстанция
          [42.298249, 69.566370], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299333, 69.566298], // 
          [42.298834, 69.565538], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.301430, 69.559571], // Спатаева
          [42.301074, 69.559275],
          [42.301011, 69.559183],
          [42.300950, 69.559118],
          [42.300950, 69.559118],
          [42.300890, 69.559094],
          [42.300768, 69.559083],
          [42.300644, 69.559097],
          [42.300469, 69.559059],
          [42.300352, 69.559062],
          [42.300287, 69.559071],
          [42.299858, 69.559065],
          [42.299727, 69.559032], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299727, 69.559032], // Рауан
          [42.299660, 69.557947],
          [42.299596, 69.557513],
          [42.299563, 69.557180],
          [42.299536, 69.556710],
          [42.299524, 69.556664],
          [42.299499, 69.556634],
          [42.299458, 69.556615],
          [42.299395, 69.556630],
          [42.299266, 69.556688],
          [42.299214, 69.556567],
          [42.299093, 69.556096],
          [42.299069, 69.555956],
          [42.299069, 69.555834],
          [42.299094, 69.555762],
          [42.299113, 69.555734],
          [42.299238, 69.555594],
          [42.299286, 69.555530],
          [42.299375, 69.555360],
          [42.299408, 69.555273],
          [42.299478, 69.554976],
          [42.299645, 69.553920],
          [42.299677, 69.553717],
          [42.299677, 69.553717],
          [42.299794, 69.553204],
          [42.299814, 69.553015],
          [42.299820, 69.552621],
          [42.299785, 69.552263],
          [42.299718, 69.551929],
          [42.299210, 69.550013],
          [42.299136, 69.549772],
          [42.299053, 69.549525],
          [42.299020, 69.549448],
          [42.298939, 69.549339],
          [42.298889, 69.549310],
          [42.298827, 69.549292],
          [42.298747, 69.549287],
          [42.298061, 69.549301],
          [42.297905, 69.549288],
          [42.297837, 69.549271],
          [42.297767, 69.549240],
          [42.297704, 69.549200],
          [42.297611, 69.549121], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299601, 69.554202], // Бозарык
          [42.299691, 69.554282],
          [42.299758, 69.554378],
          [42.299785, 69.554436],
          [42.299974, 69.555077],
          [42.300026, 69.555312],
          [42.300057, 69.555500],
          [42.300059, 69.555517],
          [42.300054, 69.555628],
          [42.300079, 69.555859],
          [42.300147, 69.556233],
          [42.300158, 69.556314],
          [42.300177, 69.556632],
          [42.300223, 69.556868],
          [42.300361, 69.557255],
          [42.300405, 69.557298],
          [42.300479, 69.557407],
          [42.300510, 69.557472],
          [42.300510, 69.557472],
          [42.300626, 69.557886],
          [42.300647, 69.558021],
          [42.300663, 69.558190], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.301428, 69.553278], // 
          [42.301260, 69.553116],
          [42.301226, 69.553092],
          [42.301189, 69.553078],
          [42.300855, 69.553092],
          [42.300814, 69.553087],
          [42.300767, 69.553059],
          [42.300720, 69.553004],
          [42.300677, 69.552982],
          [42.300464, 69.552964],
          [42.300392, 69.552947],
          [42.300233, 69.552872],
          [42.300179, 69.552855],
          [42.299818, 69.552798], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.301914, 69.551425], // 
          [42.301625, 69.551589],
          [42.301532, 69.551635],
          [42.301447, 69.551670],
          [42.301052, 69.551774],
          [42.300601, 69.551888],
          [42.299764, 69.552149], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.301065, 69.551770], // Жарық өткелі
          [42.301053, 69.551735],
          [42.301009, 69.551685],
          [42.300961, 69.551657],
          [42.300921, 69.551601],
          [42.300869, 69.551477],
          [42.300848, 69.551431],
          [42.300325, 69.550644],
          [42.300301, 69.550620],
          [42.299916, 69.550448],
          [42.299903, 69.550445],
          [42.299689, 69.550408],
          [42.299326, 69.550447], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.301555, 69.550624], //
          [42.301323, 69.550827],
          [42.301146, 69.551028],
          [42.301139, 69.551060],
          [42.301163, 69.551224], 
          [42.301327, 69.551603],
          [42.301346, 69.551699], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299917, 69.550447], // Курылыс
          [42.299939, 69.550340],
          [42.300209, 69.549934],
          [42.300221, 69.549893],
          [42.300225, 69.549791],
          [42.300217, 69.549751],
          [42.300118, 69.549564], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.300072, 69.548704], // Курылыс
          [42.299928, 69.548718],
          [42.299903, 69.548730],
          [42.299576, 69.549011],
          [42.299560, 69.549040],
          [42.299501, 69.549223],
          [42.299399, 69.549425],
          [42.299152, 69.549689],
          [42.299118, 69.549715], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299055, 69.548309], // Курылыс
          [42.299017, 69.548610],
          [42.299023, 69.548921],
          [42.299048, 69.549278],
          [42.299047, 69.549304],
          [42.299035, 69.549330],
          [42.298983, 69.549393], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299706, 69.547646], // Курылыс
          [42.299555, 69.547677],
          [42.299456, 69.547735],
          [42.299344, 69.547758],
          [42.299323, 69.547769],
          [42.299299, 69.547791],
          [42.299273, 69.547799],
          [42.299116, 69.547778],
          [42.298598, 69.547605],
          [42.298580, 69.547591],
          [42.298463, 69.547463], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.300947, 69.547994], // Строительный тупик
          [42.300690, 69.547822],
          [42.300442, 69.547627],
          [42.300413, 69.547597],
          [42.300380, 69.547540],
          [42.300329, 69.547402], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.301757, 69.543203], // Татарская Слобода
          [42.301660, 69.543526],
          [42.301619, 69.543854],
          [42.301589, 69.544016],
          [42.301570, 69.544586], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294169, 69.564806], // Тилеулес
          [42.293994, 69.564865],
          [42.293905, 69.564886],
          [42.293839, 69.564896],
          [42.293752, 69.564899],
          [42.293461, 69.564877],
          [42.292929, 69.564814],
          [42.292758, 69.564812],
          [42.292591, 69.564839],
          [42.292423, 69.564901],
          [42.292034, 69.565103],
          [42.291862, 69.565193],
          [42.291674, 69.565325],
          [42.291205, 69.565750], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294935, 69.568453], // Бақшалы-1
          [42.293687, 69.567457],
          [42.293334, 69.567189],
          [42.292775, 69.566707],
          [42.292567, 69.566533],
          [42.292567, 69.566533],
          [42.292406, 69.566455],
          [42.292320, 69.566419],
          [42.292286, 69.566394],
          [42.292135, 69.566259],
          [42.291888, 69.566000],
          [42.291596, 69.566360], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295478, 69.568169], // 
          [42.295635, 69.567746],
          [42.295643, 69.567702],
          [42.295643, 69.567671],
          [42.295621, 69.567648],
          [42.295539, 69.567586],
          [42.293029, 69.566044],
          [42.292762, 69.565875],
          [42.292372, 69.565449],
          [42.292030, 69.565102], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295995, 69.566665], // Бақшалы-3
          [42.295736, 69.567010],
          [42.295716, 69.567024],
          [42.295692, 69.567021],
          [42.293890, 69.565940],
          [42.293749, 69.565836],
          [42.293733, 69.565812],
          [42.293727, 69.565752],
          [42.293932, 69.565188],
          [42.293963, 69.565047],
          [42.293994, 69.564865], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],

        color: '#0000FF',
      },

      ///////////////////////// Састелеком 2022 //////////////////
      {
        coordinates: [
          [42.421215, 69.609217], // 
          [42.421029, 69.610648], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.422606, 69.609452], // 
          [42.422525, 69.609386],
          [42.422438, 69.609350],
          [42.422100, 69.609328],
          [42.421270, 69.609161],
          [42.421234, 69.609172],
          [42.421215, 69.609217], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.421746, 69.609256], // Бүркітті
          [42.421559, 69.610521],
          [42.421521, 69.610910],
          [42.421449, 69.611319],
          [42.421437, 69.611431],
          [42.421400, 69.611915],
          [42.421379, 69.612149],
          [42.421041, 69.614832], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.422606, 69.609452], // Бүлдірген
          [42.422495, 69.610253],
          [42.422463, 69.610560],
          [42.422243, 69.612436],
          [42.421899, 69.615364],
          [42.421548, 69.618347],
          [42.421419, 69.619645],
          [42.421439, 69.619694],
          [42.421473, 69.619732],
          [42.422263, 69.619904],
          [42.422212, 69.619888], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.423123, 69.612644], // Арша
          [42.422784, 69.615545],
          [42.422266, 69.619886],
          [42.422092, 69.621206],
          [42.422302, 69.621837],
          [42.422939, 69.623300],
          [42.423141, 69.623649],
          [42.423327, 69.624111],
          [42.423563, 69.624605],
          [42.423831, 69.625216],
          [42.423980, 69.625475], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.424041, 69.612854], // Қышқұдық
          [42.423045, 69.621203], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.426170, 69.610184], // Бүркітші
          [42.425090, 69.619117],
          [42.424663, 69.622758],
          [42.424753, 69.623966],
          [42.425626, 69.624863], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.427077, 69.610388], // Бөржар
          [42.426704, 69.613273],
          [42.425996, 69.619314],
          [42.425996, 69.619314],
          [42.425362, 69.624585], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.427981, 69.610564], // Ұлағат
          [42.427601, 69.613467],
          [42.427235, 69.616466],
          [42.426862, 69.619489],
          [42.426414, 69.623372],
          [42.426398, 69.623697],
          [42.426413, 69.623823],
          [42.426441, 69.623924],
          [42.426466, 69.623965],
          [42.426256, 69.624246],
          [42.425769, 69.624689],
          [42.425634, 69.624851],
          [42.425226, 69.625663],
          [42.425093, 69.625889],
          [42.424894, 69.626099],
          [42.424772, 69.626472], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.428857, 69.610737], // Құрақты
          [42.428690, 69.611910],
          [42.428631, 69.612456],
          [42.428599, 69.612862],
          [42.428482, 69.613637],
          [42.427693, 69.620200],
          [42.427668, 69.620433],
          [42.427604, 69.620858],
          [42.427547, 69.621128],
          [42.427532, 69.621283],
          [42.427517, 69.621334],
          [42.427214, 69.621860],
          [42.427103, 69.622072],
          [42.427058, 69.622182],
          [42.426698, 69.623316],
          [42.426563, 69.623729],
          [42.426467, 69.623965], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.429750, 69.610865], // Құмдыкөл
          [42.429716, 69.611018],
          [42.429698, 69.611195],
          [42.429684, 69.611622],
          [42.429648, 69.611914],
          [42.429591, 69.612436],
          [42.429566, 69.612604],
          [42.429553, 69.612769],
          [42.429441, 69.613352],
          [42.429386, 69.613819],
          [42.429019, 69.616887],
          [42.428800, 69.618718],
          [42.428689, 69.619193],
          [42.428570, 69.619518],
          [42.428449, 69.619771],
          [42.428349, 69.619902], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.432324, 69.622149], // Томирис
          [42.428942, 69.620491],
          [42.428611, 69.620305],
          [42.428485, 69.620186],
          [42.428381, 69.620010],
          [42.428349, 69.619902], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.428355, 69.619916], // 
          [42.428291, 69.619817],
          [42.428226, 69.619783],
          [42.425104, 69.619104],
          [42.423358, 69.618740],
          [42.421561, 69.618343], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.432204, 69.623511], // Қырықкөл
          [42.427690, 69.621248],
          [42.427543, 69.621222], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.431977, 69.624864], // Жосалы
          [42.431206, 69.624459],
          [42.430480, 69.624121],
          [42.427167, 69.622448],
          [42.427014, 69.622319], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.429407, 69.625088], // Сиректас
          [42.428749, 69.624742],
          [42.428463, 69.624614],
          [42.427430, 69.624061], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.432366, 69.627776], // Кеңтөбе
          [42.432078, 69.627593],
          [42.429188, 69.626119],
          [42.427789, 69.625434],
          [42.427076, 69.625052], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.432079, 69.627596], // Ықылас
          [42.432049, 69.627709],
          [42.432008, 69.627815],
          [42.429036, 69.634607], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.433615, 69.610537], // Уста-Махамата
          [42.433603, 69.610592],
          [42.433432, 69.610962],
          [42.433354, 69.611168],
          [42.433184, 69.611745],
          [42.433107, 69.612125],
          [42.433063, 69.612598],
          [42.433065, 69.613752],
          [42.433045, 69.614387],
          [42.432669, 69.618292],
          [42.432504, 69.620929],
          [42.432441, 69.621546],
          [42.432292, 69.622491],
          [42.432224, 69.623450],
          [42.432214, 69.623533],
          [42.431874, 69.625498],
          [42.431824, 69.625666],
          [42.431571, 69.626232],
          [42.430087, 69.629766],
          [42.427315, 69.636229],
          [42.427216, 69.636672], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.430211, 69.626642], // Енбек
          [42.426049, 69.636441],
          [42.426006, 69.636568],
          [42.425975, 69.636721],
          [42.425971, 69.636839], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.432344, 69.631421],// Желтау
          [42.432319, 69.631356],
          [42.432282, 69.631300],
          [42.432215, 69.631245], 
          [42.429189, 69.629145],
          [42.425420, 69.626831],
          [42.424775, 69.626462], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.281061, 69.589399], // Коктобе
          [42.280934, 69.588493],
          [42.280878, 69.588371],
          [42.280789, 69.588245],
          [42.280750, 69.588204],
          [42.280653, 69.588142],
          [42.280582, 69.588128],
          [42.280021, 69.588157],
          [42.279487, 69.588275],
          [42.279145, 69.588443],
          [42.278597, 69.588635],
          [42.278134, 69.588687],
          [42.277750, 69.588817],
          [42.277671, 69.588833],
          [42.277446, 69.588845],
          [42.277299, 69.588803],
          [42.276205, 69.588016],
          [42.276026, 69.587830],
          [42.275781, 69.587522],
          [42.270033, 69.583746],
          [42.269724, 69.583512],
          [42.269626, 69.583459],
          [42.269301, 69.583301],
          [42.269147, 69.583203],
          [42.268943, 69.583024],
          [42.268782, 69.582913],
          [42.267226, 69.581938],
          [42.266682, 69.581653], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.275640, 69.587692], // 
          [42.275329, 69.587553],
          [42.275222, 69.587524],
          [42.275117, 69.587532],
          [42.274883, 69.587644],
          [42.274825, 69.587647],
          [42.274756, 69.587627],
          [42.274471, 69.587426],
          [42.274141, 69.587257],
          [42.274027, 69.587215],
          [42.273898, 69.587185],
          [42.273459, 69.587158],
          [42.273341, 69.587073],
          [42.272873, 69.586829],
          [42.272552, 69.586599],
          [42.271852, 69.586149],
          [42.269826, 69.584864],
          [42.269249, 69.584446],
          [42.267938, 69.583611], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.269442, 69.585734], // 
          [42.266548, 69.583870], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.269880, 69.584901], // 
          [42.269776, 69.585351],
          [42.269671, 69.585331],
          [42.269609, 69.585342],
          [42.269569, 69.585369],
          [42.269515, 69.585452],
          [42.269441, 69.585733],
          [42.269442, 69.585734], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.281394, 69.587066], // Байтөбе
          [42.281158, 69.586971],
          [42.280964, 69.586968],
          [42.280804, 69.586941],
          [42.280566, 69.586939],
          [42.279452, 69.587134],
          [42.279002, 69.587244],
          [42.278681, 69.587365],
          [42.278502, 69.587395],
          [42.278390, 69.587393],
          [42.277320, 69.587192],
          [42.276447, 69.586708],
          [42.276369, 69.586796],
          [42.276128, 69.586533],
          [42.275888, 69.586371],
          [42.275280, 69.585885],
          [42.274809, 69.585440],
          [42.274397, 69.585155],
          [42.274082, 69.584966],
          [42.272537, 69.584069],
          [42.272276, 69.583876], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.279453, 69.587135], // Байтөбе
          [42.279187, 69.586473],
          [42.279141, 69.586420],
          [42.277083, 69.585569],
          [42.276944, 69.585539], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.271328, 69.585817], // 
          [42.271219, 69.585853],
          [42.271176, 69.585848],
          [42.270890, 69.585722],
          [42.270836, 69.585719],
          [42.270608, 69.585780],
          [42.270542, 69.585765],
          [42.269781, 69.585369],
          [42.269669, 69.585349],
          [42.269612, 69.585360],
          [42.269577, 69.585384],
          [42.269527, 69.585462],
          [42.269452, 69.585741],
          [42.269441, 69.585826],
          [42.269410, 69.585905],
          [42.269272, 69.586050],
          [42.267882, 69.589763],
          [42.267794, 69.589880],
          [42.267634, 69.589956],
          [42.266471, 69.590283],
          [42.266317, 69.590359],
          [42.266229, 69.590438],
          [42.266209, 69.590481], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.276944, 69.585538], // Таусамалы
          [42.276935, 69.585776],
          [42.276912, 69.585915],
          [42.276888, 69.586001],
          [42.276822, 69.586158],
          [42.276656, 69.586446],
          [42.276448, 69.586730],
          [42.276033, 69.587175],
          [42.275783, 69.587543],
          [42.275151, 69.588385],
          [42.274194, 69.589156],
          [42.273917, 69.589328],
          [42.273219, 69.589972], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.275161, 69.588375], // Ақмола
          [42.275481, 69.589101],
          [42.274746, 69.589685],
          [42.273971, 69.590345],
          [42.273741, 69.590428],
          [42.273670, 69.590425],
          [42.273554, 69.590441],
          [42.273479, 69.590498],
          [42.273258, 69.590792],
          [42.271826, 69.593012],
          [42.271737, 69.593184],
          [42.271692, 69.593308], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.273711, 69.592997], // Ақмола
          [42.272840, 69.591440], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.273374, 69.592394], // Ақмола
          [42.272695, 69.593211],
          [42.271827, 69.594146], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.273784, 69.591684], // Ақмола
          [42.273420, 69.591076],
          [42.273268, 69.590778], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.274709, 69.591828], // Ақ Орда
          [42.273984, 69.590337], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.276629, 69.588324], // Ақ Орда
          [42.276048, 69.589246],
          [42.275703, 69.589642],
          [42.274230, 69.590841], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.275711, 69.589634], // Ақ Орда
          [42.276204, 69.590574],
          [42.276225, 69.590633],
          [42.276228, 69.590710],
          [42.276199, 69.590795],
          [42.275909, 69.591157],
          [42.275628, 69.591388],
          [42.275586, 69.591455],
          [42.275514, 69.591635],
          [42.275447, 69.591722],
          [42.275307, 69.591826],
          [42.275129, 69.592109],
          [42.275008, 69.592220], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.277418, 69.588841], // 
          [42.277058, 69.589139],
          [42.277062, 69.589173],
          [42.277290, 69.589651],
          [42.277288, 69.589710],
          [42.277272, 69.589735],
          [42.277191, 69.589793],
          [42.277086, 69.589897],
          [42.277023, 69.589994],
          [42.276982, 69.590079],
          [42.276972, 69.590147], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.272205, 69.595263], // Бактыораза Бейсекбаева
          [42.272094, 69.595237],
          [42.272030, 69.595244],
          [42.271826, 69.595298],
          [42.271433, 69.595341],
          [42.271387, 69.595361],
          [42.270716, 69.595964],
          [42.270482, 69.596282],
          [42.270428, 69.596409],
          [42.270387, 69.596597],
          [42.270364, 69.597607], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.272205, 69.595263], // Бактыораза Бейсекбаева
          [42.272204, 69.595261],
          [42.272887, 69.595591],
          [42.272941, 69.595633],
          [42.272970, 69.595676],
          [42.272992, 69.595742],
          [42.272980, 69.595826],
          [42.272960, 69.595894],
          [42.272929, 69.595971],
          [42.272867, 69.596032],
          [42.272867, 69.596032],
          [42.271925, 69.596028],
          [42.271894, 69.596081],
          [42.271885, 69.596159],
          [42.271882, 69.596833],
          [42.271861, 69.597460],
          [42.271840, 69.597647],
          [42.271802, 69.597792],
          [42.271685, 69.598070],
          [42.271655, 69.598170],
          [42.271641, 69.598203], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.271886, 69.597075], // Жәдігер
          [42.272611, 69.597128], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.272342, 69.597678], // Алтынсарин
          [42.271845, 69.597632], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.274104, 69.596923], // Молдир булак
          [42.273605, 69.596625],
          [42.273567, 69.596627],
          [42.273547, 69.596677],
          [42.273537, 69.596854],
          [42.273547, 69.596896],
          [42.273571, 69.596930],
          [42.273826, 69.597200],
          [42.273463, 69.597942],
          [42.273258, 69.598280],
          [42.273206, 69.598415],
          [42.273044, 69.599114],
          [42.272911, 69.599626], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.275532, 69.599000], // Ермекова
          [42.274468, 69.598644],
          [42.274419, 69.598669],
          [42.274357, 69.598669],
          [42.273694, 69.598537],
          [42.273243, 69.598319], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.274480, 69.598645], // 
          [42.274547, 69.598325],
          [42.274555, 69.598241],
          [42.274548, 69.598162],
          [42.274494, 69.597979],
          [42.274431, 69.597872],
          [42.273824, 69.597199], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.279158, 69.598273], // Қарабастау
          [42.278810, 69.599154],
          [42.278765, 69.599213],
          [42.278730, 69.599209],
          [42.275796, 69.597858],
          [42.275047, 69.597515],
          [42.274419, 69.597169],
          [42.274379, 69.597029],
          [42.274194, 69.596865], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.279512, 69.598439], // Сыдыка Нурашбекова
          [42.277409, 69.597418],
          [42.277292, 69.597380],
          [42.277162, 69.597410],
          [42.277096, 69.597412],
          [42.277002, 69.597387],
          [42.276808, 69.597271],
          [42.276522, 69.597156],
          [42.276014, 69.596923],
          [42.276032, 69.596831],
          [42.274938, 69.596312],
          [42.273782, 69.595587],
          [42.273637, 69.595449], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.277244, 69.597369], // 
          [42.276924, 69.598375], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.279459, 69.597130], // Айкол
          [42.278819, 69.596850],
          [42.278526, 69.596709],
          [42.276323, 69.595792],
          [42.276171, 69.595755],
          [42.276070, 69.595721],
          [42.274899, 69.595136],
          [42.274681, 69.595013],
          [42.274068, 69.594585],
          [42.273992, 69.594520], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.274600, 69.593579], // Гажайып
          [42.276366, 69.594593],
          [42.276649, 69.594850],
          [42.276860, 69.594665],
          [42.276915, 69.594765],
          [42.277043, 69.594851],
          [42.279885, 69.596075], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.276479, 69.593820], // Имандылык
          [42.276335, 69.593679],
          [42.276064, 69.593071],
          [42.276031, 69.593020],
          [42.275778, 69.592664],
          [42.275737, 69.592571], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.276387, 69.592209], // Имандылык
          [42.275479, 69.592717], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.276387, 69.592209], // Имандылык
          [42.276421, 69.592208],
          [42.276458, 69.592223],
          [42.276579, 69.592313],
          [42.277079, 69.592904],
          [42.277585, 69.593473],
          [42.277675, 69.593608],
          [42.277744, 69.593746], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280117, 69.594887], // Уйымшыл
          [42.279339, 69.594519],
          [42.277962, 69.593950],
          [42.277892, 69.593916],
          [42.277830, 69.593868],
          [42.277744, 69.593746], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.277338, 69.592550], // 
          [42.276864, 69.591935],
          [42.276582, 69.592317], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280580, 69.593897], // Асыл Мура
          [42.280116, 69.593611],
          [42.279360, 69.593288],
          [42.278818, 69.593054],
          [42.278350, 69.592828], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.279016, 69.591824], // Кажымукан
          [42.278940, 69.591759],
          [42.278489, 69.591080],
          [42.278368, 69.590855],
          [42.278240, 69.590667],
          [42.278121, 69.590466],
          [42.278049, 69.590397],
          [42.278011, 69.590393],
          [42.277816, 69.590554],
          [42.277435, 69.591044], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280602, 69.592540], // Абая
          [42.279537, 69.592100],
          [42.279272, 69.591981],
          [42.279016, 69.591824], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.278490, 69.591081], // 
          [42.277858, 69.591742], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280311, 69.590172], // Сауле
          [42.280208, 69.590062],
          [42.279906, 69.589858],
          [42.279631, 69.589731],
          [42.279573, 69.589724],
          [42.279335, 69.589745],
          [42.279167, 69.589806],
          [42.278968, 69.589908],
          [42.278774, 69.590080],
          [42.278243, 69.590670], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280505, 69.591548], // Сауле
          [42.279958, 69.590634], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.279165, 69.589809], // Сауле
          [42.279325, 69.590225], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280350, 69.589384], // Сауле
          [42.279917, 69.589626],
          [42.279859, 69.589726],
          [42.279844, 69.589824], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.282071, 69.588915], // 
          [42.282071, 69.589218],
          [42.282096, 69.589449],
          [42.282155, 69.589690],
          [42.282155, 69.589735],
          [42.282110, 69.589838],
          [42.281983, 69.589941],
          [42.281973, 69.589965],
          [42.281973, 69.590002],
          [42.282026, 69.590228],
          [42.282019, 69.590246],
          [42.281106, 69.591136], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.282395, 69.588818], // Нуршашу
          [42.282158, 69.588056], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.283091, 69.588568], // Исатая Тайманулы
          [42.282684, 69.587303], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.283516, 69.587454], // Украинская
          [42.283081, 69.586050], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.283081, 69.586050], // П. Чайковского
          [42.283099, 69.585978],
          [42.283099, 69.585978],
          [42.283184, 69.585900],
          [42.287573, 69.583327],
          [42.289814, 69.582013],
          [42.289922, 69.581940],
          [42.290041, 69.581830],
          [42.290130, 69.581727],
          [42.291818, 69.579254], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.283146, 69.583753], // Баян батыр
          [42.284520, 69.587984], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.286076, 69.585997], // Нура
          [42.285043, 69.582871],
          [42.285016, 69.582824],
          [42.284954, 69.582763], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.286076, 69.585997], // Нура
          [42.286070, 69.586030],
          [42.286057, 69.586046],
          [42.284248, 69.587109], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.284884, 69.586749], // Нура
          [42.284668, 69.586162],
          [42.284620, 69.585915],
          [42.284441, 69.585267],
          [42.284433, 69.585179],
          [42.283887, 69.583700], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.283887, 69.583700], // Нура
          [42.283917, 69.583617],
          [42.284775, 69.583157],
          [42.284805, 69.583136],
          [42.284823, 69.583097],
          [42.284834, 69.582969],
          [42.284866, 69.582886],
          [42.284954, 69.582763], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.285487, 69.586387], // Нура
          [42.284964, 69.584854], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.284496, 69.583307], // Нура
          [42.284301, 69.582616], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.287222, 69.587211], // Бозарык
          [42.285541, 69.581957], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.287222, 69.587211], // Макаренко
          [42.289188, 69.586034],
          [42.289235, 69.586158],
          [42.289264, 69.586193],
          [42.289318, 69.586196],
          [42.290100, 69.585748], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.285541, 69.581957], // 
          [42.285550, 69.581910],
          [42.285562, 69.581884],
          [42.285867, 69.581673],
          [42.286468, 69.581118],
          [42.286917, 69.580841],
          [42.287429, 69.580559],
          [42.287864, 69.580315],
          [42.287962, 69.580228],
          [42.288055, 69.580071],
          [42.288105, 69.579892], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.286758, 69.582044], // Акжауын
          [42.286468, 69.581117],
          [42.286637, 69.580957],
          [42.286797, 69.580835],
          [42.286789, 69.580822],
          [42.286667, 69.580334],
          [42.286418, 69.579542], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.287040, 69.581881], // Макаренко
          [42.286438, 69.582225], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.287326, 69.585203], // Макаренко
          [42.286418, 69.582361],
          [42.286407, 69.582300],
          [42.286411, 69.582266],
          [42.286438, 69.582225], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.288063, 69.584812], // Макаренко
          [42.287108, 69.581916],
          [42.287079, 69.581881],
          [42.287041, 69.581880],
          [42.287040, 69.581881], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.288063, 69.584812], // Макаренко
          [42.288062, 69.584837],
          [42.288057, 69.584856],
          [42.288045, 69.584873],
          [42.287420, 69.585227],
          [42.287372, 69.585239],
          [42.287339, 69.585221],
          [42.287326, 69.585203], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.288025, 69.586032], // Макаренко
          [42.287732, 69.585049], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.288537, 69.586400], // Макаренко
          [42.288358, 69.585840], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.286962, 69.579164], // Достык
          [42.289232, 69.586167], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.292358, 69.575942], // Талтус
          [42.290823, 69.577921],
          [42.290993, 69.578147],
          [42.290335, 69.579076],
          [42.290292, 69.579120],
          [42.289624, 69.579355],
          [42.289237, 69.579595],
          [42.289169, 69.579562],
          [42.288759, 69.579777],
          [42.288612, 69.579831],
          [42.288312, 69.580010],
          [42.288211, 69.579929],
          [42.288104, 69.579892], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.288104, 69.579891], // Тобыл
          [42.288063, 69.579840],
          [42.287818, 69.579466],
          [42.287794, 69.579395],
          [42.287795, 69.579370],
          [42.288179, 69.578828],
          [42.288271, 69.578724],
          [42.288410, 69.578597],
          [42.288521, 69.578469],
          [42.288675, 69.578243],
          [42.288990, 69.577915],
          [42.289319, 69.577629],
          [42.289495, 69.577240],
          [42.289593, 69.577100],
          [42.289867, 69.576880],
          [42.290069, 69.576794],
          [42.290824, 69.577920], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.290306, 69.587094], // Спатаева
          [42.289634, 69.584045],
          [42.289824, 69.583927],
          [42.289868, 69.583866],
          [42.289873, 69.583807],
          [42.289867, 69.583778],
          [42.288906, 69.580736],
          [42.288875, 69.580700],
          [42.288839, 69.580690],
          [42.288801, 69.580695],
          [42.288597, 69.580816],
          [42.288323, 69.580004], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291642, 69.585544], // Молдир
          [42.291638, 69.585536],
          [42.291592, 69.585548],
          [42.291345, 69.585681],
          [42.291289, 69.585692],
          [42.291258, 69.585684],
          [42.291209, 69.585636],
          [42.291189, 69.585600],
          [42.291045, 69.585194],
          [42.290678, 69.584291],
          [42.290756, 69.584211],
          [42.290014, 69.581877],
          [42.289845, 69.581405],
          [42.289623, 69.580860],
          [42.289246, 69.579573],
          [42.289173, 69.579547],
          [42.288837, 69.578696], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291642, 69.585544], // 
          [42.291675, 69.585544],
          [42.291937, 69.586005],
          [42.291954, 69.586022],
          [42.291977, 69.586024],
          [42.292186, 69.585785],
          [42.292327, 69.585574], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.289630, 69.579351], // Кырман
          [42.289602, 69.579017],
          [42.289590, 69.578937],
          [42.289572, 69.578885],
          [42.289520, 69.578785],
          [42.288990, 69.577915], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.292735, 69.584912], // Дзержинского
          [42.292398, 69.584538],
          [42.292364, 69.584485],
          [42.292280, 69.584305],
          [42.292241, 69.584163],
          [42.291948, 69.583522],
          [42.291857, 69.583362],
          [42.291724, 69.583202],
          [42.291061, 69.582565],
          [42.290729, 69.582169],
          [42.290205, 69.581614], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291706, 69.584795], // Дзержинского
          [42.292976, 69.583540], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.292746, 69.582152], // 
          [42.292086, 69.581307],
          [42.291713, 69.580778], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.289567, 69.577639], // Орркендеу
          [42.290507, 69.578794], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293859, 69.580858], // 
          [42.293391, 69.580310],
          [42.293364, 69.580295],
          [42.293332, 69.580304],
          [42.293302, 69.580332],
          [42.292411, 69.581723], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294772, 69.582094], // 
          [42.293789, 69.580982],
          [42.293871, 69.580872],
          [42.293860, 69.580858], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295162, 69.580824], // 
          [42.294766, 69.582110],
          [42.294517, 69.582747],
          [42.294054, 69.583885],
          [42.293220, 69.585219],
          [42.292214, 69.586631],
          [42.291824, 69.586979], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293198, 69.575109], // Н.Сералиева
          [42.293089, 69.575346],
          [42.293078, 69.575401],
          [42.293082, 69.575514],
          [42.293180, 69.575781],
          [42.293209, 69.575874],
          [42.293206, 69.575926],
          [42.293191, 69.575980],
          [42.293163, 69.576044],
          [42.292865, 69.576548],
          [42.292826, 69.576513],
          [42.291590, 69.578142],
          [42.289663, 69.580958], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291278, 69.576882], // 
          [42.290649, 69.577658], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293152, 69.576912], // Табысты
          [42.292836, 69.577459],
          [42.292276, 69.578306],
          [42.291702, 69.579103], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294542, 69.575976], // Забадамский переулок
          [42.294522, 69.576035],
          [42.294422, 69.576229],
          [42.293659, 69.577494],
          [42.292853, 69.578822],
          [42.292591, 69.579324],
          [42.292315, 69.579851],
          [42.291704, 69.580790],
          [42.290739, 69.582180], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294963, 69.575700], // Забадамский переулок
          [42.294743, 69.575863],
          [42.294620, 69.575924],
          [42.294542, 69.575976],
          [42.294527, 69.575977],
          [42.293707, 69.575867], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296443, 69.574600], // Абат
          [42.295922, 69.574150],
          [42.295865, 69.574119],
          [42.295643, 69.574630],
          [42.295575, 69.574762],
          [42.294963, 69.575700], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298051, 69.572739], // 
          [42.298342, 69.573000],
          [42.298373, 69.573050],
          [42.298379, 69.573121],
          [42.298231, 69.573541],
          [42.297924, 69.574200],
          [42.296469, 69.577068],
          [42.296181, 69.577743],
          [42.296019, 69.578143],
          [42.295904, 69.578484],
          [42.295696, 69.579000],
          [42.295164, 69.580823], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294348, 69.573290], // 
          [42.294020, 69.573853],
          [42.293784, 69.574366], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294252, 69.574915], // 
          [42.293797, 69.574459],
          [42.293778, 69.574416],
          [42.293784, 69.574366], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295017, 69.573949], // 
          [42.294420, 69.573283],
          [42.294384, 69.573265],
          [42.294348, 69.573290], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295017, 69.573949], // 
          [42.294316, 69.574908],
          [42.294285, 69.574926],
          [42.294252, 69.574915], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295865, 69.574119], // 
          [42.295416, 69.573750],
          [42.295373, 69.573738],
          [42.295298, 69.573755],
          [42.295011, 69.573953], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.260471, 69.592102], // Қарағанды
          [42.256780, 69.588161],
          [42.256753, 69.588119],
          [42.256736, 69.588035],
          [42.256749, 69.587950],
          [42.256766, 69.587902],
          [42.256832, 69.587802], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.256832, 69.587802], // 
          [42.258797, 69.585272], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.262184, 69.588997], // 
          [42.261901, 69.588651],
          [42.260645, 69.587234],
          [42.258797, 69.585272], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.261921, 69.589957], // Жалаулы
          [42.258282, 69.585937], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.261659, 69.590903], // Қызылтас
          [42.259739, 69.588819],
          [42.258672, 69.587670],
          [42.257775, 69.586590], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.261385, 69.591892], // Ақжайқын
          [42.257202, 69.587307], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.261226, 69.592331], // 
          [42.261385, 69.591892],
          [42.261922, 69.589958],
          [42.262644, 69.587307], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.262644, 69.587307], // Маржансу
          [42.262660, 69.587299],
          [42.261753, 69.586510],
          [42.262603, 69.584991],
          [42.262658, 69.584862],
          [42.262700, 69.584706],
          [42.262745, 69.584462],
          [42.262857, 69.583827],
          [42.262874, 69.583687],
          [42.262904, 69.582945],
          [42.262948, 69.582752],
          [42.263130, 69.582376],
          [42.263157, 69.582271],
          [42.263220, 69.581873],
          [42.263392, 69.581177],
          [42.263522, 69.580818],
          [42.263591, 69.580676],
          [42.263641, 69.580545],
          [42.263677, 69.580424],
          [42.263697, 69.580291], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.262607, 69.584981], // 
          [42.261292, 69.583973],
          [42.260708, 69.583552], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.262152, 69.585794], // 
          [42.262032, 69.585689],
          [42.261929, 69.585601],
          [42.260928, 69.584826],
          [42.259992, 69.584165], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.261460, 69.582913], // 
          [42.259993, 69.584165], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.262821, 69.584027], // 
          [42.261551, 69.582919],
          [42.261521, 69.582904],
          [42.261489, 69.582899],
          [42.261460, 69.582913], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.261753, 69.586510], // Наурызым
          [42.261743, 69.586501],
          [42.261385, 69.586286],
          [42.260886, 69.585925],
          [42.259472, 69.584817],
          [42.259319, 69.584689],
          [42.258964, 69.584325],
          [42.257612, 69.582706],
          [42.256149, 69.580919],
          [42.255208, 69.579693], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.255208, 69.579693], // 
          [42.255184, 69.579675],
          [42.255154, 69.579677],
          [42.255132, 69.579699],
          [42.252917, 69.583743], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.256115, 69.583611], // 
          [42.255193, 69.582729],
          [42.255164, 69.582781],
          [42.254047, 69.581684], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.255793, 69.584374], // 
          [42.254821, 69.583405],
          [42.254793, 69.583455],
          [42.253654, 69.582325],
          [42.251074, 69.579749],
          [42.248679, 69.577384], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.255375, 69.585063], // 
          [42.253289, 69.583014],
          [42.252529, 69.582262],
          [42.250703, 69.580471],
          [42.248085, 69.577798], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.255019, 69.585716], // 
          [42.254324, 69.584936],
          [42.254104, 69.584738],
          [42.254081, 69.584786],
          [42.254045, 69.584813],
          [42.254010, 69.584810],
          [42.252917, 69.583744],
          [42.250324, 69.581172],
          [42.247538, 69.578373], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.256157, 69.580930], // 
          [42.254114, 69.584749], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.253867, 69.582007], // 
          [42.253587, 69.581441],
          [42.253163, 69.581131],
          [42.252946, 69.580930],
          [42.252425, 69.580251],
          [42.251447, 69.579076],
          [42.249127, 69.576717], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.254099, 69.581582], // 
          [42.253300, 69.580849],
          [42.252755, 69.579772],
          [42.251966, 69.578406],
          [42.249520, 69.573980], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.254342, 69.581138], // 
          [42.249811, 69.572830], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.254755, 69.580386], // 
          [42.253166, 69.577283],
          [42.251437, 69.574135],
          [42.250576, 69.572663], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.253943, 69.576565], // 
          [42.251594, 69.578803],
          [42.249959, 69.581829], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.258589, 69.583900], // 
          [42.258267, 69.584188],
          [42.255391, 69.586502],
          [42.255319, 69.586510],
          [42.255097, 69.586589],
          [42.254987, 69.586543],
          [42.251770, 69.583716],
          [42.249959, 69.581829], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.258599, 69.585518], // 
          [42.258220, 69.585504],
          [42.257678, 69.585532],
          [42.257226, 69.585604],
          [42.256839, 69.585719],
          [42.255837, 69.586281],
          [42.255684, 69.586383],
          [42.255392, 69.586501], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.256305, 69.587307], // 
          [42.255392, 69.586502], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.263417, 69.578770], // 
          [42.257703, 69.582579],
          [42.257598, 69.582690], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.257889, 69.582454], // 
          [42.256443, 69.580320],
          [42.255487, 69.578973],
          [42.254714, 69.577752],
          [42.254224, 69.576982],
          [42.252630, 69.574514],
          [42.252210, 69.573785],
          [42.251830, 69.572957],
          [42.251736, 69.572669], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.263417, 69.578770], // 
          [42.263058, 69.577757],
          [42.261536, 69.574184], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.262852, 69.579144], // 
          [42.260938, 69.574648], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.262164, 69.579603], // 
          [42.261208, 69.577318],
          [42.260279, 69.575154], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.261477, 69.580062], // 
          [42.260538, 69.577780],
          [42.259598, 69.575681], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.260830, 69.580493], // 
          [42.259911, 69.578277],
          [42.259005, 69.576156], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.260167, 69.580935], // 
          [42.259252, 69.578765],
          [42.258407, 69.576823], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.259522, 69.581365], // 
          [42.258079, 69.578000],
          [42.257901, 69.577747], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.262622, 69.573355], // 
          [42.261546, 69.574176],
          [42.261283, 69.574351],
          [42.260927, 69.574657],
          [42.260268, 69.575163],
          [42.259597, 69.575682],
          [42.259004, 69.576157],
          [42.258527, 69.576644],
          [42.258399, 69.576837],
          [42.257893, 69.577761],
          [42.256451, 69.580333], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.262028, 69.571601], // 
          [42.260544, 69.572663],
          [42.259222, 69.573710],
          [42.257696, 69.574829],
          [42.257177, 69.575881],
          [42.256766, 69.576652],
          [42.255488, 69.578972], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.262222, 69.572128], // 
          [42.258044, 69.575432], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.257900, 69.577747], // 
          [42.256772, 69.576640], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.258673, 69.576494], // 
          [42.257551, 69.574594],
          [42.256328, 69.571720], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.259977, 69.575387], // 
          [42.259223, 69.573710], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.261290, 69.574346], // 
          [42.260555, 69.572654], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.256721, 69.576734], // 
          [42.255060, 69.572709], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.257182, 69.575869], // 
          [42.255670, 69.572243], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.258304, 69.574364], // 
          [42.256854, 69.571313], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.258966, 69.573895], // 
          [42.258243, 69.572294],
          [42.257594, 69.570742], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.259578, 69.573408], // 
          [42.258235, 69.570247], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.260213, 69.572918], // 
          [42.258843, 69.569754], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.260835, 69.572453], // 
          [42.259472, 69.569317], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.261455, 69.572010], // 
          [42.260774, 69.570335], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.259472, 69.569317], // 
          [42.259457, 69.569295],
          [42.259436, 69.569289],
          [42.259416, 69.569297],
          [42.258844, 69.569753],
          [42.258235, 69.570247],
          [42.255059, 69.572710],
          [42.252631, 69.574513], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.260774, 69.570335], // 
          [42.260758, 69.570313],
          [42.260739, 69.570307],
          [42.260717, 69.570314],
          [42.259507, 69.571288],
          [42.258881, 69.571777],
          [42.258244, 69.572294],
          [42.256339, 69.573816],
          [42.255367, 69.574525],
          [42.255342, 69.574534],
          [42.255318, 69.574525],
          [42.254750, 69.574104],
          [42.254716, 69.574107],
          [42.253152, 69.575313], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.255671, 69.572244], // 
          [42.254098, 69.568643], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.255150, 69.571046], // 
          [42.257440, 69.569339], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.256482, 69.568945], // 
          [42.254772, 69.570189], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.255746, 69.568341], // 
          [42.254831, 69.569107],
          [42.254425, 69.569392], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.270666, 69.624382], // Ақсүйрік
          [42.270647, 69.624429],
          [42.270310, 69.624752],
          [42.268472, 69.626654],
          [42.267468, 69.627688],
          [42.266961, 69.628198],
          [42.266850, 69.628297],
          [42.266774, 69.628352],
          [42.266640, 69.628492],
          [42.266561, 69.628603],
          [42.266439, 69.628963],
          [42.266405, 69.629114],
          [42.266400, 69.629210],
          [42.266433, 69.629389], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.270438, 69.623034], // Тарбагатай
          [42.268020, 69.625392],
          [42.267910, 69.625516],
          [42.267872, 69.625573],
          [42.267819, 69.625689],
          [42.267751, 69.625793],
          [42.266864, 69.626848],
          [42.266006, 69.627894],
          [42.265649, 69.628282],
          [42.264524, 69.629328],
          [42.264079, 69.629856],
          [42.263930, 69.630035],
          [42.263724, 69.630260],
          [42.263530, 69.630463],
          [42.263028, 69.630920],
          [42.262338, 69.631522], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.270068, 69.622178], // Оскемен
          [42.269067, 69.623087],
          [42.268281, 69.623764],
          [42.267692, 69.624306],
          [42.266297, 69.626024],
          [42.265132, 69.627322],
          [42.264999, 69.627036],
          [42.264633, 69.627304],
          [42.263975, 69.627707],
          [42.263853, 69.627793],
          [42.263671, 69.627947],
          [42.263864, 69.628285],
          [42.263556, 69.628541],
          [42.263403, 69.628686],
          [42.263024, 69.629079],
          [42.262741, 69.629419],
          [42.261881, 69.630369],
          [42.261761, 69.630473],
          [42.261369, 69.630756],
          [42.260813, 69.631200],
          [42.260637, 69.631308],
          [42.260366, 69.631554],
          [42.260102, 69.631801], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.269945, 69.620802], // Карагайлы
          [42.267650, 69.622905],
          [42.267571, 69.622964],
          [42.267541, 69.623092],
          [42.267421, 69.623185],
          [42.267317, 69.623287],
          [42.267074, 69.623577],
          [42.265839, 69.624992],
          [42.265720, 69.625093],
          [42.265580, 69.625168],
          [42.265497, 69.625224],
          [42.265365, 69.625357],
          [42.265314, 69.625402],
          [42.265002, 69.625643],
          [42.264896, 69.625715],
          [42.264209, 69.626309],
          [42.263289, 69.627201],
          [42.262263, 69.628222],
          [42.262088, 69.628333],
          [42.261879, 69.628430],
          [42.261402, 69.628878],
          [42.261013, 69.629312],
          [42.256005, 69.634521], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.269945, 69.620802], // Айсугир
          [42.269736, 69.620058],
          [42.269680, 69.619821],
          [42.269647, 69.619730],
          [42.269617, 69.619685],
          [42.269520, 69.619620],
          [42.269419, 69.619658],
          [42.269229, 69.619766],
          [42.268846, 69.620067],
          [42.267387, 69.621438],
          [42.267308, 69.621474],
          [42.267209, 69.621484],
          [42.267152, 69.621511],
          [42.264124, 69.624532],
          [42.262727, 69.625865],
          [42.262578, 69.626054],
          [42.262415, 69.626198],
          [42.262151, 69.626553],
          [42.262105, 69.626596],
          [42.262009, 69.626664],
          [42.261952, 69.626721],
          [42.261772, 69.626926],
          [42.261357, 69.627233],
          [42.261162, 69.627460],
          [42.260853, 69.627729],
          [42.260598, 69.627984],
          [42.260457, 69.628175],
          [42.260380, 69.628256],
          [42.259929, 69.628676],
          [42.259669, 69.628942],
          [42.259593, 69.629009],
          [42.258875, 69.629603],
          [42.258296, 69.630181],
          [42.258050, 69.630425],
          [42.257923, 69.630624],
          [42.257811, 69.630761],
          [42.257399, 69.631182],
          [42.257014, 69.631550],
          [42.256755, 69.631887],
          [42.256216, 69.632488],
          [42.255858, 69.632863],
          [42.255777, 69.632930],
          [42.255081, 69.633702],
          [42.254924, 69.633944],
          [42.254903, 69.634011],
          [42.254894, 69.634211],
          [42.254853, 69.634429],
          [42.254829, 69.634544],
          [42.254508, 69.635674],
          [42.254417, 69.635899],
          [42.254329, 69.636072],
          [42.254164, 69.636543],
          [42.253855, 69.637424],
          [42.253803, 69.637693],
          [42.253829, 69.637549], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.269292, 69.616930], // Ж.Камбарулы
          [42.266346, 69.619820],
          [42.265553, 69.620682],
          [42.265211, 69.621122],
          [42.262902, 69.623191],
          [42.260959, 69.625201],
          [42.260014, 69.626125],
          [42.256112, 69.630029],
          [42.254412, 69.631933],
          [42.254285, 69.632063],
          [42.254108, 69.632222],
          [42.253289, 69.632928],
          [42.252367, 69.633893],
          [42.250231, 69.636139],
          [42.250126, 69.636338],
          [42.250104, 69.636480],
          [42.250044, 69.637613],
          [42.249979, 69.638106],
          [42.249795, 69.638819],
          [42.249651, 69.639206],
          [42.249462, 69.639590],
          [42.248874, 69.640512], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.268951, 69.615420], // Кайып Ата
          [42.268710, 69.615594],
          [42.268661, 69.615620],
          [42.268556, 69.615659],
          [42.268514, 69.615684],
          [42.268162, 69.615935],
          [42.267935, 69.616132],
          [42.267611, 69.616489],
          [42.267511, 69.616662],
          [42.267062, 69.617167],
          [42.266659, 69.617557],
          [42.265557, 69.618686],
          [42.264947, 69.619348], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.262582, 69.621057], // Кайып Ата
          [42.262558, 69.621063],
          [42.262482, 69.621048],
          [42.262428, 69.621047],
          [42.262388, 69.621055],
          [42.262338, 69.621076],
          [42.262125, 69.621206],
          [42.261925, 69.621371],
          [42.262017, 69.621530],
          [42.261788, 69.621728],
          [42.261306, 69.622387],
          [42.261177, 69.622538],
          [42.261144, 69.622566],
          [42.261004, 69.622638],
          [42.260735, 69.622883],
          [42.260454, 69.623224], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.260074, 69.626066], // Кайып Ата
          [42.259937, 69.625743],
          [42.259914, 69.625676],
          [42.259852, 69.625384],
          [42.259792, 69.625108],
          [42.259703, 69.624780],
          [42.259590, 69.624479],
          [42.259538, 69.624414],
          [42.259477, 69.624397],
          [42.259440, 69.624403],
          [42.259300, 69.624475],
          [42.258923, 69.624823],
          [42.258723, 69.625031],
          [42.258659, 69.625119],
          [42.258221, 69.625620],
          [42.257319, 69.626661],
          [42.256387, 69.627813],
          [42.255900, 69.628409],
          [42.254476, 69.629965],
          [42.253933, 69.630527],
          [42.253492, 69.631042],
          [42.253167, 69.631275],
          [42.252568, 69.631772],
          [42.252452, 69.631482],
          [42.252365, 69.631320],
          [42.252090, 69.631489],
          [42.251758, 69.631744],
          [42.251565, 69.631836],
          [42.251529, 69.631838],
          [42.251482, 69.631819],
          [42.251382, 69.631751],
          [42.251240, 69.631701],
          [42.251193, 69.631672],
          [42.251147, 69.631627],
          [42.251024, 69.631350], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.268491, 69.613861], // Мият Жолдасов
          [42.265507, 69.616824],
          [42.265320, 69.617081],
          [42.264668, 69.618131],
          [42.264259, 69.618892], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.270893, 69.609511], // Абая
          [42.270694, 69.609736],
          [42.270489, 69.609932],
          [42.269184, 69.611149],
          [42.267216, 69.613090],
          [42.266873, 69.613398],
          [42.266723, 69.613508],
          [42.266287, 69.613993],
          [42.265675, 69.614770],
          [42.265615, 69.614841],
          [42.265383, 69.615094],
          [42.265372, 69.615142],
          [42.265384, 69.615243],
          [42.265425, 69.615340],
          [42.266023, 69.616330],
          [42.266669, 69.617547],
          [42.267352, 69.618816],
          [42.267659, 69.619318],
          [42.268071, 69.620095],
          [42.268233, 69.620369], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.269166, 69.608540], // Бойыт Ата
          [42.268309, 69.609789],
          [42.268254, 69.609755],
          [42.268226, 69.609746],
          [42.268197, 69.609750],
          [42.268168, 69.609763],
          [42.268111, 69.609811],
          [42.267534, 69.610563],
          [42.266860, 69.611329],
          [42.266783, 69.611437],
          [42.266639, 69.611652],
          [42.266572, 69.611783],
          [42.266464, 69.612018],
          [42.266389, 69.612229],
          [42.266261, 69.612809], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.267000, 69.613286], // Бойыт Ата
          [42.266450, 69.612912],
          [42.266261, 69.612808],
          [42.265644, 69.612611],
          [42.265255, 69.612505], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.267542, 69.607313], // Бойыт Ата
          [42.266841, 69.608975],
          [42.266705, 69.609391],
          [42.266570, 69.609734],
          [42.266127, 69.610739],
          [42.265857, 69.611197],
          [42.265364, 69.612219],
          [42.265247, 69.612519],
          [42.265125, 69.612651],
          [42.263768, 69.614787], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.269536, 69.607342], // Улан
          [42.268999, 69.608102],
          [42.268973, 69.608119],
          [42.268910, 69.608131],
          [42.268838, 69.608122],
          [42.268673, 69.608038],
          [42.268346, 69.607695], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.269033, 69.606567], // Онеге
          [42.268786, 69.606944],
          [42.268583, 69.607293],
          [42.268346, 69.607695], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.268434, 69.605923], // Замана
          [42.268039, 69.606535], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.267972, 69.614377], // 
          [42.267226, 69.613080], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.269084, 69.619198], // 
          [42.268389, 69.617862],
          [42.268294, 69.617758],
          [42.266287, 69.613992], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.267871, 69.625574], // 
          [42.267782, 69.624781],
          [42.267701, 69.624277],
          [42.267550, 69.623550],
          [42.267527, 69.623258],
          [42.267542, 69.623086],
          [42.267570, 69.622964],
          [42.267570, 69.622947],
          [42.267520, 69.622560],
          [42.267463, 69.622321],
          [42.267352, 69.621989],
          [42.267219, 69.621625],
          [42.267211, 69.621543],
          [42.267231, 69.621428],
          [42.267226, 69.621340],
          [42.267210, 69.621259],
          [42.267128, 69.621079],
          [42.266882, 69.620642],
          [42.266541, 69.620075],
          [42.264920, 69.617755], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.267467, 69.627689], // 
          [42.266297, 69.626023],
          [42.265665, 69.625102],
          [42.265578, 69.625010],
          [42.265393, 69.624746],
          [42.265082, 69.624240],
          [42.264845, 69.623780],
          [42.264759, 69.623865],
          [42.264609, 69.623521],
          [42.263553, 69.621475],
          [42.263158, 69.620594], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.263810, 69.624828], // 
          [42.263560, 69.624321],
          [42.263041, 69.623385],
          [42.262934, 69.623228],
          [42.262892, 69.623174],
          [42.262013, 69.621524],
          [42.261916, 69.621359],
          [42.261690, 69.621024],
          [42.261619, 69.620947],
          [42.261547, 69.620887],
          [42.261418, 69.620787],
          [42.261337, 69.620700],
          [42.261228, 69.620557],
          [42.260653, 69.619482],
          [42.260635, 69.619410],
          [42.260633, 69.619268],
          [42.260651, 69.619232],
          [42.260693, 69.619218],
          [42.260841, 69.619242],
          [42.261541, 69.619298],
          [42.261778, 69.619284],
          [42.262036, 69.619251],
          [42.262081, 69.619256],
          [42.262108, 69.619288],
          [42.262279, 69.619608], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.267252, 69.631620], // 
          [42.266239, 69.632568],
          [42.266195, 69.632584],
          [42.266130, 69.632581],
          [42.266084, 69.632541],
          [42.265903, 69.632139],
          [42.264504, 69.629567],
          [42.264481, 69.629514],
          [42.264426, 69.629278],
          [42.264373, 69.629171],
          [42.263325, 69.627251],
          [42.263279, 69.627187],
          [42.263133, 69.626877],
          [42.262737, 69.625855],
          [42.262497, 69.625178],
          [42.262338, 69.624911],
          [42.261940, 69.624171],
          [42.261853, 69.623968],
          [42.261187, 69.622530], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.266938, 69.630624], // 
          [42.265678, 69.631721], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.266444, 69.629787], // Үстем
          [42.266444, 69.629787],
          [42.265330, 69.631050], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.265515, 69.631426], // Үстем
          [42.264761, 69.632133],
          [42.264618, 69.632260],
          [42.264471, 69.632427],
          [42.264398, 69.632536],
          [42.263797, 69.633184], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.265026, 69.630496], // Жақслық
          [42.263981, 69.631617],
          [42.263516, 69.632075], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.265908, 69.632151], // 
          [42.263871, 69.634068],
          [42.263769, 69.634139], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.263769, 69.634139], // 
          [42.263682, 69.634026],
          [42.263505, 69.633744],
          [42.262241, 69.631371],
          [42.261760, 69.630472],
          [42.261775, 69.630506], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.264800, 69.635757], // 
          [42.264514, 69.636057],
          [42.264420, 69.636192],
          [42.264347, 69.636273],
          [42.263629, 69.636905], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.264800, 69.635757], // 
          [42.263890, 69.634064], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.264308, 69.634844], // 
          [42.263532, 69.635654], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.260595, 69.629746], // 
          [42.258987, 69.627127], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.261403, 69.628878], // 
          [42.260864, 69.627721],
          [42.260563, 69.627076],
          [42.260338, 69.626636],
          [42.260014, 69.626126], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.260675, 69.632853], // 
          [42.258014, 69.628053],
          [42.257322, 69.626634],
          [42.256824, 69.625566],
          [42.256486, 69.624935],
          [42.256457, 69.624859],
          [42.256455, 69.624815],
          [42.256502, 69.624635],
          [42.256498, 69.624585],
          [42.256458, 69.624523],
          [42.256217, 69.624196],
          [42.256150, 69.624119],
          [42.256008, 69.623988],
          [42.255566, 69.623638], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.260240, 69.633128], // 
          [42.259666, 69.633736],
          [42.259653, 69.633797],
          [42.259660, 69.633865],
          [42.259690, 69.633941],
          [42.260840, 69.636022], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.259896, 69.633478], // 
          [42.259314, 69.632483],
          [42.259279, 69.632585],
          [42.255762, 69.626803], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.259752, 69.631187], // Оскемен
          [42.257786, 69.637342], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.257735, 69.632722], // Оскемен
          [42.257847, 69.632900],
          [42.258114, 69.633196],
          [42.258323, 69.633449],
          [42.258347, 69.633494],
          [42.258355, 69.633537],
          [42.258347, 69.633648],
          [42.258347, 69.633648],
          [42.258225, 69.633982],
          [42.257989, 69.634402],
          [42.257048, 69.635909],
          [42.257043, 69.635964],
          [42.257102, 69.636036],
          [42.257117, 69.636068],
          [42.257118, 69.636107],
          [42.257103, 69.636142],
          [42.256855, 69.636462], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.257960, 69.633041], // 
          [42.257474, 69.634267],
          [42.257277, 69.634672],
          [42.257227, 69.634742],
          [42.256215, 69.635883], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.257533, 69.636227], // 
          [42.257207, 69.636627],
          [42.256565, 69.637296],
          [42.256492, 69.637264],
          [42.256442, 69.637184],
          [42.256398, 69.636985],
          [42.256382, 69.636681],
          [42.256215, 69.635883],
          [42.256004, 69.634501],
          [42.255960, 69.634234],
          [42.255870, 69.632852], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.255463, 69.628889], // 
          [42.255369, 69.628689],
          [42.254758, 69.627790],
          [42.254452, 69.627328],
          [42.254430, 69.627168],
          [42.254373, 69.627046],
          [42.254157, 69.626784], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.256077, 69.622653], // Молдабекулы
          [42.255956, 69.622787],
          [42.255846, 69.622981],
          [42.255681, 69.623327],
          [42.255559, 69.623653],
          [42.255216, 69.624643],
          [42.255197, 69.624723],
          [42.255206, 69.624841],
          [42.255201, 69.624899],
          [42.255179, 69.624967],
          [42.254867, 69.625573],
          [42.254157, 69.626785],
          [42.254050, 69.626976],
          [42.253941, 69.627150], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.256155, 69.624125], // Молдабекулы\
          [42.255901, 69.624552],
          [42.255651, 69.625126],
          [42.255502, 69.625530], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.256830, 69.625582], // Молдабекулы
          [42.256103, 69.626292],
          [42.255731, 69.626781],
          [42.255645, 69.626897],
          [42.255133, 69.627513],
          [42.255053, 69.627606],
          [42.254929, 69.627723],
          [42.254755, 69.627810],
          [42.254572, 69.627943],
          [42.254380, 69.628107],
          [42.254114, 69.628401],
          [42.253921, 69.628688],
          [42.253817, 69.628904],
          [42.253433, 69.629695], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.253062, 69.633146], // Молдабекулы
          [42.253028, 69.633096],
          [42.252906, 69.632826],
          [42.252793, 69.632495],
          [42.252711, 69.632188],
          [42.252557, 69.631695],
          [42.252462, 69.631468],
          [42.252374, 69.631307],
          [42.252144, 69.630758],
          [42.252134, 69.630711],
          [42.252134, 69.630631],
          [42.252160, 69.630567],
          [42.252217, 69.630515], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.261171, 69.620453], // Молдабекулы
          [42.259866, 69.621791],
          [42.259848, 69.621840],
          [42.259806, 69.622093], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.260930, 69.622705], // Молдабекулы
          [42.259805, 69.622093],
          [42.259267, 69.621746],
          [42.258714, 69.621404],
          [42.258167, 69.620782],
          [42.257929, 69.620563], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.260750, 69.619670], // Молдабекулы
          [42.260354, 69.619913],
          [42.260187, 69.620037],
          [42.260023, 69.620193],
          [42.259672, 69.620631],
          [42.259390, 69.620906],
          [42.259146, 69.621120], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.259267, 69.621746], // Молдабекулы
          [42.259146, 69.621120],
          [42.258959, 69.619984],
          [42.258858, 69.619426], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.258712, 69.621404], // Молдабекулы
          [42.257423, 69.622494], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.258339, 69.620976], // Молдабекулы
          [42.257472, 69.621708], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.259402, 69.621834], // Молдабекулы
          [42.259347, 69.621957],
          [42.259122, 69.622188],
          [42.258963, 69.622289],
          [42.258655, 69.622342],
          [42.258586, 69.622331],
          [42.258547, 69.622262],
          [42.258526, 69.622174],
          [42.258321, 69.621734], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.251297, 69.640348], // 
          [42.251293, 69.640129],
          [42.251308, 69.639969],
          [42.251332, 69.639842],
          [42.251452, 69.639452],
          [42.251508, 69.639198],
          [42.251547, 69.638837],
          [42.251395, 69.638861],
          [42.251336, 69.638854],
          [42.250782, 69.638881],
          [42.250653, 69.638863],
          [42.250574, 69.638837],
          [42.250502, 69.638789],
          [42.250450, 69.638662],
          [42.250432, 69.638634],
          [42.250371, 69.638588],
          [42.250309, 69.638563], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.250629, 69.640413], // 
          [42.250493, 69.639041],
          [42.250492, 69.638907],
          [42.250505, 69.638793], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.249899, 69.633396], // 
          [42.250136, 69.633596],
          [42.250310, 69.633658],
          [42.250390, 69.633662],
          [42.250455, 69.633688],
          [42.250600, 69.633668],
          [42.251076, 69.634103],
          [42.251166, 69.634191],
          [42.251588, 69.634727],
          [42.252202, 69.635556],
          [42.252375, 69.635838],
          [42.252276, 69.636015],
          [42.252262, 69.636053],
          [42.252257, 69.636229],
          [42.252250, 69.636306],
          [42.252235, 69.636368],
          [42.252203, 69.636418],
          [42.251888, 69.636758],
          [42.251548, 69.637041],
          [42.251533, 69.637070],
          [42.251525, 69.637112],
          [42.251527, 69.637163],
          [42.251688, 69.637694],
          [42.251702, 69.637779],
          [42.251715, 69.637905], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.249899, 69.633396], // Кымызкудык 
          [42.249533, 69.632927],
          [42.249193, 69.632504],
          [42.248657, 69.631897],
          [42.248351, 69.631610],
          [42.247664, 69.631010],
          [42.246974, 69.630409],
          [42.246766, 69.630215],
          [42.246148, 69.629743],
          [42.246059, 69.629650],
          [42.245955, 69.629561],
          [42.245773, 69.629421],
          [42.245560, 69.629291],
          [42.244974, 69.628818],
          [42.243634, 69.627732],
          [42.243618, 69.627708],
          [42.243411, 69.627229],
          [42.243359, 69.627133],
          [42.243246, 69.626997],
          [42.236778, 69.619882], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.249897, 69.633395], // Күмісқұдық
          [42.249734, 69.633281],
          [42.249652, 69.633235],
          [42.249593, 69.633229],
          [42.249496, 69.633267],
          [42.249310, 69.633437],
          [42.249172, 69.633362],
          [42.248667, 69.633155],
          [42.248411, 69.633071],
          [42.248116, 69.633000],
          [42.247768, 69.632784],
          [42.247565, 69.632600],
          [42.246588, 69.631729],
          [42.245790, 69.631069],
          [42.244648, 69.630182], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.248550, 69.635342], // 
          [42.245890, 69.632544], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.248997, 69.637421], // 
          [42.248814, 69.637165],
          [42.248745, 69.637028],
          [42.247304, 69.635411],
          [42.241937, 69.629427],
          [42.238304, 69.625412],
          [42.237814, 69.624990], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.249184, 69.639249], // Новостройки
          [42.249122, 69.639083],
          [42.249066, 69.638975],
          [42.248958, 69.638815],
          [42.248555, 69.638334],
          [42.246735, 69.636319],
          [42.246031, 69.635493],
          [42.241387, 69.630336],
          [42.237088, 69.625555],
          [42.236988, 69.625302],
          [42.236918, 69.625206],
          [42.236739, 69.625145],
          [42.224890, 69.612123], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.247829, 69.639021], // 
          [42.240893, 69.631283], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.243572, 69.638687], // 
          [42.241818, 69.636697],
          [42.241571, 69.636325],
          [42.241353, 69.636036],
          [42.240255, 69.634666],
          [42.239701, 69.633931],
          [42.239335, 69.633249],
          [42.238488, 69.632010],
          [42.236197, 69.628860],
          [42.223367, 69.614716], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.247817, 69.634570], // 
          [42.246733, 69.636319],
          [42.245677, 69.638111],
          [42.245570, 69.638592],
          [42.245509, 69.638811],
          [42.245522, 69.639032],
          [42.245514, 69.639134],
          [42.245485, 69.639253],
          [42.245445, 69.639355],
          [42.245414, 69.639599], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.243247, 69.627002], // 
          [42.240840, 69.631240],
          [42.239432, 69.633437], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.242660, 69.624119], // 
          [42.236787, 69.617540],
          [42.236762, 69.617524],
          [42.236734, 69.617528],
          [42.236712, 69.617549],
          [42.236343, 69.618193], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.243241, 69.627011], // 
          [42.243227, 69.626976],
          [42.243362, 69.626733],
          [42.243377, 69.626638],
          [42.243375, 69.626538],
          [42.243352, 69.626447],
          [42.242894, 69.625569],
          [42.236343, 69.618193], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.242707, 69.627952], // 
          [42.241363, 69.626422],
          [42.239636, 69.624474],
          [42.236820, 69.621421],
          [42.236251, 69.621019], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.242183, 69.628877], // 
          [42.237548, 69.623781], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.223374, 69.614695], // 
          [42.225390, 69.611221], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.236384, 69.626037], // 
          [42.224407, 69.613074], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.224429, 69.612874], // 
          [42.223791, 69.612164],
          [42.224976, 69.610204], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.236047, 69.628706], // 
          [42.236220, 69.628338],
          [42.236414, 69.627572],
          [42.236477, 69.627127],
          [42.236485, 69.626352],
          [42.236386, 69.626041],
          [42.236321, 69.625417],
          [42.236070, 69.624372],
          [42.235894, 69.623819],
          [42.235835, 69.623481],
          [42.235935, 69.622249],
          [42.236034, 69.621671],
          [42.236251, 69.621020],
          [42.236568, 69.620210],
          [42.236207, 69.618568],
          [42.236200, 69.618507],
          [42.236215, 69.618433],
          [42.236350, 69.618201], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.236786, 69.619889], // 
          [42.236711, 69.619859],
          [42.236673, 69.619896],
          [42.236564, 69.620187], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.290568, 69.561432], // Акжар
          [42.290261, 69.561157],
          [42.286053, 69.558197],
          [42.285660, 69.557816],
          [42.285634, 69.557731], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.285634, 69.557731], // 
          [42.285648, 69.557607],
          [42.286795, 69.554556], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291046, 69.560749], // Габдуллина
          [42.290833, 69.560510],
          [42.283293, 69.555213],
          [42.283185, 69.555154],
          [42.283082, 69.555158],
          [42.282900, 69.555194], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291160, 69.559694], // Баян айлы
          [42.284694, 69.555202],
          [42.284647, 69.555185],
          [42.283800, 69.554534],
          [42.283749, 69.554357],
          [42.282928, 69.550023],
          [42.282909, 69.549840],
          [42.282908, 69.549748],
          [42.282914, 69.549693], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291520, 69.558914], // Толебаева
          [42.283558, 69.553319], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291997, 69.558185], // Кызыл-Кумская
          [42.283329, 69.552132], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291938, 69.558782], // 
          [42.291906, 69.558753],
          [42.291823, 69.558729],
          [42.291785, 69.558733],
          [42.291741, 69.558747],
          [42.291521, 69.558915],
          [42.291161, 69.559695], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291868, 69.557020], // Бакытты
          [42.291777, 69.556992],
          [42.283104, 69.550960], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.292741, 69.557071], // Сабит Муканов
          [42.292610, 69.556961],
          [42.291938, 69.556031],
          [42.291298, 69.555534],
          [42.286199, 69.551949],
          [42.281601, 69.548788],
          [42.281529, 69.548764],
          [42.281454, 69.548771], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293139, 69.555536], // Габит Мусрепов
          [42.285033, 69.549884],
          [42.282474, 69.548105], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293461, 69.554630], // Орал
          [42.287103, 69.550182],
          [42.285397, 69.548967],
          [42.283494, 69.547659],
          [42.282992, 69.547376], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293824, 69.553618], // Богенбай батыр
          [42.287557, 69.549232],
          [42.287516, 69.549314],
          [42.283612, 69.546590], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294217, 69.552573], // Биржан сал
          [42.292489, 69.551429], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.287924, 69.548507], // Биржан сал
          [42.287877, 69.548466],
          [42.286058, 69.547201],
          [42.284187, 69.545854], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294605, 69.551765], // Шакен Айманов
          [42.292608, 69.550342], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.288273, 69.547693], // Шакен Айманов
          [42.284708, 69.545205], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296071, 69.551881], // Каратасская
          [42.295213, 69.551054],
          [42.295134, 69.551015],
          [42.292762, 69.549306], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.288655, 69.546897], // Каратасская
          [42.285221, 69.544512], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296712, 69.550767], // Нуртас Ондасынов
          [42.295739, 69.550154],
          [42.293069, 69.548341],
          [42.293032, 69.548268],
          [42.292920, 69.548281],
          [42.291261, 69.548813],
          [42.291203, 69.548845],
          [42.291152, 69.548894],
          [42.291067, 69.549019],
          [42.291018, 69.549120],
          [42.290474, 69.550381],
          [42.290217, 69.551100],
          [42.287234, 69.558967], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.290678, 69.555130], // 
          [42.288764, 69.560117], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.286691, 69.545536], // 
          [42.285033, 69.549885], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293900, 69.544919], // Караспан
          [42.289715, 69.546241],
          [42.289682, 69.546268],
          [42.289657, 69.546314],
          [42.288308, 69.549748], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294108, 69.545603], // Ак Мечеть
          [42.293645, 69.545932],
          [42.290204, 69.547023],
          [42.290154, 69.547065],
          [42.288906, 69.550160], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293329, 69.547054], // Сураншы
          [42.290623, 69.547899],
          [42.290583, 69.547937],
          [42.290555, 69.547981],
          [42.289539, 69.550597], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291161, 69.548882], // Сураныш
          [42.289792, 69.546236],
          [42.289659, 69.545918],
          [42.289509, 69.545402], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297611, 69.549121], // Жетиколь
          [42.297137, 69.548636],
          [42.297073, 69.548591],
          [42.297005, 69.548575],
          [42.296941, 69.548588],
          [42.296835, 69.548673],
          [42.295725, 69.550145],
          [42.294604, 69.551767],
          [42.294211, 69.552589],
          [42.293823, 69.553617],
          [42.292609, 69.556961], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296847, 69.548660], // Торайгыров
          [42.293850, 69.546561], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298539, 69.546906], // Акан Сери
          [42.298343, 69.546960],
          [42.298285, 69.546986],
          [42.298233, 69.547026],
          [42.298073, 69.547228],
          [42.297697, 69.547921],
          [42.297662, 69.547958],
          [42.297625, 69.547977],
          [42.297595, 69.547979],
          [42.297554, 69.547968],
          [42.294107, 69.545601], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298081, 69.547215], // Ордабасы
          [42.294397, 69.544640], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297215, 69.542142], // Новая
          [42.294474, 69.549304], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291939, 69.558783], // Отырар
          [42.292030, 69.557845],
          [42.291921, 69.557408],
          [42.291868, 69.557020],
          [42.291868, 69.556506],
          [42.291936, 69.556029],
          [42.292070, 69.554843],
          [42.292489, 69.551274],
          [42.292608, 69.550344],
          [42.292761, 69.549306],
          [42.294179, 69.543807], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297648, 69.532099], // Отырар
          [42.297295, 69.533378],
          [42.297481, 69.533486],
          [42.296713, 69.536324],
          [42.295437, 69.540888],
          [42.294649, 69.543718],
          [42.293553, 69.547651],
          [42.293459, 69.547937],
          [42.293416, 69.548031],
          [42.293376, 69.548080],
          [42.293347, 69.548101],
          [42.293044, 69.548283], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291811, 69.548634], // Рыскулова
          [42.291143, 69.544781],
          [42.290743, 69.542093], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.283509, 69.558003], // Жандарбекова
          [42.284384, 69.555999],
          [42.285375, 69.553602],
          [42.285755, 69.552819],
          [42.287950, 69.548453],
          [42.288271, 69.547693],
          [42.289046, 69.546115],
          [42.289487, 69.545401],
          [42.289505, 69.545381],
          [42.289746, 69.545234],
          [42.291140, 69.544761],
          [42.294392, 69.543720],
          [42.294559, 69.543689],
          [42.294641, 69.543693],
          [42.294760, 69.543764],
          [42.298175, 69.546132],
          [42.298290, 69.546228],
          [42.298363, 69.546313],
          [42.298453, 69.546513],
          [42.298540, 69.546905], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299282, 69.545860], // Жибек Жолы
          [42.294922, 69.542750],
          [42.294973, 69.542539],
          [42.294475, 69.542225],
          [42.294390, 69.542154],
          [42.294306, 69.542096],
          [42.294270, 69.542081],
          [42.294021, 69.542029],
          [42.293864, 69.541943],
          [42.292480, 69.541042],
          [42.292413, 69.540987],
          [42.292396, 69.540962],
          [42.292386, 69.540925],
          [42.292392, 69.540856],
          [42.292551, 69.540360],
          [42.291119, 69.539532],
          [42.289301, 69.538728],
          [42.288302, 69.538322],
          [42.287454, 69.537917],
          [42.286260, 69.537287], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299767, 69.545033], // Космекен
          [42.299505, 69.544825],
          [42.295182, 69.541811], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.292362, 69.539538], // Космекен
          [42.292215, 69.539466],
          [42.292132, 69.539449],
          [42.292008, 69.539451],
          [42.291871, 69.539382],
          [42.291289, 69.539012],
          [42.290932, 69.538867],
          [42.290568, 69.538717],
          [42.289505, 69.538036],
          [42.286485, 69.536006], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.300007, 69.544788], // Арыстан баб
          [42.299900, 69.544653],
          [42.299865, 69.544579],
          [42.299855, 69.544496],
          [42.299889, 69.544256],
          [42.299876, 69.544117],
          [42.299837, 69.544008],
          [42.299806, 69.543963],
          [42.298443, 69.543007],
          [42.297214, 69.542143],
          [42.286589, 69.535073], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.300770, 69.543528], // Талдыколь
          [42.300519, 69.543286],
          [42.300178, 69.543076],
          [42.299931, 69.542814],
          [42.299831, 69.542728],
          [42.299739, 69.542670],
          [42.299642, 69.542627],
          [42.299353, 69.542533],
          [42.298822, 69.542167],
          [42.295692, 69.539978],
          [42.295390, 69.539724],
          [42.291890, 69.537409],
          [42.286693, 69.534084],
          [42.286710, 69.534102], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.301221, 69.542913], // Ахмет Жубанов
          [42.300965, 69.542630],
          [42.300853, 69.542527],
          [42.299151, 69.541303],
          [42.298384, 69.540772],
          [42.297809, 69.540442],
          [42.297779, 69.540348],
          [42.297775, 69.540298],
          [42.297802, 69.540196],
          [42.295964, 69.539022],
          [42.286722, 69.532968], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.301234, 69.542322], // 
          [42.301079, 69.542179],
          [42.300864, 69.542023],
          [42.300740, 69.541881],
          [42.300697, 69.541835],
          [42.300636, 69.541779],
          [42.300095, 69.541400],
          [42.299979, 69.541281],
          [42.299893, 69.541208],
          [42.299690, 69.541038],
          [42.299619, 69.540941],
          [42.299610, 69.540931],
          [42.299369, 69.540811], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299369, 69.540811], // 
          [42.299333, 69.540799],
          [42.299315, 69.540795],
          [42.299299, 69.540804],
          [42.299283, 69.540828],
          [42.297782, 69.544737], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299179, 69.536769], // Новая 
          [42.298773, 69.537660],
          [42.298645, 69.537972],
          [42.298645, 69.537972],
          [42.298546, 69.538132],
          [42.298509, 69.538249],
          [42.298410, 69.538496],
          [42.297802, 69.540197], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299431, 69.539182], // Жулдыс
          [42.299082, 69.539792],
          [42.299059, 69.539795],
          [42.298799, 69.539743],
          [42.298703, 69.539735],
          [42.298585, 69.539691],
          [42.298087, 69.539346],
          [42.296206, 69.538091],
          [42.292508, 69.535559],
          [42.292430, 69.535704],
          [42.291490, 69.534961],
          [42.290784, 69.534532],
          [42.290743, 69.534616],
          [42.290344, 69.534337],
          [42.289495, 69.533714],
          [42.287392, 69.532251],
          [42.286767, 69.531858], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298415, 69.538485], // Аулие ата
          [42.296453, 69.537205],
          [42.296116, 69.537023],
          [42.295687, 69.536717],
          [42.294998, 69.536184],
          [42.294571, 69.535899],
          [42.292864, 69.534679],
          [42.291163, 69.533609], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291163, 69.533609], // Нурлы
          [42.291418, 69.532861], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302059, 69.541883], // Макталы
          [42.300340, 69.538562],
          [42.300227, 69.538342],
          [42.300047, 69.538014],
          [42.299847, 69.537547],
          [42.299732, 69.537200],
          [42.299716, 69.537132],
          [42.299676, 69.537060],
          [42.299627, 69.537005],
          [42.299539, 69.536937],
          [42.298948, 69.536632],
          [42.298625, 69.536434],
          [42.296966, 69.535375],
          [42.295134, 69.534179],
          [42.293425, 69.533074], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.300020, 69.537949], // Мақталы тұйығы
          [42.299976, 69.537989],
          [42.299461, 69.538317],
          [42.299302, 69.538390],
          [42.299282, 69.538410],
          [42.299224, 69.538488],
          [42.298949, 69.539044], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297302, 69.534152], // Саулетши
          [42.296790, 69.534144],
          [42.296749, 69.534128],
          [42.296490, 69.533988],
          [42.295409, 69.533281], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.301378, 69.536820], // 
          [42.301141, 69.536767],
          [42.300951, 69.536596],
          [42.300416, 69.535948],
          [42.300268, 69.535797],
          [42.300137, 69.535678],
          [42.299866, 69.535458], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299920, 69.535207], // 
          [42.299028, 69.534840],
          [42.298845, 69.534778],
          [42.298700, 69.534722],
          [42.297905, 69.534315],
          [42.297866, 69.534316],
          [42.297841, 69.534349],
          [42.297712, 69.534720],
          [42.297690, 69.534747],
          [42.297660, 69.534746],
          [42.297213, 69.534489], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299828, 69.530727], // Мауленова
          [42.299959, 69.530897],
          [42.300000, 69.530972],
          [42.300112, 69.531262],
          [42.300190, 69.531505],
          [42.300292, 69.531987],
          [42.300347, 69.532527],
          [42.300353, 69.532770],
          [42.300340, 69.532902],
          [42.300277, 69.533165],
          [42.300095, 69.533714],
          [42.300224, 69.533803],
          [42.300159, 69.533999],
          [42.300046, 69.534448],
          [42.299918, 69.535218],
          [42.299716, 69.536172],
          [42.299607, 69.536627],
          [42.299550, 69.536946], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299875, 69.531944], // Айшықты тұйығы-4
          [42.299875, 69.532196],
          [42.299815, 69.532666],
          [42.299819, 69.532700],
          [42.299865, 69.532822],
          [42.299896, 69.532951],
          [42.299869, 69.533218],
          [42.299788, 69.533515], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299182, 69.531526], // Айшықты тұйығы-5
          [42.299333, 69.531714],
          [42.299333, 69.531714],
          [42.299347, 69.531786],
          [42.299230, 69.532408],
          [42.299051, 69.533087], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297925, 69.533755], // Бухарская
          [42.297824, 69.533676],
          [42.297484, 69.533489], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302860, 69.539818], // Айшықты-2
          [42.301779, 69.541396],
          [42.301243, 69.542334],
          [42.301120, 69.542800], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302265, 69.540685], // Айшықты-2
          [42.301212, 69.539287],
          [42.301143, 69.539162], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296858, 69.535782], // 
          [42.296679, 69.535679],
          [42.296635, 69.535677],
          [42.296587, 69.535688],
          [42.296529, 69.535750],
          [42.296484, 69.535844],
          [42.296380, 69.536148],
          [42.295401, 69.539732], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295408, 69.533281], // 
          [42.294870, 69.535089],
          [42.294688, 69.535672],
          [42.293860, 69.537721],
          [42.293134, 69.539807],
          [42.293101, 69.539888],
          [42.293059, 69.539955],
          [42.292993, 69.540001],
          [42.292927, 69.540019],
          [42.292852, 69.540009],
          [42.292779, 69.539973],
          [42.292714, 69.539935],
          [42.292692, 69.539934],
          [42.292669, 69.539965],
          [42.292550, 69.540361], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.289934, 69.542765], // Байконур
          [42.290606, 69.540875],
          [42.291118, 69.539532],
          [42.293425, 69.533073], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291163, 69.533608], // 
          [42.290434, 69.535449],
          [42.289818, 69.537186],
          [42.289300, 69.538729], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.290428, 69.543118], // Шокан Уалиханов
          [42.290068, 69.542846],
          [42.289933, 69.542765],
          [42.289652, 69.542543],
          [42.289328, 69.542236],
          [42.289243, 69.542172],
          [42.289005, 69.542022],
          [42.288726, 69.541910],
          [42.288229, 69.541618],
          [42.287968, 69.541432],
          [42.287752, 69.541245],
          [42.287575, 69.541075],
          [42.287455, 69.540988],
          [42.287179, 69.540842],
          [42.286719, 69.540533],
          [42.286607, 69.540487],
          [42.286502, 69.540452],
          [42.286249, 69.540393],
          [42.285937, 69.540261],
          [42.285774, 69.540165], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293126, 69.543178], // Шуакты
          [42.289604, 69.544275],
          [42.289504, 69.544238],
          [42.289288, 69.544114],
          [42.285183, 69.541322], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291904, 69.542665], // Жанабазар
          [42.290428, 69.543118],
          [42.289895, 69.543290],
          [42.289819, 69.543344],
          [42.289691, 69.543317],
          [42.287115, 69.541483],
          [42.285635, 69.540566], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.289489, 69.545401], // Кызыл жар
          [42.289343, 69.545324],
          [42.284977, 69.542358], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.289048, 69.546115], // Талгар
          [42.287004, 69.544738],
          [42.285519, 69.543713],
          [42.284952, 69.543405],
          [42.283661, 69.542925], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.288577, 69.540653], // 
          [42.288229, 69.541618], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280827, 69.544311], // Акжар
          [42.280865, 69.541716], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.283661, 69.542925], // Алмалы тұйығы
          [42.283781, 69.542595],
          [42.283786, 69.542500],
          [42.283768, 69.542480],
          [42.283219, 69.542227],
          [42.283076, 69.542197],
          [42.283012, 69.542152],
          [42.282898, 69.542072],
          [42.282813, 69.542023],
          [42.282729, 69.541990],
          [42.282288, 69.541815],
          [42.280951, 69.541181], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.283660, 69.542924], // Қазына
          [42.283514, 69.543371],
          [42.283487, 69.543406],
          [42.283453, 69.543409],
          [42.281992, 69.542860],
          [42.280855, 69.542508], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280951, 69.541181], // 
          [42.280865, 69.541716], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280865, 69.541716], // 
          [42.280721, 69.541224],
          [42.280687, 69.541194],
          [42.280643, 69.541122],
          [42.280621, 69.541033],
          [42.280618, 69.540982],
          [42.280622, 69.540931],
          [42.280631, 69.540896], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.282085, 69.538962], // 
          [42.281808, 69.539451],
          [42.280965, 69.540822],
          [42.280949, 69.540797],
          [42.280889, 69.540748],
          [42.280840, 69.540729],
          [42.280783, 69.540729],
          [42.280706, 69.540770],
          [42.280664, 69.540820],
          [42.280631, 69.540896], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280808, 69.540511], // 
          [42.280794, 69.540587],
          [42.280749, 69.540745], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280635, 69.540882], // 
          [42.278982, 69.539996], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280808, 69.540511], // 
          [42.280783, 69.538538],
          [42.280783, 69.538538],
          [42.280759, 69.538506],
          [42.280724, 69.538492],
          [42.280682, 69.538493], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280682, 69.538493], // 
          [42.279092, 69.539779],
          [42.279012, 69.539876],
          [42.278983, 69.539996], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.314613, 69.525754], // 
          [42.314034, 69.525054],
          [42.313901, 69.524735],
          [42.312942, 69.523999],
          [42.312773, 69.523907],
          [42.312523, 69.523806],
          [42.311467, 69.523171],
          [42.311440, 69.523124],
          [42.311427, 69.523065],
          [42.311451, 69.522932],
          [42.309172, 69.521204],
          [42.307037, 69.519520],
          [42.306621, 69.519073],
          [42.305953, 69.518567], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.313267, 69.523433], // 
          [42.313302, 69.523500],
          [42.313307, 69.523573],
          [42.313277, 69.523639],
          [42.313038, 69.524069], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.313267, 69.523433], // 
          [42.306595, 69.518249], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.306707, 69.518334], // 
          [42.306514, 69.518992], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.314323, 69.526147], // 
          [42.313569, 69.525899],
          [42.313116, 69.525784],
          [42.312634, 69.525626],
          [42.312502, 69.525536],
          [42.312450, 69.525483],
          [42.312418, 69.525422],
          [42.312255, 69.524888], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.313533, 69.525892], // 
          [42.313031, 69.526588],
          [42.312972, 69.526636],
          [42.312875, 69.526644],
          [42.312511, 69.526515],
          [42.311625, 69.526105],
          [42.311420, 69.525979],
          [42.311137, 69.525762],
          [42.310966, 69.525562],
          [42.310858, 69.525457],
          [42.310763, 69.525400],
          [42.310619, 69.525331],
          [42.310499, 69.525301],
          [42.310386, 69.525283],
          [42.310267, 69.525220],
          [42.309812, 69.524897],
          [42.309483, 69.524587],
          [42.309192, 69.524277], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.309472, 69.523687], // 
          [42.309193, 69.524278],
          [42.309102, 69.524451], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.308536, 69.525578], // 
          [42.308664, 69.525713],
          [42.308147, 69.526672], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.307494, 69.522013], // 
          [42.307447, 69.522090],
          [42.306856, 69.521642],
          [42.306397, 69.521357],
          [42.305751, 69.523431],
          [42.305544, 69.524022],
          [42.305523, 69.524100],
          [42.305516, 69.524139], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.310552, 69.523793], // Әулиекөл өткелі
          [42.308923, 69.522696],
          [42.308874, 69.522674],
          [42.308823, 69.522663],
          [42.308515, 69.522633],
          [42.307780, 69.522091],
          [42.307628, 69.521961],
          [42.307606, 69.521949],
          [42.307582, 69.521953],
          [42.307532, 69.521980],
          [42.307494, 69.522013], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.310552, 69.523793], // 
          [42.310589, 69.523847],
          [42.310593, 69.523878],
          [42.310574, 69.523938],
          [42.310367, 69.524511],
          [42.310338, 69.524557],
          [42.310309, 69.524572],
          [42.310279, 69.524575],
          [42.310229, 69.524553],
          [42.309348, 69.523944], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.309102, 69.524450], // 
          [42.309065, 69.524582],
          [42.308941, 69.524455],
          [42.308625, 69.524166],
          [42.307929, 69.523581],
          [42.307448, 69.523101],
          [42.307152, 69.522858],
          [42.307080, 69.523050],
          [42.306811, 69.522864],
          [42.306254, 69.522525],
          [42.306063, 69.522426], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.308536, 69.525578], // Шаштобе
          [42.307542, 69.524594],
          [42.306781, 69.523871],
          [42.306678, 69.523732],
          [42.306601, 69.523664],
          [42.306570, 69.523653],
          [42.306544, 69.523654],
          [42.306523, 69.523665],
          [42.306507, 69.523681],
          [42.306441, 69.523806],
          [42.306409, 69.523844],
          [42.305750, 69.523434], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.308147, 69.526672], // Егізтөбе
          [42.306407, 69.524893],
          [42.306229, 69.524737],
          [42.305611, 69.524368],
          [42.305578, 69.524336],
          [42.305554, 69.524301],
          [42.305518, 69.524189],
          [42.305516, 69.524140], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.307029, 69.519511], // 
          [42.306722, 69.520324],
          [42.306546, 69.520832],
          [42.306395, 69.521362],
          [42.305750, 69.523436], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.306514, 69.518990], // Қарашығанақ
          [42.305814, 69.521198],
          [42.304867, 69.524068],
          [42.304357, 69.525076],
          [42.303831, 69.526067], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.305953, 69.518567], // Кииктау
          [42.304328, 69.523621],
          [42.304296, 69.523641],
          [42.304258, 69.523637],
          [42.304133, 69.523533],
          [42.304100, 69.523519],
          [42.304077, 69.523536],
          [42.303953, 69.523826],
          [42.303790, 69.524161],
          [42.303650, 69.524382],
          [42.303147, 69.525296], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.305713, 69.517136], // Күмістікөл
          [42.305336, 69.518291],
          [42.305252, 69.518591],
          [42.305147, 69.519064],
          [42.304635, 69.520687],
          [42.304166, 69.522090],
          [42.304130, 69.522142],
          [42.304092, 69.522176],
          [42.304056, 69.522187],
          [42.304056, 69.522187],
          [42.303769, 69.522046],
          [42.303619, 69.522396],
          [42.303243, 69.523181],
          [42.303009, 69.523703],
          [42.302830, 69.524053],
          [42.302536, 69.524533],
          [42.302128, 69.525101], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.310700, 69.531481], // Аққыстау
          [42.310490, 69.531111],
          [42.310119, 69.530702],
          [42.306484, 69.527139],
          [42.304364, 69.525063],
          [42.303657, 69.524371],
          [42.302998, 69.523693],
          [42.301584, 69.522357],
          [42.301216, 69.521962],
          [42.300917, 69.521713],
          [42.300548, 69.521281],
          [42.299744, 69.520714],
          [42.298465, 69.519889],
          [42.298280, 69.519809],
          [42.298097, 69.519755],
          [42.297878, 69.519588],
          [42.297707, 69.519430],
          [42.297101, 69.518912], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.307694, 69.528342], // Аққыстау өткелі
          [42.308129, 69.527642],
          [42.308141, 69.527606],
          [42.308138, 69.527572],
          [42.308121, 69.527541],
          [42.305961, 69.525423], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.306918, 69.526372], // 
          [42.306485, 69.527138], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.309801, 69.530391], // Аққыстау тұйығы-2
          [42.308937, 69.531429], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.310271, 69.530870], // Аққыстау тұйығы-1
          [42.309981, 69.531271],
          [42.309668, 69.531576], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.309250, 69.529883], // Ак бата
          [42.307752, 69.531455],
          [42.306595, 69.532624], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.308309, 69.530870], // Коскорган
          [42.306489, 69.527690],
          [42.306314, 69.527354],
          [42.306297, 69.527303],
          [42.306294, 69.527267],
          [42.306301, 69.527233],
          [42.306401, 69.527056], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.308886, 69.533658], // Ак бата
          [42.308613, 69.533023],
          [42.308258, 69.532420],
          [42.307752, 69.531455],
          [42.306555, 69.529330], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.307599, 69.528232], // Егиндыколь
          [42.303596, 69.532414], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.307200, 69.532012], // 
          [42.305993, 69.529916], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.307886, 69.534859], // Кулантобе
          [42.306595, 69.532623],
          [42.304531, 69.528975],
          [42.303634, 69.527335],
          [42.303635, 69.527338],
          [42.303471, 69.527081],
          [42.302407, 69.525580],
          [42.301577, 69.524174], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.305940, 69.529931], // 
          [42.305296, 69.528800],
          [42.305224, 69.528693],
          [42.305177, 69.528633],
          [42.305092, 69.528560],
          [42.305032, 69.528540],
          [42.304985, 69.528541],
          [42.304923, 69.528559],
          [42.304867, 69.528596],
          [42.304524, 69.528962], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.304523, 69.528961], // Ащысай
          [42.303295, 69.530288],
          [42.302776, 69.530831],
          [42.302740, 69.530890],
          [42.302582, 69.531190],
          [42.301942, 69.531950],
          [42.301807, 69.532091],
          [42.301751, 69.532133],
          [42.301708, 69.532157],
          [42.301624, 69.532176], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.306136, 69.533662], // Атакорган
          [42.304745, 69.531178],
          [42.304716, 69.531095],
          [42.303041, 69.528195],
          [42.301607, 69.525694],
          [42.300519, 69.523923],
          [42.300086, 69.523329], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.306848, 69.533065], // 
          [42.306607, 69.533120],
          [42.306306, 69.533513],
          [42.306132, 69.533665],
          [42.305590, 69.534228], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.306814, 69.536174], // Зайдуллы Сатканбайулы
          [42.306292, 69.535383],
          [42.305590, 69.534227],
          [42.304177, 69.531768],
          [42.303312, 69.530291],
          [42.302463, 69.528792],
          [42.300524, 69.525375],
          [42.299767, 69.524100], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.306292, 69.535383], // Бозтоган
          [42.305802, 69.535435],
          [42.305433, 69.535521], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.305433, 69.535521], // Жылысай
          [42.304731, 69.534315],
          [42.303596, 69.532414],
          [42.302717, 69.530966],
          [42.301873, 69.529386],
          [42.299947, 69.525914],
          [42.299878, 69.525769],
          [42.297930, 69.521280],
          [42.297556, 69.520652],
          [42.297587, 69.520608],
          [42.296954, 69.519744],
          [42.296826, 69.519530],
          [42.296699, 69.519273],
          [42.296354, 69.518765], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.305433, 69.535521], // Адильбека Бекболатова
          [42.305147, 69.535856],
          [42.304819, 69.536292],
          [42.304798, 69.536307],
          [42.304715, 69.536313],
          [42.304552, 69.536235],
          [42.304457, 69.536170],
          [42.304367, 69.536080],
          [42.303885, 69.535455],
          [42.301835, 69.532061],
          [42.301799, 69.532101],
          [42.301752, 69.532136],
          [42.301707, 69.532158],
          [42.301664, 69.532173],
          [42.301624, 69.532177],
          [42.301625, 69.532066],
          [42.301613, 69.531972],
          [42.301592, 69.531860],
          [42.301559, 69.531741],
          [42.301518, 69.531635],
          [42.301467, 69.531525],
          [42.301385, 69.531366],
          [42.301067, 69.530788],
          [42.299680, 69.528197],
          [42.299496, 69.527886],
          [42.299199, 69.527284],
          [42.298968, 69.526753],
          [42.296717, 69.521583],
          [42.294854, 69.517442],
          [42.293513, 69.514510], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.305460, 69.537806], // 
          [42.305312, 69.537388],
          [42.305212, 69.537106],
          [42.305192, 69.537003],
          [42.305204, 69.536949],
          [42.305243, 69.536906],
          [42.305253, 69.536845],
          [42.305237, 69.536661],
          [42.305261, 69.536580],
          [42.305365, 69.536483], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.305280, 69.538019], // 
          [42.305169, 69.537860],
          [42.304911, 69.537602],
          [42.304848, 69.537518],
          [42.304707, 69.537229],
          [42.304670, 69.537188],
          [42.304615, 69.537170], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.303567, 69.538220], // 
          [42.303891, 69.537770],
          [42.304075, 69.537436],
          [42.304194, 69.537259], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.306606, 69.534453], // 
          [42.306119, 69.535112], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.305728, 69.526398], // 
          [42.304840, 69.527986], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.305517, 69.524870], // 
          [42.304334, 69.526951], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.303730, 69.527513], // 
          [42.301871, 69.529385], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302661, 69.522332], // Алтынды
          [42.302161, 69.523269],
          [42.301741, 69.523967],
          [42.301351, 69.524463],
          [42.301053, 69.524805],
          [42.300833, 69.525057],
          [42.300288, 69.525620],
          [42.299936, 69.525923],
          [42.299302, 69.526418],
          [42.298969, 69.526753], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302876, 69.533783], // Айшықты өткелі
          [42.302809, 69.533850],
          [42.302782, 69.533860],
          [42.302756, 69.533844],
          [42.302577, 69.533594],
          [42.302542, 69.533573],
          [42.302517, 69.533567],
          [42.302472, 69.533573],
          [42.302425, 69.533607],
          [42.301907, 69.534284],
          [42.301838, 69.534300],
          [42.301537, 69.533940],
          [42.301491, 69.533916],
          [42.301422, 69.533894], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302402, 69.534326], // Айшықты тұйығы-2
          [42.302144, 69.534682],
          [42.301902, 69.534944],
          [42.301705, 69.535145], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.301816, 69.521844], // 
          [42.301645, 69.522140],
          [42.301584, 69.522358],
          [42.301067, 69.523221], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.300766, 69.521547], // 
          [42.300678, 69.521922],
          [42.300600, 69.522116],
          [42.300386, 69.522545],
          [42.300079, 69.523342],
          [42.300044, 69.523351],
          [42.300001, 69.523416],
          [42.299813, 69.523915],
          [42.299546, 69.525016], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.300390, 69.522534], // 
          [42.299602, 69.522031], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299984, 69.520885], // Саудакент
          [42.299734, 69.521538],
          [42.299603, 69.522030],
          [42.299125, 69.524035], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.300354, 69.519620], // 
          [42.300154, 69.519701],
          [42.299217, 69.522066], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302436, 69.515438], // 
          [42.301336, 69.519304],
          [42.301181, 69.519806],
          [42.301092, 69.520040],
          [42.300548, 69.521281], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299490, 69.518869], // Кожатогай
          [42.298985, 69.520267],
          [42.298471, 69.521568],
          [42.298355, 69.521812],
          [42.298227, 69.522007], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299490, 69.518869], // 
          [42.298557, 69.518491], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298693, 69.518546], // Костерек
          [42.298102, 69.519774],
          [42.297797, 69.520313],
          [42.297547, 69.520663],
          [42.297410, 69.520832],
          [42.296717, 69.521581], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298489, 69.513072], // 
          [42.298723, 69.516705],
          [42.298885, 69.517778],
          [42.298954, 69.518663], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298878, 69.508670], // 
          [42.298039, 69.511747],
          [42.297935, 69.512154],
          [42.297901, 69.512496],
          [42.297957, 69.514184],
          [42.297945, 69.514417],
          [42.297921, 69.514645],
          [42.297935, 69.514768],
          [42.298029, 69.515163],
          [42.298032, 69.515438],
          [42.298202, 69.516437],
          [42.298330, 69.516934],
          [42.298370, 69.517256],
          [42.298386, 69.517538],
          [42.298556, 69.518489], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298032, 69.515440], // 
          [42.297520, 69.517020], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297927, 69.513427], //
          [42.297655, 69.513407], 
          [42.297595, 69.513439],
          [42.296719, 69.516926], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298708, 69.516470], // 
          [42.298203, 69.516442], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298235, 69.508004], // Бескорган
          [42.296401, 69.515026], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297493, 69.507521], // Шорнак
          [42.295424, 69.515447], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296851, 69.506925], // Актуйесай
          [42.294570, 69.515604],
          [42.294271, 69.515578],
          [42.294144, 69.515582],
          [42.294010, 69.515633], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295515, 69.508818], // Белбулак
          [42.294005, 69.514615],
          [42.293891, 69.514936],
          [42.293854, 69.515018],
          [42.293771, 69.515123], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298045, 69.511732], // Карашык
          [42.294318, 69.509975], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293513, 69.514510], // 
          [42.293477, 69.514223],
          [42.293483, 69.514108],
          [42.293946, 69.512014],
          [42.294044, 69.511196],
          [42.294173, 69.510546],
          [42.295101, 69.506963],
          [42.295230, 69.506359],
          [42.295265, 69.506125],
          [42.295269, 69.505924],
          [42.295244, 69.505718],
          [42.295183, 69.505507],
          [42.294857, 69.504550],
          [42.294804, 69.504368],
          [42.294763, 69.503932],
          [42.294814, 69.503299],
          [42.294965, 69.502069],
          [42.294946, 69.501752],
          [42.294784, 69.501333],
          [42.294380, 69.500648], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293487, 69.514096], // Акалтын
          [42.292962, 69.514059],
          [42.292128, 69.513972],
          [42.291297, 69.513972],
          [42.290881, 69.513929],
          [42.290660, 69.513880],
          [42.290112, 69.513707],
          [42.289644, 69.513657],
          [42.288715, 69.513765],
          [42.288394, 69.513855],
          [42.286711, 69.514588], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.288394, 69.513855], // Акалтын
          [42.288267, 69.513754],
          [42.285625, 69.512611], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294721, 69.506096], // 
          [42.292950, 69.514059], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294502, 69.502924], // 
          [42.292152, 69.513704],
          [42.292129, 69.513974], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293524, 69.503917], // 
          [42.291459, 69.512920],
          [42.291284, 69.513973], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293421, 69.504372], // 
          [42.291720, 69.505391], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293172, 69.505455], // 
          [42.286158, 69.509315], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.292889, 69.506719], // Абиханова
          [42.287368, 69.509826], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.292531, 69.508238], // 
          [42.292154, 69.508035],
          [42.291076, 69.507740], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.292418, 69.508178], // 
          [42.289639, 69.509762], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291953, 69.510759], // 
          [42.289639, 69.509763],
          [42.288467, 69.509209], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291698, 69.511878], // 
          [42.287163, 69.509782], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291460, 69.512921], // Саргау
          [42.286499, 69.510638], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.290880, 69.513930], // Ханшатыр
          [42.290858, 69.513865],
          [42.290812, 69.513815],
          [42.290739, 69.513757],
          [42.290582, 69.513657],
          [42.288600, 69.512746],
          [42.285820, 69.511586], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.286861, 69.509697], // 
          [42.286771, 69.509814],
          [42.286165, 69.510554],
          [42.286055, 69.510736],
          [42.285970, 69.510931],
          [42.285792, 69.511567],
          [42.285790, 69.511639],
          [42.285775, 69.511717],
          [42.285760, 69.511747],
          [42.285653, 69.511936],
          [42.285576, 69.512141],
          [42.285550, 69.512307],
          [42.285554, 69.512393],
          [42.285625, 69.512610],
          [42.285527, 69.513093], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.285176, 69.512766], // 
          [42.285345, 69.512898],
          [42.285528, 69.513092],
          [42.285662, 69.513343],
          [42.285896, 69.514021],
          [42.286076, 69.514348],
          [42.286203, 69.514460],
          [42.286352, 69.514525],
          [42.286532, 69.514576],
          [42.286711, 69.514588], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.286716, 69.514587], // 
          [42.286488, 69.514744],
          [42.286127, 69.515051],
          [42.286072, 69.515171],
          [42.286072, 69.515171],
          [42.286029, 69.515432],
          [42.286062, 69.515785],
          [42.286049, 69.515856],
          [42.286018, 69.515881],
          [42.285970, 69.515905],
          [42.285456, 69.516110],
          [42.285322, 69.516274], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.287385, 69.514295], // 
          [42.287423, 69.515640], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.289083, 69.516359], // Танатар 5
          [42.288274, 69.516124],
          [42.288227, 69.516097],
          [42.288212, 69.516055],
          [42.288214, 69.514500],
          [42.288182, 69.513949], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.289067, 69.514332], // Танатар 4
          [42.289083, 69.516359], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.289894, 69.516937], // Танатар 3
          [42.290006, 69.514292], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.290800, 69.515103], // Танатар 2
          [42.290680, 69.517545], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291596, 69.515211], // Танатар 1
          [42.291573, 69.518270], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.292406, 69.518918], // Жаркайын 
          [42.292381, 69.515567], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293537, 69.519897], // Тастыколь
          [42.292928, 69.518492],
          [42.292878, 69.515893], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294719, 69.520943], // Озкент
          [42.294651, 69.520638],
          [42.293403, 69.517789], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293563, 69.516335], // Турбат
          [42.295809, 69.521490], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295840, 69.519651], // 
          [42.294520, 69.520783], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291095, 69.518354], // Танатар
          [42.289999, 69.517753],
          [42.288796, 69.517104],
          [42.286588, 69.516079], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296835, 69.521857], // Жетижар
          [42.296798, 69.521903],
          [42.296398, 69.521784],
          [42.295920, 69.521527],
          [42.295792, 69.521484],
          [42.295222, 69.521275],
          [42.295060, 69.521182],
          [42.294704, 69.520930],
          [42.294076, 69.520416],
          [42.293537, 69.519896],
          [42.292718, 69.519170],
          [42.292585, 69.519091],
          [42.292527, 69.519087],
          [42.292497, 69.519029],
          [42.292393, 69.518906],
          [42.291573, 69.518269],
          [42.290680, 69.517543],
          [42.289893, 69.516937],
          [42.289452, 69.516590],
          [42.289084, 69.516359], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.292527, 69.519088], // Жетижар
          [42.292447, 69.519092],
          [42.292395, 69.519108],
          [42.292306, 69.519171],
          [42.292264, 69.519220],
          [42.292201, 69.519322],
          [42.292052, 69.519655],
          [42.292023, 69.519701],
          [42.292023, 69.519701],
          [42.291933, 69.519720],
          [42.291847, 69.519688],
          [42.290855, 69.519199],
          [42.290572, 69.519016],
          [42.289704, 69.518564],
          [42.288554, 69.517971],
          [42.285337, 69.516467], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.292598, 69.520926], // Шенбертал
          [42.285418, 69.517327], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294180, 69.522864], // Косарал
          [42.294046, 69.522669],
          [42.293805, 69.522490],
          [42.293443, 69.522336],
          [42.293044, 69.522201],
          [42.292681, 69.522002],
          [42.290342, 69.520824], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.288932, 69.521124], // Танбалы тас
          [42.286066, 69.519640],
          [42.285617, 69.519444], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.287541, 69.521398], // Зайсан
          [42.285715, 69.520473], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.288784, 69.517116], // 
          [42.287989, 69.519643],
          [42.287762, 69.520545],
          [42.287541, 69.521398], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291271, 69.518025], // 
          [42.291095, 69.518354],
          [42.290342, 69.520824],
          [42.290110, 69.521720], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295714, 69.521456], // 
          [42.295374, 69.521907],
          [42.295114, 69.522205],
          [42.294924, 69.522351],
          [42.294457, 69.522842],
          [42.294392, 69.522895], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296388, 69.521780], // 
          [42.296408, 69.521843],
          [42.296407, 69.521889],
          [42.295898, 69.522555], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296799, 69.521904], // Жезди
          [42.296740, 69.522045],
          [42.296577, 69.522320],
          [42.296199, 69.523064],
          [42.296074, 69.523209],
          [42.296017, 69.523243],
          [42.295748, 69.523332],
          [42.294823, 69.524142], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295406, 69.522857], // 
          [42.295583, 69.523480], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297411, 69.523199], // Қармақшы
          [42.297084, 69.523471],
          [42.296969, 69.523614],
          [42.296571, 69.524497],
          [42.296557, 69.524563],
          [42.296481, 69.525278],
          [42.296473, 69.525305],
          [42.296457, 69.525325],
          [42.296033, 69.525705],
          [42.295949, 69.525811],
          [42.296240, 69.526240],
          [42.296074, 69.526515], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296618, 69.524397], // Қармақшы
          [42.296279, 69.524313],
          [42.296210, 69.524312],
          [42.296141, 69.524332],
          [42.295518, 69.524663],
          [42.295230, 69.524860],
          [42.295210, 69.524883],
          [42.295203, 69.524918],
          [42.295282, 69.525108],
          [42.295293, 69.525145],
          [42.295286, 69.525185],
          [42.295264, 69.525228],
          [42.294973, 69.525512],
          [42.294953, 69.525543],
          [42.294952, 69.525582],
          [42.294964, 69.525616],
          [42.295199, 69.525962], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297684, 69.523814], // Қарнақ -3
          [42.296620, 69.524669], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298096, 69.524767], // Қарнақ -2
          [42.297073, 69.525686], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298489, 69.525698], // Қарнақ-1
          [42.297486, 69.526611],
          [42.297416, 69.526656],
          [42.297361, 69.526718],
          [42.297310, 69.526801],
          [42.297263, 69.526906],
          [42.297221, 69.527077],
          [42.297146, 69.527253],
          [42.296899, 69.527580],
          [42.296687, 69.527832],
          [42.296431, 69.528100], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297697, 69.527010], // Қарнақ өткелі
          [42.297073, 69.525686],
          [42.296560, 69.524539], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298914, 69.526643], // 
          [42.298028, 69.527436], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299680, 69.528196], // Отырар
          [42.299539, 69.528337],
          [42.299361, 69.528457],
          [42.299242, 69.528487],
          [42.299013, 69.528523],
          [42.298968, 69.528555],
          [42.298856, 69.528713],
          [42.298352, 69.529387],
          [42.298326, 69.529471],
          [42.298290, 69.529806],
          [42.298288, 69.529897],
          [42.298207, 69.530284],
          [42.298037, 69.530942],
          [42.297989, 69.531087],
          [42.297815, 69.531529],
          [42.297594, 69.532005],
          [42.297654, 69.532078],
          [42.297648, 69.532098], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299539, 69.528336], // Танатар
          [42.299428, 69.529137],
          [42.299285, 69.529860],
          [42.299253, 69.529954],
          [42.299219, 69.530017],
          [42.299051, 69.530571],
          [42.299003, 69.530624],
          [42.298912, 69.530687],
          [42.298878, 69.530732], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299226, 69.530001], // 
          [42.299062, 69.529936],
          [42.298925, 69.529888],
          [42.298742, 69.529786],
          [42.298670, 69.529699],
          [42.298595, 69.529640],
          [42.298503, 69.529588],
          [42.298322, 69.529511], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298462, 69.531698], // Айшықты тұйығы-7
          [42.298439, 69.531859],
          [42.298387, 69.532094],
          [42.298296, 69.532379],
          [42.298219, 69.532577], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.301491, 69.533294], // Айшықты тұйығы-3
          [42.301326, 69.533256],
          [42.301266, 69.533257],
          [42.301221, 69.533264],
          [42.301188, 69.533263],
          [42.301044, 69.533184],
          [42.300946, 69.533488], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297612, 69.532198], // Айшықты
          [42.296623, 69.531802],
          [42.296187, 69.531596],
          [42.294569, 69.530710], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297594, 69.532007], // Камытбек Мамешов
          [42.297502, 69.531890],
          [42.297206, 69.531385],
          [42.297054, 69.531196],
          [42.296930, 69.531075],
          [42.296754, 69.530932],
          [42.296550, 69.530803],
          [42.294507, 69.529779],
          [42.292737, 69.528913], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296631, 69.530242], // 
          [42.296655, 69.530333],
          [42.296714, 69.530426],
          [42.297017, 69.530617],
          [42.297043, 69.530653],
          [42.297054, 69.530714],
          [42.297038, 69.530801],
          [42.296930, 69.531076], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296292, 69.529744], // Акозек
          [42.295831, 69.529472],
          [42.295448, 69.529293],
          [42.295077, 69.529085],
          [42.292967, 69.528083],
          [42.291667, 69.527422], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295442, 69.529289], // Узынагаш
          [42.295527, 69.529053],
          [42.295751, 69.528666],
          [42.295764, 69.528615],
          [42.295767, 69.528555],
          [42.295753, 69.528502],
          [42.295705, 69.528425],
          [42.295663, 69.528398],
          [42.294957, 69.528162],
          [42.294821, 69.528053],
          [42.294489, 69.527848],
          [42.294007, 69.527604],
          [42.293215, 69.527188],
          [42.291971, 69.526609], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295260, 69.527263], // 
          [42.294696, 69.526953],
          [42.294550, 69.526873],
          [42.294115, 69.526676],
          [42.293450, 69.526420], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294366, 69.524763], // 
          [42.294366, 69.524859],
          [42.294323, 69.525099],
          [42.294262, 69.525375],
          [42.294214, 69.525535],
          [42.294162, 69.525617],
          [42.294094, 69.525682],
          [42.294055, 69.525764],
          [42.294030, 69.525901],
          [42.293943, 69.526180],
          [42.293865, 69.526581], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294977, 69.528154], // 
          [42.294361, 69.530330], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.375893, 69.656120], // Ак Отау
          [42.375894, 69.656651],
          [42.375894, 69.656655],
          [42.376085, 69.657370],
          [42.376536, 69.662450],
          [42.376581, 69.663473],
          [42.376581, 69.663592],
          [42.376644, 69.663969],
          [42.376676, 69.664031],
          [42.376902, 69.666284], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.375112, 69.654488], // Ойтоган
          [42.375047, 69.654547],
          [42.374998, 69.654678],
          [42.374985, 69.654793],
          [42.374987, 69.654894],
          [42.375002, 69.655033],
          [42.375122, 69.656558],
          [42.375291, 69.658589],
          [42.375752, 69.663618],
          [42.375861, 69.665296],
          [42.375889, 69.665442],
          [42.375935, 69.665548],
          [42.375998, 69.665621],
          [42.376328, 69.665850],
          [42.376902, 69.666284], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.373952, 69.653443], // Бексейт Тулкиева
          [42.374950, 69.664463], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.378094, 69.667251], // 
          [42.377872, 69.667222],
          [42.377606, 69.667036],
          [42.377498, 69.666944],
          [42.377132, 69.666516],
          [42.376902, 69.666284], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.375860, 69.665294], // 
          [42.375754, 69.665216],
          [42.375640, 69.665160],
          [42.375559, 69.665142],
          [42.374095, 69.665397], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.373049, 69.653588], // Маржантас
          [42.375415, 69.680895], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.376604, 69.674114], // Ак Отау
          [42.376985, 69.678524], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.375747, 69.674243], // Спандияра Кобеева
          [42.376332, 69.680626], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.375747, 69.666273], // 
          [42.374181, 69.666551], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.375203, 69.667527], // 
          [42.374281, 69.667707], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.375482, 69.668710], // Акбота
          [42.374380, 69.668881], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.375548, 69.669875], // 
          [42.374480, 69.670038], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.375508, 69.671075], // 
          [42.374576, 69.671171], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.375615, 69.672222], // 
          [42.374676, 69.672332], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.375747, 69.673369], // 
          [42.374776, 69.673508], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.372081, 69.646033], // Аксауыт
          [42.371669, 69.645778],
          [42.371627, 69.645770],
          [42.371581, 69.645765],
          [42.371543, 69.645777],
          [42.371504, 69.645811],
          [42.371461, 69.645899],
          [42.371456, 69.645951],
          [42.371680, 69.648653],
          [42.372144, 69.653743],
          [42.373051, 69.664038],
          [42.373517, 69.669405],
          [42.373961, 69.674515],
          [42.374513, 69.680792], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.374513, 69.680792], // Аксаукөл
          [42.374507, 69.680825],
          [42.374392, 69.680906],
          [42.374374, 69.680927],
          [42.374341, 69.681086],
          [42.374313, 69.681159],
          [42.374275, 69.681215],
          [42.374232, 69.681252],
          [42.374135, 69.681304],
          [42.369578, 69.684001],
          [42.369209, 69.684238],
          [42.369132, 69.684303],
          [42.369049, 69.684427],
          [42.368958, 69.684473],
          [42.368396, 69.684601],
          [42.368198, 69.684765], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.370438, 69.644599], // Гулдала
          [42.373572, 69.680213], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.373572, 69.680213], // Байтак
          [42.373568, 69.680306],
          [42.373544, 69.680384],
          [42.373510, 69.680445],
          [42.373460, 69.680501],
          [42.373405, 69.680549],
          [42.372333, 69.681182],
          [42.371284, 69.681798],
          [42.367717, 69.683963], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.369528, 69.644346], // Саяхат
          [42.370382, 69.654017],
          [42.370847, 69.659322],
          [42.372610, 69.679511],
          [42.372619, 69.679671],
          [42.372609, 69.679783],
          [42.372585, 69.679829],
          [42.372553, 69.679858],
          [42.367925, 69.682538], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.368611, 69.644018], // Аксункар
          [42.371680, 69.679569], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.367675, 69.643277], // Жасталап
          [42.369843, 69.668695],
          [42.369843, 69.668824],
          [42.369801, 69.669365],
          [42.369931, 69.671538],
          [42.369971, 69.671818],
          [42.370018, 69.671930],
          [42.370088, 69.672001],
          [42.370166, 69.672048], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.370645, 69.669831], // Жасталап
          [42.370178, 69.672063],
          [42.370178, 69.672063],
          [42.370790, 69.679621], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.366732, 69.642688], // Кулагер
          [42.366707, 69.642743],
          [42.366693, 69.642870],
          [42.369957, 69.680331],
          [42.369954, 69.680504], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.369547, 69.675622], //
          [42.369490, 69.675695], 
          [42.368806, 69.679034], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.365770, 69.642122], // Талип Тайбекова
          [42.368007, 69.668294],
          [42.368155, 69.670213],
          [42.368631, 69.675334],
          [42.368455, 69.675359],
          [42.368807, 69.679034],
          [42.369001, 69.680990], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.364811, 69.641571], // Майтобе
          [42.364770, 69.641799],
          [42.367087, 69.667925], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.367338, 69.670324], // Майтобе
          [42.367714, 69.675017],
          [42.367709, 69.675413], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363851, 69.641042], // Шугыла
          [42.363819, 69.641205],
          [42.363816, 69.641318],
          [42.366270, 69.668894], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.366738, 69.675522], // Шугыла
          [42.366800, 69.676806],
          [42.366990, 69.679167], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362999, 69.642088], // Кәусар
          [42.363164, 69.644155],
          [42.363367, 69.645971],
          [42.363431, 69.646131], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.364051, 69.643993], // 
          [42.363164, 69.644155], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363718, 69.650240], // Каусар
          [42.364418, 69.658274],
          [42.364379, 69.658528],
          [42.364328, 69.658745],
          [42.364354, 69.659050],
          [42.364382, 69.660047],
          [42.364424, 69.660280],
          [42.364663, 69.662945],
          [42.365419, 69.670614], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.364650, 69.660343], // Каусар
          [42.364724, 69.661496],
          [42.364767, 69.661929],
          [42.364818, 69.662802],
          [42.364799, 69.662869],
          [42.364754, 69.662910],
          [42.364663, 69.662945], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363052, 69.655235], // Акберды
          [42.363094, 69.655312], 
          [42.364396, 69.670650], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362336, 69.656906], // Байтас
          [42.363390, 69.669930],
          [42.363373, 69.670071],
          [42.363374, 69.670229],
          [42.363390, 69.670265],
          [42.363420, 69.670276], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.361456, 69.657999], // Шертер
          [42.361474, 69.658066],
          [42.362796, 69.673288], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363056, 69.676139], // 
          [42.363378, 69.679815],
          [42.363681, 69.683703], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.360701, 69.659371], // Ушкара
          [42.362782, 69.683461], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.359904, 69.660004], // Ушкыштар
          [42.361898, 69.683101], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.358997, 69.660206], // Таскескен
          [42.359104, 69.660974],
          [42.360783, 69.680272], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.358230, 69.661312], // Ыкылас Дукенулы
          [42.359623, 69.677047],
          [42.359612, 69.677199],
          [42.359584, 69.677276],
          [42.359504, 69.677374], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.357459, 69.662548], // Буланды
          [42.358257, 69.671556],
          [42.358255, 69.671883], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.356724, 69.664009], // Жиенкум
          [42.356804, 69.664926],
          [42.356944, 69.666912], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.355678, 69.664304], // Байгекум
          [42.356483, 69.667012],
          [42.357963, 69.672007],
          [42.358444, 69.673915],
          [42.358444, 69.673915],
          [42.358639, 69.674720],
          [42.359463, 69.677224],
          [42.360324, 69.680173],
          [42.360354, 69.680217],
          [42.360420, 69.680251],
          [42.360783, 69.680272], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.355604, 69.666502], // Курсары
          [42.358094, 69.674764],
          [42.358133, 69.674953],
          [42.358133, 69.675227],
          [42.357993, 69.677315],
          [42.357771, 69.677414], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.354910, 69.667856], // Касым Патеева
          [42.357770, 69.677413],
          [42.357865, 69.677799],
          [42.357907, 69.678170],
          [42.357909, 69.678527],
          [42.357764, 69.680736],
          [42.357681, 69.682064], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.354205, 69.668321], // 
          [42.357300, 69.678370], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.353592, 69.669285], // 
          [42.356304, 69.678137], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.372861, 69.648362], // Жасыл Желек
          [42.369029, 69.649051],
          [42.365432, 69.649626],
          [42.364591, 69.649923],
          [42.363826, 69.649982],
          [42.363758, 69.650016],
          [42.363719, 69.650076],
          [42.363717, 69.650239], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.374579, 69.653267], // Егемкула Тасанбаева
          [42.374511, 69.653308],
          [42.374335, 69.653376],
          [42.374217, 69.653405],
          [42.365032, 69.654949], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.365031, 69.654948], // 
          [42.364999, 69.654819],
          [42.364842, 69.654717],
          [42.364118, 69.654038],
          [42.364047, 69.654012], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.364047, 69.654012], // 
          [42.363888, 69.653986],
          [42.363720, 69.654096],
          [42.363636, 69.654216],
          [42.363372, 69.654697], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.364134, 69.655020], // 
          [42.363052, 69.655236], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363052, 69.655236], // 
          [42.362975, 69.655108],
          [42.362909, 69.655040],
          [42.360914, 69.653496],
          [42.360749, 69.653330],
          [42.360599, 69.653127],
          [42.360471, 69.652922],
          [42.359614, 69.651273],
          [42.359437, 69.650980],
          [42.359282, 69.650768],
          [42.359129, 69.650596],
          [42.359009, 69.650490],
          [42.358043, 69.649893], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362919, 69.655049], // 
          [42.361456, 69.657998],
          [42.361287, 69.658326],
          [42.361088, 69.658654],
          [42.360925, 69.658859],
          [42.360657, 69.659154],
          [42.360459, 69.659343],
          [42.360170, 69.659561],
          [42.359738, 69.659828],
          [42.359363, 69.660034],
          [42.358996, 69.660207],
          [42.358680, 69.660421],
          [42.358453, 69.660650],
          [42.357880, 69.661383],
          [42.357032, 69.662198],
          [42.356787, 69.662502],
          [42.356204, 69.663604],
          [42.356126, 69.663742],
          [42.355902, 69.664059],
          [42.355798, 69.664147],
          [42.355700, 69.664208],
          [42.355678, 69.664304], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.361054, 69.653607], // 
          [42.359668, 69.656393],
          [42.359538, 69.656608],
          [42.359225, 69.656944],
          [42.359093, 69.657051],
          [42.358367, 69.657471], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362552, 69.660579], // 
          [42.359959, 69.661008], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.376148, 69.658511], // 
          [42.364447, 69.660270], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.377612, 69.663331], // Кенжарык
          [42.356944, 69.666913],
          [42.356478, 69.666990],
          [42.355847, 69.667314], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.374413, 69.669270], // Акбота
          [42.369507, 69.669978],
          [42.369091, 69.670085],
          [42.367219, 69.670319],
          [42.367002, 69.670352],
          [42.366831, 69.670418],
          [42.366538, 69.670531],
          [42.365430, 69.670684],
          [42.365370, 69.670631],
          [42.365302, 69.670621],
          [42.364593, 69.670702],
          [42.363690, 69.670872],
          [42.363541, 69.670910],
          [42.363296, 69.670877],
          [42.363237, 69.671151],
          [42.363237, 69.671151],
          [42.362836, 69.671139],
          [42.362727, 69.671193],
          [42.362088, 69.671236],
          [42.358241, 69.671886],
          [42.356330, 69.672940],
          [42.354925, 69.673676], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.364032, 69.667355], // 
          [42.362208, 69.676085], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.369843, 69.668811], // 
          [42.368009, 69.668263], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.377682, 69.673906], // Хан-Тенгри
          [42.359616, 69.676534], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.361635, 69.680053], // 
          [42.361481, 69.680047],
          [42.361432, 69.680068],
          [42.361406, 69.680105],
          [42.361368, 69.680257],
          [42.361337, 69.680280],
          [42.360783, 69.680273], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.375691, 69.681965], // 
          [42.375103, 69.688269], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.375691, 69.681965], // Даналык
          [42.375683, 69.681941],
          [42.375667, 69.681928],
          [42.375464, 69.681933],
          [42.375229, 69.682018],
          [42.373399, 69.683150],
          [42.371983, 69.683985],
          [42.371140, 69.684449],
          [42.370893, 69.684568],
          [42.370621, 69.684719],
          [42.370425, 69.684868],
          [42.368220, 69.684767],
          [42.367911, 69.684722],
          [42.367538, 69.684750],
          [42.357681, 69.682065], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.376555, 69.688817], // 
          [42.376085, 69.688742],
          [42.376143, 69.688471],
          [42.376401, 69.685827], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.376792, 69.685912], // Тарлан
          [42.372181, 69.685107], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.373979, 69.688081], // 
          [42.374562, 69.682431], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.373409, 69.683145], // 
          [42.373042, 69.686955], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.372309, 69.683790], // Зере
          [42.372337, 69.683919],
          [42.372340, 69.684069],
          [42.372181, 69.685106],
          [42.372081, 69.685960],
          [42.372035, 69.686822],
          [42.371994, 69.687220],
          [42.371959, 69.687399],
          [42.371643, 69.688493],
          [42.371147, 69.690091], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.371574, 69.690334], // Зере
          [42.370618, 69.693346],
          [42.370560, 69.693518],
          [42.370498, 69.693646], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.376232, 69.687550], // 
          [42.376070, 69.687495],
          [42.372036, 69.686806], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.376455, 69.690051], // Белсенді
          [42.371906, 69.687602], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.376392, 69.690861], // Торетам
          [42.371648, 69.688479], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.375817, 69.691596], // Коксерек
          [42.371404, 69.689266], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.376226, 69.692737], // Борили\
          [42.375757, 69.692532],
          [42.371142, 69.690108],
          [42.370595, 69.689848],
          [42.370432, 69.689781],
          [42.370074, 69.689579],
          [42.369920, 69.689555],
          [42.369835, 69.689566],
          [42.369812, 69.689578],
          [42.369791, 69.689621],
          [42.369458, 69.689427], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.376144, 69.693740], // Найзақара
          [42.375766, 69.693502],
          [42.371315, 69.691122], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.376028, 69.694639], // Нурак
          [42.371059, 69.691918], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.375268, 69.695116], // Карауылтобе
          [42.370793, 69.692756], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.370013, 69.693309], // 
          [42.371026, 69.690044], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.370432, 69.689781], // Кызылкожа
          [42.369406, 69.693226], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.371559, 69.686848], // Кызылкожа
          [42.370605, 69.689850], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.370903, 69.684564], // Бесикти
          [42.371075, 69.685117],
          [42.371120, 69.685179],
          [42.371197, 69.685226],
          [42.371224, 69.685268],
          [42.371235, 69.685336],
          [42.371211, 69.685561],
          [42.371181, 69.685755],
          [42.370080, 69.689583], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.369792, 69.689620], // 
          [42.368878, 69.693071], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.369459, 69.689427], // 
          [42.369432, 69.689395],
          [42.369416, 69.689353],
          [42.369412, 69.689300],
          [42.369423, 69.689223],
          [42.370123, 69.686622], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.368279, 69.692800], // 
          [42.368760, 69.690507], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.368441, 69.690518], // Мейрам
          [42.368395, 69.690571],
          [42.367801, 69.691080],
          [42.367732, 69.691173],
          [42.367679, 69.691262],
          [42.367643, 69.691365],
          [42.367638, 69.691605],
          [42.367480, 69.692727], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.368441, 69.690518], // Нуртас
          [42.368463, 69.690463],
          [42.368465, 69.690380],
          [42.368435, 69.690348],
          [42.367264, 69.689865],
          [42.366990, 69.689680],
          [42.366883, 69.689639],
          [42.365586, 69.689304],
          [42.365393, 69.689229],
          [42.364795, 69.689023],
          [42.364574, 69.688927],
          [42.364458, 69.688899],
          [42.364368, 69.688894],
          [42.364321, 69.688909],
          [42.364294, 69.688933],
          [42.364267, 69.688990],
          [42.364158, 69.689348],
          [42.364052, 69.689500], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.369069, 69.688678], // 
          [42.367347, 69.687893], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.367385, 69.687651], // Мунайтпасова
          [42.364705, 69.686690],
          [42.364645, 69.686632],
          [42.364628, 69.686595],
          [42.364601, 69.686469],
          [42.364581, 69.686419],
          [42.364561, 69.686389],
          [42.364521, 69.686369],
          [42.363120, 69.685943], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.367542, 69.686546], // Коргантал
          [42.365098, 69.685709],
          [42.365039, 69.685654],
          [42.365012, 69.685565],
          [42.365064, 69.685272],
          [42.365064, 69.685137],
          [42.363384, 69.684602], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.367724, 69.685411], // 
          [42.367583, 69.685397],
          [42.366433, 69.685003],
          [42.365449, 69.684660],
          [42.365346, 69.684612],
          [42.365212, 69.684523],
          [42.365289, 69.684181], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.367222, 69.688510], // Коржынкол
          [42.367191, 69.688519],
          [42.366873, 69.688469],
          [42.364616, 69.687893],
          [42.364296, 69.687785],
          [42.363930, 69.687621],
          [42.363819, 69.687590],
          [42.363499, 69.687528],
          [42.362894, 69.687286], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.366981, 69.690343], // Мунайтпасова
          [42.366935, 69.690386],
          [42.366871, 69.690410],
          [42.366668, 69.690411],
          [42.366474, 69.690387],
          [42.365412, 69.690116],
          [42.364224, 69.689669],
          [42.364053, 69.689502],
          [42.363427, 69.689031],
          [42.363164, 69.688880],
          [42.362887, 69.688810],
          [42.362626, 69.688770],
          [42.362302, 69.688770],
          [42.362147, 69.688788],
          [42.361616, 69.688743],
          [42.360777, 69.688746],
          [42.359902, 69.688693],
          [42.358810, 69.688726],
          [42.358740, 69.688665],
          [42.358716, 69.688611],
          [42.358633, 69.688183],
          [42.358438, 69.687339],
          [42.358408, 69.687259],
          [42.358368, 69.687226],
          [42.358302, 69.687193],
          [42.358153, 69.687158],
          [42.357804, 69.687132], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363521, 69.689674], // Мынбулак
          [42.363155, 69.689632],
          [42.361756, 69.689679],
          [42.360966, 69.689738],
          [42.359147, 69.689823],
          [42.358816, 69.689917],
          [42.358318, 69.690103], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.364793, 69.690631], // 
          [42.363596, 69.690292], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363635, 69.689519], // 
          [42.363633, 69.689537],
          [42.363528, 69.689687],
          [42.363508, 69.690059],
          [42.363553, 69.690205],
          [42.363601, 69.690294],
          [42.363604, 69.691969], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363501, 69.683654], // 
          [42.362640, 69.688774], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.361555, 69.685319], // 
          [42.361135, 69.687375], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363126, 69.685887], // 
          [42.362085, 69.685511],
          [42.361789, 69.685372],
          [42.361567, 69.685291],
          [42.361397, 69.685213],
          [42.361174, 69.685108],
          [42.360818, 69.684986],
          [42.360560, 69.684933],
          [42.360470, 69.684931],
          [42.359742, 69.684683],
          [42.357980, 69.684126], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.360829, 69.682924], // Байтас
          [42.360680, 69.684337],
          [42.360571, 69.684933],
          [42.360672, 69.684956],
          [42.360604, 69.685393],
          [42.360499, 69.685931],
          [42.360404, 69.686493],
          [42.360327, 69.686854],
          [42.360297, 69.686959],
          [42.360233, 69.687111], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.360029, 69.682705], // Байтас
          [42.359826, 69.684237],
          [42.359426, 69.686582], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.359203, 69.682480], // Байтас
          [42.358638, 69.686134], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.359070, 69.683343], // 
          [42.357735, 69.682928],
          [42.357657, 69.682917], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362701, 69.685739], // 
          [42.362296, 69.687752], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.357825, 69.687378], // Наурыз
          [42.355971, 69.688085],
          [42.353874, 69.688857],
          [42.353705, 69.688896],
          [42.352114, 69.689437],
          [42.351911, 69.689511],
          [42.351373, 69.689669],
          [42.350399, 69.689875], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.352845, 69.692728], // Куланды
          [42.352812, 69.692614],
          [42.352133, 69.691244],
          [42.352044, 69.691110],
          [42.351994, 69.691051],
          [42.351877, 69.690954],
          [42.350324, 69.690778], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.352036, 69.690405], // 
          [42.351877, 69.690956], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.353453, 69.692588], // Бирлик
          [42.353428, 69.692453],
          [42.353348, 69.692204],
          [42.353291, 69.692073],
          [42.353201, 69.691879],
          [42.352731, 69.690904],
          [42.352588, 69.690619],
          [42.352227, 69.689976], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.354647, 69.692328], // Аксумбе
          [42.354196, 69.690441],
          [42.353718, 69.688892], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.356571, 69.691951], // 
          [42.356267, 69.689762],
          [42.355973, 69.688084], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.358124, 69.689107], // Ережеп Досымбекулы
          [42.352738, 69.690921], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363604, 69.691968], // Аксумбе
          [42.363264, 69.691904],
          [42.361192, 69.691611],
          [42.359264, 69.691385],
          [42.359103, 69.691389],
          [42.356570, 69.691951],
          [42.354648, 69.692328],
          [42.353441, 69.692591],
          [42.352831, 69.692732],
          [42.349006, 69.693476], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.355548, 69.693969], // Аксумбе
          [42.355215, 69.694070],
          [42.355186, 69.694064],
          [42.355168, 69.694049],
          [42.355155, 69.694020],
          [42.354835, 69.692291], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.353207, 69.695419], // Шокан Уалиханов
          [42.353168, 69.695414],
          [42.353087, 69.695365],
          [42.353069, 69.695346],
          [42.352903, 69.695293],
          [42.352861, 69.695287],
          [42.352760, 69.695291],
          [42.351629, 69.695490],
          [42.350818, 69.695637],
          [42.350523, 69.695636],
          [42.349951, 69.695832],
          [42.349837, 69.695904], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.353291, 69.694557], // Шокан Уалиханов
          [42.353255, 69.694733],
          [42.353213, 69.694846],
          [42.353173, 69.694850],
          [42.353105, 69.694895],
          [42.353068, 69.694938],
          [42.353042, 69.694981],
          [42.353026, 69.695025],
          [42.352911, 69.695095],
          [42.352785, 69.695139],
          [42.352219, 69.695251],
          [42.351892, 69.695311],
          [42.351366, 69.695406],
          [42.350796, 69.695498],
          [42.350662, 69.695545],
          [42.350524, 69.695636], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.350785, 69.695500], // Шокан Уалиханов
          [42.350737, 69.694802],
          [42.350743, 69.694745],
          [42.350752, 69.694704],
          [42.350774, 69.694655],
          [42.350836, 69.694583],
          [42.350916, 69.694543],
          [42.352347, 69.694313],
          [42.352700, 69.694281], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.353868, 69.694111], // 
          [42.353789, 69.694154],
          [42.353417, 69.694664],
          [42.353395, 69.694710],
          [42.353344, 69.694720], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.353844, 69.694616], // 
          [42.353395, 69.694710], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.357340, 69.694040], // Бокейхан
          [42.356898, 69.694081],
          [42.354879, 69.694652],
          [42.354749, 69.694700],
          [42.354638, 69.694755],
          [42.354084, 69.695052],
          [42.353934, 69.695107],
          [42.353443, 69.695194], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.357367, 69.695740], // Бокейхан
          [42.354720, 69.696307], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.358379, 69.691551], // 
          [42.358482, 69.692455], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362348, 69.691808], // Сандыктау
          [42.362305, 69.692681],
          [42.361636, 69.692586],
          [42.361199, 69.692551],
          [42.361132, 69.692455],
          [42.361132, 69.692455],
          [42.360714, 69.692447],
          [42.360271, 69.692442],
          [42.359368, 69.692392],
          [42.358805, 69.692406],
          [42.358689, 69.692420],
          [42.358483, 69.692472],
          [42.358349, 69.692527],
          [42.358059, 69.692605],
          [42.358033, 69.692628],
          [42.358028, 69.692671],
          [42.358171, 69.693837], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.360786, 69.693017], // Оралтай
          [42.360139, 69.693027],
          [42.359361, 69.693009],
          [42.359091, 69.693025],
          [42.358981, 69.692999],
          [42.358878, 69.692401], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362659, 69.692368], // 
          [42.362324, 69.692353], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363603, 69.691968], // 
          [42.363525, 69.693509],
          [42.363542, 69.694128],
          [42.363571, 69.694240],
          [42.363571, 69.694240],
          [42.363598, 69.695321],
          [42.363633, 69.695454],
          [42.363652, 69.696205], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363525, 69.693509], // Жасак
          [42.362640, 69.693731],
          [42.361811, 69.694021],
          [42.361650, 69.694097],
          [42.361383, 69.694167],
          [42.361250, 69.694216],
          [42.361063, 69.694296],
          [42.361023, 69.694256],
          [42.360938, 69.693976],
          [42.360958, 69.693779], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.364445, 69.693333], // Жасак
          [42.363536, 69.693283], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.364814, 69.693262], // Жасак
          [42.365242, 69.693405], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.367376, 69.693926], // Жасак
          [42.366404, 69.693754],
          [42.365435, 69.693666], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.365125, 69.694221], // Жасар булак
          [42.365012, 69.694204],
          [42.364533, 69.694193],
          [42.364228, 69.694157],
          [42.363543, 69.694131],
          [42.363467, 69.694139],
          [42.362473, 69.694457],
          [42.361862, 69.694657],
          [42.361721, 69.694710],
          [42.361172, 69.694849],
          [42.360957, 69.694794],
          [42.360867, 69.694826],
          [42.360655, 69.695010],
          [42.360038, 69.695417],
          [42.359925, 69.695483],
          [42.359684, 69.695580],
          [42.359103, 69.695711], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.367257, 69.695079], // Жасар булак
          [42.365527, 69.694599],
          [42.365132, 69.694531], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.365056, 69.695340], // Жастилек
          [42.364465, 69.695329],
          [42.363996, 69.695277],
          [42.363826, 69.695282],
          [42.363584, 69.695325],
          [42.363294, 69.695388],
          [42.362925, 69.695498],
          [42.362685, 69.695545],
          [42.362200, 69.695682],
          [42.362118, 69.695715],
          [42.362061, 69.695750],
          [42.362001, 69.695813], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.367136, 69.696219], // Жастилек
          [42.365918, 69.695880],
          [42.365093, 69.695677], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.365015, 69.696134], // Жылбұлақ
          [42.364644, 69.696187],
          [42.363652, 69.696204],
          [42.363616, 69.696218],
          [42.363597, 69.696253],
          [42.363560, 69.696429],
          [42.363527, 69.696484],
          [42.363480, 69.696520],
          [42.361845, 69.696984], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.365309, 69.692324], // Берел
          [42.365296, 69.693166],
          [42.365288, 69.693302],
          [42.365262, 69.693398],
          [42.365177, 69.693593],
          [42.365158, 69.693667],
          [42.365142, 69.693814],
          [42.365131, 69.694547],
          [42.365130, 69.694681],
          [42.365056, 69.695341],
          [42.365092, 69.695677],
          [42.365014, 69.696132],
          [42.365057, 69.696554],//
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.373502, 69.703827], // Мейрам
          [42.372991, 69.703581],
          [42.370413, 69.702119],
          [42.370072, 69.701909],
          [42.369340, 69.701528],
          [42.369356, 69.701475],
          [42.369373, 69.701450],
          [42.369422, 69.701401],
          [42.369328, 69.701152],
          [42.368956, 69.700424],
          [42.368925, 69.700385],
          [42.368837, 69.700371],
          [42.368789, 69.700362],
          [42.368576, 69.700294],
          [42.368527, 69.700267],
          [42.368492, 69.700225],
          [42.368219, 69.699690],
          [42.368228, 69.699612],
          [42.368209, 69.699537],
          [42.367206, 69.697574],
          [42.367174, 69.697491],
          [42.367042, 69.697201],
          [42.367033, 69.697142],
          [42.367035, 69.697038],
          [42.367065, 69.696874],
          [42.367256, 69.695078],
          [42.367376, 69.693927],
          [42.367535, 69.692761],
          [42.367480, 69.692726], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.368351, 69.692962], // Бесикти
          [42.368043, 69.695787], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.369148, 69.693171], // Кызылкожа
          [42.368977, 69.694965], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.370496, 69.693646], // 
          [42.370378, 69.693834],
          [42.370098, 69.694100],
          [42.369049, 69.695036],
          [42.368153, 69.695807],
          [42.368118, 69.695834],
          [42.368093, 69.695839],
          [42.368067, 69.695830],
          [42.368051, 69.695813],
          [42.368043, 69.695787], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.368977, 69.694965], // 
          [42.367298, 69.694671], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.374011, 69.695565], // 
          [42.369156, 69.700311],
          [42.369135, 69.700307],
          [42.368821, 69.699715], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.374843, 69.696004], // 
          [42.374731, 69.696179],
          [42.369438, 69.701400], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.373409, 69.701198], // Адалбакан
          [42.373382, 69.702237],
          [42.373358, 69.702472],
          [42.373336, 69.702597],
          [42.373298, 69.702736],
          [42.371101, 69.709539], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.373154, 69.700338], // Ботай
          [42.370324, 69.709092], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.372423, 69.700025], // Айкабак
          [42.369595, 69.708712], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.371666, 69.699752], // Сынтас
          [42.368960, 69.708022],
          [42.368942, 69.708221],
          [42.368922, 69.708343], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.370627, 69.700234], // Науатас
          [42.370515, 69.700663],
          [42.368126, 69.707881], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.369341, 69.701526], // 
          [42.368283, 69.704741],
          [42.367568, 69.706978],
          [42.367475, 69.707370],
          [42.367462, 69.707563],
          [42.367288, 69.711054], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.367003, 69.707260], // 
          [42.366849, 69.710934], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.368878, 69.700371], // Кокбори
          [42.367581, 69.704324],
          [42.366720, 69.706946],
          [42.366695, 69.707054],
          [42.366772, 69.707102],
          [42.366756, 69.707190],
          [42.366438, 69.714641], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.368227, 69.699708], // Шолпы
          [42.366207, 69.705847], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.367214, 69.697590], // Кокпар
          [42.366992, 69.698185],
          [42.366762, 69.698856],
          [42.366411, 69.700090],
          [42.366449, 69.703665],
          [42.366444, 69.703682],
          [42.366393, 69.703814],
          [42.366350, 69.704076],
          [42.366339, 69.705455],
          [42.366147, 69.706011],
          [42.366091, 69.706128],
          [42.365692, 69.706722],
          [42.365390, 69.707271],
          [42.365326, 69.707422],
          [42.365204, 69.707903],
          [42.365088, 69.708522],
          [42.365017, 69.709324],
          [42.364960, 69.709866],
          [42.364886, 69.710385],
          [42.364887, 69.710578],
          [42.364930, 69.710863],
          [42.364930, 69.711095],
          [42.364799, 69.711564],
          [42.364653, 69.712694],
          [42.364614, 69.712874],
          [42.364436, 69.714379],
          [42.364400, 69.714767],
          [42.364367, 69.714909],
          [42.364354, 69.714940],
          [42.364312, 69.714989],
          [42.364115, 69.715097],
          [42.363926, 69.715174],
          [42.363727, 69.715215],
          [42.363626, 69.715200],
          [42.363368, 69.715117],
          [42.362826, 69.714854],
          [42.362755, 69.714802], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.367729, 69.698598], // Кокпекти
          [42.366432, 69.702566], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.365528, 69.696974], // Орманды
          [42.365651, 69.703671], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.364695, 69.697089], // 
          [42.364818, 69.703690], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363877, 69.697060], // Мейрам
          [42.364018, 69.703722], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363071, 69.697321], // Коримдик
          [42.363200, 69.703239],
          [42.363191, 69.703306], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362242, 69.696884], // Шерқала
          [42.362359, 69.700343], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.366410, 69.700095], // Кокжар
          [42.362641, 69.700305],
          [42.362270, 69.700384], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362270, 69.700384], // Кертобел
          [42.362318, 69.700467],
          [42.362378, 69.700587],
          [42.362439, 69.700746],
          [42.362494, 69.700984],
          [42.362785, 69.702451],
          [42.362861, 69.702754],
          [42.363004, 69.703068],
          [42.363205, 69.703321],
          [42.363663, 69.703862], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.366350, 69.704079], // 
          [42.366147, 69.704315],
          [42.363027, 69.708919], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.365447, 69.703679], // 
          [42.363109, 69.707177],
          [42.362822, 69.707683],
          [42.362801, 69.707708],
          [42.362761, 69.707732], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.363964, 69.704266], // Бумын каган
          [42.362886, 69.705874],
          [42.362841, 69.706018],
          [42.362764, 69.706846],
          [42.362784, 69.707378],
          [42.362760, 69.707732],
          [42.362723, 69.708542],
          [42.362668, 69.709104],
          [42.362622, 69.709374],
          [42.364934, 69.710012], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.371665, 69.709906], // Кентарал
          [42.371100, 69.709539],
          [42.370335, 69.709098],
          [42.369608, 69.708718],
          [42.368922, 69.708344],
          [42.368124, 69.707882],
          [42.366182, 69.706754],
          [42.366000, 69.706623],
          [42.365845, 69.706481],
          [42.363662, 69.703863], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362270, 69.700384], // 
          [42.362070, 69.699753],
          [42.361592, 69.697636],
          [42.361513, 69.697507],
          [42.361477, 69.697465],
          [42.361164, 69.697134],
          [42.360777, 69.696600],
          [42.360692, 69.696516],
          [42.357503, 69.697175], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.357543, 69.697170], // Кусконыр
          [42.356940, 69.697226],
          [42.353873, 69.697673], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.357280, 69.691795], // Толе би
          [42.357340, 69.694057],
          [42.357366, 69.695740],
          [42.357489, 69.697194],
          [42.357617, 69.697206],
          [42.357747, 69.698276],
          [42.357843, 69.699272],
          [42.357958, 69.700239],
          [42.358061, 69.701043], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362863, 69.702756], // Кентарал
          [42.362832, 69.702981],
          [42.362816, 69.703394],
          [42.362798, 69.703584],
          [42.362715, 69.704174],
          [42.362565, 69.706840], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.353208, 69.695417], // Абдразакова
          [42.353288, 69.695487],
          [42.353378, 69.695588],
          [42.353415, 69.695647],
          [42.353463, 69.695779],
          [42.353586, 69.696416],
          [42.353781, 69.697445],
          [42.353792, 69.697488],
          [42.353807, 69.697519],
          [42.353840, 69.697565],
          [42.353874, 69.697674], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.352982, 69.692713], // Абдразакова
          [42.353096, 69.693608],
          [42.353191, 69.694167],
          [42.353253, 69.694411],
          [42.353303, 69.694554],
          [42.353407, 69.694978],
          [42.353444, 69.695195],
          [42.353430, 69.695253],
          [42.353578, 69.695898],
          [42.353855, 69.697362],
          [42.353866, 69.697417],
          [42.353868, 69.697487],
          [42.353852, 69.697561],
          [42.353876, 69.697689],
          [42.354337, 69.700364],
          [42.354403, 69.700342],
          [42.354682, 69.702575], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.353588, 69.696414], // Алтынсарина
          [42.353560, 69.696451],
          [42.352281, 69.697317],
          [42.352260, 69.697375],
          [42.352264, 69.697442],
          [42.352435, 69.697976],
          [42.352596, 69.698611],
          [42.352778, 69.699383], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.353922, 69.697943], // 
          [42.352596, 69.698611], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.348087, 69.697483], // Белколь
          [42.349815, 69.696142], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.350213, 69.697292], // Атбасар
          [42.349959, 69.697452],
          [42.349686, 69.697672],
          [42.349549, 69.697793],
          [42.349420, 69.697877],
          [42.349226, 69.697973],
          [42.349040, 69.698085],
          [42.348910, 69.698179],
          [42.348362, 69.698757],
          [42.347935, 69.699308],
          [42.347729, 69.699506],
          [42.347147, 69.700062], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.347150, 69.700057], // Жумабаева
          [42.347400, 69.700654],
          [42.347426, 69.700803],
          [42.347626, 69.701291],
          [42.347664, 69.701312],
          [42.347785, 69.701227],
          [42.348181, 69.700678],
          [42.348291, 69.700571],
          [42.349043, 69.699942],
          [42.349894, 69.699295], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.351051, 69.699667], // Белжайлау
          [42.350682, 69.699872],
          [42.350579, 69.699949],
          [42.350485, 69.700041],
          [42.350134, 69.700554],
          [42.349621, 69.701076],
          [42.348867, 69.701784], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.351641, 69.701678], // Сейтбекова
          [42.351460, 69.701777],
          [42.351357, 69.701880],
          [42.350693, 69.702642],
          [42.350550, 69.702773],
          [42.350427, 69.702815],
          [42.350364, 69.702813],
          [42.350306, 69.702785],
          [42.350269, 69.702744],
          [42.350269, 69.702744],
          [42.350087, 69.702311],
          [42.350064, 69.702146],
          [42.349985, 69.701881],
          [42.349875, 69.701595],
          [42.349620, 69.701075],
          [42.349064, 69.699925], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.351938, 69.702681], // Шарын
          [42.351479, 69.703624],
          [42.350691, 69.704462], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.352393, 69.704051], // Ломоносова
          [42.351394, 69.704906], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.352769, 69.705199], // Ломоносова
          [42.351773, 69.705907], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.353058, 69.706079], // Сейфуллин
          [42.352116, 69.706784], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.353349, 69.706981], // Казансу
          [42.352465, 69.707615], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.357746, 69.698260], // Баянтау
          [42.354067, 69.698818], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.357842, 69.699255], // Суханова
          [42.355588, 69.699573],
          [42.354753, 69.699697],
          [42.354245, 69.699829], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.355580, 69.700019], // Есалиева
          [42.355459, 69.700025],
          [42.351733, 69.701153],
          [42.351615, 69.701211], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.358232, 69.697073], // Болганбаева
          [42.358958, 69.702695], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.359380, 69.701973], // Болганбаева
          [42.359870, 69.701939],
          [42.359906, 69.701941],
          [42.359929, 69.701953],
          [42.359943, 69.701985],
          [42.359948, 69.702031],
          [42.359960, 69.702538],
          [42.359935, 69.704584], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.359935, 69.704584], // 
          [42.359927, 69.704617],
          [42.359907, 69.704643],
          [42.359883, 69.704652],
          [42.359398, 69.704645],
          [42.359346, 69.704630],
          [42.359234, 69.704563],
          [42.359197, 69.704506],
          [42.359174, 69.704439],
          [42.359122, 69.703829],
          [42.359135, 69.703354],
          [42.359119, 69.703010],
          [42.359087, 69.702669], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.358910, 69.696920], // Мынарал
          [42.359210, 69.699761],
          [42.359310, 69.700915],
          [42.359425, 69.702604], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.359580, 69.696746], // Астана
          [42.360046, 69.700754], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.359593, 69.696874], // 
          [42.359829, 69.696878],
          [42.359860, 69.696893],
          [42.359878, 69.696932],
          [42.359901, 69.697102],
          [42.359974, 69.697393],
          [42.360276, 69.698123],
          [42.360482, 69.698525],
          [42.360518, 69.698640],
          [42.360537, 69.698769],
          [42.361068, 69.701179], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.361725, 69.698214], // Кырман
          [42.361149, 69.698465],
          [42.361103, 69.698516],
          [42.361100, 69.698572],
          [42.361454, 69.700012],
          [42.361584, 69.700511], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362072, 69.699768], // 
          [42.360868, 69.700263], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.361104, 69.698514], // 
          [42.360535, 69.698752], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362473, 69.700964], // Едил каган
          [42.362168, 69.701069],
          [42.362025, 69.701105],
          [42.361639, 69.701147],
          [42.361585, 69.701171],
          [42.361590, 69.702526],
          [42.361560, 69.704344],
          [42.361552, 69.704379],
          [42.361552, 69.704379],
          [42.361310, 69.704452],
          [42.361283, 69.704474],
          [42.361269, 69.704516],
          [42.361025, 69.710638],
          [42.360984, 69.711103],
          [42.360975, 69.711134],
          [42.360956, 69.711153],
          [42.360838, 69.711211],
          [42.360814, 69.711233],
          [42.360443, 69.713946], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362171, 69.701068], // 
          [42.362221, 69.701772],
          [42.362274, 69.702251],
          [42.362328, 69.702523],
          [42.362360, 69.702606],
          [42.362390, 69.704119], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362094, 69.704471], // Жарма
          [42.361863, 69.710133],
          [42.361856, 69.710163],
          [42.361767, 69.710265],
          [42.361767, 69.710265],
          [42.361539, 69.711984],
          [42.361537, 69.711985], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.361587, 69.701170], // Кентарал
          [42.361056, 69.701179],
          [42.360800, 69.701212],
          [42.360775, 69.701223],
          [42.360744, 69.701288], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.360744, 69.701288], // 
          [42.360724, 69.704384], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362325, 69.702519], // Алтыншокы
          [42.362139, 69.702510],
          [42.361588, 69.702525],
          [42.360774, 69.702518],
          [42.359949, 69.702521],
          [42.359425, 69.702604],
          [42.359088, 69.702686],
          [42.355269, 69.703436], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.360471, 69.704637], // Моде каган
          [42.360250, 69.710227],
          [42.360200, 69.710673], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.360939, 69.700597], // Шойынбаев
          [42.360047, 69.700755],
          [42.359308, 69.700898],
          [42.358073, 69.701041],
          [42.355775, 69.701343], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.358811, 69.701557], // Аруана
          [42.355830, 69.702119], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.357958, 69.700223], // Маханбет Пернебекұлы
          [42.355723, 69.700595], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.359658, 69.704650], // Босага
          [42.359414, 69.710630], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.358410, 69.704929], // Байтуган
          [42.358762, 69.704868],
          [42.358792, 69.704873],
          [42.358803, 69.704888],
          [42.358796, 69.705590],
          [42.358679, 69.707419],
          [42.358584, 69.710585], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.361018, 69.710741], // 
          [42.358582, 69.710586], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.365454, 69.712805], // 
          [42.364654, 69.712677],
          [42.363864, 69.712475],
          [42.361541, 69.711969],
          [42.360740, 69.711778], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.362761, 69.707732], // 
          [42.361972, 69.707686],
          [42.361146, 69.707635],
          [42.358680, 69.707420],
          [42.358312, 69.707399],
          [42.358135, 69.707365],
          [42.358075, 69.707320],
          [42.358052, 69.707286],
          [42.357892, 69.706780],
          [42.357860, 69.706623],
          [42.357852, 69.706463],
          [42.357955, 69.704997], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.357955, 69.704997], // 
          [42.357353, 69.705121],
          [42.357341, 69.705151],
          [42.357345, 69.705187],
          [42.357417, 69.705396],
          [42.357425, 69.705431],
          [42.357425, 69.705466],
          [42.357410, 69.705533],
          [42.353729, 69.707850], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.354008, 69.702114], // 
          [42.352207, 69.703179], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.352935, 69.705391], // 
          [42.353256, 69.705223],
          [42.354760, 69.704274],
          [42.355322, 69.703917],
          [42.355366, 69.703856],
          [42.355362, 69.703810],
          [42.355350, 69.703765],
          [42.355139, 69.703251], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.357990, 69.708585], // 
          [42.354696, 69.710923], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.352959, 69.701493], // Айбын
          [42.354036, 69.704736],
          [42.354839, 69.707119],
          [42.355272, 69.708421],
          [42.355272, 69.708421],
          [42.355910, 69.710111],
          [42.356321, 69.711128],
          [42.356822, 69.712498], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.353904, 69.701815], // Айбалта
          [42.357432, 69.712202], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.355514, 69.698598], // Жылыбулак
          [42.355590, 69.699588],
          [42.355581, 69.700036],
          [42.355661, 69.700189],
          [42.355682, 69.700261],
          [42.355724, 69.700610],
          [42.355776, 69.701360],
          [42.355832, 69.702138],
          [42.355924, 69.703344],
          [42.356013, 69.704094],
          [42.356030, 69.704438],
          [42.355982, 69.705151],
          [42.356000, 69.705289],
          [42.356291, 69.706161],
          [42.357284, 69.709106],
          [42.358009, 69.711178],
          [42.358053, 69.711425],
          [42.358228, 69.711709],
          [42.358254, 69.711728],
          [42.358511, 69.711789], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.356801, 69.704979], // Ойкарагай
          [42.358156, 69.709082], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.357923, 69.705470], // 
          [42.357416, 69.705517], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.358583, 69.710586], // 
          [42.358511, 69.711792],
          [42.358495, 69.713355],
          [42.358471, 69.713526],
          [42.358426, 69.713665],
          [42.358338, 69.713876],
          [42.358143, 69.713906],
          [42.358035, 69.713963],
          [42.357687, 69.714259],
          [42.357633, 69.714282],
          [42.357454, 69.714313],
          [42.356001, 69.715163],
          [42.355668, 69.715380],
          [42.355480, 69.715447],
          [42.355282, 69.715484],
          [42.355076, 69.715488],
          [42.354901, 69.715423],
          [42.354746, 69.715339],
          [42.354643, 69.715298], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.357342, 69.719147], // 
          [42.357052, 69.719375],
          [42.356802, 69.719861],
          [42.356732, 69.719920],
          [42.356666, 69.719939],
          [42.356548, 69.719884],
          [42.356459, 69.719758],
          [42.355502, 69.717503],
          [42.355434, 69.717384],
          [42.355279, 69.717174],
          [42.355058, 69.716805], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.404413, 69.586726], // К.Балтабеков
          [42.401886, 69.583793],
          [42.401816, 69.583679],
          [42.401802, 69.583610],
          [42.401800, 69.583499],
          [42.401789, 69.583436], 
          [42.401767, 69.583377],
          [42.401450, 69.583036], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.404003, 69.587341], // Ырыс
          [42.400955, 69.583787], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.403436, 69.588187], // Мендешова
          [42.402645, 69.587230],
          [42.400419, 69.584636], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.402886, 69.589009], // Байкара
          [42.399883, 69.585497], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.402340, 69.589823], // Жусан
          [42.399342, 69.586365], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.401778, 69.590718], // Молшылық
          [42.401412, 69.590240],
          [42.398815, 69.587203], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.401262, 69.591553], // Аулиетас
          [42.400758, 69.590994],
          [42.398267, 69.588051], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.400684, 69.592414], // Бетеге
          [42.399057, 69.590463],
          [42.397736, 69.588924], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.399947, 69.592950], // Балбырауын
          [42.397187, 69.589764], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.399534, 69.594024], // Баганалы
          [42.396653, 69.590604], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.398958, 69.594849], // Изен
          [42.398903, 69.594764],
          [42.398149, 69.593820],
          [42.397441, 69.593016],
          [42.396115, 69.591485], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.398318, 69.595620], // Алтыбакан
          [42.395235, 69.591845], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.397797, 69.596570], // Акшагала
          [42.394688, 69.592699], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.397114, 69.597546], // Мырзабекова
          [42.397034, 69.597460],
          [42.397001, 69.597409],
          [42.396967, 69.597340],
          [42.396920, 69.597219],
          [42.396840, 69.597056],
          [42.394229, 69.593589],
          [42.394185, 69.593495], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.396813, 69.598178], // Исакова
          [42.393463, 69.594185], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.396268, 69.599002], // Арипова
          [42.395928, 69.598566],
          [42.395528, 69.598093],
          [42.394512, 69.596830],
          [42.392924, 69.595092], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.396268, 69.599002], // Кара Бура
          [42.395861, 69.599593],
          [42.395784, 69.599681],
          [42.395744, 69.599698],
          [42.395661, 69.599688],
          [42.395625, 69.599661],
          [42.394998, 69.598853],
          [42.393902, 69.597564],
          [42.392436, 69.595926], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.395140, 69.600614], // Айдапкелова
          [42.394423, 69.599667],
          [42.393572, 69.598618],
          [42.391957, 69.596727], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.393849, 69.600415], // Косай баба
          [42.392594, 69.598955],
          [42.391415, 69.597635], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.394214, 69.602493], // Омирзака
          [42.394205, 69.602448],
          [42.393567, 69.601754],
          [42.393510, 69.601693],
          [42.393219, 69.601264],
          [42.393130, 69.601053],
          [42.393019, 69.600868],
          [42.392817, 69.600618],
          [42.390915, 69.598479], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.392645, 69.601752], // Ибрагим Қожа
          [42.392532, 69.601612],
          [42.390414, 69.599325], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.392240, 69.602686], // Серікбаева
          [42.390929, 69.601242],
          [42.389913, 69.600173], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.391322, 69.604168], // Ережеп Искаков
          [42.390688, 69.603428],
          [42.389875, 69.602514],
          [42.389148, 69.601501], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.391617, 69.602006], // Акшам
          [42.391221, 69.602666],
          [42.390688, 69.603429], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.390936, 69.601251], // 
          [42.390062, 69.602725], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.390506, 69.603225], // Акшам
          [42.388750, 69.606064],
          [42.387957, 69.607356],
          [42.387933, 69.607430],
          [42.387925, 69.607517],
          [42.387724, 69.608328], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.389883, 69.602526], // Омирали Бердешов
          [42.388205, 69.605192],
          [42.387246, 69.606944],
          [42.387068, 69.607522], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.401449, 69.583036], // Каламбаева
          [42.399343, 69.586364],
          [42.398815, 69.587202],
          [42.397736, 69.588925],
          [42.396653, 69.590604],
          [42.396105, 69.591497],
          [42.396008, 69.591662],
          [42.395972, 69.591676],
          [42.395950, 69.591666],
          [42.395632, 69.591276],
          [42.395604, 69.591233],
          [42.394184, 69.593497],
          [42.394161, 69.593517],
          [42.394142, 69.593527],
          [42.394086, 69.593521],
          [42.393968, 69.593475],
          [42.393903, 69.593476],
          [42.391948, 69.596741],
          [42.390414, 69.599327],
          [42.389193, 69.601392],
          [42.389140, 69.601514],
          [42.388949, 69.601851],
          [42.388790, 69.602284], 
          [42.388602, 69.602755],
          [42.388483, 69.603135],
          [42.388447, 69.603215],
          [42.388323, 69.603423],
          [42.388032, 69.603824],
          [42.387265, 69.604804],
          [42.387108, 69.605049],
          [42.386913, 69.605316],
          [42.386748, 69.605601],
          [42.386571, 69.606009],
          [42.386495, 69.606232],
          [42.386435, 69.606576],
          [42.386398, 69.606997],
          [42.386365, 69.607704], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.401337, 69.579608], // Асфендиярова
          [42.401472, 69.579788],
          [42.401517, 69.579875],
          [42.401527, 69.579981],
          [42.401505, 69.580076],
          [42.401358, 69.580421],
          [42.401315, 69.580592],
          [42.401312, 69.580685],
          [42.401347, 69.580870],
          [42.401593, 69.581519],
          [42.401938, 69.582236],
          [42.401958, 69.582302],
          [42.401616, 69.582782],
          [42.401060, 69.583630],
          [42.400504, 69.582898],
          [42.400470, 69.582868],
          [42.400437, 69.582858],
          [42.400406, 69.582866],
          [42.400376, 69.582896],
          [42.399778, 69.583866],
          [42.399778, 69.583866],
          [42.398563, 69.585998],
          [42.397872, 69.587127],
          [42.395539, 69.590877],
          [42.395506, 69.590952],
          [42.395510, 69.591056],
          [42.395533, 69.591114],
          [42.395606, 69.591234], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.398509, 69.587667], // 
          [42.397017, 69.586336], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.403415, 69.575270], // Қозыбай
          [42.399162, 69.581309],
          [42.398577, 69.582080],
          [42.397788, 69.583294],
          [42.396124, 69.585963], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.396124, 69.585963], // Байсыбаев
          [42.393005, 69.590877],
          [42.392250, 69.592097],
          [42.391012, 69.594029],
          [42.389677, 69.596206],
          [42.389293, 69.596820],
          [42.389013, 69.597196],
          [42.388246, 69.598082], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.402738, 69.574394], // Шойман аже
          [42.395538, 69.585019], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.395538, 69.585019], // Оналбаева
          [42.394849, 69.586014],
          [42.394470, 69.586631],
          [42.393350, 69.588419],
          [42.391832, 69.590941],
          [42.390391, 69.593382],
          [42.389098, 69.595582],
          [42.389003, 69.595782],
          [42.388975, 69.595880],
          [42.388943, 69.596150],
          [42.388927, 69.596254],
          [42.388899, 69.596344],
          [42.388400, 69.597450],
          [42.388325, 69.597600],
          [42.388225, 69.597754], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.401768, 69.573137], // Мынбаев
          [42.400667, 69.575101],
          [42.400010, 69.576149],
          [42.399495, 69.576916],
          [42.398546, 69.578183],
          [42.398038, 69.578962], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.397043, 69.580489], // 
          [42.397061, 69.580548],
          [42.397061, 69.580616],
          [42.397038, 69.580686],
          [42.396413, 69.581766],
          [42.395662, 69.582989],
          [42.395296, 69.583649],
          [42.395179, 69.583831],
          [42.395091, 69.583955],
          [42.395103, 69.584015],
          [42.395095, 69.584099],
          [42.395071, 69.584168],
          [42.394989, 69.584284],
          [42.394920, 69.584306],
          [42.394804, 69.584402],
          [42.394376, 69.584989], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.400951, 69.572078], // Едилбаева
          [42.399100, 69.575382],
          [42.388028, 69.595333], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.396808, 69.586672], // Мызакулова
          [42.396767, 69.586606],
          [42.396210, 69.586058],
          [42.396115, 69.585952],
          [42.395883, 69.585610],
          [42.395756, 69.585356],
          [42.395662, 69.585195],
          [42.394999, 69.584276], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.395091, 69.583956], // 
          [42.394713, 69.583348], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.388412, 69.599638], // Зенги ата
          [42.388348, 69.599447],
          [42.388028, 69.595333], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.388028, 69.595333], // Торғын
          [42.387931, 69.595117],
          [42.387757, 69.594880],
          [42.387628, 69.594738],
          [42.386784, 69.593910],
          [42.386326, 69.593546], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.388073, 69.595914], // Бескара
          [42.385712, 69.594668],
          [42.385617, 69.594624],
          [42.385415, 69.594553], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.388163, 69.597038], // Көкиірім
          [42.387958, 69.597057],
          [42.387883, 69.597045],
          [42.387821, 69.597005],
          [42.386755, 69.595612], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.388247, 69.598083], // Жеруйық
          [42.388202, 69.598113],
          [42.387766, 69.598252],
          [42.387649, 69.598267],
          [42.387607, 69.598261],
          [42.387574, 69.598244],
          [42.387537, 69.598210],
          [42.385415, 69.595308], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.387161, 69.599329], // Акдария
          [42.385600, 69.597172],
          [42.385305, 69.596561], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.386612, 69.600216], // Бесарка
          [42.384900, 69.597790], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.386601, 69.601732], // Даулетти
          [42.386335, 69.601338],
          [42.386109, 69.601045],
          [42.386058, 69.600996],
          [42.385960, 69.600892],
          [42.384298, 69.598712],
          [42.383983, 69.598153],
          [42.383989, 69.598212], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.385444, 69.601841], // Огемтау
          [42.383684, 69.599517],
          [42.383400, 69.598755], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.385275, 69.603271], // Акжунис
          [42.384858, 69.602670],
          [42.383428, 69.600766],
          [42.383274, 69.600500],
          [42.382645, 69.598996], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.387780, 69.598249], // Балкарагай
          [42.387717, 69.598475],
          [42.387161, 69.599329],
          [42.386612, 69.600216],
          [42.386050, 69.601008],
          [42.385436, 69.601853],
          [42.384857, 69.602670], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.388597, 69.594314], // 
          [42.387512, 69.593185], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.389092, 69.593419], // 
          [42.389078, 69.593399],
          [42.387751, 69.591968], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.389607, 69.592483], // Едилбаев
          [42.388497, 69.591374], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.390099, 69.591600], // 
          [42.389021, 69.590518], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.390595, 69.590691], // 
          [42.389665, 69.589736], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.400619, 69.572663], // Коныраулы
          [42.392708, 69.564725], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.400093, 69.573590], // Досмухамедова
          [42.392166, 69.565564], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.399603, 69.574474], // Естай акын
          [42.391668, 69.566471], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.399107, 69.575369], // Камбар батыр
          [42.389804, 69.565950], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.398626, 69.576242], // Байшешек
          [42.390680, 69.568307], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.398124, 69.577159], // Тойчиев
          [42.390175, 69.569213], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.397622, 69.578062], // Бокейханов
          [42.387903, 69.568261],
          [42.387720, 69.568137], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.397120, 69.578958], // Смагул Садвакасов
          [42.389238, 69.570936], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.396635, 69.579839], // Туганбай Алпысбаев
          [42.388740, 69.571838], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.396109, 69.580796], // Абил Спанов
          [42.391394, 69.575922],
          [42.389817, 69.574359],
          [42.388185, 69.572841], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.395506, 69.581908], // Уали аты
          [42.393507, 69.579878],
          [42.392775, 69.579051],
          [42.392693, 69.578960],
          [42.391930, 69.578217],
          [42.391782, 69.578091],
          [42.391620, 69.577918],
          [42.391303, 69.577522],
          [42.390798, 69.577012],
          [42.390441, 69.576699],
          [42.390229, 69.576454],
          [42.390140, 69.576369],
          [42.389174, 69.575558],
          [42.387553, 69.574007], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.395011, 69.582816], // Ахмет Аширов
          [42.390281, 69.577951],
          [42.390184, 69.578123],
          [42.388648, 69.576553],
          [42.387021, 69.574971], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.393796, 69.583016], // Карасай
          [42.393105, 69.582338],
          [42.392645, 69.581783],
          [42.391668, 69.580742],
          [42.389750, 69.578893],
          [42.388186, 69.577389],
          [42.386572, 69.575785], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.393300, 69.583903], // Сарбаз
          [42.393277, 69.583874],
          [42.392809, 69.583388],
          [42.389216, 69.579891],
          [42.387675, 69.578361],
          [42.385536, 69.576237],
          [42.385435, 69.576180], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.392814, 69.584792], // Жанысбаев Оразбай ата
          [42.392028, 69.584020],
          [42.391932, 69.583945],
          [42.391655, 69.583770],
          [42.390284, 69.582430], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.388717, 69.580927], // 
          [42.387105, 69.579331],
          [42.385646, 69.577903],
          [42.385598, 69.577836],
          [42.385548, 69.577703], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.392325, 69.585695], // 
          [42.391439, 69.584832],
          [42.391383, 69.584828],
          [42.391250, 69.584875],
          [42.389772, 69.583385],
          [42.388227, 69.581834],
          [42.386580, 69.580322],
          [42.385228, 69.578996],
          [42.385029, 69.578742], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.391597, 69.586780], // Костанай
          [42.389241, 69.584437],
          [42.388841, 69.583941],
          [42.387697, 69.582821],
          [42.387559, 69.582731],
          [42.386047, 69.581259],
          [42.384499, 69.579744],
          [42.384430, 69.579722], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.389356, 69.586034], // 
          [42.388792, 69.587089],
          [42.386539, 69.584817],
          [42.384927, 69.583208],
          [42.383862, 69.582144],
          [42.383761, 69.582067], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.389356, 69.586034], // 
          [42.389353, 69.585961],
          [42.388719, 69.585395],
          [42.387085, 69.583839],
          [42.385498, 69.582206],
          [42.384246, 69.581001],
          [42.384092, 69.580899], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.394303, 69.582093], // Майлыбаев Әсілбек
          [42.391745, 69.586748],
          [42.391714, 69.586787],
          [42.391689, 69.586802],
          [42.391627, 69.586798],
          [42.391597, 69.586780], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.396652, 69.566544], // Жоламан
          [42.386540, 69.584818], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.395032, 69.564451], // 
          [42.394992, 69.564519],
          [42.394950, 69.564690],
          [42.394957, 69.564809],
          [42.394931, 69.564979],
          [42.394733, 69.565412],
          [42.384924, 69.583204], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.392912, 69.564208], // Кемербастау
          [42.392870, 69.564260],
          [42.391176, 69.567367],
          [42.390672, 69.568322],
          [42.389706, 69.570057],
          [42.388739, 69.571838],
          [42.388177, 69.572853],
          [42.387546, 69.574019],
          [42.387013, 69.574984],
          [42.386565, 69.575798],
          [42.386045, 69.576774],
          [42.385028, 69.578743], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.394965, 69.564674], // Жагажай
          [42.392040, 69.564005],
          [42.391896, 69.564027],
          [42.386825, 69.573296], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.389803, 69.565949], // Сырнай булак
          [42.389799, 69.565963],
          [42.389702, 69.566078],
          [42.387334, 69.570376],
          [42.387270, 69.570468],
          [42.387217, 69.570510],
          [42.387163, 69.570515],
          [42.387048, 69.570478], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],

        color: '#0000FF',
      },

     

      //////////////// Састелеком жүргізілмеген /////////////////////

      {
        coordinates: [
          [42.409400, 69.652145], //
          [42.399091, 69.650589], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.403111, 69.666875], // Дәуір
          [42.404308, 69.664100],
          [42.403642, 69.663377],
          [42.403866, 69.662906],
          [42.403999, 69.662600],
          [42.404100, 69.662316],
          [42.404169, 69.662048],
          [42.404246, 69.661858],
          [42.404338, 69.661677],
          [42.404438, 69.661522],
          [42.404803, 69.661098],
          [42.404881, 69.660973],
          [42.405113, 69.660657],
          [42.405282, 69.660373],
          [42.405521, 69.659899],
          [42.406053, 69.658635],
          [42.406440, 69.657739],
          [42.406814, 69.656719],
          [42.407340, 69.654531],
          [42.407705, 69.652011], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.406646, 69.651843], // Нарсай
          [42.406404, 69.653477],
          [42.406312, 69.653972],
          [42.405990, 69.655438],
          [42.405796, 69.656164],
          [42.404913, 69.658416],
          [42.404826, 69.658602],
          [42.403324, 69.660994],
          [42.403250, 69.661156],
          [42.403092, 69.661556],
          [42.402949, 69.661851],
          [42.402720, 69.662346],
          [42.403641, 69.663377],
          [42.402409, 69.666300], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.400771, 69.659987], // Қадырғали Жалаири
          [42.401087, 69.659454],
          [42.401799, 69.658453],
          [42.402522, 69.657447],
          [42.402986, 69.656697],
          [42.403161, 69.656338],
          [42.403340, 69.655933],
          [42.403511, 69.655503],
          [42.403707, 69.654917],
          [42.403874, 69.654230],
          [42.404840, 69.648342], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.406224, 69.661088], // Шалкиіз Жырау
          [42.407146, 69.658971],
          [42.407720, 69.657440],
          [42.407726, 69.657339],
          [42.407719, 69.657283],
          [42.407702, 69.657230],
          [42.407675, 69.657181],
          [42.407637, 69.657145],
          [42.405796, 69.656162],
          [42.403688, 69.654984],
          [42.401582, 69.654036], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.404883, 69.660971], //
          [42.404705, 69.660731],
          [42.403182, 69.659060],
          [42.402959, 69.658694],
          [42.402313, 69.657720],
          [42.402203, 69.657570],
          [42.402111, 69.657406],
          [42.401359, 69.655641],
          [42.401156, 69.655239],
          [42.400775, 69.654824],
          [42.400668, 69.654593], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.407096, 69.648775], //
          [42.405944, 69.648547],
          [42.403859, 69.648259],
          [42.402611, 69.648011],
          [42.402543, 69.647989],
          [42.402507, 69.647996],
          [42.402482, 69.648037],
          [42.402369, 69.648499],
          [42.402215, 69.648765],
          [42.401780, 69.649291],
          [42.401683, 69.649482],
          [42.401552, 69.650834], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.401438, 69.658960], //
          [42.399800, 69.655244], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.402619, 69.648013], // Омбы
          [42.402517, 69.648541],
          [42.402229, 69.650847],
          [42.402178, 69.651119],
          [42.402106, 69.651387],
          [42.401673, 69.653737],
          [42.401647, 69.653851],
          [42.401609, 69.653933],
          [42.401504, 69.654049],
          [42.401184, 69.654228],
          [42.400655, 69.654602],
          [42.399681, 69.655329],
          [42.399541, 69.655345],
          [42.399398, 69.655288],
          [42.398931, 69.655019],
          [42.398436, 69.654830], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.406683, 69.666364], //
          [42.398878, 69.657951],
          [42.398409, 69.657497],
          [42.397584, 69.657054],
          [42.397341, 69.656975],
          [42.389763, 69.656109], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.399234, 69.658330], //
          [42.398910, 69.664400], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.404090, 69.667500], //
          [42.403111, 69.666876],
          [42.402905, 69.666743],
          [42.402739, 69.666679],
          [42.402610, 69.666603],
          [42.402487, 69.666455],
          [42.402421, 69.666312],
          [42.401716, 69.665781],
          [42.400530, 69.665019],
          [42.399843, 69.664585],
          [42.399735, 69.664564],
          [42.399641, 69.664558],
          [42.399541, 69.664565],
          [42.399374, 69.664631],
          [42.398907, 69.664529],
          [42.389661, 69.663496], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.398279, 69.657673], //
          [42.398205, 69.657991],
          [42.397920, 69.663936],
          [42.397860, 69.664405], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.396953, 69.657128], //
          [42.396456, 69.664261], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.395345, 69.664135], //
          [42.395618, 69.657631],
          [42.394591, 69.657486], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.391663, 69.660124], //
          [42.391442, 69.663694], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.391448, 69.663521], //
          [42.391664, 69.660148], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.392349, 69.660168], //
          [42.388960, 69.659789], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.390766, 69.660434], // Көмекбаев
          [42.390563, 69.663599], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.389930, 69.659897], //
          [42.389670, 69.663494],
          [42.389200, 69.668118], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.392237, 69.669633], //
          [42.388389, 69.669261], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.389096, 69.656266], //
          [42.388245, 69.670022],
          [42.388254, 69.670441],
          [42.388352, 69.671225],
          [42.388641, 69.672191],
          [42.388986, 69.672923],
          [42.389525, 69.673682],
          [42.389721, 69.673883], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.295295, 69.565601], // Бакшалы-2
          [42.295159, 69.565500],
          [42.294980, 69.565472],
          [42.294667, 69.565241],
          [42.294268, 69.564918],
          [42.294169, 69.564806], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296718, 69.558862], // Бакшалы-2
          [42.296422, 69.560231],
          [42.296382, 69.560529],
          [42.296377, 69.560725],
          [42.296414, 69.561082],
          [42.296464, 69.561468],
          [42.296477, 69.561595],
          [42.296489, 69.561823],
          [42.296487, 69.561922],
          [42.296480, 69.562006],
          [42.296470, 69.562075],
          [42.296454, 69.562129],
          [42.296426, 69.562190],
          [42.296360, 69.562276],
          [42.296171, 69.562439],
          [42.296159, 69.562451],
          [42.295862, 69.562671],
          [42.295660, 69.562852],
          [42.295081, 69.563574],
          [42.294968, 69.563741],
          [42.294872, 69.563953],
          [42.294809, 69.564074],
          [42.294559, 69.564502],
          [42.294519, 69.564562],
          [42.294519, 69.564562],
          [42.294372, 69.564696],
          [42.294310, 69.564736],
          [42.294169, 69.564806], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294309, 69.564037], // 
          [42.294281, 69.564095],
          [42.294251, 69.564186],
          [42.294234, 69.564395],
          [42.294244, 69.564506],
          [42.294305, 69.564738], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295249, 69.563362], // 
          [42.294921, 69.562613],
          [42.294854, 69.562313], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294845, 69.561532], // 
          [42.295010, 69.561579],
          [42.295049, 69.561629],
          [42.295072, 69.561734],
          [42.295147, 69.562290],
          [42.295166, 69.562418],
          [42.295227, 69.562557],
          [42.295551, 69.562980], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297375, 69.565664], // Теспе
          [42.297920, 69.564800],
          [42.297937, 69.564732],
          [42.297943, 69.564669],
          [42.297809, 69.564501],
          [42.297605, 69.564331],
          [42.297227, 69.564078],
          [42.297187, 69.564059],
          [42.297146, 69.564054],
          [42.297064, 69.564103],
          [42.296920, 69.564425],
          [42.296312, 69.563706],
          [42.295672, 69.562842], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296604, 69.566043], // 1-й тупик Теспе
          [42.296357, 69.565776],
          [42.296308, 69.565704],
          [42.296157, 69.565388],
          [42.296136, 69.565325],
          [42.296135, 69.565257],
          [42.296151, 69.565210],
          [42.296318, 69.565024],
          [42.296365, 69.564945],
          [42.296417, 69.564752],
          [42.296393, 69.564734],
          [42.296372, 69.564698],
          [42.296367, 69.564614],
          [42.296381, 69.564176],
          [42.296420, 69.564054],
          [42.296497, 69.563927], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296817, 69.565516], // 2-й тупик Теспе
          [42.296566, 69.564961],
          [42.296504, 69.564850],
          [42.296418, 69.564753], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299150, 69.563613], // Топырақты
          [42.299425, 69.563391],
          [42.299448, 69.563368],
          [42.299459, 69.563344],
          [42.299456, 69.563282],
          [42.299388, 69.563149],
          [42.299343, 69.563087],
          [42.299142, 69.562871],
          [42.298420, 69.562333],
          [42.298319, 69.562285],
          [42.298130, 69.562246],
          [42.297725, 69.562280],
          [42.296478, 69.562487],
          [42.296334, 69.562496],
          [42.296236, 69.562486],
          [42.296194, 69.562473],
          [42.296158, 69.562450], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297743, 69.562278], // Теспе
          [42.297751, 69.562895],
          [42.297757, 69.562951],
          [42.297776, 69.563013],
          [42.297815, 69.563080],
          [42.298190, 69.563515],
          [42.298418, 69.563841],
          [42.298419, 69.563906],
          [42.298402, 69.563952],
          [42.298237, 69.564180], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298629, 69.563830], // 
          [42.298485, 69.563876],
          [42.298420, 69.563892], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298625, 69.563033], // 
          [42.298532, 69.563173],
          [42.298460, 69.563260],
          [42.298190, 69.563514], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297289, 69.561878], // 
          [42.297303, 69.562350],
          [42.297331, 69.562883],
          [42.297331, 69.563074],
          [42.297318, 69.563428], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298348, 69.561503], // 
          [42.298321, 69.561824],
          [42.298308, 69.561942],
          [42.298293, 69.561962],
          [42.298251, 69.561968],
          [42.298132, 69.561935],
          [42.297952, 69.561843],
          [42.297730, 69.561837],
          [42.297725, 69.561400], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299148, 69.562878], // 
          [42.299165, 69.562873],
          [42.299300, 69.562866],
          [42.299410, 69.562826],
          [42.299512, 69.562736],
          [42.299626, 69.562565],
          [42.299812, 69.562370],
          [42.299867, 69.562343],
          [42.300044, 69.562303],
          [42.299933, 69.561792],
          [42.299914, 69.561684],
          [42.299883, 69.561408],
          [42.299877, 69.561271],
          [42.299861, 69.561095],
          [42.299826, 69.560825], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299827, 69.560824], // 
          [42.300259, 69.560641],
          [42.300497, 69.560579],
          [42.300533, 69.560562],
          [42.300595, 69.560512],
          [42.300702, 69.560354],
          [42.300736, 69.560265],
          [42.300752, 69.560157],
          [42.300741, 69.559965],
          [42.300747, 69.559857],
          [42.300793, 69.559711],
          [42.300856, 69.559631],
          [42.300922, 69.559568],
          [42.300979, 69.559530],
          [42.301099, 69.559508],
          [42.301239, 69.559528],
          [42.301414, 69.559589],
          [42.301436, 69.559574], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.300258, 69.561237], // 
          [42.299883, 69.561423], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299826, 69.560825], // Садовая
          [42.299819, 69.560810],
          [42.299199, 69.560611],
          [42.299143, 69.560583],
          [42.299083, 69.560525],
          [42.299049, 69.560014],
          [42.299007, 69.559663],
          [42.298963, 69.559358],
          [42.298968, 69.559293],
          [42.298993, 69.559200], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299081, 69.560525], // 
          [42.298794, 69.560451],
          [42.298729, 69.560358],
          [42.298707, 69.560340],
          [42.298524, 69.560264],
          [42.298497, 69.560263], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298497, 69.560263], // 
          [42.298473, 69.560280],
          [42.298460, 69.560310],
          [42.298436, 69.560431],
          [42.298392, 69.561188], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298794, 69.560452], // 
          [42.298708, 69.560534],
          [42.298658, 69.560679],
          [42.298622, 69.560925], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.300646, 69.559096], // Спатаева
          [42.300602, 69.559118],
          [42.299730, 69.559229],
          [42.299164, 69.559232],
          [42.299095, 69.559227],
          [42.298979, 69.559198],
          [42.298617, 69.559194],
          [42.298313, 69.559168],
          [42.298114, 69.559163],
          [42.297976, 69.559174],
          [42.297630, 69.559234],
          [42.297566, 69.559240],
          [42.297480, 69.559239],
          [42.297397, 69.559228],
          [42.297190, 69.559145],
          [42.296946, 69.558978],
          [42.296832, 69.558914],
          [42.296717, 69.558863], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298992, 69.559200], // Спатаева
          [42.298999, 69.559023],
          [42.298989, 69.558914],
          [42.298947, 69.558723],
          [42.298813, 69.558434],
          [42.298769, 69.558330],
          [42.298645, 69.557962],
          [42.298578, 69.557684],
          [42.298503, 69.557430],
          [42.298420, 69.557213],
          [42.298434, 69.557249], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297720, 69.560951], // Спатаева
          [42.297668, 69.560436],
          [42.297620, 69.560295],
          [42.297607, 69.560214],
          [42.297607, 69.560060],
          [42.297597, 69.559935],
          [42.297570, 69.559892],
          [42.297533, 69.559860],
          [42.297466, 69.559816],
          [42.297420, 69.559756],
          [42.297313, 69.559713],
          [42.297237, 69.559708],
          [42.297175, 69.559717],
          [42.297115, 69.559736],
          [42.297068, 69.559767],
          [42.297013, 69.559812],
          [42.296948, 69.559860],
          [42.296894, 69.559892],
          [42.296745, 69.559936],
          [42.296673, 69.559949],
          [42.296564, 69.559936],
          [42.296488, 69.559915], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297024, 69.560537], // 
          [42.297029, 69.560107],
          [42.297015, 69.560071],
          [42.296908, 69.559998],
          [42.296836, 69.559910], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299267, 69.556689], // Тилеулес
          [42.299205, 69.556739],
          [42.298775, 69.556976],
          [42.298410, 69.557220],
          [42.298082, 69.557537],
          [42.298021, 69.557584],
          [42.297678, 69.557781],
          [42.297423, 69.557854],
          [42.297080, 69.557986],
          [42.297036, 69.558015],
          [42.297009, 69.558055],
          [42.296899, 69.558325],
          [42.296717, 69.558864], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296716, 69.558863], // Улгили
          [42.296654, 69.558824],
          [42.296561, 69.558729],
          [42.296425, 69.558644],
          [42.296289, 69.558532],
          [42.296128, 69.558384],
          [42.295977, 69.558262],
          [42.295687, 69.558077],
          [42.295409, 69.557840],
          [42.295071, 69.557399],
          [42.294988, 69.557322],
          [42.294587, 69.557007],
          [42.294380, 69.556815],
          [42.294278, 69.556738],
          [42.293790, 69.556456],
          [42.293499, 69.556249], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296654, 69.558823], //
          [42.296595, 69.558857], 
          [42.296548, 69.558869],
          [42.296482, 69.558870],
          [42.296080, 69.558770], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296566, 69.559560], // Спатаева
          [42.296298, 69.559359],
          [42.296231, 69.559328],
          [42.296172, 69.559324],
          [42.296069, 69.559339],
          [42.296029, 69.559357],
          [42.295951, 69.559410],
          [42.295878, 69.559475],
          [42.295810, 69.559562],
          [42.295742, 69.559683],
          [42.295537, 69.560001],
          [42.295454, 69.560098],
          [42.295264, 69.560399], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295810, 69.559559], // Спатаева
          [42.295686, 69.559567],
          [42.295526, 69.559507],
          [42.295260, 69.559249],
          [42.294810, 69.558615], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295534, 69.560005], // Спатаева
          [42.295007, 69.559572],
          [42.294581, 69.559131],
          [42.294534, 69.559097],
          [42.294502, 69.559095],
          [42.294358, 69.559187], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295832, 69.559532], // 
          [42.295632, 69.559144],
          [42.295576, 69.559008], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296574, 69.558742], // Ермек су
          [42.296546, 69.558573],
          [42.296451, 69.558211],
          [42.296173, 69.557326],
          [42.296110, 69.557084],
          [42.295906, 69.556379],
          [42.295856, 69.555963],
          [42.295860, 69.555734],
          [42.296039, 69.555131], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296110, 69.557085], // Речная
          [42.296088, 69.557138],
          [42.296005, 69.557207],
          [42.295951, 69.557229],
          [42.295830, 69.557197],
          [42.295655, 69.557069],
          [42.295588, 69.557012],
          [42.295183, 69.556597], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296110, 69.557085], // 
          [42.296170, 69.557024],
          [42.296408, 69.556862], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297685, 69.557777], // Садовая
          [42.297560, 69.557035],
          [42.297462, 69.556350], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299096, 69.555757], // Садовая
          [42.299036, 69.555743],
          [42.298291, 69.555959],
          [42.298256, 69.555956],
          [42.298221, 69.555938],
          [42.298187, 69.555874],
          [42.298063, 69.555339],
          [42.298036, 69.555153], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298066, 69.555353], // 
          [42.298260, 69.555289],
          [42.298391, 69.555209],
          [42.298404, 69.555172],
          [42.298426, 69.555045],
          [42.298388, 69.554786],
          [42.298388, 69.554786], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298183, 69.555865], // 
          [42.297770, 69.555999],
          [42.297591, 69.556061],
          [42.297484, 69.556091],
          [42.297447, 69.556094], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.299478, 69.554971], // Ермек су
          [42.299228, 69.554707],
          [42.298970, 69.554288],
          [42.298660, 69.553637],
          [42.298618, 69.553560], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.298303, 69.553435], // 
          [42.298272, 69.553558],
          [42.298226, 69.553678],
          [42.298193, 69.553728],
          [42.298046, 69.553705],
          [42.298036, 69.553708],
          [42.298029, 69.553730],
          [42.298023, 69.554141], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297604, 69.553386], // Ермек су
          [42.297549, 69.553406],
          [42.297378, 69.553554],
          [42.297170, 69.553792],
          [42.296994, 69.554031],
          [42.296994, 69.554031],
          [42.296817, 69.554256],
          [42.296793, 69.554278],
          [42.296763, 69.554296],
          [42.296723, 69.554303],
          [42.296588, 69.555206],
          [42.296576, 69.555334],
          [42.296562, 69.555696], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297114, 69.555190], // 
          [42.296733, 69.555020],
          [42.296629, 69.554940], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297202, 69.554415], // 
          [42.296854, 69.554211], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296723, 69.554303], // 2-й переулок Ермек су
          [42.296718, 69.554288],
          [42.295649, 69.553438], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295649, 69.553438], // 
          [42.296018, 69.552855],
          [42.296088, 69.552805],
          [42.296142, 69.552785],
          [42.296193, 69.552831],
          [42.296463, 69.553196], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          
          [42.296607, 69.555089], // 3-й переулок Ермек су
          [42.296518, 69.555093],
          [42.296288, 69.555070],
          [42.296199, 69.555057],
          [42.296145, 69.555055],
          [42.296078, 69.555080],
          [42.296032, 69.555115],
          [42.295691, 69.555055],
          [42.295481, 69.555064],
          [42.294834, 69.555153],
          [42.294808, 69.555151],
          [42.294788, 69.555141],
          [42.294769, 69.555103],
          [42.294685, 69.554832], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296031, 69.555116], // 
          [42.296038, 69.555130], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295130, 69.555112], // 3-й переулок Ермек су
          [42.295168, 69.555775],
          [42.295163, 69.556141], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294040, 69.558490], // Ермек су
          [42.294045, 69.558264],
          [42.294037, 69.558227],
          [42.294016, 69.558201],
          [42.293891, 69.558118],
          [42.293490, 69.557860],
          [42.293422, 69.557836],
          [42.293382, 69.557830],
          [42.293300, 69.557842],
          [42.293068, 69.557992],
          [42.292957, 69.558010],
          [42.292838, 69.557915], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.295649, 69.553437], // 1-й переулок Ермек су
          [42.294684, 69.554832],
          [42.294407, 69.555149],
          [42.294305, 69.555235],
          [42.294015, 69.555524],
          [42.293883, 69.555634],
          [42.293730, 69.555811],
          [42.293415, 69.556401],
          [42.293138, 69.557012],
          [42.293063, 69.557142],
          [42.292961, 69.557492],
          [42.292958, 69.557565],
          [42.292945, 69.557631],
          [42.292920, 69.557711],
          [42.292830, 69.557928],
          [42.292724, 69.558100],
          [42.292665, 69.558181],
          [42.292261, 69.558694],
          [42.292230, 69.558732],
          [42.292106, 69.558854], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296520, 69.551574], // Ермек су
          [42.296213, 69.552001],
          [42.295434, 69.553193], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.296520, 69.551574], // Керқұдық
          [42.296530, 69.551574],
          [42.297481, 69.552959], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.297958, 69.552449], // Бесбақан
          [42.297873, 69.552116],
          [42.297527, 69.551485],
          [42.297042, 69.550782], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.294440, 69.560220], // Ермек су
          [42.293960, 69.559894],
          [42.293896, 69.559842],
          [42.293863, 69.559783],
          [42.293740, 69.559584],
          [42.293707, 69.559547],
          [42.293669, 69.559527],
          [42.293628, 69.559531],
          [42.293389, 69.559590],
          [42.293326, 69.559582],
          [42.293076, 69.559412],
          [42.293014, 69.559329],
          [42.292580, 69.558994],
          [42.292271, 69.558684], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293305, 69.558759], // 
          [42.292778, 69.558589],
          [42.292748, 69.558601],
          [42.292725, 69.558625],
          [42.292580, 69.558994], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293025, 69.560269], // Керуен саз
          [42.292876, 69.560895],
          [42.292633, 69.560854],
          [42.292570, 69.561277],
          [42.292414, 69.561238],
          [42.291533, 69.561087],
          [42.291475, 69.561066],
          [42.291447, 69.561043],
          [42.291431, 69.561014],
          [42.291418, 69.560912],
          [42.291420, 69.560838],
          [42.291431, 69.560763],
          [42.291485, 69.560501],
          [42.291490, 69.560432],
          [42.291487, 69.560363],
          [42.291472, 69.560313],
          [42.291465, 69.560304],
          [42.291344, 69.560222], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.292100, 69.560232], // 
          [42.291924, 69.560166],
          [42.291888, 69.560142],
          [42.291658, 69.559875],
          [42.291472, 69.560313], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.293433, 69.561163], // 
          [42.293107, 69.561090],
          [42.293066, 69.561071],
          [42.292879, 69.560913],
          [42.292639, 69.560871],
          [42.292392, 69.560812], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.290797, 69.561776], // Бескайнар
          [42.292288, 69.562169],
          [42.292394, 69.562231],
          [42.292611, 69.562338],
          [42.293066, 69.562628],
          [42.293131, 69.562648],
          [42.293496, 69.562537],
          [42.293568, 69.562529],
          [42.293588, 69.562519],
          [42.293615, 69.562459],
          [42.293616, 69.562374], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.292396, 69.562231], // Тупик Бескайнар
          [42.292358, 69.563081],
          [42.292352, 69.563108],
          [42.292339, 69.563126],
          [42.292321, 69.563131],
          [42.292289, 69.563131], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.292531, 69.562063], // 
          [42.292288, 69.562168], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.290748, 69.563809], // 
          [42.290733, 69.563613],
          [42.290731, 69.563297],
          [42.290780, 69.562893],
          [42.290911, 69.562247],
          [42.290908, 69.562110],
          [42.29093, 69.561813], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291829, 69.563254], // Қабылтау
          [42.291749, 69.563259],
          [42.291666, 69.563249],
          [42.291584, 69.563206],
          [42.291523, 69.563131],
          [42.291463, 69.563019],
          [42.291399, 69.562853],
          [42.291002, 69.562432],
          [42.290950, 69.562343],
          [42.290909, 69.562180], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291283, 69.563869], // 
          [42.291291, 69.563729],
          [42.291291, 69.563472],
          [42.291398, 69.562852], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.291826, 69.562755], // 
          [42.291505, 69.562790],
          [42.291327, 69.562777], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],

        color: '#FF0000',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],

        color: '#0000FF',
      },
      ///////////////////  Састелеком жүргізілмеген 2022 //////////////
      {
        coordinates: [
          [42.425296, 69.609977], // Аңырақай
          [42.424058, 69.620536],
          [42.424093, 69.620733], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.432995, 69.614911], // 
          [42.431386, 69.614353],
          [42.431120, 69.614238],
          [42.428494, 69.613638],
          [42.426717, 69.613277],
          [42.424931, 69.612888],
          [42.424913, 69.613045],
          [42.424042, 69.612854],
          [42.422243, 69.612440], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.430592, 69.611060], // 
          [42.429750, 69.610865],
          [42.429632, 69.610832],
          [42.428857, 69.610737],
          [42.427088, 69.610390],
          [42.426815, 69.610313],
          [42.426167, 69.610185],
          [42.425297, 69.609975], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.430770, 69.617254], // 
          [42.430770, 69.617254],
          [42.429033, 69.616871],
          [42.428130, 69.616702],
          [42.428042, 69.616656],
          [42.427789, 69.616572],
          [42.427238, 69.616449],
          [42.426363, 69.616296],
          [42.425035, 69.615995],
          [42.424908, 69.615996],
          [42.424781, 69.615975],
          [42.424568, 69.615919], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.430593, 69.611059], // Доланалы
          [42.430534, 69.611870],
          [42.430271, 69.614021],
          [42.430121, 69.615163],
          [42.430096, 69.615552],
          [42.429889, 69.617079],
          [42.429897, 69.617228],
          [42.429639, 69.619137],
          [42.429595, 69.619353], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.432271, 69.607805], // Ханқорған
          [42.432160, 69.607858],
          [42.432061, 69.607985],
          [42.431903, 69.608352],
          [42.431785, 69.608757],
          [42.431749, 69.608979],
          [42.431670, 69.609794],
          [42.431581, 69.610562],
          [42.431493, 69.611129],
          [42.431470, 69.611435],
          [42.431120, 69.614237],
          [42.430770, 69.617254],
          [42.430451, 69.619790], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.433615, 69.610536], // 
          [42.433278, 69.610346],
          [42.431783, 69.609788],
          [42.431783, 69.609788],
          [42.431347, 69.609758],
          [42.430846, 69.609774], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.433239, 69.611553], // Ханқорған 7 өткелі
          [42.432941, 69.611390],
          [42.432596, 69.611272],
          [42.431826, 69.610978],
          [42.431541, 69.610893], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.433063, 69.612582], // Ханқорған 6 өткелі
          [42.431803, 69.612119],
          [42.431416, 69.612011], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.433063, 69.613745], // Ханқорған 5 өткелі
          [42.431281, 69.613121], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.432889, 69.616030], // Ханқорған 4 өткелі
          [42.431465, 69.615520],
          [42.431002, 69.615382], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.432780, 69.617182], // Ханқорған 3 өткелі
          [42.432660, 69.617109],
          [42.431099, 69.616530],
          [42.430868, 69.616456], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.432669, 69.618275], // Ханқорған 2 өткелі
          [42.432492, 69.618204],
          [42.431694, 69.617937],
          [42.431215, 69.617737],
          [42.431141, 69.617705],
          [42.431019, 69.617665],
          [42.430747, 69.617584], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.432592, 69.619349], // Ханқорған 1 өткелі
          [42.432156, 69.619227],
          [42.431029, 69.618805],
          [42.430614, 69.618651], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.432512, 69.620755], // 
          [42.432352, 69.620706],
          [42.430465, 69.619797],
          [42.429178, 69.619151],
          [42.429057, 69.619088],
          [42.428769, 69.618893], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.432345, 69.631422], // Ақжолтай
          [42.432308, 69.631605],
          [42.432311, 69.633106],
          [42.432326, 69.633376],
          [42.432311, 69.633832],
          [42.432287, 69.633934],
          [42.432261, 69.634008],
          [42.431660, 69.635251],
          [42.431637, 69.635340],
          [42.431641, 69.635513], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.432320, 69.631549], // Атажұрт
          [42.430569, 69.635392],
          [42.430507, 69.635559],
          [42.430496, 69.635643],
          [42.430501, 69.635720], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.429546, 69.635710], // Жомарт
          [42.431651, 69.630864],
          [42.432286, 69.629340],
          [42.432311, 69.629307],
          [42.432392, 69.629259], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.431576, 69.626219], // Жана Курылыс
          [42.429406, 69.625087], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.429854, 69.623804], // Шадиабат
          [42.429072, 69.626064],
          [42.427507, 69.630440],
          [42.427305, 69.630947],
          [42.427005, 69.631596], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.429527, 69.626295], // 
          [42.428647, 69.628808], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.424644, 69.637048], // Манкент
          [42.424686, 69.636793],
          [42.428472, 69.625770], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.424643, 69.633934], // Мельничная
          [42.423747, 69.636806],
          [42.423709, 69.637060], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.427882, 69.622812], // Пошанова
          [42.427432, 69.624059],
          [42.427078, 69.625050],
          [42.425190, 69.630501],
          [42.424137, 69.633598],
          [42.423037, 69.636732],
          [42.422982, 69.637066], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.423364, 69.633907], // Жастар
          [42.422906, 69.635132],
          [42.422543, 69.636004],
          [42.422346, 69.636670],
          [42.422309, 69.637076], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.424773, 69.626465], // Узбек
          [42.424063, 69.628774],
          [42.423046, 69.631525],
          [42.422735, 69.632739],
          [42.422680, 69.632939],
          [42.421788, 69.635501],
          [42.421357, 69.636554],
          [42.421275, 69.636808],
          [42.421254, 69.636908],
          [42.421236, 69.637095], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.422719, 69.630345], // Аманат
          [42.422392, 69.630928],
          [42.422177, 69.631423],
          [42.421782, 69.632593],
          [42.421736, 69.632856],
          [42.421715, 69.632922],
          [42.421622, 69.633130],
          [42.421484, 69.633585],
          [42.421355, 69.633887],
          [42.420778, 69.635380],
          [42.420678, 69.635593],
          [42.420563, 69.635803],
          [42.419889, 69.636711],
          [42.419799, 69.636875],
          [42.419766, 69.636998], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.431830, 69.634858], // Жүзасу
          [42.431049, 69.634369],
          [42.427881, 69.632164],
          [42.425192, 69.630503],
          [42.423762, 69.629610],
          [42.423147, 69.629046], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [42.427511, 69.635731], // Ақкиіксай
          [42.426584, 69.635111],
          [42.425482, 69.634436],
          [42.424131, 69.633576],
          [42.422740, 69.632721],
          [42.422685, 69.632925],
          [42.421823, 69.632456], //
        ],

        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],

        color: '#FF0000',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],

        color: '#FF0000',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],

        color: '#FF0000',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],

        color: '#FF0000',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],

        color: '#FF0000',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],

        color: '#FF0000',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],

        color: '#FF0000',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],

        color: '#FF0000',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],

        color: '#FF0000',
      },
    ]
  },

  ////////////////////////////////////// ILink.net //////////////////////////////////////////////////////////
  {
    name: 'ILink.net',
    lines: [
      {
        coordinates: [
          [42.375796, 69.517200], //Қыз Жібек
          [42.375538, 69.519717],
          [42.375740, 69.522550], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.374965, 69.517052], //Шұбартөбе
          [42.374692, 69.519500],
          [42.375130, 69.527007], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.374099, 69.516801], //Көкжал
          [42.373825, 69.519302],
          [42.374336, 69.527857], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.373281, 69.516695], //Шерғала
          [42.372926, 69.519750],
          [42.373157, 69.524946],
          [42.373427, 69.528030], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.373434, 69.528538], //Шатыркол
          [42.376205, 69.533283], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376466, 69.519969], //
          [42.373056, 69.519141], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375692, 69.522639], //
          [42.374973, 69.522731], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.372854, 69.519858], //Майлысай
          [42.369647, 69.519016], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.372910, 69.520656], //Акдидар
          [42.369085, 69.520383],
          [42.366148, 69.524116], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.371890, 69.520686], //Шаугар
          [42.373329, 69.528346], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.371063, 69.520640], //Берен
          [42.372519, 69.529074], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.371459, 69.528237], //Қорған көл
          [42.370366, 69.523466], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.370392, 69.523311], //
          [42.370161, 69.520566], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.370641, 69.528213], //
          [42.370403, 69.527757],
          [42.370179, 69.527143],
          [42.370060, 69.526636],
          [42.369989, 69.525980],
          [42.369858, 69.525268],
          [42.369122, 69.522127],
          [42.369084, 69.521865],
          [42.369117, 69.521666],
          [42.369232, 69.521284],
          [42.369290, 69.520482], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369702, 69.520389], //Қорған көл
          [42.369544, 69.518119], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369495, 69.518175], //Сызған
          [42.365547, 69.523297], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.438500, 69.541222], // Центральная улица
          [42.433189, 69.557151], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.437409, 69.542512], // Клубничная
          [42.433536, 69.554080], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.436302, 69.543873], // Цветочная
          [42.434168, 69.550491], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.434349, 69.550735], // Малиновая
          [42.433232, 69.553911], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.433206, 69.554009], // Малиновая
          [42.431975, 69.557506], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.435724, 69.543593], //Химиков
          [42.433597, 69.550228],
          [42.433844, 69.550381],//
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.433844, 69.5503814], // Садовая
          [42.431421, 69.557788], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.435199, 69.543275], // Фруктовая
          [42.433092, 69.549842], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.433304, 69.550114], //Ореховая
          [42.430913, 69.557462], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.437104, 69.536424], // Мичурина
          [42.430234, 69.557589], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.434367, 69.542802], // Виноградная
          [42.432247, 69.549313], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.433817, 69.542471], // Кунцевича
          [42.431687, 69.548999], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.433254, 69.542160], // Русские Березки
          [42.431145, 69.548664], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.431836, 69.549259], // Абрикосовая
          [42.430461, 69.553523], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.431414, 69.548965], // Белая Ромашка
          [42.430000, 69.553250], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.432723, 69.541835], // Зелёная улица
          [42.432723, 69.541835], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.430950, 69.548687], // Вишневая
          [42.429593, 69.552971], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.432422, 69.541670], // Вишневая
          [42.430309, 69.548181], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.430466, 69.548472], // Приовражная
          [42.429929, 69.550250], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.431863, 69.541337], // Жарких
          [42.430226, 69.546479], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.431232, 69.540950], // Фруктовая
          [42.430366, 69.543803],
          [42.430198, 69.544217], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.436296, 69.547828], //
          [42.431405, 69.544936],
          [42.430810, 69.544583],
          [42.430166, 69.544277],
          [42.429871, 69.544196], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.435171, 69.551106], //
          [42.430331, 69.548196], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.432216, 69.557896], //
          [42.430604, 69.556634], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.431114, 69.554679], // Серегина
          [42.428545, 69.553059], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.430771, 69.555858], // Комарова
          [42.428005, 69.554118], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.430375, 69.557081], // Гагарина
          [42.427923, 69.555492], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.438488, 69.541177], //
          [42.437157, 69.540394], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.437157, 69.540394], //
          [42.436044, 69.543656], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.436037, 69.539714], // Сиреневая
          [42.436589, 69.540045],
          [42.435481, 69.543334], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.434970, 69.543054], // Дружбы
          [42.430743, 69.540593], //
        ],
        color: '#0000FF'
      },

      ///////// Ынтымақ-1 ///////////
      {
        coordinates: [
          [42.371060, 69.511240], // Керней
          [42.375742, 69.512484], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.370836, 69.512320], // Яссы
          [42.376004, 69.513626], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.370513, 69.513363], // Атанұры
          [42.373032, 69.514118],
          [42.376102, 69.514794], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.370211, 69.514427], // Талдысай
          [42.372978, 69.515277],
          [42.376292, 69.516059], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369901, 69.515457], // Тасбұлақ
          [42.373295, 69.516528],
          [42.376234, 69.517192], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.365737, 69.519624], // 
          [42.362936, 69.523268], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363528, 69.521040], // Катын Копыр
          [42.362495, 69.522331], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363099, 69.520272], // Катын Копыр
          [42.362386, 69.521083], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.365313, 69.515693], // Тортколь
          [42.365426, 69.515765],
          [42.367399, 69.518088], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.364709, 69.516495], // Бейбитшилик
          [42.366790, 69.519000],
          [42.366831, 69.519024],
          [42.366859, 69.519027],
          [42.366910, 69.519003],
          [42.367409, 69.518220],
          [42.367418, 69.518190],
          [42.367421, 69.518158],
          [42.367414, 69.518117],
          [42.367399, 69.518088], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.366741, 69.517320], //
          [42.367220, 69.516519], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.366145, 69.518223], //
          [42.365562, 69.519188], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.365485, 69.517438], //
          [42.364589, 69.518826], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },

      /////////////////////// ILink.net 2022 ///////////////////////////////

      {
        coordinates: [
          [42.368325, 69.532737], // Шугала
          [42.368291, 69.532647],
          [42.368270, 69.532605],
          [42.368128, 69.532402],
          [42.360783, 69.524058],
          [42.360633, 69.523902],
          [42.360522, 69.523792],
          [42.360458, 69.523742],
          [42.360397, 69.523716],
          [42.360206, 69.523678],
          [42.360134, 69.523683],
          [42.360086, 69.523696],
          [42.357136, 69.525036],
          [42.357060, 69.525083],
          [42.356983, 69.525163],
          [42.356104, 69.526323], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.367464, 69.533303], // Сардар
          [42.367388, 69.533192],
          [42.367157, 69.532894],
          [42.360203, 69.524977],
          [42.360128, 69.524887],
          [42.360094, 69.524870],
          [42.358770, 69.525487],
          [42.357673, 69.526076],
          [42.357578, 69.526145],
          [42.357484, 69.526230],
          [42.357420, 69.526304],
          [42.357064, 69.526836],
          [42.356672, 69.527265], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.366664, 69.533989], // Ак Биик
          [42.366585, 69.533846],
          [42.360031, 69.526384],
          [42.359857, 69.526202],
          [42.359820, 69.526193],
          [42.359787, 69.526197],
          [42.358717, 69.526716],
          [42.356954, 69.527777], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.365840, 69.534666], // Аскар Тау
          [42.365805, 69.534583],
          [42.365752, 69.534505],
          [42.365544, 69.534242],
          [42.362770, 69.531089],
          [42.361966, 69.530182],
          [42.360032, 69.528016], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360032, 69.528016], // Мейиримди
          [42.360004, 69.527492],
          [42.359996, 69.527439],
          [42.359973, 69.527393],
          [42.359937, 69.527379],
          [42.359892, 69.527393],
          [42.359787, 69.527465],
          [42.359265, 69.527756],
          [42.358431, 69.528263],
          [42.358323, 69.528322],
          [42.358043, 69.528455],
          [42.357410, 69.528691],
          [42.357374, 69.528708], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.361439, 69.529593], // Инжу Маржан
          [42.361412, 69.529592],
          [42.360120, 69.529286],
          [42.359927, 69.529239],
          [42.359843, 69.529190],
          [42.359578, 69.528965],
          [42.359542, 69.528961],
          [42.359501, 69.528978],
          [42.358025, 69.529856],
          [42.357923, 69.529728],
          [42.357909, 69.529731],
          [42.357770, 69.529851],
          [42.357696, 69.529928],
          [42.356890, 69.530998],
          [42.356261, 69.531836],
          [42.355257, 69.533208], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.365052, 69.535314], // Ак Едил
          [42.364977, 69.535176],
          [42.364927, 69.535103],
          [42.364869, 69.535024],
          [42.363614, 69.533614],
          [42.362038, 69.531879],
          [42.361793, 69.531569], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.361793, 69.531569], // Ак Едил
          [42.361882, 69.530863],
          [42.360969, 69.530716],
          [42.360919, 69.530714],
          [42.360894, 69.530720],
          [42.360862, 69.530761],
          [42.360858, 69.530797],
          [42.360907, 69.532016],
          [42.360912, 69.532563],
          [42.360949, 69.533421],
          [42.360945, 69.533464],
          [42.360935, 69.533492],
          [42.360914, 69.533512],
          [42.360631, 69.533597],
          [42.360565, 69.533610],
          [42.360429, 69.533602],
          [42.360272, 69.533562],
          [42.360205, 69.533556],
          [42.359957, 69.533576],
          [42.359831, 69.533564],
          [42.359715, 69.533561],
          [42.359638, 69.533572],
          [42.359260, 69.533681], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.364226, 69.535995], // Алтын Астык
          [42.364129, 69.535813],
          [42.364084, 69.535746],
          [42.361622, 69.532926], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363431, 69.536661], // Каркаралы
          [42.363300, 69.536441],
          [42.361335, 69.534227], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.362558, 69.537377], // Раугаш
          [42.362490, 69.537241],
          [42.362319, 69.536984],
          [42.360811, 69.535148], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.361779, 69.537975], // Танкурай
          [42.361742, 69.537901],
          [42.361672, 69.537791],
          [42.360841, 69.536836],
          [42.360358, 69.536190],
          [42.360342, 69.536136],
          [42.360338, 69.536085],
          [42.360354, 69.536040],
          [42.360481, 69.535859],
          [42.360501, 69.535807],
          [42.360510, 69.535756],
          [42.360509, 69.535692],
          [42.360423, 69.535258],
          [42.360166, 69.534290],
          [42.360154, 69.534216],
          [42.360132, 69.533560],
          [42.360108, 69.531138],
          [42.360030, 69.530572], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360024, 69.538235], // Шарайна
          [42.360095, 69.537877],
          [42.360092, 69.537807],
          [42.360081, 69.537734],
          [42.359905, 69.536964],
          [42.359445, 69.535065],
          [42.359240, 69.534303],
          [42.359221, 69.534179],
          [42.359213, 69.533987],
          [42.359236, 69.533918],
          [42.359250, 69.533831],
          [42.359260, 69.533679],
          [42.359273, 69.532882],
          [42.359275, 69.532122],
          [42.359265, 69.531712],
          [42.359245, 69.531205],
          [42.359229, 69.531064],
          [42.359150, 69.530485], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360030, 69.530572], //
          [42.359150, 69.530485], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.359214, 69.533980], //
          [42.358967, 69.533872],
          [42.358920, 69.533862],
          [42.358892, 69.533870],
          [42.358871, 69.533894],
          [42.358860, 69.533933],
          [42.358811, 69.534274],
          [42.358800, 69.534406],
          [42.358803, 69.534438],
          [42.358814, 69.534461],
          [42.358879, 69.534507],
          [42.358891, 69.534534],
          [42.358893, 69.534597],
          [42.358795, 69.535696],
          [42.358760, 69.535679],
          [42.358704, 69.535669],
          [42.358231, 69.535650], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.358742, 69.533596], // Аккогершин
          [42.358610, 69.533561],
          [42.358533, 69.533510],
          [42.358492, 69.533501],
          [42.358103, 69.533485],
          [42.357747, 69.533456],
          [42.357541, 69.533461],
          [42.357184, 69.534104],
          [42.357092, 69.534255],
          [42.356966, 69.534393],
          [42.356730, 69.534588],
          [42.356654, 69.534667], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.358571, 69.530977], // Саркырама
          [42.358249, 69.531208],
          [42.357945, 69.531346],
          [42.357730, 69.531471],
          [42.357685, 69.531511],
          [42.357598, 69.531656],
          [42.357455, 69.531881],
          [42.355997, 69.533899], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.362801, 69.524824], //
          [42.362339, 69.527389],
          [42.362409, 69.527479],
          [42.362386, 69.527552],
          [42.362238, 69.528655],
          [42.362180, 69.528905],
          [42.362143, 69.528865],
          [42.361620, 69.532927],
          [42.361540, 69.533515],
          [42.361506, 69.533702],
          [42.361476, 69.533838],
          [42.361328, 69.534241],
          [42.360613, 69.535485],
          [42.360509, 69.535691], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360261, 69.523690], //
          [42.360129, 69.524887], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.359228, 69.531060], // Сыргалы
          [42.358990, 69.531163],
          [42.358691, 69.531306],
          [42.358585, 69.530977],
          [42.358542, 69.530896],
          [42.358263, 69.530306],
          [42.358126, 69.530064],
          [42.358025, 69.529856],
          [42.357922, 69.529729],
          [42.357874, 69.529619],
          [42.357374, 69.528708],
          [42.356960, 69.527791],
          [42.356694, 69.527299],
          [42.356639, 69.527214],
          [42.356208, 69.526457],
          [42.356103, 69.526324],
          [42.356059, 69.526270],
          [42.355844, 69.526056],
          [42.355827, 69.526048],
          [42.355719, 69.526064],
          [42.355675, 69.526059],
          [42.355632, 69.526036],
          [42.355294, 69.525786], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.364411, 69.588176], // Сарыозек
          [42.365884, 69.581774],
          [42.365902, 69.581661],
          [42.365902, 69.581661],
          [42.365901, 69.581599],
          [42.365891, 69.581541],
          [42.365871, 69.581481],
          [42.361903, 69.570844], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363395, 69.587737], // Дархан
          [42.363416, 69.587663],
          [42.363459, 69.587653],
          [42.363507, 69.587636],
          [42.363547, 69.587610],
          [42.363584, 69.587573],
          [42.363612, 69.587524],
          [42.363633, 69.587467],
          [42.364958, 69.581801],
          [42.364962, 69.581731],
          [42.364959, 69.581670],
          [42.364941, 69.581613],
          [42.364897, 69.581509],
          [42.363626, 69.578103],
          [42.362394, 69.574786],
          [42.362359, 69.574697],
          [42.362312, 69.574619],
          [42.362284, 69.574556],
          [42.361100, 69.571377],
          [42.360441, 69.569582],
          [42.359815, 69.567955], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.362689, 69.587450], // Егемен-ел
          [42.363962, 69.581942],
          [42.363974, 69.581857],
          [42.363975, 69.581779],
          [42.363968, 69.581698],
          [42.363946, 69.581626],
          [42.362850, 69.578756],
          [42.362818, 69.578585],
          [42.362807, 69.578549],
          [42.361541, 69.575238],
          [42.361499, 69.575162],
          [42.360276, 69.571897],
          [42.358992, 69.568505], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.361820, 69.587090], // Ерулик
          [42.362956, 69.582078],
          [42.362973, 69.581988],
          [42.362977, 69.581920],
          [42.362973, 69.581854],
          [42.362958, 69.581787],
          [42.362019, 69.579249],
          [42.360710, 69.575750],
          [42.358180, 69.569069], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360961, 69.586708], // Нурлы Жол
          [42.361867, 69.582859],
          [42.361987, 69.582162],
          [42.361991, 69.582027],
          [42.361980, 69.581895],
          [42.361965, 69.581827],
          [42.361217, 69.579775],
          [42.359923, 69.576317],
          [42.357384, 69.569634], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360125, 69.586356], // Игилик
          [42.360985, 69.582634],
          [42.360998, 69.582563],
          [42.361003, 69.582475],
          [42.360996, 69.582408],
          [42.360977, 69.582327],
          [42.360950, 69.582233],
          [42.360919, 69.582143],
          [42.356425, 69.570295], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.358878, 69.582676], // Жана коныс
          [42.358874, 69.582614],
          [42.358879, 69.582562],
          [42.359120, 69.581534],
          [42.359150, 69.581373],
          [42.359171, 69.581223],
          [42.359177, 69.581069],
          [42.359172, 69.580971],
          [42.359145, 69.580774],
          [42.359060, 69.580493],
          [42.355417, 69.570999], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.359146, 69.580784], //
          [42.359037, 69.580819],
          [42.359013, 69.580820],
          [42.358992, 69.580807],
          [42.358979, 69.580781],
          [42.358921, 69.580594],
          [42.355271, 69.571099], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.358991, 69.582071], // Изеты
          [42.358903, 69.581951],
          [42.357691, 69.579914],
          [42.357551, 69.579636],
          [42.356993, 69.578357],
          [42.355742, 69.575043],
          [42.354446, 69.571677], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356643, 69.578962], // Капшараева
          [42.355994, 69.578196],
          [42.355959, 69.578141],
          [42.355924, 69.578073],
          [42.355558, 69.577054],
          [42.355455, 69.576653],
          [42.355423, 69.576565],
          [42.355375, 69.576450],
          [42.355108, 69.575883],
          [42.354780, 69.575100],
          [42.354404, 69.574232],
          [42.354096, 69.573392],
          [42.353652, 69.572243], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.362788, 69.570262], // Озекти
          [42.361902, 69.570843],
          [42.361100, 69.571377],
          [42.360276, 69.571898],
          [42.356713, 69.574383],
          [42.355741, 69.575043], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363961, 69.573531], // Атасу
          [42.362322, 69.574664],
          [42.359906, 69.576312],
          [42.357298, 69.578113],
          [42.357055, 69.578296],
          [42.356995, 69.578359],
          [42.356643, 69.578962], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.365285, 69.576942], // Алтын гасыр
          [42.364373, 69.577620],
          [42.362862, 69.578660],
          [42.362809, 69.578718],
          [42.360270, 69.580457],
          [42.359287, 69.581134],
          [42.359256, 69.581152],
          [42.359228, 69.581161],
          [42.359175, 69.581159], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.365685, 69.580985], //
          [42.364903, 69.581524], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.366294, 69.584839], // М.Байзакова
          [42.365282, 69.584388],
          [42.363568, 69.583648],
          [42.359067, 69.581754], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360077, 69.582180], // Нуржауган
          [42.359524, 69.584657],
          [42.359460, 69.584984],
          [42.359450, 69.585070],
          [42.359454, 69.585146],
          [42.359470, 69.585217],
          [42.359526, 69.585366],
          [42.359574, 69.585551],
          [42.359629, 69.585873],
          [42.359638, 69.585954],
          [42.359638, 69.586021], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.362405, 69.566115], // С.Оскенбая
          [42.361087, 69.562683],
          [42.360477, 69.560965],
          [42.360126, 69.559827],
          [42.359919, 69.558938], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363205, 69.565601], // Шорабек Мустафаева
          [42.361899, 69.562118],
          [42.360648, 69.558832],
          [42.360521, 69.558572], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363985, 69.565097], //Ештай ата
          [42.363679, 69.564186],
          [42.362698, 69.561609],
          [42.362470, 69.560967],
          [42.361343, 69.558047],
          [42.360639, 69.556301],
          [42.360550, 69.556018],
          [42.360454, 69.555593],
          [42.360258, 69.554766], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.364834, 69.564545], //Отырар
          [42.363494, 69.561085],
          [42.362153, 69.557546],
          [42.360986, 69.554651], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.365643, 69.564009], //К.Аманжолова 
          [42.363044, 69.557190],
          [42.362937, 69.556952],
          [42.361883, 69.554168], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.366529, 69.563430], // Бекзат Саттарханов
          [42.365141, 69.559972],
          [42.363817, 69.556379],
          [42.362777, 69.553703], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.367319, 69.562870], //Келес
          [42.366020, 69.559366],
          [42.365979, 69.559394],
          [42.365031, 69.556704],
          [42.364954, 69.556511],
          [42.364708, 69.555836],
          [42.364688, 69.555799],
          [42.364622, 69.555711],
          [42.363663, 69.553264], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.368150, 69.562273], //Манас
          [42.368117, 69.562230],
          [42.366907, 69.559059],
          [42.366828, 69.558829],
          [42.365904, 69.556440],
          [42.365499, 69.555332],
          [42.365391, 69.555162],
          [42.364585, 69.553037],
          [42.364410, 69.552658],
          [42.364117, 69.551634],
          [42.364049, 69.551317],
          [42.363902, 69.550806],
          [42.363817, 69.550549],
          [42.362839, 69.547806],
          [42.362813, 69.547691],
          [42.361785, 69.544978],
          [42.361412, 69.543997],
          [42.359828, 69.540034], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.368935, 69.561711], // Даулбаевой
          [42.367629, 69.558296],
          [42.366251, 69.554735],
          [42.366232, 69.554706],
          [42.365421, 69.552465],
          [42.365415, 69.552381],
          [42.365453, 69.552190],
          [42.365446, 69.552084],
          [42.365430, 69.552035],
          [42.363608, 69.547282],
          [42.363581, 69.547167],
          [42.363557, 69.547084],
          [42.362362, 69.543969],
          [42.362163, 69.543468],
          [42.360652, 69.539540],
          [42.360193, 69.538425], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363004, 69.550352], //Диханколь
          [42.362863, 69.550440],
          [42.362817, 69.550443],
          [42.362790, 69.550421],
          [42.362753, 69.550318],
          [42.361987, 69.548314],
          [42.361952, 69.548202],
          [42.359068, 69.540640], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.368516, 69.557763], //Богенбая Алменова
          [42.367635, 69.558310],
          [42.365141, 69.559971],
          [42.364311, 69.560550],
          [42.363501, 69.561100],
          [42.361898, 69.562117],
          [42.361087, 69.562682],
          [42.360244, 69.563232], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.364119, 69.551644], //
          [42.363095, 69.552185], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363834, 69.550601], //
          [42.362841, 69.551145], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360248, 69.544778], //Жумабике Серикбаева
          [42.374286, 69.535035], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.359068, 69.540640], //Молодая Гвардия
          [42.359792, 69.540078],
          [42.359819, 69.540046],
          [42.359876, 69.539956],
          [42.360207, 69.539706],
          [42.360283, 69.539671],
          [42.360478, 69.539636],
          [42.360522, 69.539620],
          [42.360641, 69.539547], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369848, 69.561070], //С.Юсупова
          [42.366402, 69.552037],
          [42.366278, 69.551542],
          [42.366121, 69.551050],
          [42.365693, 69.549890],
          [42.364427, 69.546587],
          [42.362994, 69.542897],
          [42.361534, 69.539388],
          [42.361378, 69.539063],
          [42.361098, 69.538384], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.368021, 69.553457], //Арайтан
          [42.367260, 69.551506],
          [42.367253, 69.551320],
          [42.367277, 69.551155],
          [42.367270, 69.551082],
          [42.366745, 69.549596],
          [42.366651, 69.549412],
          [42.365335, 69.545959],
          [42.363929, 69.542230],
          [42.363696, 69.542391],
          [42.362000, 69.537975], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369059, 69.559096], // Актасты
          [42.369072, 69.556715],
          [42.369051, 69.556325],
          [42.369009, 69.555953],
          [42.368977, 69.555842],
          [42.368810, 69.555485],
          [42.368511, 69.554703],
          [42.368021, 69.553457], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.364505, 69.541836], //Баисбаева
          [42.362764, 69.537359], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369189, 69.555134], // Баршин
          [42.368171, 69.551197],
          [42.368158, 69.551115],
          [42.368168, 69.550920],
          [42.368161, 69.550777],
          [42.368072, 69.550477],
          [42.366202, 69.545482],
          [42.366137, 69.545391],
          [42.365362, 69.543392],
          [42.365281, 69.543129],
          [42.365176, 69.542705],
          [42.365016, 69.542240], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363596, 69.536827], //Баршин
          [42.366441, 69.544365], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.367499, 69.544445], // Братьев Тажибаевых
          [42.366111, 69.540736],
          [42.364311, 69.536081], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369610, 69.552361], //Арай
          [42.369009, 69.550758],
          [42.368959, 69.550282],
          [42.368931, 69.550122],
          [42.368307, 69.548414],
          [42.367028, 69.544917], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.370424, 69.551804], //Тажибаева
          [42.369054, 69.547899],
          [42.369112, 69.547859],
          [42.367869, 69.544333], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.368308, 69.543888], //Ескараева
          [42.365126, 69.535420], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.371243, 69.551254], //Ескараева
          [42.370517, 69.549352],
          [42.369795, 69.547389], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369121, 69.543322], //Мырзаби Ерназарова
          [42.365893, 69.534845], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.371978, 69.550685], //Мырзаби Ерназарова
          [42.371574, 69.549596],
          [42.371509, 69.549458],
          [42.371357, 69.549181],
          [42.370515, 69.546871], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.372724, 69.550154], //Куралбая Сыздыкулы
          [42.372413, 69.549231],
          [42.367432, 69.536142],
          [42.366627, 69.534171], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.373510, 69.549627], // Сейита Сагынбаева 
          [42.372319, 69.546372],
          [42.372056, 69.545755],
          [42.370730, 69.542309],
          [42.370703, 69.542194],
          [42.368176, 69.535532],
          [42.368137, 69.535432],
          [42.367571, 69.534106],
          [42.367338, 69.533591], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.368268, 69.533083], //Айтбая Шеримкулова
          [42.373751, 69.547606], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375122, 69.548564], //Абилакима Абдрахманова
          [42.374799, 69.547591],
          [42.373657, 69.544653],
          [42.372358, 69.541181],
          [42.372305, 69.541084],
          [42.370933, 69.537462],
          [42.370899, 69.537392],
          [42.369903, 69.534882],
          [42.369175, 69.532826],
          [42.369107, 69.532552], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375626, 69.547120], //Арасантау
          [42.373146, 69.540635],
          [42.373087, 69.540539],
          [42.370444, 69.533552],
          [42.370064, 69.532371], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376749, 69.547461], //Ойсыл баба
          [42.371208, 69.532839], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377627, 69.546936], //Барак хан
          [42.374757, 69.539589],
          [42.374713, 69.539409],
          [42.372310, 69.533127], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378104, 69.545661], //Керей хан
          [42.374187, 69.535161], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378071, 69.588985], //Алтынкорган
          [42.380034, 69.580766],
          [42.380050, 69.580614],
          [42.380054, 69.580435],
          [42.379796, 69.567577],
          [42.379725, 69.563564],
          [42.379365, 69.547098],
          [42.379324, 69.546254],
          [42.379218, 69.546282],
          [42.378956, 69.545313],
          [42.378757, 69.544725],
          [42.377062, 69.540282],
          [42.376759, 69.539658], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.379586, 69.586542], //Казахской орды
          [42.379830, 69.585536],
          [42.379924, 69.584818],
          [42.380132, 69.584135],
          [42.380778, 69.581571],
          [42.380976, 69.581661],
          [42.380904, 69.574396],
          [42.380754, 69.569105],
          [42.380773, 69.567534],
          [42.380636, 69.562752],
          [42.380627, 69.560839],
          [42.380352, 69.547507],
          [42.380348, 69.546611],
          [42.380389, 69.545920],
          [42.380384, 69.545865],
          [42.380348, 69.545737],
          [42.379134, 69.543097],
          [42.378469, 69.541327], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376544, 69.538127], //Аль Фараби
          [42.361952, 69.548204], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378443, 69.541309], // Яссауи
          [42.364134, 69.551245], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.370612, 69.549644], //
          [42.368977, 69.550523], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.368166, 69.550908], //
          [42.368037, 69.550978],
          [42.367348, 69.551205],
          [42.367101, 69.551345],
          [42.366377, 69.551859], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381587, 69.545693], //Бауыржан Момышулы
          [42.378467, 69.546516],
          [42.378266, 69.546605],
          [42.371425, 69.551119],
          [42.371334, 69.551217],
          [42.368863, 69.552898],
          [42.362154, 69.557544],
          [42.359919, 69.558938], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.386991, 69.562482], // Изенды
          [42.386879, 69.556337],
          [42.386808, 69.554512], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.386242, 69.552610], // Коскол
          [42.385953, 69.552943],
          [42.385921, 69.553029],
          [42.385910, 69.553128],
          [42.386069, 69.564563], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.385247, 69.565998], //Нуртау
          [42.384965, 69.551780],
          [42.384968, 69.551539],
          [42.385147, 69.550956], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384000, 69.549578], //Изгилик
          [42.384407, 69.567072],
          [42.384391, 69.567195],
          [42.384370, 69.567268],
          [42.384340, 69.567327],
          [42.384297, 69.567368],
          [42.384257, 69.567386],
          [42.372583, 69.567791], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.383470, 69.567426], // Кус Жолы
          [42.383082, 69.548631],
          [42.383121, 69.548302],
          [42.383204, 69.548054], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.382172, 69.546670], //Алтынбастау
          [42.382627, 69.570042], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.380503, 69.545974], //Мангилик Ел
          [42.380571, 69.546172],
          [42.381135, 69.546014],
          [42.381175, 69.546020],
          [42.381206, 69.546049],
          [42.381243, 69.546216],
          [42.381577, 69.562595],
          [42.381678, 69.567498],
          [42.381956, 69.581829],
          [42.381947, 69.581931],
          [42.381914, 69.582031],
          [42.381854, 69.582051],
          [42.381600, 69.581950], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378479, 69.546511], //Баулык
          [42.379187, 69.580924], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378202, 69.580525], //Алмалы Бак
          [42.378245, 69.580130],
          [42.377631, 69.548195],
          [42.377561, 69.547425],
          [42.377498, 69.547109], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376610, 69.547700], //Шубарколь
          [42.376717, 69.551008],
          [42.376771, 69.551600],
          [42.376796, 69.552501],
          [42.376774, 69.553866],
          [42.376674, 69.554318],
          [42.376652, 69.554894],
          [42.376751, 69.557031],
          [42.376752, 69.557162],
          [42.376631, 69.559677],
          [42.376665, 69.562599],
          [42.376635, 69.563540],
          [42.376612, 69.563879],
          [42.376688, 69.567263],
          [42.376816, 69.575163], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377015, 69.563836], //Шубарколь
          [42.377321, 69.577680], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375872, 69.574567], //Тындала
          [42.375417, 69.551057],
          [42.375347, 69.548762],
          [42.375331, 69.548543], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.374454, 69.549120], //Улы Дала
          [42.374504, 69.551099],
          [42.374643, 69.556283],
          [42.374672, 69.558622],
          [42.374705, 69.559049],
          [42.374705, 69.559049],
          [42.374696, 69.561036],
          [42.374733, 69.563760],
          [42.374962, 69.573229],
          [42.374945, 69.573340],
          [42.374916, 69.573409],
          [42.374680, 69.573601], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.373542, 69.549722], //Самал Жел
          [42.373589, 69.550208],
          [42.373694, 69.553392],
          [42.373668, 69.553673],
          [42.374033, 69.571182],
          [42.374830, 69.571153],
          [42.375012, 69.571118],
          [42.381745, 69.570915], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.372679, 69.5502901], //Кобыланды батыр
          [42.372688, 69.550503],
          [42.372779, 69.552120],
          [42.372742, 69.552463],
          [42.372991, 69.564389],
          [42.373056, 69.567772], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.371774, 69.550858], //Тауекел хан
          [42.372101, 69.564388], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.370899, 69.551510], //Мугалжар
          [42.370921, 69.551649],
          [42.370939, 69.551918],
          [42.371150, 69.563084],
          [42.371115, 69.563429],
          [42.371077, 69.563589],
          [42.370985, 69.563837], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369913, 69.552175], //Жанибек хан
          [42.369974, 69.552480],
          [42.370082, 69.555168],
          [42.370211, 69.560410],
          [42.370197, 69.560709],
          [42.370171, 69.560827],
          [42.370084, 69.560942],
          [42.369868, 69.561080], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381601, 69.581951], //Сазтөбе
          [42.380783, 69.581557],
          [42.377145, 69.580112], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.382243, 69.577559], //Алтынжал
          [42.378090, 69.577685],
          [42.377307, 69.577744], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381805, 69.574452], //Аулие агаш
          [42.381593, 69.574415],
          [42.381473, 69.574405],
          [42.380906, 69.574395],
          [42.378123, 69.574476],
          [42.376774, 69.574516],
          [42.375037, 69.574629], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.386991, 69.562478], //
          [42.386983, 69.562616],
          [42.386961, 69.562658],
          [42.386933, 69.562675],
          [42.386896, 69.562681],
          [42.386389, 69.562654],
          [42.386047, 69.563097],
          [42.385992, 69.563112],
          [42.384300, 69.562797],
          [42.383483, 69.562335],
          [42.383350, 69.562333],
          [42.380104, 69.562817],
          [42.379869, 69.562929],
          [42.379691, 69.563095],
          [42.379550, 69.563159],
          [42.379333, 69.563222],
          [42.378814, 69.563300],
          [42.377894, 69.563482],
          [42.377893, 69.563572],
          [42.376597, 69.563883],
          [42.376610, 69.563561],
          [42.376538, 69.563581],
          [42.375657, 69.563647],
          [42.374316, 69.563839],
          [42.373873, 69.564064],
          [42.373682, 69.564115],
          [42.371662, 69.564524],
          [42.371316, 69.564667], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.386895, 69.558448], //Естемес би
          [42.369428, 69.559156],
          [42.369363, 69.559161],
          [42.369255, 69.559196],
          [42.369158, 69.559257], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.387314, 69.554491], //Алпамыс батыр
          [42.369232, 69.555182],
          [42.369190, 69.555135],
          [42.369125, 69.554250],
          [42.369054, 69.553780],
          [42.368865, 69.552896], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384037, 69.550770], //Тамшы булак
          [42.373616, 69.551170], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.368935, 69.561710], //Алтынарык
          [42.369091, 69.561600],
          [42.371630, 69.568232],
          [42.376114, 69.580053],
          [42.376148, 69.580160],
          [42.376160, 69.580248],
          [42.376138, 69.580691],
          [42.376009, 69.581300],
          [42.375161, 69.584644],
          [42.375088, 69.584870],
          [42.375024, 69.585176],
          [42.374296, 69.588230],
          [42.373515, 69.591628],
          [42.373410, 69.591938],
          [42.373409, 69.591938], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.368150, 69.562275], // Алымтау
          [42.368184, 69.562315],
          [42.368683, 69.563295],
          [42.369574, 69.565496],
          [42.374969, 69.579730],
          [42.374881, 69.582000],
          [42.374319, 69.584011],
          [42.374285, 69.584276],
          [42.372561, 69.591567], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.368762, 69.566068], // Тарактас
          [42.373561, 69.578617], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.367897, 69.566665], //Талас
          [42.370713, 69.574034],
          [42.370698, 69.574146],
          [42.371008, 69.574946],
          [42.372569, 69.578774],
          [42.372613, 69.578900],
          [42.372837, 69.579730],
          [42.372864, 69.579890],
          [42.373025, 69.581128],
          [42.373040, 69.581468],
          [42.373019, 69.581622],
          [42.371294, 69.589059], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.366279, 69.567766], // Аламан
          [42.371251, 69.580834],
          [42.371278, 69.580935],
          [42.371285, 69.581051],
          [42.371248, 69.581288],
          [42.370073, 69.586392], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.365461, 69.568348], // Ушкиян
          [42.368571, 69.576510], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369242, 69.578289], // Ушкиян
          [42.370293, 69.581030],
          [42.370308, 69.581104],
          [42.370310, 69.581239],
          [42.370298, 69.581322],
          [42.369223, 69.586050],
          [42.368881, 69.587227],
          [42.368845, 69.587557],
          [42.368825, 69.587681],
          [42.368542, 69.588519], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.364684, 69.568884], // Оркенды
          [42.369298, 69.581051],
          [42.369322, 69.581153],
          [42.369309, 69.581340],
          [42.367395, 69.589472], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363887, 69.569457], // Алтын дала
          [42.368326, 69.581150],
          [42.368342, 69.581236],
          [42.368346, 69.581319],
          [42.368319, 69.581505],
          [42.367415, 69.585286],
          [42.367419, 69.585433],
          [42.367408, 69.585515],
          [42.367353, 69.585625],
          [42.367084, 69.586621],
          [42.367076, 69.586693],
          [42.367065, 69.586847],
          [42.366562, 69.589011], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.368677, 69.563287], // Атаконыс
          [42.361979, 69.567811], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369086, 69.564426], //Талдыбулак
          [42.364930, 69.567274], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.371185, 69.564364], // Азекти
          [42.365460, 69.568347],
          [42.362785, 69.570266], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.372433, 69.567680], //
          [42.370809, 69.568760], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369076, 69.569968], //Атасу
          [42.364256, 69.573277], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.374589, 69.573696], //Орис
          [42.373705, 69.573746],
          [42.371590, 69.573923],
          [42.371368, 69.573945],
          [42.371010, 69.574039],
          [42.370795, 69.574041],
          [42.370570, 69.573981],
          [42.369657, 69.574033],
          [42.369118, 69.574165],
          [42.365645, 69.575510],
          [42.365052, 69.575858], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375868, 69.576771], // Бектау
          [42.373929, 69.577064],
          [42.373567, 69.576969],
          [42.373274, 69.576923],
          [42.372860, 69.576929],
          [42.372352, 69.576983],
          [42.370538, 69.577482],
          [42.370354, 69.577566],
          [42.370012, 69.577757],
          [42.369118, 69.578348],
          [42.368449, 69.578915],
          [42.366917, 69.580306],
          [42.366735, 69.580428], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378758, 69.586114], // Айтас
          [42.377933, 69.585683],
          [42.377103, 69.585310],
          [42.372623, 69.583376],
          [42.370912, 69.582682],
          [42.370129, 69.582304],
          [42.369043, 69.582700],
          [42.368863, 69.582993],
          [42.367309, 69.583646],
          [42.367073, 69.583702],
          [42.366719, 69.583691], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377048, 69.589374], // Бесқарағай
          [42.370073, 69.586391],
          [42.367541, 69.585286],
          [42.367333, 69.585254],
          [42.366438, 69.584914], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.371991, 69.590021], //Нығмет Төлендиұлы өткелі
          [42.368543, 69.588519],
          [42.367711, 69.588137], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.374500, 69.591969], // Шубарколь
          [42.377144, 69.580221], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378199, 69.580527], // Найзалы
          [42.372327, 69.605286], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.379038, 69.580990], // Нурлытан
          [42.375826, 69.594579],
          [42.375553, 69.595748],
          [42.375289, 69.596911],
          [42.375057, 69.598060], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375057, 69.598060], // Талапты 4 өткелі
          [42.376545, 69.598742], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377619, 69.594234], // Казахской орды
          [42.377494, 69.594684],
          [42.377438, 69.595022],
          [42.377394, 69.595165],
          [42.377345, 69.595379],
          [42.377281, 69.595736],
          [42.377100, 69.596216],
          [42.376887, 69.597098],
          [42.376859, 69.597424],
          [42.376706, 69.597901],
          [42.376542, 69.598760],
          [42.376163, 69.600360], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376810, 69.597576], // Талапты 3 өткелі
          [42.375289, 69.596911], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377054, 69.596394], // Талапты 2 өткелі
          [42.375553, 69.595748], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377349, 69.595363], // Талапты 1 өткелі
          [42.377060, 69.595224],
          [42.376856, 69.595081],
          [42.376720, 69.594993],
          [42.376419, 69.594830],
          [42.376177, 69.594747],
          [42.375826, 69.594579], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.400199, 69.565533], // Тилектес
          [42.398837, 69.567692], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.400893, 69.566338], //Ақпейіл
          [42.398910, 69.569422], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.402144, 69.565681], //Ырысты
          [42.402078, 69.566219],
          [42.402050, 69.566303],
          [42.400233, 69.569212], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.403039, 69.565866], //Бастау
          [42.402950, 69.566726],
          [42.402905, 69.566861],
          [42.400899, 69.570012], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.405750, 69.549718], //Жигер
          [42.404197, 69.563595],
          [42.403930, 69.566067],
          [42.403819, 69.567096],
          [42.403806, 69.567193],
          [42.403759, 69.567351],
          [42.402938, 69.568708],
          [42.400862, 69.571933], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.404807, 69.566245], //Дидар
          [42.404640, 69.567760],
          [42.404596, 69.567897],
          [42.403620, 69.569475],
          [42.401509, 69.572773], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.407549, 69.549579], //Талжибек
          [42.407535, 69.549925],
          [42.407284, 69.551960],
          [42.406483, 69.559159],
          [42.405703, 69.566408],
          [42.405472, 69.568398],
          [42.405434, 69.568500],
          [42.404315, 69.570234],
          [42.404314, 69.570234], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.406600, 69.566595], //Тобылгы
          [42.406340, 69.568740],
          [42.406303, 69.568901],
          [42.406171, 69.569197],
          [42.405010, 69.571030], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.409525, 69.548742], //Кызылкол
          [42.407162, 69.569773], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.409448, 69.557290], //Булбул
          [42.408145, 69.569624], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.410586, 69.555057], //Гульдер Ай
          [42.410480, 69.555667],
          [42.409078, 69.569526], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.409981, 69.569372], //Айтумар
          [42.411456, 69.555243], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.410941, 69.569025], //Айтумар
          [42.412292, 69.555375], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.411861, 69.568826], //Айдарлы
          [42.413203, 69.555486], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.414124, 69.555552], //
          [42.412832, 69.567919], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.414994, 69.555719], //Имангара
          [42.413763, 69.568131], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.412253, 69.564898], //Тыршилик
          [42.408719, 69.564234], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.408690, 69.564511], //Тыршилик
          [42.392599, 69.561286], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.402859, 69.550857], //Ақпейіл
          [42.401218, 69.565469], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.400814, 69.551743], //Қожа Ахмет Йассауи 
          [42.399332, 69.565077], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.398257, 69.552570], //
          [42.396884, 69.564588], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.396266, 69.552235], //Талшын
          [42.396183, 69.552376],
          [42.396140, 69.552432],
          [42.395878, 69.552670],
          [42.395819, 69.552764],
          [42.395775, 69.552884],
          [42.395689, 69.553347],
          [42.394863, 69.560520], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.399469, 69.563800], //Жақұт
          [42.394775, 69.562883],
          [42.394683, 69.562853],
          [42.394500, 69.562757],
          [42.394352, 69.562732],
          [42.394244, 69.562749],
          [42.394170, 69.562802],
          [42.393959, 69.563039], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.407635, 69.565531], //Жақұт
          [42.404060, 69.564829], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.407880, 69.563177], //Тангышык
          [42.392207, 69.560015],
          [42.392137, 69.560052],
          [42.391959, 69.560466], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.415027, 69.563302], //Арайлы Тан\
          [42.392115, 69.558799],
          [42.392002, 69.558803],
          [42.391507, 69.559885], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.392239, 69.557556], //Айтумар
          [42.408163, 69.560721], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.410058, 69.559863], //Жайсан
          [42.392652, 69.556446], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.415256, 69.561032], //Жайсан
          [42.410015, 69.560097], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.408448, 69.558343], //Мамыражай
          [42.393266, 69.555311], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.412954, 69.557957], //Бирлик
          [42.394007, 69.554310], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.394924, 69.553213], //Илтипат
          [42.410350, 69.556269], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.396269, 69.552226], //Музтау
          [42.415695, 69.555836], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.400811, 69.551760], //Акбура
          [42.410655, 69.553836], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.405998, 69.572144], //Алғыс
          [42.399954, 69.565226], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.395154, 69.564233], //Акбосага
          [42.413665, 69.568092], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.396431, 69.612179], // Қойлық
          [42.396436, 69.611890], // 
          [42.396384, 69.611840],
          [42.395164, 69.611981],
          [42.395164, 69.611981],
          [42.394590, 69.611849],
          [42.394535, 69.611771],
          [42.394457, 69.610626],
          [42.394423, 69.610052],
          [42.394457, 69.609840],
          [42.394828, 69.609089],//
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.394828, 69.609089], //Балықты
          [42.395201, 69.609086],
          [42.396821, 69.608877], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.394458, 69.610643], //Рамадан ата
          [42.397011, 69.610330], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.396791, 69.607422], //Баққой
          [42.396004, 69.607521], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.397172, 69.612648], //Бойшұбар
          [42.397184, 69.612410],
          [42.397100, 69.611239],
          [42.397010, 69.610314],
          [42.396820, 69.608862],
          [42.396790, 69.608512],
          [42.396792, 69.607406],
          [42.396824, 69.605521], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.397894, 69.611165], //Жасұлан
          [42.397659, 69.605591], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.398536, 69.605473], //
          [42.398769, 69.611082], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.399649, 69.605325], //Айдаркөл
          [42.399968, 69.610970],
          [42.399838, 69.612958], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.400538, 69.605206], //Нұрата
          [42.400862, 69.610905], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.401205, 69.605105], //Жезқанат
          [42.401670, 69.607826],
          [42.401861, 69.612969], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.402287, 69.610793], //Құлпысары
          [42.397101, 69.611244], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.399364, 69.612901], //Қиятас
          [42.399385, 69.612559],
          [42.399374, 69.612508],
          [42.399340, 69.612486],
          [42.397633, 69.612308],
          [42.397578, 69.612321],
          [42.397505, 69.612377],
          [42.397404, 69.612410],
          [42.397183, 69.612393], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.401220, 69.612282], //Қиятас
          [42.399883, 69.612384], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.404685, 69.612629], //Кылыш
          [42.404354, 69.612562],
          [42.404254, 69.612519],
          [42.404120, 69.612417],
          [42.403995, 69.612258],
          [42.403887, 69.612035],
          [42.403793, 69.611727],
          [42.403722, 69.611452],
          [42.403644, 69.611268],
          [42.403546, 69.611113],
          [42.403490, 69.611043],
          [42.403241, 69.610797],
          [42.403147, 69.610679],
          [42.403116, 69.610623],
          [42.403091, 69.610554],
          [42.403073, 69.610462],
          [42.403062, 69.610327],
          [42.403035, 69.609368],
          [42.402999, 69.608166],
          [42.402944, 69.606965],
          [42.402919, 69.606810],
          [42.402548, 69.604925],
          [42.402604, 69.604899],
          [42.401898, 69.603533], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.404464, 69.610147], //Сарыбұлақ
          [42.403599, 69.609381],
          [42.403561, 69.609359],
          [42.402540, 69.609400], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.404464, 69.610147], //Моншақты
          [42.404540, 69.610262],
          [42.404573, 69.610332],
          [42.404608, 69.610469],
          [42.404620, 69.610548],
          [42.404723, 69.613527], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.405141, 69.609013], //Құмауыл
          [42.404313, 69.608224],
          [42.404277, 69.608135],
          [42.403516, 69.605326],
          [42.403465, 69.604774],
          [42.403267, 69.602219], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.405537, 69.608349], //Шежіре
          [42.404551, 69.604760],
          [42.404540, 69.604638],
          [42.404287, 69.601227], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.401771, 69.595813], //Төлтай
          [42.403250, 69.598405],
          [42.404668, 69.600874],
          [42.405422, 69.602024],
          [42.405442, 69.602066],
          [42.405451, 69.602110],
          [42.405629, 69.604494],
          [42.406314, 69.606946], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.405651, 69.608756], //Көлтоған
          [42.405804, 69.613393], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.406907, 69.613323], //Ғаламшар
          [42.406789, 69.609698], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.406454, 69.600924], //Талдықұдық
          [42.406829, 69.605785], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.404494, 69.610091], //
          [42.404462, 69.610147],
          [42.405139, 69.609014],
          [42.405537, 69.608351],
          [42.406314, 69.606948],
          [42.406807, 69.605976],
          [42.406824, 69.605930],
          [42.406829, 69.605886],
          [42.406829, 69.605785], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.407228, 69.605770], //
          [42.406516, 69.607216],
          [42.406216, 69.607812],
          [42.405651, 69.608758],
          [42.404610, 69.610490], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.407228, 69.605770], //Сейхун
          [42.407843, 69.605721],
          [42.408554, 69.605656],
          [42.408444, 69.602767],
          [42.407698, 69.602804],
          [42.407670, 69.602023],
          [42.407655, 69.601840],
          [42.407197, 69.599548],
          [42.406562, 69.598084],
          [42.405651, 69.596924],
          [42.401381, 69.591503], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.408942, 69.613640], //
          [42.408958, 69.613188],
          [42.408834, 69.612853],
          [42.408099, 69.612767], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.408834, 69.612853], //Құмжиек
          [42.408440, 69.602860], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.409950, 69.613691], //Акпан
          [42.409579, 69.603996], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.411067, 69.613912], //Тассуат
          [42.410678, 69.603851], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.412164, 69.614241], //Алқакол
          [42.411771, 69.603705],
          [42.411522, 69.600643],
          [42.411505, 69.600546],
          [42.410546, 69.598215], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.412007, 69.610059], //
          [42.408733, 69.610286], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.411894, 69.607089], //
          [42.408628, 69.607325], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.407969, 69.609621], //
          [42.405686, 69.609766], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.411770, 69.603706], //Жантөре ата
          [42.408497, 69.604143], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.411637, 69.602101], //Шыңтас
          [42.409767, 69.602337],
          [42.408959, 69.602433],
          [42.408894, 69.602425],
          [42.408625, 69.602259], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.411090, 69.599558], //
          [42.410604, 69.599923],
          [42.409781, 69.602335], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.412822, 69.603545], //Үстірт
          [42.412551, 69.600232],
          [42.412531, 69.600098],
          [42.412477, 69.599946],
          [42.411484, 69.597557], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.412249, 69.597023], //Ақкөпір
          [42.410546, 69.598216],
          [42.409841, 69.598710],
          [42.409824, 69.598735],
          [42.408442, 69.602783], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.411924, 69.596139], //Шабыт
          [42.409528, 69.598297], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.408149, 69.602774], //
          [42.409629, 69.598428], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.399084, 69.598169], //Шалгай
          [42.402071, 69.603370], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.399809, 69.597098], //Корган
          [42.402941, 69.602546], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.403785, 69.601681], //Шыршық
          [42.400889, 69.596609],
          [42.400470, 69.596124], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.402650, 69.595013], //Қаржау
          [42.405526, 69.600037], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.406194, 69.598909], //Ақтиін
          [42.405649, 69.597956],
          [42.405638, 69.597916],
          [42.405655, 69.597878],
          [42.406054, 69.597439], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.407110, 69.604328], //Жантөре
          [42.396835, 69.605702], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.411421, 69.594837], //Қойтас
          [42.406908, 69.598907],
          [42.406859, 69.598796],
          [42.405527, 69.600036],
          [42.404286, 69.601228],
          [42.403786, 69.601681],
          [42.402940, 69.602547],
          [42.401898, 69.603533],
          [42.401492, 69.603939], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.410097, 69.592143], //Нұрлы
          [42.399886, 69.601411], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.403530, 69.594236], //Талпыныс
          [42.400888, 69.596608], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.402733, 69.593226], //
          [42.401938, 69.593948],
          [42.398568, 69.598930], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.405218, 69.595158], //Талапкер
          [42.404464, 69.593669],
          [42.402074, 69.590278], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.407909, 69.598004], //Көркемай
          [42.405352, 69.592865],
          [42.402781, 69.589194], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.409848, 69.598704], //Құланшы
          [42.408960, 69.597541],
          [42.408789, 69.597198],
          [42.406277, 69.592117],
          [42.406162, 69.591915],
          [42.403493, 69.588131], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.409680, 69.596409], //Ашыкол
          [42.407130, 69.591242],
          [42.406848, 69.590782],
          [42.405605, 69.588997],
          [42.404071, 69.587267], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.410555, 69.595600], //Жанаша
          [42.407878, 69.590199],
          [42.407819, 69.590100],
          [42.406524, 69.588253],
          [42.404762, 69.586238], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.411924, 69.596138], //Маржанбұлақ
          [42.411416, 69.594823],
          [42.408883, 69.589666],
          [42.408757, 69.589435],
          [42.407351, 69.587441],
          [42.405428, 69.585248], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.408890, 69.589680], //Рабат
          [42.403659, 69.594399], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.409469, 69.588288], //Екпінді
          [42.406017, 69.584345], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.411589, 69.589113], //Құттыбілік
          [42.408607, 69.585713], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.413182, 69.589341], //Торғауыт
          [42.409189, 69.584794], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },

      ///////////////////////  ILink.net жүргізілмеген /////////////////////

      {
        coordinates: [
          [42.373076, 69.517583], //Мұхит
          [42.369576, 69.516621], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.372935, 69.518746], //Сартай батыр
          [42.369634, 69.517818], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.372130, 69.523287], //
          [42.371880, 69.523409],
          [42.371674, 69.523478],
          [42.371500, 69.523491],
          [42.371254, 69.523431],
          [42.370567, 69.523523],
          [42.369609, 69.523802], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.372761, 69.525983], //
          [42.369169, 69.527190], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.369169, 69.527190], //
          [42.368307, 69.524495], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.368307, 69.524495], //
          [42.367237, 69.525770], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.367992, 69.523388], //
          [42.366731, 69.524987], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.368382, 69.518008], //Аққамшы
          [42.364882, 69.522404], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.436496, 69.536010], // Фабричная
          [42.435440, 69.539250], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.436005, 69.539670], // Строительная
          [42.433653, 69.538151], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.435849, 69.540138], // Мира
          [42.433479, 69.538749], //
        ],
        color: '#FF0000'
      },
      // {
      //   coordinates: [
      //     [42.433325, 69.539334], // Тамерлан
      //     [42.433479, 69.538749], //
      //   ],
      //   color: '#FF0000'
      // },
      {
        coordinates: [
          [42.435474, 69.541298], // Рахат
          [42.433119, 69.539926], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.435276, 69.541884], // Ак-коше
          [42.432915, 69.540485], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.435087, 69.542404], // Зайтун
          [42.432790, 69.540995], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.435272, 69.538440], // Фабричная
          [42.433810, 69.537612], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.436157, 69.535843], //
          [42.435130, 69.539079], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.435556, 69.537571], //
          [42.434321, 69.536822], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.435836, 69.536688], //
          [42.434442, 69.535843], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.436158, 69.535776], //
          [42.434697, 69.534925], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.434697, 69.534925], //
          [42.432530, 69.541513], //
        ],
        color: '#FF0000'
      },

      ///////// Ынтымақ-1 ///////////
      {
        coordinates: [
          [42.376623, 69.514454], // Козыкорпеш
          [42.376714, 69.513921],
          [42.376720, 69.513825],
          [42.376696, 69.513753],
          [42.376669, 69.513728],
          [42.376531, 69.513655],
          [42.376504, 69.513628],
          [42.376480, 69.513563],
          [42.376476, 69.513501],
          [42.376509, 69.512793],
          [42.376525, 69.512690],
          [42.376615, 69.512203],
          [42.376643, 69.511943],
          [42.377098, 69.509486],
          [42.377124, 69.509217],
          [42.377129, 69.508922],
          [42.377078, 69.507005],
          [42.377122, 69.506875],
          [42.377332, 69.506100],
          [42.377441, 69.505644],
          [42.377539, 69.505142],
          [42.377811, 69.504027],
          [42.377869, 69.503874],
          [42.378897, 69.501491], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.376643, 69.511943], // К. Муратова
          [42.375120, 69.508920],
          [42.373741, 69.506259], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.376276, 69.509095], // Досбола Назарова
          [42.374684, 69.505942],
          [42.374464, 69.505465],
          [42.374396, 69.505388],
          [42.374217, 69.505240],
          [42.374176, 69.505177],
          [42.374108, 69.505030],
          [42.373838, 69.504539],
          [42.373100, 69.505347], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.377079, 69.507005], // Суюнбая
          [42.376809, 69.506824],
          [42.376670, 69.506706],
          [42.376533, 69.506563],
          [42.376204, 69.506143],
          [42.375177, 69.504668],
          [42.373740, 69.502645],
          [42.373650, 69.502589],
          [42.373037, 69.501709],
          [42.371103, 69.499002],
          [42.371083, 69.498953],
          [42.371072, 69.498899],
          [42.371073, 69.498848],
          [42.371101, 69.498729],
          [42.371204, 69.498539],
          [42.371280, 69.498431], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.380119, 69.498146], // Жамбыла Жабаева
          [42.380110, 69.498176],
          [42.379574, 69.498981],
          [42.376713, 69.502675],
          [42.375177, 69.504669],
          [42.373740, 69.506260], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.379833, 69.496590], // Р. Кошкарбаева
          [42.379718, 69.496685],
          [42.379639, 69.496766],
          [42.379327, 69.497199],
          [42.378869, 69.497882],
          [42.378701, 69.498161],
          [42.378596, 69.498314],
          [42.378181, 69.498861],
          [42.378147, 69.498898],
          [42.378115, 69.498918],
          [42.378034, 69.498952],
          [42.378001, 69.498972],
          [42.377687, 69.499358],
          [42.377743, 69.499444],
          [42.376854, 69.500537],
          [42.376309, 69.501266],
          [42.375947, 69.501696], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.374442, 69.503633], //
          [42.375507, 69.501954], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.372840, 69.506663], // Батырбай Алпысбаева
          [42.373295, 69.507435],
          [42.373963, 69.508701], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.374616, 69.509918], // Сусамыр
          [42.373188, 69.509510],
          [42.372838, 69.509440],
          [42.372734, 69.509426],
          [42.372648, 69.509425],
          [42.372573, 69.509454],
          [42.372485, 69.509513],
          [42.372392, 69.509618], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.375259, 69.511147], // Тайбурыл
          [42.371747, 69.510266], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.373963, 69.508705], // Шерғала
          [42.373853, 69.508849],
          [42.373826, 69.508920],
          [42.373798, 69.508963],
          [42.373772, 69.508982],
          [42.373745, 69.508987],
          [42.373549, 69.508950],
          [42.373364, 69.508951],
          [42.373295, 69.508961],
          [42.373254, 69.508989],
          [42.373229, 69.509019],
          [42.373177, 69.509525],
          [42.373102, 69.510630],
          [42.372962, 69.511747], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.368117, 69.513048], // Ирисмат аты
          [42.368197, 69.512964],
          [42.368197, 69.512964],
          [42.368241, 69.512891],
          [42.368567, 69.512198],
          [42.368974, 69.511159],
          [42.369004, 69.511072],
          [42.369398, 69.510372],
          [42.369493, 69.510254],
          [42.370541, 69.509251],
          [42.370541, 69.509251],
          [42.371218, 69.508499],
          [42.371538, 69.508109],
          [42.373039, 69.506398], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.370003, 69.514820], //
          [42.369580, 69.514594],
          [42.369482, 69.514524],
          [42.369393, 69.514439],
          [42.368197, 69.513199],
          [42.368081, 69.512983],
          [42.367998, 69.512861],
          [42.367377, 69.512232],
          [42.367468, 69.512077],
          [42.367202, 69.511926],
          [42.367055, 69.511804],
          [42.365636, 69.510414], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.369799, 69.513416], //
          [42.368573, 69.512186], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.369997, 69.511998], //
          [42.369006, 69.511067], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.370315, 69.511166], //
          [42.369658, 69.510085], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.370992, 69.510621], //
          [42.370329, 69.509456], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.384885, 69.508377], // Иле
          [42.379238, 69.516564], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.379531, 69.517268], // Жуантобе
          [42.379704, 69.517246],
          [42.379840, 69.517257],
          [42.379882, 69.517242],
          [42.379925, 69.517216],
          [42.379993, 69.517144],
          [42.380977, 69.515792],
          [42.388041, 69.505633], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.380744, 69.517513], // Жидебай
          [42.380782, 69.517437],
          [42.380897, 69.517321],
          [42.381018, 69.517229],
          [42.381278, 69.516962],
          [42.381346, 69.516869],
          [42.381331, 69.516820],
          [42.381325, 69.516778],
          [42.381340, 69.516738],
          [42.385997, 69.510146], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.381859, 69.517650], // Жезқазған
          [42.383633, 69.515206],
          [42.386629, 69.510918], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.382975, 69.517797], // Майкудык
          [42.386365, 69.512921],
          [42.387248, 69.511692], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.383537, 69.518743], // Кызылкайнар
          [42.384118, 69.517900],
          [42.384841, 69.516769], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.386350, 69.514596], // Саққорған
          [42.382595, 69.509950], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.382457, 69.516826], // Түймекент
          [42.382974, 69.517799],
          [42.383536, 69.518744], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.380978, 69.515792], //
          [42.381510, 69.516495], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.381347, 69.516868], // Ушан
          [42.381360, 69.516868],
          [42.381859, 69.517650],
          [42.384731, 69.522311], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.380743, 69.517510], // Уялы
          [42.380736, 69.517555],
          [42.380746, 69.517603],
          [42.380775, 69.517661],
          [42.384822, 69.524194], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.384693, 69.526059], // Кемертоган
          [42.384095, 69.525017],
          [42.384223, 69.524877],
          [42.379569, 69.517454],
          [42.379546, 69.517395],
          [42.379529, 69.517268], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.384939, 69.520975], //
          [42.384331, 69.521691],
          [42.383686, 69.522352],
          [42.383066, 69.523045], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.384670, 69.527751], // Көккемер
          [42.383572, 69.525759],
          [42.383561, 69.525713],
          [42.383556, 69.525669],
          [42.383557, 69.525599],
          [42.383456, 69.525426],
          [42.383435, 69.525375],
          [42.381696, 69.522433],
          [42.381615, 69.522523],
          [42.377984, 69.516430], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.377506, 69.517291], // Тұздыбастау
          [42.377437, 69.517176],
          [42.379230, 69.520174],
          [42.380279, 69.521990],
          [42.380433, 69.522259],
          [42.382808, 69.525978],
          [42.382845, 69.526082],
          [42.382860, 69.526187],
          [42.382855, 69.526381],
          [42.383071, 69.526848],
          [42.384192, 69.528732], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.385064, 69.532112], // Бәйге
          [42.382268, 69.527117],
          [42.382262, 69.527072],
          [42.382277, 69.527040],
          [42.382182, 69.526782],
          [42.380380, 69.523892],
          [42.376972, 69.518116],
          [42.376890, 69.517912],
          [42.376885, 69.517884],
          [42.376890, 69.517856],
          [42.376932, 69.517757], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.384192, 69.524907], //
          [42.382778, 69.526536],
          [42.382278, 69.527041], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.384734, 69.533145], // Қызылтан
          [42.376502, 69.519119], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.384014, 69.533750], // Жаркудык
          [42.384014, 69.533750],
          [42.382768, 69.531611],
          [42.381847, 69.529898],
          [42.379076, 69.525301],
          [42.378962, 69.525205],
          [42.378923, 69.524966],
          [42.376549, 69.520976], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.383142, 69.534105], // Сырымбет
          [42.380325, 69.529180],
          [42.378371, 69.525749],
          [42.376595, 69.522725],
          [42.376309, 69.522397], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.382509, 69.534785], // Сакиналы
          [42.382000, 69.533901],
          [42.381793, 69.533491],
          [42.381602, 69.533069],
          [42.376316, 69.524079], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.375756, 69.524862], // Сандыккол
          [42.383219, 69.537719], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.375431, 69.526172], // Миялы
          [42.375767, 69.526723],
          [42.378159, 69.530792],
          [42.378396, 69.531227],
          [42.382954, 69.538939],
          [42.383986, 69.541056], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.375021, 69.527353], // Назконыр
          [42.375738, 69.528576],
          [42.376098, 69.529264],
          [42.376908, 69.530579],
          [42.379978, 69.535910],
          [42.382605, 69.540305],
          [42.382806, 69.540563],
          [42.383344, 69.541617], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.374258, 69.527998], // Отау
          [42.373467, 69.528343],
          [42.373436, 69.528538], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.376204, 69.533280], // Шатыркол
          [42.378121, 69.536520],
          [42.378743, 69.537613],
          [42.380016, 69.539814],
          [42.380111, 69.539995],
          [42.380344, 69.540287],
          [42.381227, 69.541704],
          [42.381498, 69.542011],
          [42.381617, 69.542111],
          [42.381681, 69.542186],
          [42.381724, 69.542274],
          [42.381788, 69.542472], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.375871, 69.533825], //
          [42.376234, 69.534378],
          [42.376246, 69.534427],
          [42.376229, 69.534472],
          [42.376090, 69.534647],
          [42.376075, 69.534684],
          [42.376085, 69.534725],
          [42.376686, 69.535721],
          [42.377438, 69.536881], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.376686, 69.535721], //
          [42.376127, 69.536403], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.376127, 69.536403], //
          [42.376114, 69.536441],
          [42.376125, 69.536483],
          [42.376577, 69.537228],
          [42.376596, 69.537245],
          [42.376617, 69.537248],
          [42.376635, 69.537235], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.376635, 69.537235], //
          [42.377214, 69.536537], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.375125, 69.527185], // Күлтегін
          [42.375149, 69.527147],
          [42.375254, 69.526839],
          [42.375431, 69.526171],
          [42.375722, 69.524913],
          [42.376289, 69.522514],
          [42.376535, 69.521130],
          [42.376550, 69.520978],
          [42.376553, 69.520641],
          [42.376440, 69.517285], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.382240, 69.521815], // Жезкент
          [42.374348, 69.529985], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.381491, 69.527822], // Жасотау
          [42.379998, 69.529591],
          [42.378395, 69.531228],
          [42.377662, 69.531960],
          [42.377609, 69.531862],
          [42.376238, 69.533345], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.383390, 69.530937], // Қаракөл
          [42.378155, 69.536459], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.371694, 69.510155], // Шахбутова
          [42.371603, 69.509963],
          [42.370951, 69.508798], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.372391, 69.509619], //
          [42.371547, 69.508096],
          [42.370614, 69.506704],
          [42.370561, 69.506778],
          [42.369857, 69.505738],
          [42.369113, 69.504703],
          [42.368517, 69.503987], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.373020, 69.508861], //
          [42.372192, 69.507371], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.373073, 69.506356], // Бакбаева
          [42.368909, 69.500612], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.374780, 69.499838], // Ташкентская
          [42.374166, 69.499192],
          [42.373859, 69.498919],
          [42.373770, 69.498865],
          [42.373709, 69.498842],
          [42.373652, 69.498842],
          [42.373614, 69.498858],
          [42.373570, 69.498903],
          [42.372255, 69.500617],
          [42.369415, 69.504239],
          [42.368347, 69.505836],
          [42.367580, 69.507249],
          [42.367248, 69.507785],
          [42.366917, 69.508419],
          [42.366606, 69.509269],
          [42.366508, 69.509710],
          [42.366499, 69.509799],
          [42.366502, 69.509880],
          [42.366526, 69.509941],
          [42.366565, 69.509991],
          [42.367061, 69.510464], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.372903, 69.501525], // Баян Сулу
          [42.371168, 69.503919],
          [42.371104, 69.503964],
          [42.371060, 69.504009],
          [42.369858, 69.505764],
          [42.368235, 69.508057],
          [42.368327, 69.508170],
          [42.367070, 69.510454],
          [42.367157, 69.510553],
          [42.367258, 69.510645],
          [42.367335, 69.510743],
          [42.367806, 69.511426], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.372398, 69.498360], // Кара Су
          [42.371512, 69.499599],
          [42.369770, 69.501790],
          [42.369813, 69.501855],
          [42.369666, 69.502042],
          [42.369508, 69.502324],
          [42.368881, 69.503440],
          [42.368162, 69.504519],
          [42.367858, 69.504930],
          [42.367596, 69.505245],
          [42.367552, 69.505285],
          [42.367429, 69.505322],
          [42.367399, 69.505347],
          [42.367023, 69.505968],
          [42.366921, 69.506105],
          [42.366771, 69.506415],
          [42.367583, 69.507248], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.366637, 69.511411], // Коксарай
          [42.365691, 69.513109],
          [42.365615, 69.513253],
          [42.365091, 69.514064], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.366180, 69.513870], // Жанатурмыс
          [42.365101, 69.512700],
          [42.365075, 69.512688],
          [42.365041, 69.512680],
          [42.364980, 69.512723],
          [42.364659, 69.513210],
          [42.364546, 69.513361],
          [42.364537, 69.513391],
          [42.364548, 69.513444],
          [42.365646, 69.514715], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.366101, 69.510867], // 
          [42.365672, 69.511556],
          [42.365273, 69.512280], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.375953, 69.499373], //
          [42.375640, 69.498945],
          [42.375538, 69.498825],
          [42.375507, 69.498801],
          [42.375406, 69.498753],
          [42.375218, 69.498421],
          [42.375185, 69.498324],
          [42.375185, 69.498324],
          [42.375164, 69.498115],
          [42.375155, 69.498063],
          [42.375130, 69.498027],
          [42.375105, 69.498021],
          [42.374370, 69.498049],
          [42.374308, 69.498035],
          [42.374246, 69.497973],
          [42.374214, 69.497915],
          [42.374176, 69.497762], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.374176, 69.497762], // Жанатилек
          [42.374235, 69.497735],
          [42.374284, 69.497683],
          [42.374308, 69.497643],
          [42.374842, 69.495996],
          [42.375246, 69.494719],
          [42.375419, 69.494289],
          [42.375445, 69.494219],
          [42.375450, 69.494183],
          [42.375429, 69.494043],
          [42.375487, 69.493741],
          [42.375928, 69.492182],
          [42.375946, 69.492077],
          [42.375976, 69.491680],
          [42.376352, 69.489140],
          [42.376378, 69.488899],
          [42.376390, 69.488667], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.376970, 69.488447], // Жанатилек
          [42.374397, 69.489293],
          [42.372640, 69.489923],
          [42.371126, 69.490548], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.374177, 69.497761], // Темирши
          [42.374173, 69.497734],
          [42.375322, 69.490826],
          [42.375425, 69.490183],
          [42.375488, 69.489721],
          [42.375502, 69.489518],
          [42.375522, 69.488954], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.373376, 69.497603], // Аккурай
          [42.373899, 69.494252],
          [42.374077, 69.493291],
          [42.374666, 69.489551],
          [42.374671, 69.489476],
          [42.374673, 69.489416],
          [42.374668, 69.489362],
          [42.374643, 69.489214], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.372548, 69.497482], //  Акжулдыз
          [42.373077, 69.494108],
          [42.373777, 69.489925],
          [42.373772, 69.489519], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.371760, 69.497320], // Бозжусан
          [42.372242, 69.494035],
          [42.372947, 69.489817], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.370958, 69.497110], // Баксай
          [42.371443, 69.493815],
          [42.372068, 69.490154], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.370025, 69.497114], // Новостройка
          [42.370086, 69.496890],
          [42.370259, 69.496000],
          [42.370342, 69.495668],
          [42.370384, 69.495430],
          [42.370650, 69.493539],
          [42.371094, 69.490571],
          [42.371639, 69.488149],
          [42.371676, 69.487935],
          [42.371829, 69.486658], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.374176, 69.497700], //
          [42.373377, 69.497604],
          [42.372549, 69.497483],
          [42.372157, 69.497423],
          [42.371747, 69.497318],
          [42.370087, 69.496890], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.370796, 69.490722], // Жамбыла Жабаева
          [42.370738, 69.490917],
          [42.370276, 69.493971],
          [42.370211, 69.494289],
          [42.369939, 69.495291],
          [42.369782, 69.496099],
          [42.369583, 69.497191],
          [42.369512, 69.497499],
          [42.368957, 69.499345],
          [42.368710, 69.500187], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.376190, 69.488704], //
          [42.376227, 69.488195],
          [42.376229, 69.487603],
          [42.376243, 69.487450],
          [42.376280, 69.487244],
          [42.376338, 69.487004], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.374408, 69.489290], // 
          [42.374297, 69.488250],
          [42.374299, 69.488207],
          [42.374314, 69.488182],
          [42.374691, 69.488069], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.373652, 69.489562], //
          [42.373532, 69.488526], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.373366, 69.528421], // Шаугар
          [42.373214, 69.527662],
          [42.372170, 69.523250],
          [42.372046, 69.522796],
          [42.371975, 69.522241],
          [42.371895, 69.520585], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [42.369544, 69.518119], // Қорған көл
          [42.369523, 69.516603],
          [42.369526, 69.516508],
          [42.369539, 69.516409],
          [42.371060, 69.511239], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#FF0000'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#FF0000'
      },
      /////////////////////////// Асар-2////////////////////////////////
      {
        coordinates: [
          [42.411913, 69.571281], // Нұржол
          [42.419274, 69.588961],
          [42.419339, 69.589146],
          [42.419395, 69.589392],
          [42.419353, 69.603322], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.421318, 69.600928], //
          [42.419361, 69.600950],
          [42.417571, 69.600901],
          [42.417382, 69.600888], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.421496, 69.599742], //
          [42.419367, 69.599702],
          [42.418875, 69.599681],
          [42.417401, 69.599679],
          [42.417375, 69.599670],
          [42.417355, 69.599645],
          [42.417349, 69.599606],
          [42.417358, 69.598532],
          [42.417371, 69.598483],
          [42.417409, 69.598465], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.417409, 69.598465], // Текелі
          [42.419379, 69.598485],
          [42.419790, 69.598478],
          [42.421653, 69.598538], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.421678, 69.597261], // Тастөбе
          [42.421071, 69.597255],
          [42.419382, 69.597286],
          [42.417338, 69.597235], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.422937, 69.595525], // Тасөткел
          [42.422817, 69.596077],
          [42.422802, 69.596108],
          [42.422778, 69.596116],
          [42.421705, 69.596081],
          [42.419382, 69.596060],
          [42.417820, 69.596056],
          [42.417372, 69.596075], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.422739, 69.594531], //
          [42.422728, 69.594851],
          [42.422716, 69.594888],
          [42.422688, 69.594902],
          [42.421763, 69.594901],
          [42.421665, 69.594907],
          [42.421329, 69.594872],
          [42.420768, 69.594833],
          [42.417577, 69.594848],
          [42.417512, 69.594858], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.423806, 69.592494], // Тасқара
          [42.423654, 69.592692],
          [42.423442, 69.593033],
          [42.423246, 69.593655],
          [42.423228, 69.593680],
          [42.423205, 69.593686],
          [42.423098, 69.593671],
          [42.421698, 69.593669],
          [42.419393, 69.593578],
          [42.417391, 69.593610], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.423806, 69.592494], // Сарыадыр
          [42.419385, 69.592446], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.423592, 69.591278], // Саралжын
          [42.417645, 69.591205], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.423684, 69.590022], // Сарай Берке
          [42.421415, 69.590018],
          [42.421129, 69.590039],
          [42.421021, 69.590030],
          [42.420900, 69.590001],
          [42.420764, 69.589983],
          [42.420570, 69.589985],
          [42.420141, 69.590005],
          [42.419926, 69.589982],
          [42.419816, 69.589974],
          [42.419718, 69.589985],
          [42.419608, 69.590012],
          [42.419502, 69.590020],
          [42.419388, 69.590011], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.423697, 69.588823], //
          [42.421726, 69.588794],
          [42.420109, 69.588820], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.424633, 69.588606], // Ақ Жайық
          [42.424619, 69.587583],
          [42.423749, 69.587597],
          [42.423043, 69.587623],
          [42.422362, 69.587575],
          [42.421939, 69.587566],
          [42.421717, 69.587584], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.425190, 69.587889], //
          [42.425085, 69.587809],
          [42.424700, 69.587581],
          [42.424619, 69.587583], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.421495, 69.599741], //
          [42.421577, 69.599746],
          [42.421603, 69.599741],
          [42.421624, 69.599718],
          [42.421636, 69.599680],
          [42.421644, 69.599590],
          [42.421704, 69.596100],
          [42.421680, 69.594926],
          [42.421723, 69.592446],
          [42.421716, 69.591240],
          [42.421744, 69.590019],
          [42.421730, 69.588529],
          [42.421721, 69.588379],
          [42.421703, 69.588284],
          [42.421663, 69.588154],
          [42.421601, 69.587989],
          [42.418394, 69.580324], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.421753, 69.581992], // Қорғантөбе
          [42.422404, 69.583444],
          [42.423685, 69.586604],
          [42.423725, 69.586742],
          [42.423737, 69.586871],
          [42.423749, 69.587597],
          [42.423711, 69.588834],
          [42.423687, 69.591192],
          [42.423673, 69.591238],
          [42.423592, 69.591276], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.417616, 69.590697], //
          [42.425504, 69.584711], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.417232, 69.589598], // Қымбат
          [42.425065, 69.583598], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.416650, 69.588633], // Алмазная
          [42.424603, 69.582513], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.423816, 69.580396], // Армандастар
          [42.421754, 69.581992],
          [42.419680, 69.583529],
          [42.417665, 69.585135],
          [42.415759, 69.586537], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.426506, 69.574178], //
          [42.422505, 69.577281],
          [42.418393, 69.580323],
          [42.414497, 69.583336], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.426506, 69.574178], //
          [42.424722, 69.569905], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },

      /////////// Асар-2 жүргізілмеген ///////////////
      {
        coordinates: [
          [42.428799, 69.562562], // 
          [42.409359, 69.577523], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.426937, 69.565459], //
          [42.409794, 69.578849], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.427476, 69.566403], // Жезкиик
          [42.409915, 69.579640], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.428267, 69.567226], // Арқат
          [42.412560, 69.579131], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.428585, 69.568374], // Атакент
          [42.413000, 69.580167], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.427026, 69.571027], //
          [42.413530, 69.581245], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.426061, 69.573141], //
          [42.413990, 69.582289], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.426462, 69.575606], // Көкмайса
          [42.414970, 69.584383], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.425411, 69.577809], //
          [42.415380, 69.585434], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.420610, 69.566239], // Текті
          [42.425370, 69.577667], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.418412, 69.567541], //
          [42.424271, 69.581466],
          [42.424645, 69.582522],
          [42.425812, 69.585242],
          [42.426381, 69.587830], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.416307, 69.568828], //
          [42.419971, 69.577725], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.425737, 69.578958], //
          [42.423862, 69.580302], //
        ],
        color: '#0000FF'
      },
     
     
      {
        coordinates: [
          [42.424667, 69.562843], //
          [42.425599, 69.565082], //
          [42.426500, 69.567186],
          [42.427363, 69.569286],
          [42.427685, 69.569955],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.428189, 69.561646], //
          [42.425150, 69.564023], //
          [42.422983, 69.565671],
          [42.420976, 69.567210],
          [42.418941, 69.568736],
          [42.416946, 69.570223],
          [42.416881, 69.570324],
          [42.414841, 69.571902],
          [42.412778, 69.573467],
          [42.410764, 69.575016],
          [42.408812, 69.576532],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.407929, 69.574386], //
          [42.411695, 69.571507], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.416489, 69.569325], //
          [42.414384, 69.570847], //
          [42.412335, 69.572419],
          [42.410312, 69.573954],
          [42.408351, 69.575429],
        ],
        color: '#0000FF'
      },
       ///////////////////////Кызылжар//////////////////////
      {
        coordinates: [
          [42.367147, 69.504720], //
          [42.365292, 69.502972], //
          [42.364979, 69.502734],
          [42.364908, 69.502535],
          [42.364869, 69.502486],
          [42.363453, 69.501233],
          [42.363426, 69.501201],
          [42.362947, 69.502150],
          [42.364399, 69.503504],
          [42.364534, 69.503648],
          [42.366717, 69.505683],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.364979, 69.502734], //
          [42.364534, 69.503648], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.367147, 69.504720], //
          [42.366717, 69.505683], //
          [42.366646, 69.505873],
          [42.366336, 69.506717],
          [42.364092, 69.504665],////
          [42.363936, 69.504443],
          [42.362457, 69.503090],
          [42.362947, 69.502150],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.364979, 69.502734], //
          [42.364092, 69.504665], //
          [42.363685, 69.505586],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.362457, 69.503090], //
          [42.361968, 69.504010], //
          [42.363684, 69.505588],
          [42.363657, 69.505664],
          [42.365997, 69.507793], //
          [42.366038, 69.507850],
          [42.366050, 69.507914],
          [42.366051, 69.508013],
          [42.366037, 69.508115],
          [42.365832, 69.508988],
          [42.363327, 69.506699],
          [42.361507, 69.504974],
          [42.361465, 69.504928],
          [42.361968, 69.504010],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363657, 69.505664], //
          [42.363592, 69.505822],
          [42.363327, 69.506699],//
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.365832, 69.508990], //
          [42.365610, 69.509946], //
          [42.365548, 69.510133],
          [42.363092, 69.507847],
          [42.360978, 69.505836],
          [42.361465, 69.504928],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363327, 69.506699], //
          [42.363092, 69.507847], //
          [42.363055, 69.508090],
          [42.362982, 69.508407],
          [42.362883, 69.508791],
          [42.362805, 69.509009],
          [42.362312, 69.509898],
          [42.361811, 69.510782 ], // Шубартау
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360978, 69.505836], //
          [42.360449, 69.506744], //
          [42.361504, 69.507782],
          [42.362518, 69.508704],
          [42.362668, 69.508864],
          [42.362805, 69.509009],
          [42.365198, 69.511188],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.364532, 69.511969], //
          [42.362315, 69.509898], //
          [42.359892, 69.507680],
          [42.360450, 69.506745],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.359892, 69.507680], //
          [42.359349, 69.508558], //
          [42.359777, 69.508984],
          [42.361727, 69.510707],
          [42.361911, 69.510879],
          [42.363048, 69.511956],
          [42.363159, 69.511746],
          [42.363192, 69.511723],
          [42.363230, 69.511737],
          [42.363283, 69.511777],
          [42.364256, 69.512785],
          [42.364190, 69.512983],
          [42.364149, 69.513212],
          [42.364085, 69.513391],
          [42.364034, 69.513478],
          [42.363851, 69.513761],
          [42.362755, 69.512557],
          [42.363048, 69.511956],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.362755, 69.512557], //
          [42.362642, 69.512790], //
          [42.362581, 69.512854],
          [42.362541, 69.512864],
          [42.362422, 69.512785],
          [42.358804, 69.509470],
          [42.359349, 69.508558],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.358804, 69.509470], //
          [42.358230, 69.510467], //
          [42.359282, 69.511278],
          [42.360718, 69.512593],
          [42.361986, 69.513776],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.361260, 69.511717], //
          [42.360718, 69.512593], //
          [42.360058, 69.513347],
          [42.361643, 69.514819],
          [42.361671, 69.514770],
          [42.361829, 69.514551],
          [42.361870, 69.514515],
          [42.361911, 69.514507],
          [42.361952, 69.514520],
          [42.362005, 69.514560],
          [42.362740, 69.515398],
          [42.363276, 69.514612],
          [42.363848, 69.513766],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363276, 69.514612], //
          [42.362436, 69.513603], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360059, 69.513346], //
          [42.358731, 69.512205],
          [42.357680, 69.511389], //
          [42.358229, 69.510464],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357680, 69.511389], //
          [42.357133, 69.512315], //
          [42.357998, 69.512949],
          [42.359236, 69.514007],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357133, 69.512315], //
          [42.356056, 69.514172], //
          [42.357924, 69.515622],
          [42.358086, 69.515736],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.359443, 69.514012], //
          [42.359938, 69.514548], //
          [42.361969, 69.516572],
          [42.361579, 69.517202],
          [42.361500, 69.517251],
          [42.361439, 69.517241],
          [42.361309, 69.517148],
          [42.361242, 69.517100],
          [42.359777, 69.515681],
          [42.358872, 69.514814], // Акторгай
          [42.358815, 69.514752],
          [42.358725, 69.514727],
          [42.358661, 69.514768],
          [42.358490, 69.515057],
          [42.358231, 69.515450],
          [42.358086, 69.515733],
          [42.357843, 69.516681], // Тогансай
          [42.358421, 69.516863],
          [42.358667, 69.516979],
          [42.360150, 69.517757],
          [42.360257, 69.517858],
          [42.359873, 69.518280],
          [42.359826, 69.518342],
          [42.359655, 69.518752],
          [42.358369, 69.518043],
          [42.358180, 69.517888],
          [42.358064, 69.517709],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.361969, 69.516572], //
          [42.362738, 69.515400], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357843, 69.516681], //
          [42.357552, 69.517692], ////
          [42.357664, 69.517902],
          [42.357956, 69.518679],
          [42.358035, 69.519031],
          [42.358069, 69.519329],
          [42.358084, 69.519955],
          [42.357919, 69.520102],
          [42.356848, 69.518871],
          [42.356337, 69.519695],
          [42.357430, 69.521071],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357552, 69.517692], //
          [42.356848, 69.518871], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356337, 69.519695], // Актансай
          [42.355839, 69.520569], // Арыстанды
          [42.356537, 69.521424],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355839, 69.520569], //
          [42.355288, 69.521436], //
          [42.355957, 69.522229],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355288, 69.521436], //
          [42.354648, 69.522449], //
          [42.352210, 69.520521], //Сулусай
          [42.352762, 69.519625], //Баканас
          [42.353199, 69.518895],
          [42.353309, 69.518702], //Арыстанды
          [42.353873, 69.517779], //Актансай
          [42.354429, 69.516873], //Аркалык
          [42.354992, 69.515974], //Акмая
          [42.355524, 69.515062], //Тоғансай
          [42.356056, 69.514171],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355524, 69.515062], //
          [42.357662, 69.516672], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354992, 69.515974], //
          [42.357315, 69.517789], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354429, 69.516873], //
          [42.356883, 69.518813], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353873, 69.517779], //
          [42.356337, 69.519695], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353309, 69.518702], //
          [42.355791, 69.520641], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352762, 69.519625], //
          [42.355219, 69.521531], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363425, 69.501199],
          [42.363665, 69.500771], //
          [42.363593, 69.500688], //
          [42.361694, 69.499212],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363267, 69.501518], //
          [42.361369, 69.500077], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.362831, 69.502360], //
          [42.360950, 69.500914], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.362348, 69.503293], //
          [42.360733, 69.502054], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.361853, 69.504224], //
          [42.360529, 69.503167], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.361365, 69.505108], //
          [42.360312, 69.504272], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360873, 69.506024], //
          [42.360043, 69.505314], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360354, 69.506896], //
          [42.360001, 69.506593], //
          [42.359861, 69.506497],
          [42.359375, 69.506231],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.359830, 69.507756], //
          [42.356998, 69.505579], ///////////
          [42.357163, 69.505141],
          [42.357301, 69.504540], //Клдкент
          [42.357545, 69.503432], // Теренозек
          [42.357779, 69.502323], //Сыганак
          [42.358000, 69.501236], //Нурлыкент
          [42.358291, 69.500157], //Ордакент
          [42.358481, 69.499563],
          [42.358543, 69.499174],
          [42.358536, 69.499119],
          [42.358513, 69.499067],
          [42.357489, 69.498268],
          [42.356238, 69.497257],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357489, 69.498268], //
          [42.357088, 69.499200], ////Ордакент2
          [42.355596, 69.498024]
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357088, 69.499200], //
          [42.358291, 69.500157], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357088, 69.499200], //
          [42.356651, 69.500182], ////Нурлыкент2
          [42.355070, 69.498917],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356651, 69.500182], //
          [42.358000, 69.501236], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356651, 69.500182], //
          [42.356236, 69.501164], ////Сыганак2
          [42.354588, 69.499874],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356236, 69.501164], //
          [42.357779, 69.502323], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356236, 69.501164], //
          [42.355815, 69.502157], //// Теренозек2
          [42.353991, 69.500743],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355815, 69.502157], //
          [42.357545, 69.503432], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355815, 69.502157], //
          [42.355397, 69.503140], ////Кoлдкент2
          [42.353557, 69.501748],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357301, 69.504540], //
          [42.355397, 69.503140], //
          [42.354988, 69.504099], //
          [42.354564, 69.505078],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356998, 69.505579], //
          [42.354988, 69.504099], //
          [42.353146, 69.502712],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.359312, 69.508621], //
          [42.356822, 69.506784],
          [42.354564, 69.505078], //
          [42.352594, 69.503576],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.358760, 69.509541], //
          [42.358674, 69.509470], //
          [42.354788, 69.506531],
          [42.353044, 69.505199],
          [42.352200, 69.504541],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.358228, 69.510464], //
          [42.357502, 69.509886], //
          [42.353844, 69.507115],
          [42.351709, 69.505489],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353844, 69.507115], //
          [42.353416, 69.508133], // Актерек
          [42.353025, 69.509115], //Акторгай
          [42.352585, 69.510131], //Сарыкол
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357679, 69.511390], //
          [42.357530, 69.511250], //
          [42.353416, 69.508133],
          [42.351197, 69.506451],
          [],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357134, 69.512316], //
          [42.353025, 69.509115], //
          [42.350730, 69.507385],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356562, 69.513298], //
          [42.354185, 69.511312], //
          [42.354098, 69.511227],
          [42.353678, 69.510932],
          [42.352585, 69.510131],
          [42.351187, 69.509069],//Жанакурылыс
          [42.350880, 69.508817],
          [42.350180, 69.508296],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351187, 69.509069], //
          [42.349989, 69.511883], //
          [42.346922, 69.519099],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353676, 69.510935], //
          [42.349410, 69.521134], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354826, 69.512934], //
          [42.350854, 69.509870], //
          [42.349893, 69.509181],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354202, 69.513742], //
          [42.351681, 69.511843], //
          [42.349280, 69.510001],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353949, 69.514301], //
          [42.349716, 69.521366], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354568, 69.515020], //
          [42.350365, 69.521878], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354636, 69.516552], //
          [42.353404, 69.515218], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353196, 69.518894], //
          [42.352008, 69.517533], //
          [42.351213, 69.516839],
          [42.347284, 69.513843],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352154, 69.520590], //
          [42.350948, 69.519303], //
          [42.346193, 69.515677],
          [42.347184, 69.513780],
          [42.347231, 69.513627],
          [42.347152, 69.513424],
          [42.346915, 69.513177],
          [42.346916, 69.513113],
          [42.346939, 69.513045],
          [42.347436, 69.512103],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353693, 69.514729], //
          [42.351182, 69.512809],
          [42.348762, 69.510927], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353134, 69.515654], //
          [42.350841, 69.513891], //
          [42.348277, 69.511903],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352208, 69.520518], //
          [42.351059, 69.522397], //
          [42.353062, 69.523877],
          [42.353432, 69.524135],
          [42.354466, 69.525077],
          [42.354840, 69.525863],
          [42.355020, 69.526092],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351059, 69.522397], //
          [42.348928, 69.520745],
          [42.346919, 69.519104],
          [42.343218, 69.516285], //
          [42.341961, 69.519155], //махмудова
          [42.340964, 69.521361],
          [42.340729, 69.522137],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352809, 69.524759], //
          [42.346301, 69.519816], //
          
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351793, 69.525272], //
          [42.351091, 69.524676], //
          [42.345892, 69.520753],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350667, 69.525651], //
          [42.350136, 69.525210], //
          [42.345759, 69.521894],
          [42.345455, 69.521803],
          [42.341965, 69.519157],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350142, 69.526545], //
          [42.347365, 69.524419], //
          [42.345103, 69.522627],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.349529, 69.527384], //
          [42.346948, 69.525402], //
          [42.344857, 69.523789],
          [42.344816, 69.523779],
          [42.344786, 69.523811],
          [42.344404, 69.524667],
          [42.344347, 69.524813],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351788, 69.522937], //
          [42.351778, 69.522986], //
          [42.351089, 69.524677],
          [42.350674, 69.525638],
          [42.350144, 69.526548],
          [42.348776, 69.528310],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.348927, 69.520744], //
          [42.346521, 69.526469], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.346765, 69.518981], //
          [42.346293, 69.519818], //
          [42.344326, 69.524540],
          [42.344280, 69.524770],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.344988, 69.517601], //
          [42.343735, 69.520476], //
          [42.342495, 69.523471],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353127, 69.525688], //
          [42.353451, 69.526704], //
          [42.353592, 69.526984],
          [42.353986, 69.527529],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352168, 69.525911], //
          [42.352798, 69.528136], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351247, 69.526231], //
          [42.351801, 69.528379], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350346, 69.526945], //
          [42.350814, 69.528631], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF'
      },


    ]
  },

  ////////////////////////////////////// КентукиЭнержи //////////////////////////////////////////////////////


  ////////////////////////////////////// Алма ТВ ////////////////////////////////////////////////////////////
  {
    name: 'Алма ТВ',
    lines: [
      {
        coordinates: [
          [42.358238, 69.618298], // Жанқожа батыр
          [42.355635, 69.629106], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357695, 69.617979], // Жанагасыр
          [42.355091, 69.629013], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356993, 69.617559], // Жаналык
          [42.355711, 69.622848], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356316, 69.617061], // Айкап
          [42.353398, 69.628699], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355841, 69.616794], // Коктем
          [42.354500, 69.622210], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355248, 69.616401], // Парниковая
          [42.353941, 69.621933], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354793, 69.616196], // Коктас
          [42.352496, 69.626063], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354226, 69.615792], // Жулдыз
          [42.351854, 69.625833], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353770, 69.615511], // Актас
          [42.350826, 69.626989], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353249, 69.615156], // Шолпан
          [42.351803, 69.620939], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352734, 69.614887], // Полярная
          [42.351249, 69.620787], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351839, 69.615783], // Макатаева 
          [42.351158, 69.618590],
          [42.350474, 69.621655],
          [42.350181, 69.622774],
          [42.350171, 69.622842],
          [42.350166, 69.623020],
          [42.350192, 69.623174],
          [42.350236, 69.623317],
          [42.350427, 69.623827],
          [42.351031, 69.625300],
          [42.351077, 69.625377],
          [42.351136, 69.625450],
          [42.351205, 69.625513], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352561, 69.615571], // 
          [42.352517, 69.615569],
          [42.352339, 69.615598],
          [42.351927, 69.615565],
          [42.351916, 69.615542],
          [42.351891, 69.615509],
          [42.351857, 69.615478],
          [42.351793, 69.615433],
          [42.351142, 69.615084],
          [42.351056, 69.615051],
          [42.351013, 69.615047],
          [42.350860, 69.615066], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351132, 69.616453], // Трактористов
          [42.350542, 69.618670],
          [42.350111, 69.620532], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350183, 69.616762], // Сазды
          [42.350027, 69.619247], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.353674, 69.624258], // Наурызек
          [42.353166, 69.626354], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357950, 69.619385], // Жастар
          [42.355975, 69.618541],
          [42.355429, 69.618338],
          [42.354881, 69.618088],
          [42.353812, 69.617572],
          [42.351627, 69.616609],
          [42.351360, 69.616506],
          [42.351133, 69.616452],
          [42.351019, 69.616468],
          [42.349641, 69.616951], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.357010, 69.623395], // Джусупова
          [42.350695, 69.620585],
          [42.350652, 69.620789],
          [42.350112, 69.620533],
          [42.349193, 69.620154],
          [42.349120, 69.620132],
          [42.349049, 69.620121],
          [42.348852, 69.620122], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356226, 69.624261], // Заман
          [42.354672, 69.623568], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355480, 69.627351], // Акбура\
          [42.354610, 69.627042],
          [42.353167, 69.626354],
          [42.352496, 69.626062],
          [42.351855, 69.625832],
          [42.351200, 69.625511], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355256, 69.628314], // Иргелес
          [42.350987, 69.626364], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355607, 69.623985], // 
          [42.355007, 69.626251],
          [42.354831, 69.627122], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354583, 69.628994], // Ынталы
          [42.354583, 69.628994], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.352010, 69.622371], // Жанакала
          [42.350472, 69.621656],
          [42.348891, 69.620958], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.351005, 69.627835], // 
          [42.350747, 69.627145],
          [42.349841, 69.624830],
          [42.348915, 69.622518],
          [42.348882, 69.622397],
          [42.348848, 69.622176],
          [42.348840, 69.622025],
          [42.348890, 69.620958], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.350364, 69.619434], // Акжал
          [42.350013, 69.619240],
          [42.349903, 69.619205],
          [42.349811, 69.619168],
          [42.349459, 69.618993], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.374333, 69.737868], // Сүйінші
          [42.376054, 69.734756], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375567, 69.733686], // Жүзімдік
          [42.373698, 69.737088], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.373287, 69.735845], // Балбүрген
          [42.376388, 69.730404], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377246, 69.730562], // 
          [42.377279, 69.730598],
          [42.377291, 69.730627],
          [42.377293, 69.730663],
          [42.377279, 69.730693],
          [42.376944, 69.731184], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377246, 69.730562], // 
          [42.376970, 69.730228],
          [42.376760, 69.730022],
          [42.375972, 69.729476],
          [42.375768, 69.729400],
          [42.375447, 69.729395], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.372854, 69.734658], // Кемел
          [42.375696, 69.729680], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376432, 69.726205], // Қырмызы
          [42.376368, 69.726433],
          [42.376300, 69.726607],
          [42.375950, 69.727199],
          [42.375606, 69.727803],
          [42.375451, 69.728152],
          [42.375043, 69.728844],
          [42.372781, 69.732951],
          [42.372668, 69.733105],
          [42.372303, 69.733740], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377709, 69.729522], // Ақиқат
          [42.377522, 69.729369],
          [42.375665, 69.728322],
          [42.375450, 69.728152],
          [42.375306, 69.728395],
          [42.374594, 69.727658], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378380, 69.728556], // Байкала
          [42.375960, 69.727186], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.379362, 69.727895], // Байкент
          [42.378771, 69.727522],
          [42.377414, 69.726804],
          [42.376419, 69.726198],
          [42.376352, 69.726156],
          [42.375947, 69.725961],
          [42.375582, 69.725761], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.380028, 69.727049], // Торесай
          [42.377345, 69.725430],
          [42.377181, 69.725348],
          [42.375822, 69.724656], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.380602, 69.726087], // Адеми
          [42.376975, 69.724003],
          [42.376021, 69.723493], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381657, 69.725446], // 
          [42.381231, 69.725401],
          [42.380991, 69.725356],
          [42.380834, 69.725331],
          [42.380468, 69.725317], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.380363, 69.724696], // 
          [42.380422, 69.725156],
          [42.380468, 69.725317], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381656, 69.724803], // Сандугаш\
          [42.381115, 69.724698],
          [42.380754, 69.724675],
          [42.380365, 69.724713],
          [42.380190, 69.724645],
          [42.380116, 69.724602],
          [42.380053, 69.724536],
          [42.379994, 69.724432],
          [42.379828, 69.724022],
          [42.379636, 69.723910],
          [42.379491, 69.723861],
          [42.379327, 69.723836],
          [42.379327, 69.723836],
          [42.378852, 69.723832],
          [42.377513, 69.723026],
          [42.377289, 69.722934],
          [42.376187, 69.722340],
          [42.376233, 69.722378], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.382918, 69.722748], // 
          [42.381605, 69.722296], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381707, 69.721532], // 
          [42.381394, 69.724039], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381707, 69.721532], // Асар
          [42.376801, 69.718899], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381382, 69.724030], // 
          [42.379846, 69.724032], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.379646, 69.723031], // Байсал
          [42.376437, 69.721200], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.380320, 69.722119], // Кызан
          [42.376727, 69.720146],
          [42.376456, 69.720050], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.382488, 69.720618], // Адырна
          [42.377096, 69.717707], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384629, 69.717457], // 
          [42.384584, 69.717647],
          [42.384490, 69.717794],
          [42.383998, 69.718388],
          [42.383840, 69.719127],
          [42.383788, 69.719251],
          [42.383588, 69.719466],
          [42.383224, 69.719674],
          [42.383142, 69.719680],
          [42.383038, 69.719657],
          [42.382807, 69.719549], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384912, 69.718619], // 
          [42.383998, 69.718388], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384531, 69.719676], // 
          [42.384410, 69.719630],
          [42.384346, 69.719598],
          [42.384294, 69.719523],
          [42.384160, 69.718965],
          [42.384127, 69.718901],
          [42.384077, 69.718853],
          [42.384054, 69.718843],
          [42.383981, 69.718833],
          [42.383902, 69.718851], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.385762, 69.716490], // 
          [42.385520, 69.717438],
          [42.385485, 69.717496],
          [42.385404, 69.717524],
          [42.385319, 69.717515],
          [42.384892, 69.717394],
          [42.384742, 69.717389],
          [42.384627, 69.717457], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384503, 69.717774], // 
          [42.383797, 69.717884],
          [42.383634, 69.717840],
          [42.383567, 69.717767],
          [42.383421, 69.717494],
          [42.383310, 69.717297],
          [42.383288, 69.717203],
          [42.383290, 69.716912],
          [42.383249, 69.716806],
          [42.383207, 69.716775],
          [42.382742, 69.716570], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.382803, 69.719546], // Аялы
          [42.377443, 69.716576], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.382742, 69.716571], // 
          [42.382748, 69.716741],
          [42.382689, 69.717043],
          [42.382085, 69.719116], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.382742, 69.716571], // Корнекти
          [42.378192, 69.714114], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381995, 69.716171], // Дауылпаз
          [42.381265, 69.718695], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381631, 69.717448], // Кеншилер
          [42.377825, 69.715325], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.383109, 69.715561], // Кырат
          [42.378770, 69.713135],
          [42.378524, 69.713020], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.383101, 69.714265], // Аккорган
          [42.378858, 69.711911], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.383344, 69.713143], // Қызбел
          [42.380720, 69.711632],
          [42.379189, 69.710819], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.382934, 69.711610], // Самарқан
          [42.379537, 69.709716], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.383284, 69.710531], // 
          [42.379867, 69.708606], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384039, 69.711862], // Орбулак
          [42.384336, 69.710853],
          [42.384345, 69.710765],
          [42.384326, 69.710664],
          [42.384177, 69.710241],
          [42.383788, 69.709525],
          [42.383675, 69.709422],
          [42.380958, 69.707966],
          [42.380618, 69.707777],
          [42.379626, 69.707391],
          [42.379103, 69.707144],
          [42.378836, 69.707060],
          [42.378733, 69.707015],
          [42.378560, 69.706895], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384601, 69.705403], // Шуақты күндер
          [42.384022, 69.705880],
          [42.383351, 69.706574],
          [42.383059, 69.706841],
          [42.382650, 69.707287],
          [42.382406, 69.707474],
          [42.381822, 69.707669],
          [42.381555, 69.707715],
          [42.381004, 69.707887],
          [42.380973, 69.707912],
          [42.380945, 69.707961],
          [42.380995, 69.707894], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384257, 69.708512], // Шуақты күндер
          [42.382407, 69.707473], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381608, 69.705566], // 
          [42.380619, 69.707778], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381719, 69.708374], // Новостройки
          [42.376975, 69.724003], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.380144, 69.707594], // С.Балкыбекова
          [42.376453, 69.720065],
          [42.376406, 69.720264],
          [42.376454, 69.720737],
          [42.376462, 69.720871],
          [42.376457, 69.720994],
          [42.376434, 69.721215],
          [42.376184, 69.722356],
          [42.376019, 69.723509],
          [42.375924, 69.724172],
          [42.375823, 69.724657],
          [42.375725, 69.725221],
          [42.375583, 69.725760],
          [42.375195, 69.726580],
          [42.374594, 69.727657],
          [42.372127, 69.732278],
          [42.371436, 69.733645], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376407, 69.720250], // Кызан
          [42.376364, 69.720264],
          [42.375705, 69.720103],
          [42.375464, 69.720117],
          [42.375232, 69.720156], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375199, 69.726569], // 
          [42.374226, 69.725631], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378560, 69.706898], // Б.Бабашулы
          [42.375238, 69.718618],
          [42.375219, 69.720473],
          [42.374812, 69.724852],
          [42.374753, 69.724993],
          [42.374224, 69.725631],
          [42.372653, 69.727399],
          [42.372229, 69.727956],
          [42.372080, 69.728233],
          [42.371971, 69.728582],
          [42.370970, 69.731537],
          [42.370774, 69.732277],
          [42.370717, 69.732402],
          [42.370657, 69.732505],
          [42.368194, 69.735750],
          [42.367974, 69.736001],
          [42.367707, 69.736215],
          [42.365417, 69.738165], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.383341, 69.690928], // Утебаева
          [42.379048, 69.705527],
          [42.378560, 69.706898], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.387030, 69.692457], // 
          [42.387144, 69.691895], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.386634, 69.691738], // Акселеу
          [42.386437, 69.691692],
          [42.386331, 69.691709],
          [42.386269, 69.691736],
          [42.386208, 69.691793],
          [42.386159, 69.691881],
          [42.386098, 69.691975],
          [42.385362, 69.695062],
          [42.385091, 69.695990],
          [42.385091, 69.695990],
          [42.384187, 69.698416],
          [42.383969, 69.698911],
          [42.383337, 69.700213],
          [42.382473, 69.701714],
          [42.382081, 69.702264],
          [42.381679, 69.702890],
          [42.381609, 69.702991],
          [42.381419, 69.703222],
          [42.380475, 69.704116],
          [42.380204, 69.704450], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.385945, 69.690159], // 
          [42.385691, 69.691175],
          [42.385567, 69.691843], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.385235, 69.691878], // 
          [42.384620, 69.694297],
          [42.384347, 69.695143],
          [42.384203, 69.695534], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.385457, 69.688692], // Тугейболат
          [42.384677, 69.691529],
          [42.384818, 69.691631],
          [42.384716, 69.691910],
          [42.383789, 69.695359],
          [42.383062, 69.697877],
          [42.382476, 69.700095],
          [42.382414, 69.700328],
          [42.381717, 69.702352],
          [42.381624, 69.702692],
          [42.381593, 69.702863],
          [42.381610, 69.702965], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384051, 69.691298], // Тогайбек Ташмуханбетова
          [42.380203, 69.704448],
          [42.380144, 69.704574],
          [42.380083, 69.704657],
          [42.380003, 69.704737],
          [42.379810, 69.704852],
          [42.379086, 69.705398], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384557, 69.687824], // 
          [42.384557, 69.687824],
          [42.384679, 69.687931],
          [42.384725, 69.688139],
          [42.384703, 69.688337],
          [42.383878, 69.691176], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384557, 69.687824], // 
          [42.384510, 69.687820],
          [42.382695, 69.688075],
          [42.381716, 69.688203],
          [42.381224, 69.688225],
          [42.380613, 69.688102],
          [42.380183, 69.687968],
          [42.380050, 69.687937],
          [42.379707, 69.687902], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384449, 69.689174], // 
          [42.383334, 69.688618], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384164, 69.690155], // 
          [42.383035, 69.689631], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.383465, 69.687963], // Маханулы
          [42.383335, 69.688620],
          [42.383036, 69.689632],
          [42.382810, 69.690489],
          [42.382738, 69.690651],
          [42.382706, 69.690686],
          [42.380974, 69.696642], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.382696, 69.688075], // Жанабай Айнабек
          [42.380382, 69.696337], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.380309, 69.696298], // Сарыарка
          [42.379227, 69.699786], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381717, 69.688203], // Жарылкап Жумабай
          [42.381254, 69.689896],
          [42.381219, 69.690147],
          [42.381224, 69.690222],
          [42.381355, 69.690375],
          [42.381371, 69.690463],
          [42.380431, 69.693708],
          [42.379750, 69.696007], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381231, 69.688223], // Сайран
          [42.380820, 69.689715],
          [42.379114, 69.695663], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.386101, 69.691968], // Аюлы
          [42.385982, 69.691943],
          [42.385883, 69.691897],
          [42.385742, 69.691852],
          [42.385638, 69.691835],
          [42.385505, 69.691853],
          [42.385375, 69.691888],
          [42.385226, 69.691879],
          [42.384940, 69.691740],
          [42.384798, 69.691635],
          [42.384667, 69.691540],
          [42.384040, 69.691291],
          [42.383870, 69.691170],
          [42.383340, 69.690925],
          [42.382130, 69.690331],
          [42.381252, 69.689895],
          [42.380816, 69.689732], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.385094, 69.695976], // 
          [42.382238, 69.694649], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.384190, 69.698402], // 
          [42.383067, 69.697860], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381625, 69.694339], // 
          [42.379791, 69.693359], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.381547, 69.696938], // Айтеке би
          [42.379114, 69.695663], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378950, 69.705801], // Мергентобе
          [42.378526, 69.705912],
          [42.378294, 69.706183],
          [42.376686, 69.707816],
          [42.375945, 69.708810],
          [42.375583, 69.709356],
          [42.374658, 69.710749],
          [42.374498, 69.710984],
          [42.374367, 69.711150],
          [42.374315, 69.711178],
          [42.374250, 69.711191],
          [42.374023, 69.711197],
          [42.373850, 69.711181], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376377, 69.709595], // 
          [42.375935, 69.710665],
          [42.375873, 69.710860],
          [42.375719, 69.711148],
          [42.375699, 69.711277],
          [42.375685, 69.711314],
          [42.375650, 69.711362],
          [42.375574, 69.711535],
          [42.375184, 69.713143],
          [42.375157, 69.713224],
          [42.374952, 69.713957],
          [42.374816, 69.714316],
          [42.374791, 69.714425],
          [42.374791, 69.714508],
          [42.374770, 69.714506], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375191, 69.714291], // 
          [42.375053, 69.714235],
          [42.375001, 69.714204],
          [42.374902, 69.714077], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.374770, 69.714506], // 
          [42.374457, 69.714460], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.378533, 69.705910], // 
          [42.378508, 69.705849],
          [42.378316, 69.704797],
          [42.375932, 69.703427],
          [42.375622, 69.703228],
          [42.373951, 69.702301], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377860, 69.706608], // 
          [42.376893, 69.705163], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.377151, 69.707319], // 
          [42.376233, 69.705850], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376431, 69.707791], // 
          [42.375413, 69.706202],
          [42.373343, 69.704993], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376377, 69.709594], // 
          [42.376396, 69.709504],
          [42.376394, 69.709430],
          [42.376371, 69.709362],
          [42.376146, 69.709030],
          [42.375944, 69.708785],
          [42.375923, 69.708752],
          [42.375517, 69.708062],
          [42.375012, 69.707229], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375523, 69.708073], // 
          [42.374923, 69.708743],
          [42.374899, 69.708758],
          [42.374872, 69.708757],
          [42.374851, 69.708739],
          [42.374401, 69.708034], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.373711, 69.708624], // 
          [42.374392, 69.709735],
          [42.374405, 69.709771],
          [42.374403, 69.709809],
          [42.374386, 69.709838],
          [42.373496, 69.710832],
          [42.373428, 69.710966], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.373819, 69.710461], // 
          [42.373080, 69.709259], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376272, 69.703627], // Бозқараған
          [42.375591, 69.705968],
          [42.375474, 69.706295], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.375628, 69.703234], // Бозкент
          [42.374502, 69.707174],
          [42.374450, 69.707311],
          [42.374404, 69.707398],
          [42.374336, 69.707486],
          [42.374242, 69.707586],
          [42.374022, 69.707744],
          [42.373733, 69.708001],
          [42.372719, 69.709110],
          [42.372583, 69.709314],
          [42.372437, 69.709597],
          [42.372345, 69.709727],
          [42.372282, 69.709801],
          [42.372211, 69.709856],
          [42.372079, 69.709930],
          [42.371920, 69.710102], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.374920, 69.702841], // Бозғұрт
          [42.373750, 69.707072],
          [42.373753, 69.707308],
          [42.373749, 69.707334],
          [42.373480, 69.708155],
          [42.373487, 69.708274], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.374270, 69.702474], // 
          [42.373571, 69.705127],
          [42.373251, 69.706344],
          [42.373128, 69.706658],
          [42.373037, 69.707044],
          [42.372678, 69.708228],
          [42.372359, 69.709119],
          [42.372276, 69.709249],
          [42.372116, 69.709673],
          [42.372111, 69.709910], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.372906, 69.710842], // 
          [42.372779, 69.710364],
          [42.372367, 69.709695], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.376580, 69.713886], // Кентарал
          [42.376319, 69.713725],
          [42.375185, 69.713142],
          [42.374678, 69.712830],
          [42.374474, 69.712619],
          [42.374393, 69.712163],
          [42.374361, 69.711991],
          [42.374279, 69.711760],
          [42.374169, 69.711549],
          [42.374119, 69.711478],
          [42.373834, 69.711163],
          [42.373732, 69.711106],
          [42.373416, 69.710962],
          [42.373245, 69.710899],
          [42.372906, 69.710842],
          [42.371750, 69.709971], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.374478, 69.712623], // Балқантау
          [42.374408, 69.712728],
          [42.374169, 69.713570],
          [42.374091, 69.713802],
          [42.373961, 69.714134],
          [42.373725, 69.714667],
          [42.373662, 69.714869],
          [42.373652, 69.714960],
          [42.373665, 69.715321],
          [42.373656, 69.715600],
          [42.373721, 69.715934],
          [42.373844, 69.716294], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.373662, 69.715446], // Бауыртал
          [42.373473, 69.715523],
          [42.373428, 69.715523],
          [42.373359, 69.715489],
          [42.372675, 69.714328],
          [42.371548, 69.712511],
          [42.371199, 69.712101],
          [42.370845, 69.711849],
          [42.370903, 69.711894], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.374719, 69.715492], // Пармен
          [42.374307, 69.717059],
          [42.374244, 69.717179],
          [42.374184, 69.717357],
          [42.374114, 69.717753],
          [42.374036, 69.718436],
          [42.373791, 69.721449],
          [42.373782, 69.721703],
          [42.373723, 69.722221],
          [42.373700, 69.722334],
          [42.373615, 69.722546],
          [42.372718, 69.724359],
          [42.370634, 69.728135],
          [42.370533, 69.728445],
          [42.370372, 69.728904],
          [42.370075, 69.729890],
          [42.369897, 69.730425],
          [42.369775, 69.730726],
          [42.369699, 69.730999],
          [42.369590, 69.731281],
          [42.369125, 69.731953],
          [42.368946, 69.732264],
          [42.368575, 69.732833],
          [42.368411, 69.733130],
          [42.368109, 69.733547],
          [42.367757, 69.733985],
          [42.367545, 69.734300],
          [42.367209, 69.734683],
          [42.366683, 69.735233],
          [42.365458, 69.736190],
          [42.365171, 69.736477],
          [42.364938, 69.736795],
          [42.364834, 69.736971], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.374036, 69.718421], // Бәйгеторы
          [42.371029, 69.713432],
          [42.370941, 69.713327],
          [42.370452, 69.713045],
          [42.370277, 69.712893], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.373913, 69.719921], // Бесжалтау
          [42.370404, 69.714283],
          [42.370411, 69.714159],
          [42.370503, 69.714041],
          [42.370668, 69.713926],
          [42.371031, 69.713439], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.373789, 69.721451], // Бесшағыл
          [42.371748, 69.717753],
          [42.371651, 69.717546],
          [42.371431, 69.717200],
          [42.371403, 69.717017],
          [42.371010, 69.716350], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.372688, 69.714353], // Бозай
          [42.371373, 69.715857],
          [42.370767, 69.716612],
          [42.370693, 69.716606],
          [42.370155, 69.715706], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.372649, 69.717894], // 
          [42.372152, 69.718489], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.372736, 69.724316], // 
          [42.371216, 69.722747], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.371938, 69.721489], // 
          [42.369935, 69.724971], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.371938, 69.721489], // 
          [42.368506, 69.718092],
          [42.367732, 69.717608], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369935, 69.724971], // 
          [42.367201, 69.722337], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.367201, 69.722337], // 
          [42.369094, 69.718697], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.368427, 69.719980], // 
          [42.367151, 69.718701], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.368769, 69.723846], // 
          [42.363267, 69.733705], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363697, 69.732939], // 
          [42.365698, 69.735042], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356533, 69.614196], // Тежибай ата
          [42.359140, 69.615308], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.359430, 69.614001], // Монке би
          [42.357625, 69.613231],
          [42.357011, 69.612964],
          [42.356788, 69.612852],
          [42.356134, 69.612605], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356134, 69.612605], // 
          [42.356032, 69.612124],
          [42.356025, 69.611901],
          [42.356602, 69.608885], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.356173, 69.616347], // Жанкент
          [42.356235, 69.616120],
          [42.356235, 69.616120],
          [42.356229, 69.615774],
          [42.355519, 69.612197],
          [42.355205, 69.610595],
          [42.355188, 69.610538],
          [42.355116, 69.610434],
          [42.354915, 69.609237],
          [42.354727, 69.608216], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355385, 69.615697], // 
          [42.355760, 69.614121], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355522, 69.612218], // Мынжылкы
          [42.355503, 69.612259],
          [42.354733, 69.615274], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354020, 69.615148], // Кокбулак
          [42.355129, 69.610605],
          [42.355137, 69.610536], 
          [42.355130, 69.610452], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.354435, 69.610222], // Аргымак
          [42.353815, 69.612826], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.355928, 69.614201], // Байыркум
          [42.353248, 69.612426],
          [42.353169, 69.612355],
          [42.353107, 69.612258], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.365201, 69.620567], // Ак Маржан
          [42.359239, 69.616562], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.365647, 69.619514], // Тежибай ата
          [42.365907, 69.618718],
          [42.365905, 69.618647],
          [42.365883, 69.618587],
          [42.365844, 69.618551],
          [42.363984, 69.617402],
          [42.363735, 69.617261],
          [42.359465, 69.615468], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.364715, 69.617855], // 
          [42.363921, 69.619693], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363192, 69.619139], // Алтын бесик
          [42.367527, 69.609113],
          [42.368310, 69.605765], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.364511, 69.616090], // Монке би
          [42.359743, 69.614161], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.364252, 69.615245], // Келешек
          [42.366950, 69.603465],
          [42.366953, 69.603209], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.363399, 69.614744], // Ризашылык
          [42.365765, 69.604634], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.362372, 69.615210], // Канагат
          [42.364912, 69.604152], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.361538, 69.614885], // Мирас
          [42.364086, 69.603725], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.360638, 69.614528], // Корикты
          [42.363569, 69.601816], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.365722, 69.612774], // Орлеу
          [42.367184, 69.606713], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.365915, 69.612847], // Байтурсынова
          [42.360481, 69.610517], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.367096, 69.610114], // Умит
          [42.363005, 69.608373], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.368781, 69.607433], // Салтанатты
          [42.361901, 69.604335], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369667, 69.608209], // Гулмайса
          [42.369268, 69.608422],
          [42.368407, 69.608743],
          [42.368017, 69.608862],
          [42.367920, 69.608900],
          [42.367663, 69.609047],
          [42.367530, 69.609095], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.367351, 69.605793], // Абдраша Назарбекова
          [42.368099, 69.602757], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.367343, 69.601869], // 
          [42.367028, 69.603236], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.367896, 69.603579], // Казиева
          [42.367782, 69.603554],
          [42.362588, 69.601399], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.366459, 69.601501], // Алтын бесик
          [42.366140, 69.602872], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.365668, 69.600901], // Болашак 
          [42.365298, 69.602519], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.364429, 69.602162], // Акдала
          [42.364813, 69.600503], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.412916, 69.614529], // 
          [42.408152, 69.614002], //
          [42.408244, 69.617174],
          [42.408232, 69.618098], //6
          [42.408217, 69.620478],
          [42.412818, 69.621193], //4
          [42.412837, 69.619768],
          [42.412757, 69.618687],
          [42.412745, 69.618641],
          [42.412717, 69.618622],
          [42.411284, 69.618465], //2
          [42.408236, 69.618100],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.411284, 69.618465], // 
          [42.411023, 69.620916], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.409638, 69.614362], // 
          [42.411168, 69.614549], //
          [42.412494, 69.614716],
          [42.412525, 69.614737],
          [42.412538, 69.614763],
          [42.412543, 69.614786],
          [42.412488, 69.615336],
          [42.412453, 69.615609],
          [42.412380, 69.616601],
          [42.411131, 69.616431],
          [42.409507, 69.616248],
          [42.409638, 69.614362],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.409508, 69.616180], // 
          [42.408214, 69.616022], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.409586, 69.615062], // 
          [42.409061, 69.614986], //
          [42.409024, 69.614986],
          [42.408969, 69.615001],
          [42.408893, 69.615071],
          [42.408852, 69.615126],
          [42.408832, 69.615209],
          [42.408825, 69.615268],
          [42.408818, 69.615752],
          [42.408821, 69.615808],
          [42.408829, 69.615853],
          [42.408905, 69.616104],
          [42.408908, 69.616116],
          [42.408860, 69.616956],
          [42.408864, 69.617037],
          [42.408878, 69.617111],
          [42.408894, 69.617154],
          [42.409021, 69.617337],
          [42.409073, 69.617359],
          [42.409366, 69.617375],
          [42.409426, 69.617342],
          [42.409607, 69.617367],
          [42.409640, 69.617387],
          [42.409784, 69.617620],
          [42.409793, 69.617643],
          [42.409795, 69.617667],
          [42.409755, 69.618282],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.408152, 69.614002], // 
          [42.402499, 69.613343], //
          [42.402277, 69.619562],
          [42.408217, 69.620478],
        ],
        color: '#0000FF',
      },
    
      {
        coordinates: [
          [42.366477, 69.589327], // 
          [42.364401, 69.598253], //
          [42.363859, 69.600532],
          [42.363560, 69.601819],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.366477, 69.589327], // 
          [42.367325, 69.589693], //
          [42.366485, 69.593240],
          [42.365248, 69.598590],
          [42.364815, 69.600489],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.367325, 69.589693], // 
          [42.368180, 69.590060], //
          [42.367352, 69.593593],
          [42.367114, 69.594523],
          [42.366105, 69.598956],
          [42.365666, 69.600908],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.368180, 69.590060], // 
          [42.369050, 69.590435], //
          [42.368203, 69.593951],
          [42.367614, 69.596636],
          [42.367420, 69.597329],
          [42.366964, 69.599300],
          [42.366456, 69.601507],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369050, 69.590435], // 
          [42.369372, 69.590573], //
          [42.369490, 69.590680],
          [42.369595, 69.590733],
          [42.369654, 69.590749],
          [42.369807, 69.590763],
          [42.369917, 69.590806],//
          [42.367345, 69.601866],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.369372, 69.590573], // 
          [42.370772, 69.591173], //
          [42.368095, 69.602755],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.370772, 69.591173], // 
          [42.371624, 69.591536], //
          [42.370788, 69.595028],
          [42.370012, 69.598330],
          [42.369144, 69.602240],
          [42.369113, 69.602455],
          [42.368147, 69.603458],
          [42.368045, 69.603539],
          [42.367893, 69.603580],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.371624, 69.591536], // 
          [42.372495, 69.591910], //
          [42.371654, 69.595406],
          [42.370287, 69.601366],
          [42.369113, 69.602455],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.372495, 69.591910], // 
          [42.373317, 69.592260], //
          [42.372522, 69.595763],
          [42.371992, 69.598064],
          [42.371497, 69.600187],
          [42.370287, 69.601366],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.373317, 69.592260], // 
          [42.373661, 69.592417], //
          [42.373780, 69.592534],
          [42.373881, 69.592596],
          [42.374031, 69.592612],
          [42.374132, 69.592625],
          [42.374320, 69.592704],//
          [42.374193, 69.593411],
          [42.374126, 69.593709],
          [42.374035, 69.594135],
          [42.373489, 69.596321],
          [42.373291, 69.597347],
          [42.373264, 69.597443],
          [42.373192, 69.597594],
          [42.373163, 69.597696],
          [42.373132, 69.597858],
          [42.373095, 69.598335],
          [42.373095, 69.598406],
          [42.373076, 69.598500],
          [42.373050, 69.598565],
          [42.373010, 69.598620],
          [42.372861, 69.598675],
          [42.372816, 69.598709],
          [42.372663, 69.598990], //
          [42.371497, 69.600187],
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [42.372663, 69.598990], // 
          [42.372712, 69.599075], //
          [42.372775, 69.599308],
          [42.372779, 69.599386],
          [42.372719, 69.599627],
          [42.372630, 69.600013],
          [42.372611, 69.600132],
          [42.372581, 69.600398],
          [42.372548, 69.600666],
          [42.372527, 69.600742],//
          [42.372228, 69.600965],
          [42.370113, 69.603057],
          [42.369685, 69.603428],///////////////
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },

      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF'
      },

    ]
  },

  ////////////////////////////////////// Астел //////////////////////////////////////////////////////////////
  {
    name: 'Астел',
    lines: [
      {
        coordinates: [
          [42.280992, 69.738218], // Асылтас
          [42.281731, 69.744223],
          [42.281979, 69.746396],
          [42.282086, 69.747562],
          [42.282262, 69.751281],
          [42.282246, 69.751730],
          [42.282240, 69.751892], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.281356, 69.748141], // Жайдары
          [42.280132, 69.738704], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.279161, 69.737975], // Айбота
          [42.280811, 69.750640], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.278351, 69.738233], // Акжаркын
          [42.279870, 69.750187], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.278883, 69.749277], // Шымкорган
          [42.277538, 69.738835], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.277837, 69.748741], // Думан
          [42.276941, 69.741637], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.276182, 69.742004], // Окжетпес
          [42.276233, 69.742352],
          [42.276249, 69.742719],
          [42.276272, 69.742980],
          [42.276282, 69.743147],
          [42.276544, 69.745122],
          [42.276879, 69.747981],
          [42.276887, 69.748175], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.275972, 69.747679], // Мерей
          [42.275374, 69.743019], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.274536, 69.743966], // Коктобе
          [42.274968, 69.747164], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.273545, 69.744367], //
          [42.273856, 69.746556], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.272503, 69.744623], //
          [42.272660, 69.745731],
          [42.272689, 69.745854], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.281443, 69.741888], // Коктерек
          [42.275413, 69.743347], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.281689, 69.743870], // Атамұра
          [42.280004, 69.744257],
          [42.278288, 69.744663],
          [42.278278, 69.744692],
          [42.275668, 69.745310], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.282107, 69.747967], // Далабазар
          [42.278822, 69.748731], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], //
          [], //
        ],
        color: '#0000FF',
      },
    ]
  },

  ////////////////////////////////////// Казахтелеком ///////////////////////////////////////////////////////
  {
    name: 'Казахтелеком',
    lines: [
      {
        coordinates: [
          [42.344634, 69.625272], // Парасат
          [42.344358, 69.626435],
          [42.343788, 69.628621],
          [42.343580, 69.629379],
          [42.342752, 69.632946], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.343005, 69.629953], // 
          [42.341440, 69.629269],
          [42.340779, 69.632066], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.344634, 69.625272], // 
          [42.344687, 69.625295],
          [42.344660, 69.625461],
          [42.344659, 69.625495],
          [42.344682, 69.625526],
          [42.349853, 69.627710], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.344359, 69.626435], // Айша Биби
          [42.351028, 69.629217], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.355227, 69.631290], // Айша Биби
          [42.354827, 69.631104],
          [42.354319, 69.630902],
          [42.352734, 69.630197],
          [42.352485, 69.630024], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.350964, 69.630445], // Мирзаходжаева
          [42.344065, 69.627468], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.354100, 69.631834], // Мирзаходжаева
          [42.352421, 69.631144], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.350820, 69.631585], // Жетысу
          [42.343788, 69.628622], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.352154, 69.632055], // Жетысу
          [42.353874, 69.632834], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.350501, 69.632326], // Шанырак
          [42.345259, 69.630145],
          [42.343579, 69.629379], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.343331, 69.630488], // Оркениет
          [42.347889, 69.632440],
          [42.348918, 69.632847], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.346569, 69.633141], // Таншолпан
          [42.344759, 69.632333],
          [42.343071, 69.631604], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.347889, 69.632440], // 
          [42.347476, 69.634166], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.350964, 69.630445], // Адилет
          [42.351000, 69.630472],
          [42.351031, 69.630506],
          [42.351052, 69.630564],
          [42.351065, 69.630626],
          [42.351067, 69.630685],
          [42.351062, 69.630749],
          [42.350666, 69.632286],
          [42.350648, 69.632318],
          [42.350601, 69.632358],
          [42.350501, 69.632326], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.351603, 69.633596], // Акшатау
          [42.350451, 69.638800],
          [42.349805, 69.641493], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.350134, 69.636051], // Акшокы
          [42.349332, 69.639540],
          [42.348917, 69.641170], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.348452, 69.635333], // Д. Жумабекова
          [42.347834, 69.637872], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.353182, 69.633576], // Ушозен
          [42.352856, 69.633437],
          [42.352819, 69.633429],
          [42.352784, 69.633431],
          [42.352719, 69.633475],
          [42.352697, 69.633530],
          [42.350707, 69.641818], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.354314, 69.630899], // Кербулак
          [42.354099, 69.631834],
          [42.354072, 69.632044],
          [42.353938, 69.632659],
          [42.353874, 69.632834],
          [42.352853, 69.637203], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.354540, 69.634121], // 
          [42.353659, 69.633736], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.301867, 69.581544], // 
          [42.302484, 69.581687], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302484, 69.581687], // 
          [42.304551, 69.582355], //
          [42.304551, 69.582355],
          [42.309213, 69.583751],
          [42.309213, 69.583751],
          [42.308492, 69.587117],
          [42.308492, 69.587117],
          [42.308462, 69.587395],
          [42.308462, 69.587395],
          [42.308474, 69.587469],
          [42.308474, 69.587469],
          [42.308615, 69.588000],
          [42.308615, 69.588000],
          [42.307635, 69.588503],


          
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.307444, 69.588583], // 
          [42.306613, 69.589068], //
          [42.306147, 69.589290],
          [42.306027, 69.588972],
          [42.305763, 69.587849],
          [42.305430, 69.586669],
          [42.305394, 69.586500],
          [42.305354, 69.586112],
          [42.305288, 69.585122],
          [42.305050, 69.583661],
          [42.304953, 69.583331],
          [42.304538, 69.582363],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.309222, 69.583747], // 
          [42.313417, 69.585025], //
          [42.313560, 69.585002],
          [42.315277, 69.580007],
          [42.312992, 69.578690],
          [42.310562, 69.577633],
          [42.309225, 69.583719],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.310556, 69.577599], // 
          [42.302771, 69.574322], //
          [42.302157, 69.579568],
          [42.301775, 69.581490],
          [42.302573, 69.581725],
          [42.303111, 69.579447],
          [42.303599, 69.577356],
          [42.304174, 69.574903],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.301776, 69.581492], // 
          [42.301622, 69.581939], //
          [42.301622, 69.582396],
          [42.301677, 69.582859],
          [42.301724, 69.583239],
          [42.302310, 69.585994],
          [42.302914, 69.588830],
          [42.302936, 69.590124],
          [42.302960, 69.593472],
          [42.302872, 69.594193],
          [42.302382, 69.596441],
          [42.301568, 69.599557],
          [42.302802, 69.600126],
          [42.304067, 69.596171],
          
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.302802, 69.600126],
          [42.304411, 69.602219], // Ерманова
          [42.299544, 69.609085], //
          [42.298199, 69.609011],
          [42.298093, 69.608908],
          [42.296383, 69.606304],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.314492, 69.582250], // 
          [42.313678, 69.581745], //
          [42.313400, 69.581633],
          [42.312580, 69.581111],
          [42.312582, 69.580892],
          [42.312534, 69.580702],
          [42.312523, 69.580601],
          [42.312971, 69.578689],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.310560, 69.577617], // 
          [42.312349, 69.569952], //
          [42.312431, 69.569701],
          [42.318512, 69.562494],
          [42.315731, 69.557204],
          [42.308512, 69.566815],
          [42.306372, 69.575829],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.308512, 69.566815], // 
          [42.312431, 69.569701], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.311063, 69.568713], // 
          [42.310818, 69.567828], //
          [42.312601, 69.565343],
          [42.314412, 69.562984],
          [42.317001, 69.559598],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.314002, 69.567800], // 
          [42.311274, 69.562879], // 
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.312955, 69.560740], // 
          [42.314408, 69.562971], //
          [42.315840, 69.565617],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [ 
          [42.321717, 69.568536], //
          [42.321763, 69.568843],
          [42.321711, 69.569025],
          [42.320260, 69.573453],// мангельдина Аскарова
          [42.318886, 69.577585],// Уркимбаева Аскарова
          [42.318423, 69.578974],// мамутова аскарова
          [42.317629, 69.581390],
          [42.319614, 69.584063],
          [42.323754, 69.582274],
          [42.324277, 69.580759],// Уркимбаева Аскарова
          [42.324811, 69.579389],
          [42.326930, 69.574033],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.321717, 69.568536], // 
          [42.318512, 69.562494], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.326930, 69.574033], // 
          [42.321238, 69.570470], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.320260, 69.573453], // 
          [42.325881, 69.576699], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.319354, 69.576141], // 
          [42.324381, 69.579110], //
          [42.324808, 69.579404],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.318886, 69.577585], // 
          [42.324277, 69.580759], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.318423, 69.578974], // 
          [42.323028, 69.581762], //
          [42.323065, 69.581822],
          [42.323091, 69.582103],
          [42.323195, 69.582243],
          [42.323247, 69.582492],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.315273, 69.579992], // 
          [42.317626, 69.581390], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.319614, 69.584063], // 
          [42.320568, 69.587703], // момышулы республика
          [42.318459, 69.596879], // тауке хана кунаева
          [42.317076, 69.596290], //пазылбекова кунаева
          [42.315979, 69.595821], //туркестанская кунаева
          [42.316069, 69.595326],
          [42.316487, 69.592464],
          [42.317808, 69.592721],
          [42.317076, 69.596290 ],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.317808, 69.592721], // 
          [42.319369, 69.593033], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.319369, 69.593033], // 
          [42.321923, 69.594086], //
          [42.321016, 69.597964], //иляева кунаева
          [42.323870, 69.599004],
          [42.324752, 69.595319],
          [42.321923, 69.594086],
          [42.322974, 69.589624],//иляева рыскулбекова
          [42.325772, 69.590798],
          [42.324752, 69.595319],

        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.325772, 69.590798], // 
          [42.325955, 69.589272], //
          [42.325838, 69.588559],
          [42.324791, 69.585482],//момышулы желтоксана
          [42.323754, 69.582274],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.322974, 69.589624], // 
          [42.321350, 69.583329], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.324791, 69.585482], // 
          [42.320568, 69.587703], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.320568, 69.587703], // 
          [42.317005, 69.589593], //
          [42.315979, 69.595821],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.317005, 69.589593], // 
          [42.314909, 69.586797], //
          [42.313541, 69.585003],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.314909, 69.586797], // 
          [42.316536, 69.584527], //
          [42.316594, 69.584397],
          [42.317629, 69.581390],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.315979, 69.595821], // 
          [42.314614, 69.600046], // Токаева туркестанская
          [42.310212, 69.598326],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.310212, 69.598326], // 
          [42.311374, 69.601081], // Казыбек би Турысова
          [42.310972, 69.602774],
          
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.311374, 69.601081], // 
          [42.309833, 69.600442], //
          [42.309485, 69.602137],
          [42.309374, 69.603359],
          [42.309814, 69.603392],
          [42.310212, 69.603373],
          [42.310615, 69.603473],
          [42.310853, 69.603627],
          [42.311286, 69.604046],
          [42.311462, 69.604237],
          [42.313081, 69.606549], //Ташенова Туркестанская
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.314614, 69.600046], // 
          [42.314040, 69.602197], // Казыбекби Туркестанская
          [42.313081, 69.606549],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.311374, 69.601081], // 
          [42.314040, 69.602197], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.314040, 69.602197], // 
          [42.315431, 69.602789], // Казыбекби МайлыКожа
          [42.316915, 69.603405], // Таукехана Казыбекби
          [42.319473, 69.604512], // иляева Казыбекби
          [42.318960, 69.606673], // иляева Диваева
          [42.318446, 69.608937], // Ташенова Иляева
          [42.317919, 69.611148], // Иляева Орынбаева
          [42.316718, 69.610657],
          [42.317776, 69.606261],
          [42.316386, 69.605699],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.318960, 69.606673], // 
          [42.317776, 69.606261], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.318446, 69.608937], // 
          [42.315880, 69.607839], //
          [42.316915, 69.603405],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.315880, 69.607839], // 
          [42.315364, 69.610062], //
          [42.316718, 69.610657],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.319473, 69.604512], // 
          [42.321016, 69.597964], //
          [42.318459, 69.596879], // тауке хана кунаева
          [42.316915, 69.603405],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.319890, 69.597501], // 
          [42.318873, 69.601795], //
          [42.318789, 69.601759],
          [42.318269, 69.603994],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.319997, 69.602284], // 
          [42.317449, 69.601200], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.320486, 69.600155], // 
          [42.318074, 69.599099], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.315881, 69.607839], // 
          [42.313284, 69.606712], //
          [42.313078, 69.606556],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.319376, 69.593035], // Таукехана байтурсынова
          [42.321921, 69.594089], //
          [42.346674, 69.604644],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.332611, 69.593710], // 
          [42.330679, 69.601846], // Маделикожа кунаева
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.332402, 69.594595], // 
          [42.333826, 69.595208], //
          [42.332989, 69.598810],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.336521, 69.597084], // 
          [42.335850, 69.600024], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.340977, 69.597317], // 
          [42.339932, 69.601765], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.340977, 69.597317], // 
          [42.341751, 69.597654], //
          [42.342788, 69.598312],
          [42.347124, 69.600214],
          [42.347165, 69.600258],
          [42.347179, 69.600307],
          [42.347178, 69.600344],
          [42.347163, 69.600414],
          [42.346571, 69.602626],
          [42.346204, 69.603937],

        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.346571, 69.602626], // 
          [42.347077, 69.602922], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.344723, 69.614233], // 
          [42.343085, 69.621165], //
          [42.343473, 69.621211],
          [42.345180, 69.621883],
          [42.345173, 69.622340],
          [42.344543, 69.625128],
          [42.342397, 69.624191],
          [42.338693, 69.622406],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.343085, 69.621165], // 
          [42.342397, 69.624191], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.345180, 69.621883], // 
          [42.345806, 69.619308], //
          [42.344650, 69.618792],
          [42.344489, 69.618746],
          [42.344489, 69.618746],
          [42.344119, 69.618741],
          [42.343707, 69.618568],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.344543, 69.625128], // 
          [42.348035, 69.626644], //
          [42.348457, 69.624571],
          [42.348478, 69.624060],
          [42.348352, 69.622346],
          [42.348333, 69.621248],
          [42.348532, 69.616728],
          [42.348587, 69.616439],
          [42.348663, 69.616208],
          [42.348752, 69.615965],
          [42.348849, 69.615778],
          [42.348978, 69.615601],
          [42.349092, 69.615499],
          [42.348986, 69.615388],
          [42.346133, 69.614197],
          [42.345844, 69.615472],
          [42.345743, 69.615998],
          [42.345463, 69.617026],
          [42.346141, 69.617361],
          [42.347146, 69.617972],
          [42.347565, 69.618161],
          [42.348441, 69.618251],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.345806, 69.619308], // 
          [42.346232, 69.619448], //
          [42.346924, 69.619517],
          [42.347250, 69.619566],
          [42.348378, 69.619879],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.343085, 69.621165], // 
          [42.341502, 69.628004], //
          [42.338523, 69.626737],
          [42.338506, 69.626797],
          [42.338472, 69.626842],
          [42.338423, 69.626860],
          [42.338372, 69.626855],
          [42.336313, 69.625982],
          [42.336234, 69.625992],
          [42.336209, 69.626038],
          [42.335751, 69.628026],
          [42.335381, 69.629824],
          [42.340141, 69.631884],
          [42.340569, 69.632014],
          [42.341502, 69.628004],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.340569, 69.632014], // 
          [42.346258, 69.634417], //
          [42.348035, 69.626639],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.358824, 69.618102], // 
          [42.365604, 69.622142], //
          [42.366383, 69.623441],
          [42.366516, 69.624128],
          [42.366457, 69.625194],
          [42.366374, 69.625576],
          [42.364579, 69.630244], //шанина
          [42.361310, 69.639403],
          [42.357973, 69.633714], // аллея
          [42.355971, 69.630259],
          [42.355776, 69.630023],
          [42.358824, 69.618102],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.364579, 69.630244], // 
          [42.363052, 69.629198], //
          [42.360778, 69.627699],
          [42.360561, 69.627571],
          [42.356818, 69.625951],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.360778, 69.627699], // 
          [42.363216, 69.621183], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.360778, 69.627699], // 
          [42.360565, 69.628257], //
          [42.359564, 69.629586],
          [42.357977, 69.633714],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.361310, 69.639403], // 
          [42.359187, 69.646073], //
          [42.357073, 69.652662],
          [42.354726, 69.660030],
          [42.354100, 69.662242],
          [42.353954, 69.663177],
          [42.353937, 69.663776],
          [42.349411, 69.663872],
          [42.349140, 69.663846],
          [42.349693, 69.660920],
          [42.350310, 69.657443],
          [42.351728, 69.649471],
          [42.352890, 69.642614],
          [42.355776, 69.630023],

        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.350310, 69.657443], // 
          [42.354726, 69.660030], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.351728, 69.649471], // 
          [42.352983, 69.650284], //
          [42.357073, 69.652622],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.352890, 69.642614], // 
          [42.353677, 69.642865], //
          [42.354933, 69.643576],
          [42.359179, 69.646064],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.357973, 69.633714], // 
          [42.357791, 69.633944], //
          [42.356381, 69.638441],
          [42.354933, 69.643577],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.315731, 69.557204], // 
          [42.316818, 69.555840], //
          [42.317774, 69.554620],
          [42.317598, 69.553947],
          [42.317647, 69.553811],
          [42.319251, 69.552215],
          [42.323338, 69.548221],
          [42.327532, 69.543891],
          [42.333102, 69.537911],
          [42.334078, 69.536859],
          [42.334386, 69.536444],
          [42.334792, 69.535549],
          [42.335444, 69.533983],
          [42.336093, 69.532922],
          [42.336554, 69.531990],
          [42.336978, 69.531062],
          [42.340721, 69.522142],
          [42.344285, 69.524771],
          [42.351911, 69.530878],
          [42.349711, 69.534602],
          [42.348196, 69.536743],
          [42.345223, 69.540040],
          [42.343904, 69.541131],
          [42.341921, 69.542399],
          [42.340551, 69.543710],
          [42.338748, 69.545747],
          [42.338032, 69.546716],
          [42.337465, 69.547697],
          [42.336635, 69.549880],
          [42.335862, 69.552859],
          [42.335594, 69.553710],
          [42.334121, 69.557871],
          [42.329323, 69.568212],
          [42.328810, 69.569214],
          [42.326929, 69.574034],
          
          [],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.327532, 69.543901],
          [42.327608, 69.544030],
          [42.330538, 69.549712],
          [42.333903, 69.556043],
          [42.334004, 69.556174],
          [42.334076, 69.556213],
          [42.334678, 69.556098], // 
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.334451, 69.556966], // 
          [42.337269, 69.557983], //
          [42.339198, 69.558846],
          [42.344509, 69.560033],
          [42.344788, 69.560003],
          [42.345032, 69.559893],
          [42.345300, 69.559709],
          [42.347332, 69.564836],
          [42.348659, 69.568456],
          [42.348518, 69.568539],
          [42.348271, 69.568639],
          [42.347145, 69.569017],
          [42.347004, 69.569080],
          [42.346846, 69.569173],
          [42.346486, 69.569452],
          [42.344632, 69.571200],
          [42.342362, 69.573580],
          [42.341064, 69.575005],
          [42.339045, 69.577175],
          [42.337977, 69.578302],
          [42.337749, 69.578508],
          [42.332161, 69.581542], //Кожанова
          [42.324786, 69.585487],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.332161, 69.581542], // 
          [42.328594, 69.571026], //
          [42.328400, 69.570455],
          [42.328346, 69.570414],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.332161, 69.581542], // 
          [42.333925, 69.586813], //
          [42.334004, 69.587030],
          [42.334033, 69.587162],
          [42.334062, 69.587390],
          [42.332611, 69.593713],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.331234, 69.564062], // 
          [42.331394, 69.564284], //
          [42.331466, 69.564417],
          [42.331504, 69.564503],
          [42.331649, 69.564882],
          [42.331976, 69.565789],
          [42.332191, 69.566429],
          [42.334509, 69.573160],
          [42.335042, 69.574812],
          [42.335362, 69.575754],
          [42.335866, 69.576992],
          [42.336263, 69.578146],
          [42.336346, 69.578407],
          [42.336547, 69.579153],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.318512, 69.562494], // 
          [42.321198, 69.559259], //
          [42.322208, 69.558036],
          [42.323333, 69.556859],
          [42.323659, 69.556558],
          [42.324432, 69.555570],
          [42.326251, 69.553826], //Алпысбаева
          [42.330539, 69.549711],
          [],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.323337, 69.548221], // 
          [42.326251, 69.553826], //
          [42.328611, 69.558387],
          [42.331160, 69.563257],
          [42.331281, 69.563464],
          [42.331441, 69.563634],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.328611, 69.558387], // 
          [42.320473, 69.566187], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.346258, 69.634417], // 
          [42.345488, 69.637600], //
          [42.345215, 69.638512],
          [42.345102, 69.639329],
          [42.345019, 69.639766],
          [42.342688, 69.638829], //ТЕрискей
          [42.339269, 69.637494], //Рыскулова
          [42.335782, 69.635984],
          [42.335570, 69.635815],
          [42.332783, 69.631770],
          [42.333455, 69.629021],
          [42.335381, 69.629822],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.336918, 69.630492], // 
          [42.335840, 69.635162], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.339269, 69.637494], // 
          [42.340575, 69.632015], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.342688, 69.638829], // 
          [42.342320, 69.640583], //
          [42.342334, 69.640819],
          [42.342360, 69.640946], 
          [42.343188, 69.642794],//
          [42.342181, 69.643674],
          [42.340842, 69.640852],
          [42.341400, 69.638360],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.342181, 69.643674], // 
          [42.340772, 69.645072], //
          [42.340322, 69.645384],
          [42.338645, 69.640145],
          [42.339268, 69.637494],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.343188, 69.642794], // 
          [42.344505, 69.641592], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.335781, 69.635986], // 
          [42.335537, 69.636175], //
          [42.330894, 69.638892],
          [42.332493, 69.643853],
          [42.338099, 69.640854],
          [42.338456, 69.640538],
          [42.338643, 69.640143],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.330896, 69.638883], // 
          [42.328625, 69.631852], //
          [42.328531, 69.631906],
          [42.328431, 69.631907],
          [42.328231, 69.631840],
          [42.326873, 69.631317], //Уалиханова
          [42.317165, 69.627148], //Желтоксана
          [42.316119, 69.631666], // Елшибекбатыр
          [42.313372, 69.643448], //Сайрамская
          [42.323097, 69.647646],
          [42.323594, 69.647863],
          [42.324108, 69.648052],
          [42.324502, 69.648092],
          [42.324852, 69.648052],
          [42.325204, 69.647875],
          [42.325484, 69.647671], //Уалихаова сайрамская
          [42.332493, 69.643852],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.326873, 69.631317], // 
          [42.325830, 69.635842], // Елшибек батыр2
          [42.324145, 69.643170], //Орынбай акын
          [42.324184, 69.643377],
          [42.325484, 69.647671],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.324145, 69.643170], // 
          [42.314385, 69.639041], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.316119, 69.631666], // 
          [42.325830, 69.635842], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.325435, 69.637685], // 
          [42.326301, 69.638044], //
          [42.326416, 69.638093],
          [42.326441, 69.638121],
          [42.326447, 69.638154],
          [42.326459, 69.638215],
          [42.326467, 69.638243],
          [42.326493, 69.638302],
          [42.326518, 69.638334],
          [42.326555, 69.638357],
          [42.327533, 69.638786],
          [42.327649, 69.638873],
          [42.327698, 69.638947],
          [42.327772, 69.639131],
          [42.328248, 69.640530],
          [42.328259, 69.640563],
          [42.328284, 69.640581],
          [42.328318, 69.640584],
          [42.328352, 69.640572],
          [42.328473, 69.640501],
          [42.328505, 69.640505],
          [42.328521, 69.640524],
          [42.328532, 69.640552],
          [42.328544, 69.640570],
          [42.328721, 69.641043],
          [42.328762, 69.641037],
          [42.328995, 69.640896],
          [42.329532, 69.640544],
          [42.329576, 69.640524],
          [42.329635, 69.640528],
          [42.329669, 69.640549],
          [42.329705, 69.640591],
          [42.329734, 69.640639],
          [42.329763, 69.640689],
          [42.329794, 69.640770],
          [42.330425, 69.642612],
          [42.330451, 69.642715],
          [42.330470, 69.642939],
          [42.330400, 69.643000],
          [42.330354, 69.643028],
          [42.330297, 69.643048],
          [42.330167, 69.643082],
          [42.328854, 69.643275],
          [42.328762, 69.643285],
          [42.328686, 69.643279],
          [42.328609, 69.643249],
          [42.328528, 69.643203],
          [42.327641, 69.642573],
          [42.326919, 69.642170],
          [42.326831, 69.642118],
          [42.324653, 69.641053],
        ],
        color: '#0000FF',
      },

      
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },


    ]
  },

 ////////////////////////////////////// Транстелеком /////////////////////////////////
  {
    name: 'Транстелеком',
    lines: [
          {
            coordinates: [
              [42.394474, 69.679041], // 
              [42.396394, 69.681102], //2
              [42.398442, 69.683318],
              [42.392318, 69.693726],
              [42.390252, 69.691480], //2
              [42.389483, 69.690674],
              [42.389371, 69.690518],
              [42.389300, 69.690368],
              [42.389261, 69.690207],
              [42.389115, 69.688336],
              [42.389118, 69.688212],
              [42.389139, 69.688094],
              [42.389195, 69.687963],
              [42.394474, 69.679041],
              
            ],
            color: '#0000FF',
          },
          {
            coordinates: [
              [42.396394, 69.681102], // 
              [42.396138, 69.681587],
              [42.396138, 69.681587],
              [42.390252, 69.691480], //
            ],
            color: '#0000FF',
          },
          {
            coordinates: [
              [42.391355, 69.684287], // 
              [42.391702, 69.684657], //
              [42.392928, 69.685969],
              [42.393268, 69.686334],
              [42.393417, 69.686459],
              [42.393984, 69.687085],
              [42.394332, 69.687490],
              [42.395256, 69.688526],
            ],
            color: '#0000FF',
          },
      
    ]
  },

  ///////////////////Beeline///////////////////////////////////
  {
    name: 'Beeline',
    lines: [
      ////////////////Турдыабат///////////////////////
      {
        coordinates: [
          [42.280960, 69.738141], // 
          [42.281429, 69.741896], // Коктерек
          [42.281678, 69.743879], // Атамұра
          [42.281961, 69.746274],
          [42.282108, 69.747971], //дала базар
          [42.282253, 69.751271],
          [42.282250, 69.751632],
          [42.282200, 69.751998],
          [42.281349, 69.751133],
          [42.280827, 69.750811], //Айбота
          [42.279862, 69.750190], //Акжаркын
          [42.279145, 69.749750],
          [42.278929, 69.749591],//Шымкорган
          [42.277910, 69.748828],
          [42.277830, 69.748743],//Duman
          [42.277550, 69.748534],
          [42.276880, 69.748177],//Окжетпес
          [42.275967, 69.747686],//Мерей
          [42.274962, 69.747170],//Коктобе
          [42.273850, 69.746548],//
          [42.272841, 69.746024],
          [42.272719, 69.745932],
          [42.272667, 69.745810],
          [42.272501, 69.744629],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.282200, 69.751998], // 
          [42.282013, 69.753089], //
          [42.281774, 69.752861],
          [42.281707, 69.752823],
          [42.281534, 69.752777],
          [42.281349, 69.752748],
          [42.281212, 69.752693],
          [42.280992, 69.752526],
          [42.280419, 69.752030],
          [42.280249, 69.751919],////
          [42.279534, 69.751511],////
          [42.279449, 69.751382],
          [42.277617, 69.749820],
          [42.277573, 69.749802],
          [42.277518, 69.749806],
          [42.277349, 69.750080],
          [42.277632, 69.750527],
          [42.277733, 69.750775],
          [42.278912, 69.752619],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.277349, 69.750080], // 
          [42.276696, 69.749201], //
          [42.276403, 69.748936],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280249, 69.751919], // 
          [42.280211, 69.752022], //
          [42.280172, 69.752239],
          [42.280160, 69.752424],
          [42.280163, 69.753181],
          [42.280193, 69.753845],
          [42.280184, 69.754241],
          [42.280162, 69.754368],
          [42.280075, 69.754724],
          [42.280017, 69.755066],///////
          [42.279997, 69.755425],
          [42.280045, 69.756644],
          [42.280028, 69.756908],
          [42.279956, 69.757281],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280017, 69.755066], // 
          [42.279909, 69.754906], //
          [42.279782, 69.754453],
          [42.279722, 69.754152],
          [42.279681, 69.753817],
          [42.279614, 69.753483],
          [42.279507, 69.753097],
          [42.279460, 69.752851],
          [42.279430, 69.752561],
          [42.279484, 69.751878],
          [42.279534, 69.751511],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.281429, 69.741896], //
          [42.278023, 69.742701], 
          [42.275410, 69.743357], //
          [42.275663, 69.745322],
          [42.275967, 69.747686],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.281678, 69.743879], // 
          [42.278283, 69.744672],
          [42.278286, 69.744700],
          [42.275663, 69.745322], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.282108, 69.747971], // 
          [42.278820, 69.748740], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.280118, 69.738624], // 
          [42.280273, 69.739801], //
          [42.280571, 69.742098],
          [42.280826, 69.744075],
          [42.281070, 69.745945],
          [42.281350, 69.748139],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.279120, 69.737764], // 
          [42.279523, 69.740706], //
          [42.279747, 69.742289],
          [42.279999, 69.744266],
          [42.280234, 69.746107],
          [42.280519, 69.748341],
          [42.280813, 69.750596],
          [42.280827, 69.750811],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.279120, 69.737764], // 
          [42.278342, 69.738229], //
          [42.278890, 69.742504],
          [42.279142, 69.744464],
          [42.279659, 69.748547],
          [42.279862, 69.750190],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.278342, 69.738229], // 
          [42.277535, 69.738751], //
          [42.277578, 69.739270],
          [42.278026, 69.742706],
          [42.278286, 69.744695],
          [42.278817, 69.748740],
          [42.278929, 69.749591],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.276929, 69.741579], // 
          [42.277830, 69.748743], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.276180, 69.742010], // 
          [42.276226, 69.742346], //
          [42.276273, 69.743137],
          [42.276539, 69.745113],
          [42.276880, 69.748177],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.274530, 69.743980], // 
          [42.274960, 69.747168], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.273540, 69.744373], // 
          [42.273850, 69.746548], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.275123, 69.748354], // 
          [42.275178, 69.748445], //
          [42.275194, 69.748500],
          [42.275194, 69.748570],
          [42.275215, 69.748650],
          [42.276802, 69.750956],
          [42.277393, 69.751930],
          [42.279359, 69.755187],
          [42.279445, 69.755438],
          [42.279509, 69.755824],
          [42.279615, 69.756764],
          [42.279455, 69.756702],
          [42.278801, 69.756123],
          [42.278564, 69.755815],
          [42.277834, 69.754439],
          [42.277155, 69.753274],
          [42.276238, 69.751813],
          [42.275651, 69.750952],
          [42.275101, 69.750133],
          [42.274600, 69.749410],
          [42.274250, 69.748919],
          [42.273106, 69.747615],
          [42.273011, 69.747743],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.276238, 69.751813], // 
          [42.276368, 69.751662], //
          [42.276815, 69.750985],
          [42.276803, 69.750957],
          [42.277351, 69.750076],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.272782, 69.747507], // 
          [42.272583, 69.747917], //
          [42.272480, 69.748214],
          [42.272380, 69.748684],
          [42.272350, 69.748717],
          [42.272280, 69.748740],
          [42.271973, 69.748740],
          [42.271908, 69.748783],
          [42.271882, 69.748851],
          [42.271843, 69.749514],
          [42.271852, 69.749624],
          [42.271881, 69.749675],
          [42.271950, 69.749711],
          [42.272074, 69.749730],
          [42.272358, 69.749684],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.279278, 69.756917], // 
          [42.279047, 69.756775], //
          [42.278035, 69.756484],
          [42.277731, 69.756387],
          [42.277473, 69.756264],
          [42.277407, 69.756208],
          [42.276889, 69.755625],
          [42.276751, 69.755494],
          [42.276518, 69.755303],
          [42.276233, 69.755116],
          [42.276058, 69.755028],
          [42.275473, 69.754829],
          [42.274699, 69.754445],
          [42.274488, 69.754314],
          [42.274434, 69.753627],
          [42.274424, 69.753269],//
          [42.274405, 69.753124],
          [42.274402, 69.753113],
          [42.274377, 69.753011],
          [42.274325, 69.752912],
          [42.274253, 69.752825],
          [42.274151, 69.752742],
          [42.273884, 69.752618],
          [42.273651, 69.752465],
          [42.273372, 69.752245],
          [42.273052, 69.751974],
          [42.272682, 69.751611],
          [42.272083, 69.750909],
          [42.271327, 69.751416],
          [42.270629, 69.750244],
          [42.270498, 69.749906],////
          [42.270779, 69.748895],
          [42.270595, 69.748765],
          [42.270844, 69.747809],
          [42.271000, 69.747388],
          [42.271389, 69.746510],
          [42.271433, 69.746455],
          [42.271278, 69.746385],
          [42.270962, 69.746300],
          [42.270848, 69.746328],
          [42.270460, 69.746607],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.274488, 69.754314], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.274424, 69.753269], // 
          [42.274707, 69.753166], //
          [42.274837, 69.753099],
          [42.274943, 69.752966],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.274325, 69.752912], // 
          [42.274375, 69.752085], //
          [42.274624, 69.752156],
          [42.274723, 69.752210],
          [42.274994, 69.752421],
          [42.275129, 69.752565],
          [42.275225, 69.752738],
          [42.275426, 69.753026],
          [42.275495, 69.753088],
          [42.275536, 69.753069],
          [42.275605, 69.752947],
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.273372, 69.752245], // 
          [42.273772, 69.751327], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [42.271327, 69.751416], // Ризаева
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
      {
        coordinates: [
          [], // 
          [], //
        ],
        color: '#0000FF',
      },
    ]
  }
];